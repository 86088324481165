<template>
  <v-container fluid style="height: 100%; padding: 0px;">
    <v-row
      style="padding: 0px; height: 100%; margin: 0px;"
    >
      <!-- Listado Localidades -->
      <v-col
        cols="12"
        style="height: 100%; padding-bottom: 0px;"
      >
        <v-card>
          <v-toolbar dense flat >
            <v-toolbar-title><v-icon>mdi-map-marker</v-icon> Localidades</v-toolbar-title>
            <v-spacer></v-spacer>             
            <v-btn rounded color="teal accent-4" dark><v-icon small>mdi-plus-circle-outline</v-icon>&nbsp;Agregar</v-btn>
          </v-toolbar>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line dense
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="configuraciones.localidades"
            :search="search"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="(item,index) in items" :key="index">
                  <td> {{item.provincia}} </td>
                  <td> {{item.localidad}} </td>
                  <td>{{item.departamento}}</td>
                  <td>{{item.codigo_postal}}</td>
                  <td>
                    <v-icon color="primary" @click="editarLocalidad(item)">mdi-square-edit-outline</v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
        <!-- Modal Edit Localidad  -->
        <template>
          <div class="text-xs-top">
            <v-dialog  v-model="dialogLocalidad" max-width="700px">
              <v-card>
                <v-card-title>  
                  <v-toolbar dense flat >
                    <v-toolbar-title><v-icon>mdi-map-marker</v-icon> Editar Localidad</v-toolbar-title>
                  </v-toolbar>
                </v-card-title>      
                <v-card-text>      
                  <v-container fluid style="margin: 0px; padding: 0px; width: 100%">
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="provincia"
                          label="Provincia"
                          placeholder="Provincia"
                          outlined dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="localidad"
                          label="Localidad"
                          placeholder="Localidad"
                          outlined dense
                        ></v-text-field>
                      </v-col>                        
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="departamento"
                          label="Departamento"
                          placeholder="Departamento"
                          outlined dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="codigo_postal"
                          label="Codigo Postal"
                          placeholder="Codigo Postal"
                          outlined dense
                        ></v-text-field>
                      </v-col>
                      <v-col class="d-flex" cols="12" sm="12">
                        <v-switch v-model="entrega_domicilio" class="mx-2" label="Envio a Domicilio"></v-switch>
                        <v-switch v-model="retiro_local" class="mx-2" label="Retiro Sucursal"></v-switch>
                        <v-switch v-model="estado" class="mx-2" label="Habilitado"></v-switch>
                      </v-col>
                    </v-row>
                    <v-col class="text-center" cols="12" sm="12">                         
                      <v-btn small color="primary" @click="modificarLocalidad()">Modificar</v-btn>                        
                    </v-col>
                  </v-container>
                </v-card-text>      
              </v-card>
            </v-dialog>
          </div>
        </template>
        <!-- Modal Peticiones  -->
        <v-dialog v-model="dialogLoading" persistent width="350">
          <v-card color="#ed1841" dark>
            <v-card-title>
              {{ tituloModal }}
            </v-card-title>
            <v-card-text>
              <v-progress-circular
                style="margin-left:125px;"
                :size="50"
                :width="8"
                color="#ffff"
                indeterminate
              ></v-progress-circular>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import router from '../../router'
import {mapActions, mapState} from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
export default {
  data() {
    return {
      search: '',
      headers: [
        { text: 'Provincia', align: 'left',value:'provincia' },
        { text: 'Localidad',align: 'left',value:'localidad'},
        { text: 'Departamento',align: 'left',value:'departamento'},
        { text: 'Codigo Postal' ,value:'codigo_postal'},
        { text: 'Acciones' },
      ],
      dialogLocalidad : false,
      provincia :"", 
      localidad :"",
      departamento :"", 
      codigo_postal :"",
      entrega_domicilio :false,
      retiro_local :false, 
      estado : false,
      index : "",
      snackBar : false,
      snackBarText : '',
      snackBarColor : '',
      art :"",
      dialogLoading : false,
      tituloModal : "",
      checkbox : false,
      configuraciones: {}
    }
  },
  components: {

  },
  created () {
    this.usuarioLogueado()
  },
  computed: {
    ...mapState(['respContactoAdmin', 'respLocalidadesAdmin']),
  },
  methods: {
    ...mapActions(['getInfoContacto', 'updateLocalidad']),
    usuarioLogueado(){
      if (localStorage.getItem('tokenAdmin')){
        // prosigo con la carga
        this.getLocalidades()
      }else{
        // redirecciono al Login
        router.push({name: 'LoginAdmin', params: {sesionExpirada: 1}})
      }
    },
    async getLocalidades(){
      await this.getInfoContacto()
    },
    editarLocalidad(item){
      this.index = this.configuraciones.localidades.indexOf(item)

      if(parseInt(item.retiro_local) == 0){
        this.retiro_local = false
      }else{
        this.retiro_local = true
      }
      
      if(parseInt(item.entrega_domicilio) == 0){
        this.entrega_domicilio = false
      }else{
        this.entrega_domicilio = true
      }

      if(parseInt(item.estado) == 0){
        this.estado = true
      }else{
        this.estado = false
      }
      this.provincia = item.provincia
      this.localidad = item.localidad
      this.departamento = item.departamento
      this.codigo_postal = item.codigo_postal      
      this.dialogLocalidad = true
    },
    modificarLocalidad(){
      Swal.fire({
        title: 'Modificar Localidad',
        text: '¿Confirma guardar los cambios realizados?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.tituloModal = 'Modificando Localidad...'
          this.dialogLoading  = true
          let envio = 0
          if(this.entrega_domicilio == true ){
            envio = 1
          } 
          let retiro = 0
          if(this.retiro_local == true ){
            retiro = 1
          } 
          let estado_loc = 1
          if(this.estado == true ){ 
            estado_loc = 0
          } 
          let data = {
            provincia : this.provincia,
            localidad : this.localidad,
            departamento : this.departamento,
            codigo_postal : this.codigo_postal,
            entrega_domicilio : envio,
            retiro_local : retiro,
            estado : estado_loc,
            index : this.index
          }
          this.updateLocalidad(data)
        }
      })
    }
  },
  watch: {
    respContactoAdmin: function(){
      if (Object.keys(this.respContactoAdmin).length > 0){
        if (parseInt(this.respContactoAdmin.resultado) === 1){
          this.configuraciones = this.respContactoAdmin.contacto
        }
      }
    },
    respLocalidadesAdmin: function(){
      if (Object.keys(this.respLocalidadesAdmin).length > 0){
        this.dialogLoading  = false
        if (parseInt(this.respLocalidadesAdmin.resultado) === 1){
          Swal.fire(
            'Modificar Localidad', 
            'Localidad modificada exitosamente', 
            'success'
          )
        }else{
          Swal.fire(
            'Subiendo Imagenes', 
            this.respLocalidadesAdmin.msj, 
            'error'
          )
        }
        this.dialogLocalidad = false
      }
    }
  },
}
</script>

<style>
  /* .v-data-table-header{
    background-color:#D8D8D8;
  } */
  .img_tam{
    width: 150px;
  }
</style>