<template>
  <v-dialog
    v-model="visible"
    width="500px"
    persistent
  >
    <v-card 
			flat
			width="500px"
		>
			<v-card-title>Buscando Código Postal</v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12">
						<v-autocomplete
							v-model="selectedProvincia"
							:items="provincias"
							:rules="Rules"
							return-object
							clearable
							outlined dense
							label="Provincia"
							item-text="provincia_nombre"
							placeholder="Seleccione una Provincia"
							@change="buscarLocalidades()"
						></v-autocomplete>
					</v-col>
					<v-col cols="12">
						<v-autocomplete
							v-model="selectedLocalidad"
							:items="localidades"
							:rules="Rules"
							return-object
							clearable
							outlined dense
							label="Ciudad"
							item-text="localidad_nombre"
							placeholder="Seleccione su Ciudad"
						></v-autocomplete>
					</v-col>
          <v-col cols="12" v-if="errorDomicilioEntrega">
            <v-alert dense text type="info">
							{{ msjErrorDomEntrega }}
						</v-alert>
          </v-col>
				</v-row>
				<v-row align="center" justify="center">
					<v-btn color="success" @click="setUbicacionEntrega()" style="margin: 5px">
						Seleccionar Localidad
					</v-btn>
					<v-btn color="warning" @click="visible = false" style="margin: 5px">
						Volver
					</v-btn>
				</v-row>
			</v-card-text>
		</v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapState} from 'vuex'
export default {
  props: {
    activo: Boolean
  },
  data() {
    return {
      visible: false,
      selectedProvincia: {},
      provincias: [],
      selectedLocalidad: {},
      localidades: [],
      errorDomicilioEntrega: false,
      msjErrorDomEntrega: '',
      Rules: [
        v => !!v || "Campo requerido.",
			]
    }
  },
  computed: {
    ...mapState(['respProvincias', 'respLocalidades']),
  },
  created () {
    //this.obtenerProvincias()
  },
  methods: {
    ...mapActions(['getProvincias', 'getLocalidades', 'showLoading', 'closeLoading']),
    async obtenerProvincias(){
      await this.getProvincias()
    },
    async buscarLocalidades(){
      if (Object.keys(this.selectedProvincia).length > 0){
        this.showLoading()
        await this.getLocalidades(this.selectedProvincia)
      }
    },
    setUbicacionEntrega(){
      // lleno los campos?
      if (Object.keys(this.selectedProvincia).length == 0 || Object.keys(this.selectedLocalidad).length == 0){
        this.msjErrorDomEntrega = 'Debe completar todos los Datos para proseguir'
        this.errorDomicilioEntrega = true
        return
      }
      this.errorDomicilioEntrega = false
      this.$emit('setCodigoPostal', {localidad: this.selectedLocalidad, provincia: this.selectedProvincia})
      this.visible = false
    }
  },
  watch: {
    activo: function(){
      this.visible = this.activo
    },
    visible: function(){
      if (this.visible == false){
        this.$emit('closeModalCP')
      }
    },
    respProvincias: function(){
      if (Object.keys(this.respProvincias).length > 0){
        if (parseInt(this.respProvincias.resultado) === 1){
          this.provincias = this.respProvincias.provincias.provincias
        }else{
          console.log(this.respProvincias.msj)
          this.msjErrorDomEntrega = 'El servicio Búsqueda de Códigos Postales no se encuentra disponible por el momento'
          this.errorDomicilioEntrega = true
          this.selectedProvincia = {}
          this.selectedLocalidad = {}
        }
      }
    },
    respLocalidades: function(){
      this.closeLoading()
      if (Object.keys(this.respLocalidades).length > 0){
        if (parseInt(this.respLocalidades.resultado) === 1){
          this.localidades = this.respLocalidades.localidades.localidades
        }else{
          console.log(this.respLocalidades.msj)
          this.msjErrorDomEntrega = 'El servicio Búsqueda de Códigos Postales no se encuentra disponible por el momento'
          this.errorDomicilioEntrega = true
          this.selectedProvincia = {}
          this.selectedLocalidad = {}
        }
      }
    }
  },
}
</script>

<style>

</style>