<template>
  <v-container fluid>
    <v-row>
      <!-- Filtros -->
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-card raised flat>
          <!-- Titulo Filtros con sus botones para ocultar o mostrar -->
          <v-toolbar color="accent" dark height="40%" flat>
            <v-toolbar-title style="font-size:19px" class="formatoPalabras black--text">
              Filtros
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              default
              icon
              small
              color="secondary"
              @click="switch1 = false"
              v-if="switch1"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            <v-btn
              default
              icon
              small
              color="secondary"
              @click="switch1 = true"
              v-if="!switch1"
            >
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </v-toolbar>
          <!-- Filtros Categorias -->
          <v-card-text v-if="switch1">
            <v-container>
              <v-row>
                <v-col cols="5" class="pa-0 ma-0">
                  <div class="black--text formatoPalabras pt-3">Ordenar por:</div>
                </v-col>
                <v-col cols="7" class="pa-0 ma-0">
                  <v-select
                    :items="filtrosOrden"
                    solo
                    flat
                    label="Orden"
                    v-model="selectedFiltroOrden"
                    item-text="nombre"
                    return-object
                    class="formatoPalabras"
                    style="font-size: 15px"
                    @change="ordenarListado()"
                  ></v-select>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <br/>
              <v-row
                align="center"
                class="fill-height"
                v-for="(filtro, index) in filtrosCategoria" :key="index"
              >
                <br/>
                <br/>
                <v-col cols="12" style="height: 5px; margin-left: 0px;">
                  <div class="black--text formatoPalabras">
                    <h3>
                      {{ filtro.nombre }}
                    </h3>
                  </div>
                </v-col>
                <v-col cols="12" v-for="(check, id) in filtro.subFiltros" :key="id" style="height: 5px; margin-top: auto;">
                  <v-row style="height: auto; margin-top: -10px; margin-left: 0px;">
                    <v-checkbox
                      v-model="selectedFiltros"
                      :label="check.valor_det"
                      :value="check"
                      class="my-checkbox formatoPalabras"
                      dense
                    ></v-checkbox>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Vusualizacion Articulos -->
      <v-col cols="12" sm="12" md="9" lg="9">
        <!-- Listado Articulos -->
        <v-data-iterator
          :items="articulosPaginados"
          :search="search"
          :items-per-page="tamanioPaginado"
          :hide-default-footer="mostrarPaginado"
        >
          <!-- Filtro del Listado -->
          <!-- <template v-slot:header>
            <v-toolbar
              dark
              color="primary"
              class="mb-1"
              height="50%"
            >
              <v-text-field
                v-model="search"
                clearable
                default
                dense
                solo-inverted
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Ingrese al menos 3 letras"
                class="formatoPalabras"
              ></v-text-field>
              <template v-if="$vuetify.breakpoint.mdAndUp">
                <v-spacer></v-spacer>
                <v-btn-toggle v-model="sortDesc" mandatory>
                  <v-btn
                    small
                    depressed
                    color="primary"
                    :value="false"
                  >
                    <v-icon>mdi-sort-alphabetical-descending</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    depressed
                    color="primary"
                    :value="true"
                  >
                    <v-icon>mdi-sort-alphabetical-ascending</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </template>
            </v-toolbar>
          </template> -->
          <!-- Contenido del Listado -->
          <template v-slot:default="props">
            <v-row dense>
              <v-col
                v-for="card in props.items"
                :key="card.articuloNombre"
                cols="12" xs="12" sm="3" xl="3" md="3" lg="3"
              >
                <UnArticuloCarrusel 
                  :datosArticulo="card" 
                  @asignarAlCarrito="agregarAlCarrito"
                />
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>
    <!-- Boton Flotante Ir a Inicio de Pagina -->
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      color="primary"
      @click="toTop"
    >
      <v-icon>mdi-arrow-up-bold</v-icon>
    </v-btn>
    <!-- Loading -->
    <LoadingCircular 
      :active.sync="loadingCarga" 
      :can-cancel="true"   
      color="#ed1841"         
      oader="dots"
      background-color="#252527"
      :is-full-page="true">
    </LoadingCircular>
  </v-container>
</template>

<script>
import UnArticuloCarrusel from '../index/UnArticuloCarrusel'
import {mapActions, mapState} from 'vuex'
import LoadingCircular from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
export default {
  props: {
    articulos: Array,
    filtros: Object,
    ordenFiltros: Array
  },
  components:{
    UnArticuloCarrusel,
    LoadingCircular
  },
  data(){
    return {
      fab: false,
      bottom: false,
      switch1: true,
      beers: [],
      indiceActual: 0,
      articulosPaginados: [],
      search: '',
      sortDesc: false,
      tamanioPaginado: 0,
      mostrarPaginado: true,
      filtrosCategoria: [],
      filtrosSubCategoria: [],
      filtrosMarca: [],
      selectedFiltros: [],
      cargaSubCategorias: 0,
      movil: false,
      selectedFiltroOrden: {},
      filtrosOrden: [],
      inicioFormulario: 0
    }
  },
  created () {
    this.isMovil()
    window.addEventListener('scroll', () => {
      this.bottom = this.bottomVisible()
    })
  },
  computed: {
    ...mapState(['articulosCarrito', 'loadingCarga', 'datosUsuario'])
  },
  methods: {
    ...mapActions(['pushArtCarrito', 'loadCarrito']),
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    bottomVisible() {
      const scrollY = window.scrollY
      const visible = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight - 200
      const bottomOfPage = visible + scrollY >= pageHeight
      return bottomOfPage || pageHeight < visible
    },
    async iniciarCarrito(){
      await this.loadCarrito()
    },
    agregarArticulos(cant, listadoArticulos){
      // mostramos de a 8 articulos
      let cont = 0
      while ((parseInt(this.indiceActual) <= listadoArticulos.length - 1) && cont <= cant){
        if (this.articulosPaginados.indexOf(listadoArticulos[this.indiceActual]) === -1){
          this.articulosPaginados.push(listadoArticulos[this.indiceActual])
          cont = cont + 1
        }
        this.indiceActual = this.indiceActual + 1
      }
    },
    containsObject(obj, list) {
      var i;
      for (i = 0; i < list.length; i++) {
        if (list[i] === obj) {
          return true;
        }
      }

      return false;
    },
    agregarSubCategorias(listadoArticulos){
      // necesito armar las subcategorias de las categorias que estoy recibiendo en filtros
      this.filtrosSubCategoria = []
      // ya tengo la marca agregada?
      let contMarca = 0
      let posMarca = 0
      for (let indice in this.filtrosCategoria){
        if (this.filtrosCategoria[indice].nombre.toUpperCase() == 'MARCA'){
          contMarca = contMarca + 1
          posMarca = indice
        }
      }
      if (parseInt(contMarca) === 0){
        this.filtrosCategoria.push({nombre: 'Marca', subFiltros: []})
      }else{
        if (!this.filtrosCategoria[posMarca].hasOwnProperty('subFiltros')){
          this.filtrosCategoria[posMarca].subFiltros = []
        }
      }
      // la marca esta en la posicion 0?
      if (parseInt(posMarca) !== 0){
        this.filtrosCategoria = this.reordenarFiltros(this.filtrosCategoria, posMarca, 0)
      }
      // recorro mis filtros principales para empezar a comparar
      for (let id in this.filtrosCategoria){
        // por cada uno, recorro mi array de articulos para ver si tengo caracteristicas que se correspondan
        for (let jd in listadoArticulos){
          // ahora pregunto si el filtro coincide con alguna caracteristica del articulo
          // las caracteristicas es otro array, ojo, puede o no tener caracteristicas
          if (Array.isArray(listadoArticulos[jd].articuloCaracteristicas)){
            // es array
            let listado = listadoArticulos[jd].articuloCaracteristicas.filter(element => element.campo_det == this.filtrosCategoria[id].nombre)
            if (listado.length > 0){
              // pregunto si el elemento que estoy por agregar ya existe en el array
              for (let i in listado){
                let cont = 0
                if (this.filtrosSubCategoria.length > 0){
                  for (let j in this.filtrosSubCategoria){
                    if (this.filtrosSubCategoria[j].campo_det.toUpperCase() == listado[i].campo_det.toUpperCase()){
                      if (this.filtrosSubCategoria[j].valor_det == listado[i].valor_det){
                        cont = cont + 1
                      }
                    }
                  }
                  if (parseInt(cont) === 0){
                    this.filtrosSubCategoria.push(listado[i])
                  }
                }else{
                  this.filtrosSubCategoria.push(listado[i])
                }
              }
            }
          }
        }
      }
      // procedo a agregarla a los filtros
      this.addSubCategorias()
    },
    addSubCategorias(){
      let posMarca = 0
      // procedo a agregar mis subcategorias a mis filtros
      for (let id in this.filtrosCategoria){
        // asigno un nuevo campo como array siempre y cuando no sea la Marca, porque lo asigno con anterioridad
        if (this.filtrosCategoria[id].nombre.toUpperCase() !== 'MARCA'){
          this.filtrosCategoria[id].subFiltros = []
        }else{
          // guardo la posicion del objeto que contiene la Marca
          posMarca = id
        }
        // recorro mis subcategorias
        for (let jd in this.filtrosSubCategoria){
          // pregunto si mi filtro coincide con el nombre de la subcategoria
          if (this.filtrosCategoria[id].nombre.toUpperCase() == this.filtrosSubCategoria[jd].campo_det.toUpperCase()){
            // la subcategoria que estoy por agregar ya existe en mi array de subFiltros?
            if (this.filtrosCategoria[id].subFiltros.length > 0){
              let cont = 0
              for (let i in this.filtrosCategoria[id].subFiltros){
                if (this.filtrosCategoria[id].subFiltros[i].valor_det == this.filtrosSubCategoria[jd].valor_det){
                  cont = cont + 1
                }
              }
              if (parseInt(cont) === 0){
                this.filtrosCategoria[id].subFiltros.push(this.filtrosSubCategoria[jd])
              }
            }else{
              this.filtrosCategoria[id].subFiltros.push(this.filtrosSubCategoria[jd])
            }
          }
        }
      }
      // procedo a poner la marca primero
      if (parseInt(posMarca) !== 0){
        this.filtrosCategoria = this.reordenarFiltros(this.filtrosCategoria, posMarca, 0)
      }
      // ahora agrego los subfiltros que le falta a Marca
      this.addSubCategoriasMarca()
    },
    addSubCategoriasMarca(){
      for (let id in this.articulos){
        let fil = {
          campo_det: 'Marca',
          valor_det: this.articulos[id].articuloMarca
        }
        let cont = 0
        if (this.filtrosCategoria[0].subFiltros.length > 0){
          for (let jd in this.filtrosCategoria[0].subFiltros){
            if (this.filtrosCategoria[0].subFiltros[jd].valor_det == fil.valor_det){
              cont = cont + 1
            }
          }
          if (parseInt(cont) === 0){
            this.filtrosCategoria[0].subFiltros.push(fil)
          }
        }else{
          this.filtrosCategoria[0].subFiltros.push(fil)
        }
      }
    },
    reordenarFiltros(arr, old_index, new_index){
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    },
    recalcularFiltros(){
      // primero verifico si tengo alguna marca seleccionada
      let tengoMarca = this.selectedFiltros.filter(element => element.campo_det.toUpperCase() == 'MARCA')
      if (tengoMarca.length > 0){
        // recalculo filtros, la marca condiciona los demas filtros
        let listado = []
        for (let id in tengoMarca){
          // tomo cada marca y solo dejo los filtros que corresponden a esa marca
          for (let jd in this.articulos){
            // pregunto si coincide la marca
            if (tengoMarca[id].valor_det.toUpperCase() == this.articulos[jd].articuloMarca.toUpperCase()){
              listado.push(this.articulos[jd])
            }
          }
          this.agregarSubCategorias(listado)
        }
      }else{
        // dejo los filtros a como estaban originalmente, todos
        this.agregarSubCategorias(this.articulos)
      }
    },
    aplicarFiltros(){
      let listado = []
      // tengo marcas seleccionadas?
      let tengoMarca = this.selectedFiltros.filter(element => element.campo_det.toUpperCase() == 'MARCA')
      let sinMarca = this.selectedFiltros.filter(element => element.campo_det.toUpperCase() != 'MARCA')
      if (tengoMarca.length > 0){
        // filtro los articulos que tienen esta marca primero
        for (let id in tengoMarca){
          // ahora recorro mi listado de articulos
          for (let jd in this.articulos){
            // comparo directamente por la marca
            if (tengoMarca[id].valor_det.toUpperCase() == this.articulos[jd].articuloMarca.toUpperCase()){
              if (listado.indexOf(this.articulos[jd]) === -1){
                listado.push(this.articulos[jd])
              }
            }
            // comparo por las caracteristicas, si vino la marca deberia tenerla en cuenta, es array las caracteristicas?
            if (Array.isArray(this.articulos[jd].articuloCaracteristicas)){
              // es array, lo recorro
              for (let i in this.articulos[jd].articuloCaracteristicas){
                if (this.articulos[jd].articuloCaracteristicas[i].campo_det.toUpperCase() == 'MARCA'){
                  if (this.articulos[jd].articuloCaracteristicas[i].valor_det.toUpperCase() == tengoMarca[id].valor_det.toUpperCase()){
                    if (listado.indexOf(this.articulos[jd]) === -1){
                      listado.push(this.articulos[jd])
                    }
                  }
                }
              }
            }
          }
        }
        // en este punto, mi nuevo listado de articulos pasa a ser listado, de ahi aplico el resto de los filtros
        if (sinMarca.length > 0){
          let listadoo = []
          // aplico los filtros adicionales
          for (let id in sinMarca){
            // recorro mi nuevo listado
            for (let jd in listado){
              // tengo un array en caracteristicas?
              if (Array.isArray(listado[jd].articuloCaracteristicas)){
                for (let i in listado[jd].articuloCaracteristicas){
                  // comparo campo y valor
                  if (sinMarca[id].campo_det.toUpperCase() == listado[jd].articuloCaracteristicas[i].campo_det.toUpperCase()){
                    if (sinMarca[id].valor_det.toUpperCase() == listado[jd].articuloCaracteristicas[i].valor_det.toUpperCase()){
                      listadoo.push(listado[jd])
                    }
                  }
                }
              }
            }
          }
          this.agregarArticulos(listadoo.length, listadoo)
        }else{
          // listo, aplico directamente mi array filtrado con marcas
          this.agregarArticulos(listado.length, listado)
        }
      }else{
        // sin marca seleccionada?
        if (sinMarca.length > 0){
          // aplico los filtros adicionales
          for (let id in sinMarca){
            // recorro mi nuevo listado
            for (let jd in this.articulos){
              // tengo un array en caracteristicas?
              if (Array.isArray(this.articulos[jd].articuloCaracteristicas)){
                for (let i in this.articulos[jd].articuloCaracteristicas){
                  // comparo campo y valor
                  if (sinMarca[id].campo_det.toUpperCase() == this.articulos[jd].articuloCaracteristicas[i].campo_det.toUpperCase()){
                    if (sinMarca[id].valor_det.toUpperCase() == this.articulos[jd].articuloCaracteristicas[i].valor_det.toUpperCase()){
                      listado.push(this.articulos[jd])
                    }
                  }
                }
              }
            }
          }
          this.agregarArticulos(listado.length, listado)
        }else{
          this.agregarArticulos(7, this.articulos)
        }
      }
      // cuando termino, ejecuto el ordenar
      this.ordenarListado()
    },
    isMovil(){
			var mobile = {
        Android: function() {
          return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
          return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
          return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
          return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
          return (mobile.Android() || mobile.BlackBerry() || mobile.iOS() || mobile.Opera() || mobile.Windows());
        }
      }
      // controlo la salida de mobile
      if (Array.isArray(mobile.any())){
        if (mobile.any().length > 0){
          this.switch1 = false
          this.movil = true
        }
      }
    },
    async agregarAlCarrito(articuloSeleccionado){
      // articuloSeleccionado debe ser un objeto
      if (Object.keys(articuloSeleccionado).length > 0){
        // ya se encuentra en el carrito? las cantidades son editables, por eso no busco exactamente el objeto
        if (this.articulosCarrito.length > 0){
          let cont = 0
          for (let id in this.articulosCarrito){
            if (this.articulosCarrito[id].articuloKey == articuloSeleccionado.articuloKey){
              cont = cont + 1
            }
          }
          if (parseInt(cont) === 0){
            this.$store.dispatch('executeSnackBar', {msj: 'Artículo Agregado al Carrito', color: 'success', activo: true})
            await this.pushArtCarrito(articuloSeleccionado)
          }else{
            // ya se encuentra en el carrito
            this.$store.dispatch('executeSnackBar', {msj: 'El Artículo seleccionado ya se encuentra en el Carrito', color: 'warning', activo: true})
          }
        }else{
          this.$store.dispatch('executeSnackBar', {msj: 'Artículo Agregado al Carrito', color: 'success', activo: true})
          await this.pushArtCarrito(articuloSeleccionado)
        }
      }
    },
    ordenarListado(){
      if (typeof this.selectedFiltroOrden == 'object' && this.selectedFiltroOrden != null && this.selectedFiltroOrden != undefined){
        if (Object.keys(this.selectedFiltroOrden).length > 0){
          // aplicaron filtro de orden, veo que criterio usaron
          if (parseInt(this.selectedFiltroOrden.codigo) == 1){
            // ordenan de menor a mayor, pero aplicaron filtros?
            if (this.selectedFiltros.length > 0){
              this.articulosPaginados.sort(function(a,b){
                if (parseInt(a.articuloPrecio) < parseInt(b.articuloPrecio)) {
                  return -1;
                }
                if (parseInt(a.articuloPrecio) > parseInt(b.articuloPrecio)) {
                  return 1;
                }
                // a must be equal to b
                return 0;
              })
            }else{
              this.articulos.sort(function(a,b){
                if (parseInt(a.articuloPrecio) < parseInt(b.articuloPrecio)) {
                  return -1;
                }
                if (parseInt(a.articuloPrecio) > parseInt(b.articuloPrecio)) {
                  return 1;
                }
                // a must be equal to b
                return 0;
              })
            }
          }else if (parseInt(this.selectedFiltroOrden.codigo) == 2){
            // ordenan de mayor a menor, pero aplicaron filtros?
            if (this.selectedFiltros.length > 0){
              this.articulosPaginados.sort(function(a,b){
                if (parseInt(a.articuloPrecio) > parseInt(b.articuloPrecio)) {
                  return -1;
                }
                if (parseInt(a.articuloPrecio) < parseInt(b.articuloPrecio)) {
                  return 1;
                }
                // a must be equal to b
                return 0;
              })
            }else{
              this.articulos.sort(function(a,b){
                if (parseInt(a.articuloPrecio) > parseInt(b.articuloPrecio)) {
                  return -1;
                }
                if (parseInt(a.articuloPrecio) < parseInt(b.articuloPrecio)) {
                  return 1;
                }
                // a must be equal to b
                return 0;
              })
            }
          }else{
            // ordenan por mayor descuento, pero aplicaron filtros?
            if (this.selectedFiltros.length > 0){
              this.articulosPaginados.sort(function(a,b){
                if (parseInt(a.articuloDescuento) > parseInt(b.articuloDescuento)) {
                  return -1;
                }
                if (parseInt(a.articuloDescuento) < parseInt(b.articuloDescuento)) {
                  return 1;
                }
                // a must be equal to b
                return 0;
              })
            }else{
              this.articulos.sort(function(a,b){
                if (parseInt(a.articuloDescuento) > parseInt(b.articuloDescuento)) {
                  return -1;
                }
                if (parseInt(a.articuloDescuento) < parseInt(b.articuloDescuento)) {
                  return 1;
                }
                // a must be equal to b
                return 0;
              })
            }
          }
          if (this.selectedFiltros.length > 0){
            this.indiceActual = 0
            let listado = this.articulosPaginados
            this.articulosPaginados = []
            this.agregarArticulos(1000, listado)
          }else{
            this.indiceActual = 0
            this.articulosPaginados = []
            this.agregarArticulos(1000, this.articulos)
          }
        }
      }
    }
  },
  watch: {
    articulos: async function(){
      if (this.articulos.length > 0 && Object.keys(this.selectedFiltroOrden).length == 0 && this.inicioFormulario == 0){
        this.inicioFormulario = 1
        this.tamanioPaginado = this.articulos.length
        this.agregarArticulos(7, this.articulos)
      }
      if ((this.filtrosCategoria.length > 0) && parseInt(this.cargaSubCategorias) === 1 && this.articulos.length > 0){
        this.cargaSubCategorias = 0
        this.agregarSubCategorias(this.articulos)
      }
      await this.iniciarCarrito()
    },
    bottom(bottom){
      if (bottom && this.selectedFiltros.length === 0) {
        this.agregarArticulos(7, this.articulos)
      }
    },
    search: function(){
      if (this.search.length >= 3){
        this.indiceActual = 0
        this.articulosPaginados = []
        this.agregarArticulos(1000, this.articulos)
      }else{
        this.indiceActual = 0
        this.articulosPaginados = []
        this.agregarArticulos(7, this.articulos)
      }
    },
    filtros: function(){
      if (Object.keys(this.filtros).length > 0){
        // pregunto si vino un array en filtros
        if (Array.isArray(this.filtros.filtros)){
          // es array, tengo mis filtros definidos
          for (let i in this.filtros.filtros){
            let fil = {
              nombre: this.filtros.filtros[i]
            }
            this.filtrosCategoria.push(fil)
          }
        }else{
          // sin filtros definidos, agrego la marca manualmente
          this.filtrosCategoria.push({nombre: 'Marca', subFiltros: []})
        }
        //this.filtrosCategoria.push({nombre: 'Marca'})
        this.cargaSubCategorias = 1
      }
    },
    ordenFiltros: function(){
      if (this.ordenFiltros.length > 0){
        this.filtrosOrden = this.ordenFiltros
        // asigno un filtro por defecto
        this.selectedFiltroOrden = this.filtrosOrden.filter(element => parseInt(element.codigo) == 1)[0]
        this.ordenarListado()
      }
    },
    filtrosCategoria: function(){
      if ((this.filtrosCategoria.length > 0) && parseInt(this.cargaSubCategorias) === 1 && this.articulos.length > 0){
        this.cargaSubCategorias = 0
        this.agregarSubCategorias(this.articulos)
      }
    },
    selectedFiltros: function(){
      this.recalcularFiltros()
      if (this.selectedFiltros.length > 0){
        this.indiceActual = 0
        this.articulosPaginados = []
        this.aplicarFiltros()
      }else{
        this.indiceActual = 0
        this.articulosPaginados = []
        this.agregarArticulos(7, this.articulos)
      }
    },
    datosUsuario: function(){
      if (Object.keys(this.datosUsuario).length > 0){
        if (this.datosUsuario.hasOwnProperty('resultado')){
          if (parseInt(this.datosUsuario.resultado) === 1){
            // es nuevo registro o login normal?
            if (parseInt(this.datosUsuario.tipo) === 1){
              // login normal
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Inicio de Sesión',
                text: 'Su Inicio de Sesión fue exitoso, espere a ser redireccionado',
                showConfirmButton: false,
                timer: 3000
              })
            }else if (parseInt(this.datosUsuario.tipo) === 2){
              // registro
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Nuevo Registro',
                text: 'Su registro fue exitoso, espere a ser redireccionado',
                showConfirmButton: false,
                timer: 3000
              })
            }else{
              // hizo logout
              setTimeout(() => [location.reload()], 2000)
            }
          }else{
            if (parseInt(this.datosUsuario.tipo) === 1){
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Inicio de Sesión',
                text: this.datosUsuario.msj,
                showConfirmButton: false,
                timer: 3000
              })
            }else if (parseInt(this.datosUsuario.tipo) === 2){
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Nuevo Registro',
                text: this.datosUsuario.msj,
                showConfirmButton: false,
                timer: 3000
              })
            }else{
              // hizo logout
              setTimeout(() => [location.reload()], 2000)
            }
          }
        }
      }
    },
    selectedFiltroOrden: function(){
      if (this.selectedFiltroOrden == null || this.selectedFiltroOrden == undefined){
        // quitaron el filtro, ordeno alfabeticamente y pagino nuevamente
        this.selectedFiltroOrden = {}
        this.indiceActual = 0
        this.articulosPaginados = []
        this.tamanioPaginado = this.articulos.length
        this.articulos.sort(function(a,b){
          if (a.articuloNombre < b.articuloNombre) {
            return -1;
          }
          if (a.articuloNombre > b.articuloNombre) {
            return 1;
          }
          // a must be equal to b
          return 0;
        })
        this.agregarArticulos(7, this.articulos)
      }
    }
  },
}
</script>

<style scoped>
  ::v-deep .my-checkbox .v-label{
    font-size: 12px;
  }
  .formatoPalabras{
    font-family: 'Poppins', sans-serif;
  }
</style>