<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="50%"
    >
      <v-card
        flat
      >
        <v-card-title>
          <v-icon class="ma-2">mdi-cart-minus</v-icon> Datos de la Venta
          <v-spacer></v-spacer>
          <v-btn icon @click="activo = false" title="Cerrar"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="pt-2">
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" class="py-2">
              <v-data-table
                class="cebra elevation-2 mt-2"
                :headers="headers"
                :items="detalles"
                dense
                :search="search"
              >
                <!-- Buscador en el datatable -->
                <template v-slot:top>
                  <v-row class="d-flex justify-end pa-2" no-gutters>
                    <v-col cols="6" sm="6">
                      <SearchDataTable
                        v-model="search"
                      />
                    </v-col>
                  </v-row>
                </template>
                <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                <template
                  v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                  v-slot:[`item.${header.value}`]="{ value }"
                >
                  {{ header.formatter(value) }}
                </template>
                <!-- Msj que se mostrara si no existen resultados -->
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    max-width="400"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    Sin Datos para mostrar.
                  </v-alert>
                </template>
                <!-- ultima row para el total -->
                <template slot="body.append">
                  <tr class="font-weight-bold">
                    <th></th>
                    <th class="text-center">Total</th>
                    <th class="text-center">{{ totalCantidad }}</th>
                    <th class="text-center">{{ formatMoney(totalFinal) }}</th>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SearchDataTable from '../../genericos/SearchDataTable.vue'
import { format_money } from '../../../util/util'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    SearchDataTable,
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalVerVenta', value)
      }
    },
    detalles: {
      get(){
        return this.datos.detalles
      }
    }
  },
  data() {
    return {
      formatMoney: format_money,
      headers: [
        { text: 'Código', align: 'center', value: 'codigo_bb' },
        { text: 'Nombre', align: 'center', value: 'nombre' },
        { text: 'Cantidad', align: 'center', value: 'cantidad' },
        { text: 'Precio', align: 'center', value: 'precio', formatter: format_money }
      ],
      search: '',
      totalCantidad: 0,
      totalFinal: 0
    }
  },
  methods: {
    initModal(){
      if (this.detalles.length > 0){
        // obtengo la cantidad
        this.totalCantidad = this.detalles.reduce((sum, fin) => sum + fin.cantidad, 0)
        // obtengo el importe total
        this.totalFinal = this.detalles.reduce((sum, fin) => sum + fin.precio, 0)
      }
    },
    clearModal(){
      this.search = ''
      this.totalCantidad = 0
      this.totalFinal = 0
    }
  },
  watch: {
    activo: function(){
      if (this.activo == true) this.initModal()
      else this.clearModal()
    }
  },
}
</script>

<style>

</style>