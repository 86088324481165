<template>
  <v-container fluid style="height: 100%; padding: 0px;">
    <v-row
      style="padding: 0px; height: 100%; margin: 0px;"
    >
      <!-- Listado de Menus -->
      <v-col
        cols="12"
        style="height: 100%; padding-bottom: 0px;"
      >
        <v-card>
          <v-toolbar dense flat >
            <v-toolbar-title>
              <v-icon>mdi-menu-open</v-icon> Administador de Menus
            </v-toolbar-title>
            <v-spacer></v-spacer>       
            <v-btn rounded color="teal accent-4" dark @click="nuevoMenuPadre()"><v-icon small>mdi-plus-circle-outline</v-icon>&nbsp;Agregar</v-btn>
          </v-toolbar>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line dense
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="menus"
            :search="search"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="(item,index) in items" :key="index">
                  <td class="text-center"> {{item.nombre}} </td>
                  <td class="text-center">
                    <v-chip small :color="getColor(item.inhabilitado)" dark>{{getEstadoName(item.inhabilitado)}}</v-chip>             
                  </td>
                  <td class="text-center">
                    <v-icon color="primary" title="Editar" @click="editarMenu(item)">mdi-square-edit-outline</v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- Modal Nuevo/Modificar Menu -->
    <v-dialog
      v-model="dialogMenu"
      max-width="900px"
    >
      <v-card
        flat
      >
        <v-card-title>
          <v-toolbar flat>
            <v-toolbar-title v-if="nuevoMenu == false">
              <v-icon>mdi-square-edit-outline</v-icon> Modificar Menu <strong>{{ tituloDialogModMenu }}</strong>
            </v-toolbar-title>
            <v-toolbar-title v-else>
              <v-icon>mdi-square-edit-outline</v-icon> Agregar Nuevo Menu
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-row>
            <!-- Nombre -->
            <v-col cols="8" class="pb-0">
              <v-text-field
                v-model="menuNombre"
                label="Nombre Menu"
                placeholder="Nombre Menu"
                outlined dense
                @keyup.enter="confirmarMenuEcommerce()"
              ></v-text-field>
            </v-col>
            <!-- Orden -->
            <v-col cols="2" class="pb-0">
              <v-text-field
                v-model="menuOrden"
                label="Orden Menu"
                placeholder="Orden Menu"
                outlined dense
              ></v-text-field>
            </v-col>
            <!-- Estado -->
            <v-col cols="2" class="pb-0">
              <v-switch style="margin-top:4px" v-model="menuActivo" class="mx-2" label="Activo"></v-switch>
            </v-col>
            <!-- Tipo de Menu -->
            <v-col :cols="esPadre == true ? 6 : 4" v-if="permiteSubMenus == true">
              <v-autocomplete
                v-model="selectedTipoMenuu"
                :items="tipoMenus"
                outlined dense
                item-text="nombre"
                label="Tipo de Menu"
                placeholder="Seleccione un Tipo"
                return-object
                @change="controlarPadre()"
                :disabled="nuevoMenu == true"
              ></v-autocomplete>
            </v-col>
            <!-- Seleccion de Padre -->
            <v-col cols="4" v-if="permiteSubMenus == true && esPadre == false">
              <v-autocomplete
                v-model="selectedPadre"
                :items="menusPadres"
                outlined dense
                item-text="nombre"
                label="Padre"
                placeholder="Seleccione un Menú Padre"
                return-object
              ></v-autocomplete>
            </v-col>
            <!-- Categoria -->
            <v-col :cols="esPadre == true ? 6 : 4" v-if="permiteSubMenus == true">
              <v-autocomplete
                v-model="selectedCategoriaa"
                :items="listadoCategorias"
                outlined dense
                item-text="nombre"
                label="Categoria"
                placeholder="Seleccione una Categoria"
                return-object
              ></v-autocomplete>
            </v-col>
            <!-- Sub Menus -->
            <v-col cols="12" v-if="permiteSubMenus == true" class="pt-0">
              <v-card flat>
                <v-card-title class="pt-0">
                  Sub Menus
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    color="indigo"
                    title="Agregar un Nuevo SubMenu"
                    small
                    @click="agregarNuevoSubMenu()"
                    v-if="permiteSubMenus == true"
                  >
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-data-table
                    :headers="headersSubMenus"
                    :items="subMenus"
                    :items-per-page="5"
                  >
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                          <td class="text-center"> {{item.nombre}} </td>
                          <td class="text-center">
                            <v-chip small :color="getColor(item.estado)" dark>{{getEstadoName(item.estado)}}</v-chip>             
                          </td>
                          <td class="text-center">
                            <v-icon color="primary" title="Editar" @click="editarSubMenu(item)">mdi-square-edit-outline</v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions style="justify-content: end;">
          <v-btn
            color="green"
            class="white--text"
            @click="confirmarMenuEcommerce()"
            v-if="nuevoMenu == false"
          >
            Guardar
          </v-btn>
          <v-btn
            color="green"
            class="white--text"
            @click="confirmarNuevoMenuEcommerce()"
            v-if="nuevoMenu == true"
          >
            Confirmar
          </v-btn>
          <v-btn
            color="indigo"
            class="white--text"
            @click="dialogMenu = false"
          >
            Volver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal Agregar/Modificar SubMenu -->
    <v-dialog
      v-model="dialogSubMenu"
      max-width="900px"
    >
      <v-card
        flat
      >
        <v-card-title>
          <v-toolbar flat>
            <v-toolbar-title v-if="nuevoSubMenu == false">
              <v-icon>mdi-square-edit-outline</v-icon> Modificar Sub Menu <strong>{{ tituloDialogModSubMenu }}</strong>
            </v-toolbar-title>
            <v-toolbar-title v-else>
              <v-icon>mdi-square-edit-outline</v-icon> Modificar Sub Menu <strong>{{ tituloDialogModSubMenu }}</strong>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-row>
            <!-- Nombre -->
            <v-col cols="4">
              <v-text-field
                v-model="subMenuNombre"
                label="Nombre SubMenu"
                placeholder="Nombre SubMenu"
                outlined dense
                @keyup.enter="confirmarSubMenuEcommerce()"
              ></v-text-field>
            </v-col>
            <!-- Tipo de Menu -->
            <v-col cols="3">
              <v-autocomplete
                v-model="selectedTipoMenu"
                :items="tipoMenus"
                outlined dense
                item-text="nombre"
                label="Tipo de Menu"
                placeholder="Seleccione un Tipo"
                return-object
                :disabled="nuevoSubMenu == true"
              ></v-autocomplete>
            </v-col>
            <!-- Categoria -->
            <v-col cols="3">
              <v-autocomplete
                v-model="selectedCategoria"
                :items="listadoCategorias"
                outlined dense
                item-text="nombre"
                label="Categoria"
                placeholder="Seleccione una Categoria"
                return-object
              ></v-autocomplete>
            </v-col>
            <!-- Estado -->
            <v-col cols="2">
              <v-switch style="margin-top:4px" v-model="subMenuActivo" class="mx-2" label="Activo"></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions style="justify-content: center;">
          <v-btn
            color="green"
            class="white--text"
            @click="confirmarSubMenuEcommerce()"
            v-if="nuevoSubMenu == false"
          >
            Confirmar
          </v-btn>
          <v-btn
            color="green"
            class="white--text"
            @click="confirmarNuevoSubMenuEcommerce()"
            v-if="nuevoSubMenu == true"
          >
            Confirmar
          </v-btn>
          <v-btn
            color="indigo"
            class="white--text"
            @click="dialogSubMenu = false"
          >
            Volver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import router from '../../router'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {mapActions, mapState} from 'vuex'
export default {
  data() {
    return {
      search: '',
      menus: [],
      headers: [
        { text: 'Nombre', align: 'center', value:'nombre' },
        { text: 'Estado', align: 'center', value:'inhabilitado'},
        { text: 'Acciones', align: 'center'}
      ],
      dialogMenu: false,
      tituloDialogModMenu: '',
      menuNombre: '',
      menuActivo: false,
      menuOrden: 0,
      subMenus: [],
      headersSubMenus: [
        { text: 'Nombre', align: 'center', value:'nombre' },
        { text: 'Estado', align: 'center', value:'inhabilitado'},
        { text: 'Acciones', align: 'center'}
      ],
      permiteSubMenus: false,
      dialogSubMenu: false,
      tituloDialogModSubMenu: '',
      subMenuNombre: '',
      subMenuActivo: false,
      tipoMenus: [{id: 0, nombre: 'Padre'}, {id: 1, nombre: 'Hijo'}],
      selectedTipoMenu: {},
      listadoCategorias: [],
      selectedCategoria: {},
      selectedMenu: {},
      selectedSubMenu: {},
      selectedTipoMenuu: {},
      selectedCategoriaa: {},
      esPadre: false,
      selectedPadre: {},
      menusPadres: [],
      menuExistente: {},
      nuevoSubMenu: false,
      nuevoMenu: false
    }
  },
  components: {
    
  },
  computed: {
    ...mapState(['respMenusAdmin', 'respUpMenuAdmin'])
  },
  created () {
    this.usuarioLogueado()
  },
  methods: {
    ...mapActions(['getMenusAdmin', 'updateMenusAdmin']),
    async usuarioLogueado(){
      if (localStorage.getItem('tokenAdmin')){
        // prosigo con la carga
        await this.getMenusAdmin()
      }else{
        // redirecciono al Login
        router.push({name: 'LoginAdmin', params: {sesionExpirada: 1}})
      }
    },
    getColor(estado) {
      if (parseInt(estado) == 0) return 'green'
      else if (parseInt(estado) == 1) return 'orange'
    },
    getEstadoName(estado){
      if (parseInt(estado) ==0 ) return 'Activo'
      else if (parseInt(estado) == 1) return 'Inactivo'
    },
    editarMenu(item){
      console.log('es menu', item)
      // asigno el menu seleccionado
      this.selectedMenu = item
      // limpio el listado de subMenus
      this.subMenus = []
      // asigno el nombre del menu
      this.tituloDialogModMenu = item.nombre
      this.menuNombre = item.nombre
      // controlo el estado
      if (item.inhabilitado == 0){
        this.menuActivo = true
      }else{
        this.menuActivo = false
      }
      // asigno el orden
      this.menuOrden = item.orden
      // asigno los subMenus
      if (item.hasOwnProperty('submenu')){
        this.subMenus = item.submenu
      }
      // reviso si es para menus de articulos
      if (parseInt(item.categoria) > 0){
        this.permiteSubMenus = true
      }else{
        this.permiteSubMenus = false
      }

      // asigno el tipo de menu
      this.selectedTipoMenuu = this.tipoMenus.filter(element => element.id == 0)[0]
      // indico que es padre
      this.esPadre = true
      // verifico la categoria
      let unaCategoria = this.listadoCategorias.filter(element => parseInt(element.key_firebase) == parseInt(item.categoria))
      if (unaCategoria.length > 0){
        this.selectedCategoriaa = unaCategoria[0]
      }
      // abro el modal
      this.dialogMenu = true
    },
    editarSubMenu(item){
      console.log('es sub menu', item)
      // indico que no estoy creando, sino editando un submenu
      this.nuevoSubMenu = false
      this.nuevoMenu = false
      // asigno el sub Menu elegido
      this.selectedSubMenu = item
      // asigno el nombre del submenu elegido
      this.tituloDialogModSubMenu = item.nombre
      this.subMenuNombre = item.nombre
      // verifico el estado
      if (item.estado == 0){
        this.subMenuActivo = true
      }else{
        this.subMenuActivo = false
      }
      // asigno el tipo de Menu
      this.selectedTipoMenu = {id: 1, nombre: 'Hijo'}
      // asigno la categoria
      let unaCategoria = this.listadoCategorias.filter(element => parseInt(element.key_firebase) == parseInt(item.categoria))
      if (unaCategoria.length > 0){
        this.selectedCategoria = unaCategoria[0]
      }
      // abro el modal
      this.dialogSubMenu = true
    },
    agregarNuevoSubMenu(){
      // indico que estoy creando un nuevo submenu
      this.nuevoSubMenu = true
      this.nuevoMenu = false
      // limpio los campos del modal
      this.subMenuNombre = ''
      this.selectedTipoMenu = {id: 1, nombre: 'Hijo'}
      this.selectedCategoria = {}
      this.subMenuActivo = true
      // abro el modal
      this.dialogSubMenu = true
    },
    getOrdenMenu(){
      try{
        // ordeno el listado de menus por el orden y obtengo el mayor
        let listadoMenus = this.menus
        let mayorOrden = 0
        let mayorKey = 0
        let existe = 0
        // primero controlo a ver si ya existe el menu
        let unListado = []
        if (this.nuevoMenu == false){
          unListado = listadoMenus.filter(element => element.nombre.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == this.selectedSubMenu.nombre.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") && element.categoria == this.selectedSubMenu.categoria)
        }
        if (unListado.length == 0 && this.nuevoMenu == false){
          // calculo el orden y la key
          listadoMenus.sort(function(a,b){
            if (parseInt(a.orden) > parseInt(b.orden)) {
              return -1;
            }
            if (parseInt(a.orden) < parseInt(b.orden)) {
              return 1;
            }
            // a must be equal to b
            return 0;
          })
          mayorOrden = parseInt(listadoMenus[0].orden)
          // ahora ordeno el mismo listado pero por keyFirebase
          listadoMenus.sort(function(a,b){
            if (parseInt(a.keyFirebase) > parseInt(b.keyFirebase)) {
              return -1;
            }
            if (parseInt(a.keyFirebase) < parseInt(b.keyFirebase)) {
              return 1;
            }
            // a must be equal to b
            return 0;
          })
          mayorKey = parseInt(listadoMenus[0].keyFirebase)
        }else{
          if (this.nuevoMenu == false){
            mayorOrden = parseInt(unListado[0].orden)
            mayorKey = parseInt(unListado[0].keyFirebase)
            existe = 1
          }else{
            // calculo el orden y la key
            listadoMenus.sort(function(a,b){
              if (parseInt(a.orden) > parseInt(b.orden)) {
                return -1;
              }
              if (parseInt(a.orden) < parseInt(b.orden)) {
                return 1;
              }
              // a must be equal to b
              return 0;
            })
            mayorOrden = parseInt(listadoMenus[0].orden)
            // ahora ordeno el mismo listado pero por keyFirebase
            listadoMenus.sort(function(a,b){
              if (parseInt(a.keyFirebase) > parseInt(b.keyFirebase)) {
                return -1;
              }
              if (parseInt(a.keyFirebase) < parseInt(b.keyFirebase)) {
                return 1;
              }
              // a must be equal to b
              return 0;
            })
            mayorKey = parseInt(listadoMenus[0].keyFirebase)
          }
        }
        return {
          resultado: 1,
          msj: 'OK',
          mayorOrden: mayorOrden,
          mayorKey: mayorKey,
          existe: existe
        }
      }catch(error){
        return {
          resultado: 0,
          msj: 'Ocurrio un problema al ejecutar el metodo getOrdenMenu: ' + error.message,
          mayorOrden: null,
          mayorKey: null,
          existe: null
        }
      }
    },
    getMenuGrande(){
      if (this.menuNombre.length == 0){
        Swal.fire({
          title: 'Modificando Menu', 
          text: 'El Nombre de la Categoría no puede estar vacío.', 
          icon: 'error'
        })
        return
      }
      if (this.menuOrden.length == 0){
        Swal.fire({
          title: 'Modificando Menu', 
          text: 'El Orden de la Categoría no puede estar vacío.', 
          icon: 'error'
        })
        return
      }
      if (Object.keys(this.selectedTipoMenuu).length === 0){
        Swal.fire({
          title: 'Modificando Menu', 
          text: 'Debe seleccionar un tipo de Menu.', 
          icon: 'error'
        })
        return
      }
      if (Object.keys(this.selectedCategoriaa).length === 0){
        Swal.fire({
          title: 'Modificando Menu', 
          text: 'Debe seleccionar una Categoria para el Menu.', 
          icon: 'error'
        })
        return
      }
      // si llega hasta aqui, los datos son consistentes
      let estadoMenu = 0
      if (this.menuActivo == false){
        estadoMenu = 1
      }
      let esPadre = 0
      if (this.selectedTipoMenuu.id == 0){
        esPadre = 1
      }
      let to = ''
      let url = '/'
      let icono = ''
      let keyFirebase = ''
      // icono
      if (this.selectedMenu.icono == null || this.selectedMenu.icono == undefined){
        icono = 'mdi-cursor-default-outline'
      }else{
        icono = this.selectedMenu.icono
      }
      if (this.selectedMenu.keyFirebase != null && this.selectedMenu.keyFirebase != undefined && this.selectedMenu.keyFirebase.length > 0){
        keyFirebase = this.selectedMenu.keyFirebase
      }else{
        if (this.nuevoMenu == true){
          let datosOrden = this.getOrdenMenu()
          keyFirebase = parseInt(datosOrden.mayorKey) + 1
        }
      }

      if (this.nuevoMenu == true){
        to = '/articulos/' + this.menuNombre
      }else{
        to = this.selectedMenu.to
      }
      
      let menuGrande = {
        categoria: parseInt(this.selectedCategoriaa.key_firebase),
        estado: estadoMenu,
        icono: icono,
        inhabilitado: estadoMenu,
        nombre: this.menuNombre,
        orden: this.menuOrden,
        submenu: this.subMenus,
        to: to,
        url: url
      }
      return {
        menuGrande: menuGrande,
        keyFirebase: keyFirebase,
        esPadre: esPadre
      }
    },
    convertirAPadre(){
      Swal.fire({
        title: 'Modificando Sub Menu.',
        text: 'Esta convirtiendo en Menu Principal al Sub Menu seleccionado. ¿Confirma esta accion?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          // selectedSubMenu tiene toda la info original en el listado del menu seleccionado, primero debo pasar a sacarlo del listado
          let pos = this.subMenus.indexOf(this.selectedSubMenu)
          this.subMenus.splice(pos, 1)
          // una vez quitado del listado de submenus, debo insertarlo en el listado de Menus
          let estadoMenu = 0
          if (this.subMenuActivo == false){
            estadoMenu = 1
          }
          let datosOrden = this.getOrdenMenu()
          if (parseInt(datosOrden.resultado) === 1){
            let orden = 0
            let key = 0
            if (datosOrden.existe == 0){
              orden = datosOrden.mayorOrden + 1
              key = datosOrden.mayorKey + 1
            }else{
              orden = datosOrden.mayorOrden
              key = datosOrden.mayorKey
            }
            let unMenu = {
              categoria: parseInt(this.selectedCategoria.key_firebase),
              estado: estadoMenu,
              inhabilitado: estadoMenu,
              nombre: this.subMenuNombre,
              orden: orden,
              to: '/articulos/' + this.subMenuNombre,
              url: '/'
            }
            // aqui tengo que hacer 2 cosas, actualizar el menu del cual estoy sacando el submenu, y luego insertar en nuevo Menu, primero arreglo el Menu grande
            let menuGrande = this.getMenuGrande()
            await this.updateMenusAdmin({menuGrande: menuGrande, menuPeque: {menuPeque: unMenu, keyFirebase: key}})
          }else{
            Swal.fire({
              title: 'Modificando Sub Menu', 
              text: datosOrden.msj, 
              icon: 'error'
            })
            return
          }
        }
      })
    },
    convertirAHijo(menuUp){
      if (Object.keys(this.selectedPadre).length === 0){
        Swal.fire({
          title: 'Modificando Menu', 
          text: 'Debe seleccionar el Menu Padre si desea convertir a este menu en Hijo.', 
          icon: 'error'
        })
        return
      }
      Swal.fire({
        title: 'Modificando Menu.',
        text: '¿Desea guardar los cambios realizados?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then(async (result) => {
        // busco el menu padre al cual voy a colocar
        let unMenuPadre = this.menus.filter(element => parseInt(element.keyFirebase) == parseInt(this.selectedPadre.keyFirebase))
        if (unMenuPadre.length > 0){
          // armar el nuevo menu hijo
          console.log(menuUp)
          let subMenusActual = []
          if (menuUp.menuGrande.hasOwnProperty('submenu')){
            subMenusActual = menuUp.menuGrande.submenu
          }
          let unSubMenu = {
            categoria: menuUp.menuGrande.categoria,
            estado: menuUp.menuGrande.estado,
            inhabilitado: menuUp.menuGrande.inhabilitado,
            nombre: menuUp.menuGrande.nombre,
            to: '/articulos/' + unMenuPadre[0].nombre + '/' + menuUp.menuGrande.nombre,
            url: menuUp.menuGrande.url,
            submenu: subMenusActual
          }
          // lo asigno a los submenus de unMenuPadre
          if (unMenuPadre[0].hasOwnProperty('submenu')){
            unMenuPadre[0].submenu.push(unSubMenu)
          }else{
            unMenuPadre[0].submenu = []
            unMenuPadre[0].submenu.push(unSubMenu)
          }
          // incorpore el submenu en el menu padre, armo lo que necesito para actualizar
          let unMenuGrande = {
            categoria: unMenuPadre[0].categoria,
            estado: unMenuPadre[0].estado,
            icono: unMenuPadre[0].icono,
            inhabilitado: unMenuPadre[0].inhabilitado,
            nombre: unMenuPadre[0].nombre,
            orden: unMenuPadre[0].orden,
            submenu: unMenuPadre[0].submenu,
            to: unMenuPadre[0].to,
            url: unMenuPadre[0].url
          }
          // en menuUp tengo el menu grande que pasa a ser hijo, debo deshabilitarlo
          menuUp.menuGrande.estado = 1
          menuUp.menuGrande.inhabilitado = 1
          await this.updateMenusAdmin({menuGrande: {menuGrande: unMenuGrande, keyFirebase: unMenuPadre[0].keyFirebase}, menuPeque: {menuPeque: menuUp.menuGrande, keyFirebase: menuUp.keyFirebase}})
        }else{
          // no se puede determinar a qué menú padre pertenece, detener la ejecución
          Swal.fire({
            title: 'Modificando Menu', 
            text: 'No se puede determinar a qué menú principal asociar.', 
            icon: 'error',
            timer: 5000
          })
        }
      })
    },
    confirmarSubMenuEcommerce(){
      // tipo de menu?
      if (Object.keys(this.selectedTipoMenu).length == 0){
        Swal.fire({
          title: 'Modificando Sub Menu', 
          text: 'Debe seleccionar un Tipo de Menu para poder continuar.', 
          icon: 'error'
        })
        return
      }
      // categoria?
      if (Object.keys(this.selectedCategoria).length == 0){
        Swal.fire({
          title: 'Modificando Sub Menu', 
          text: 'Debe seleccionar una Categoria para poder continuar.', 
          icon: 'error'
        })
        return
      }
      // nombre?
      if (this.subMenuNombre.length == 0){
        Swal.fire({
          title: 'Modificando Sub Menu', 
          text: 'El nombre del Sub Menu no puede estar vacío.', 
          icon: 'error'
        })
        return
      }
      // controlo si lo mantiene como hijo, si lo cambio a padre se debe quitar del listado de subMenus
      if (this.selectedTipoMenu.id == 0){
        // lo esta haciendo padre, debe salir del listado de subMenus, primero preguntar si realmente quiere convertir a padre
        this.convertirAPadre()
      }else{
        // lo mantiene como subMenu, guardar los cambios hasta confirmar, busco en el listado de submenus el que el usuario selecciono
        let pos = this.selectedMenu.submenu.indexOf(this.selectedSubMenu)
        let pos1 = this.subMenus.indexOf(this.selectedSubMenu)
        // armo la nueva estructura del nuevo submenu
        let estadoMenu = 0
        if (this.subMenuActivo == false){
          estadoMenu = 1
        }
        let subMenusActual = []
        if (this.selectedSubMenu.hasOwnProperty('submenu')){
          subMenusActual = this.selectedSubMenu.submenu
        }
        let unSubMenu = {
          categoria: parseInt(this.selectedCategoria.key_firebase),
          estado: estadoMenu,
          inhabilitado: estadoMenu,
          nombre: this.subMenuNombre,
          to: this.selectedSubMenu.to,
          url: this.selectedSubMenu.url,
          submenu: subMenusActual
        }
        // lo asigno a la posicion en donde se encontraba originalmente
        this.selectedMenu.submenu[pos] = unSubMenu
        // quito el anterior que estaba en el listado de submenus y agrego el nuevo
        this.subMenus.splice(pos1, 1)
        // agrego el nuevo
        this.subMenus.push(unSubMenu)
        // cierro el modal de modificacion de sub menus
        this.dialogSubMenu = false
      }
    },
    confirmarNuevoSubMenuEcommerce(){
      // tipo de menu?
      if (Object.keys(this.selectedTipoMenu).length == 0){
        Swal.fire({
          title: 'Modificando Sub Menu', 
          text: 'Debe seleccionar un Tipo de Menu para poder continuar.', 
          icon: 'error'
        })
        return
      }
      // categoria?
      if (Object.keys(this.selectedCategoria).length == 0){
        Swal.fire({
          title: 'Modificando Sub Menu', 
          text: 'Debe seleccionar una Categoria para poder continuar.', 
          icon: 'error'
        })
        return
      }
      // nombre?
      if (this.subMenuNombre.length == 0){
        Swal.fire({
          title: 'Modificando Sub Menu', 
          text: 'El nombre del Sub Menu no puede estar vacío.', 
          icon: 'error'
        })
        return
      }
      // ya existe en el listado de submenus?
      let unListado = this.subMenus.filter(element => element.nombre.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == this.subMenuNombre.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") && element.categoria == this.selectedCategoria.key_firebase)
      if (unListado.length == 0){
        // agregar
        let estadoMenu = 0
        if (this.subMenuActivo == false){
          estadoMenu = 1
        }
        let subMenusActual = []
        let unSubMenu = {
          categoria: parseInt(this.selectedCategoria.key_firebase),
          estado: estadoMenu,
          inhabilitado: estadoMenu,
          nombre: this.subMenuNombre,
          to: '/articulos/' + this.selectedMenu.nombre + '/' + this.subMenuNombre,
          url: '/',
          submenu: subMenusActual
        }
        //this.selectedMenu.submenu.push(unSubMenu)
        this.subMenus.push(unSubMenu)
      }else{
        // ya existe
        Swal.fire({
          title: 'Agregando Sub Menu', 
          text: 'El sub menú que está agregando ya se encuentra en el listado de Sub Menus.', 
          icon: 'info'
        })
      }
      // cierro el modal
      this.dialogSubMenu = false
    },
    confirmarMenuEcommerce(){
      let menuUp = this.getMenuGrande()
      // controlo si lo mantiene como padre
      if (menuUp.esPadre == 1){
        // listo, actualizo normal
        Swal.fire({
          title: 'Modificando Sub Menu.',
          text: '¿Desea guardar los cambios realizados?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No'
        }).then(async (result) => {
          // controlo menuExistente
          if (Object.keys(this.menuExistente).length == 0){
            await this.updateMenusAdmin({menuGrande: menuUp, menuPeque: {}})
          }else{
            // consigo la key
            let keyFirebase = this.menuExistente.keyFirebase
            delete this.menuExistente.keyFirebase
            await this.updateMenusAdmin({menuGrande: menuUp, menuPeque: {menuPeque: this.menuExistente, keyFirebase: keyFirebase}})
          }
        })
      }else{
        // debo convertir a hijo
        this.convertirAHijo(menuUp)
      }
    },
    confirmarNuevoMenuEcommerce(){
      let menuUp = this.getMenuGrande()
      Swal.fire({
        title: 'Creando Nuevo Menu.',
        text: '¿Desea guardar los cambios realizados?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then(async (result) => {
        // controlo
        await this.updateMenusAdmin({menuGrande: menuUp, menuPeque: {}})
      })
    },
    controlarPadre(){
      if (Object.keys(this.selectedTipoMenuu).length > 0){
        if (this.selectedTipoMenuu.id == 0){
          // es padre
          this.esPadre = true
        }else{
          // no es padre
          this.esPadre = false
        }
      }
    },
    nuevoMenuPadre(){
      // indico que voy a agregar un menu padre
      this.nuevoMenu = true
      // limpio campos
      this.tituloDialogModMenu = ''
      this.menuNombre = ''
      this.menuOrden = ''
      this.menuActivo = true
      this.selectedTipoMenuu = {id: 0, nombre: 'Padre'}
      this.permiteSubMenus = true
      this.esPadre = true
      this.selectedCategoriaa = {}
      this.subMenus = []
      this.selectedTipoMenu = {}
      this.selectedCategoria = {}
      this.selectedMenu = {}
      this.selectedSubMenu = {}
      this.selectedPadre = {}
      // abro el modal
      this.dialogMenu = true
    }
  },
  watch: {
    respMenusAdmin: function(){
      if (Object.keys(this.respMenusAdmin).length > 0){
        if (parseInt(this.respMenusAdmin.resultado) === 1){
          // todo OK, asigno menus y categorias
          this.menus = this.respMenusAdmin.listadoMenus
          this.listadoCategorias = []
          for (let id in this.respMenusAdmin.categorias){
            if (this.respMenusAdmin.categorias[id].inhabilitado == 0){
              this.listadoCategorias.push(this.respMenusAdmin.categorias[id])
            }
          }
        }else{
          Swal.fire({
            title: 'Iniciando Formulario', 
            text: this.respMenusAdmin.msj, 
            icon: 'error',
            timer: 5000
          })
        }
      }else{

      }
    },
    respUpMenuAdmin: function(){
      if (Object.keys(this.respUpMenuAdmin).length > 0){
        if (parseInt(this.respUpMenuAdmin.resultado) === 1){
          // exito en la actualizacion
          Swal.fire({
            title: 'Modificando Menu', 
            text: 'Actualización finalizada con éxito.', 
            icon: 'success',
            timer: 4000
          })
          setTimeout(() => [location.reload()], 4000)
        }else{
          // es por token expirado?
          if (this.respUpMenuAdmin.tokenExpirado == 1){
            // ir al login
            localStorage.removeItem('tokenAdmin')
            location.reload()
          }else{
            // otro error
            Swal.fire({
              title: 'Modificando Sub Menu', 
              text: this.respUpMenuAdmin.msj, 
              icon: 'error'
            })
            return
          }
        }
      }
    },
    menus: function(){
      if (this.menus.length > 0){
        this.menusPadres = this.menus.filter(element => parseInt(element.categoria) > 0)
      }
    },
    menuActivo: function(){
      if (this.nuevoMenu == false){
        if (this.menuActivo == true){
          // controlo si el menu habilitado se encuentra como submenu en cualquier otro menu, por nombre y por categoria
          let listadoConSubmenus = this.menus.filter(element => element.hasOwnProperty('submenu') == true && Array.isArray(element.submenu) == true && element.submenu.length > 0)
          if (listadoConSubmenus.length > 0){
            let padre = {}
            let cont = 0
            let submenuAux = {}
            // controlo a ver si en alguno de los submenus tengo el menu que estoy habilitando
            for (let id in listadoConSubmenus){
              let unSubMenu = listadoConSubmenus[id].submenu.filter(element => element.nombre.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == this.selectedMenu.nombre.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") && parseInt(element.categoria) == parseInt(this.selectedMenu.categoria) && listadoConSubmenus[id].inhabilitado == 0 && element.estado == 0)
              if (unSubMenu.length > 0){
                // avisar que este menu ya se encuentra como submenu en otro menu, deshabilitar el boton para cambiar el estado
                cont = cont + 1
                padre = listadoConSubmenus[id]
                submenuAux = unSubMenu[0]
              }
            }
            if (parseInt(cont) > 0){
              Swal.fire({
                title: 'Modificando Menu', 
                text: 'El menú que está habilitando ya se encuentra agregado en el Menú ' + padre.nombre + '. Si continua con la modificación, estará quitando este sub menú en ' + padre.nombre + ' y habilitando éste menú padre.', 
                icon: 'info'
              })
              // asigno a menuExistente
              let pos = padre.submenu.indexOf(submenuAux)
              padre.submenu[pos].estado = 1
              padre.submenu[pos].inhabilitado = 1
              this.menuExistente = padre
            }else{
              this.menuExistente = {}
            }
          }else{
            this.menuExistente = {}
          }
        }else{
          this.menuExistente = {}
        }
      }
    },
    selectedTipoMenuu: function(){
      if (Object.keys(this.selectedTipoMenuu).length > 0){
        if (this.selectedTipoMenuu.id == 1){
          // controlo a ver si se encuentra como hijo en otro Menú
          let listadoConSubmenus = this.menus.filter(element => element.hasOwnProperty('submenu') == true && Array.isArray(element.submenu) == true && element.submenu.length > 0)
          if (listadoConSubmenus.length > 0){
            let padre = {}
            let cont = 0
            for (let id in listadoConSubmenus){
              let unSubMenu = listadoConSubmenus[id].submenu.filter(element => element.nombre.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == this.selectedMenu.nombre.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") && parseInt(element.categoria) == parseInt(this.selectedMenu.categoria) && listadoConSubmenus[id].inhabilitado == 0 && element.estado == 0)
              if (unSubMenu.length > 0){
                // avisar que este menu ya se encuentra como submenu en otro menu, deshabilitar el boton para cambiar el estado
                cont = cont + 1
                padre = listadoConSubmenus[id]
              }
            }
            if (parseInt(cont) > 0){
              Swal.fire({
                title: 'Modificando Menu', 
                text: 'No puede convertir este menu en hijo ya que actualmente existe en ' + padre.nombre + '.', 
                icon: 'info'
              })
              this.selectedTipoMenuu = {id: 0, nombre: 'Padre'}
              this.esPadre = true
            }
          }
        }else{
          this.menuExistente = {}
        }
      }
    },
    subMenuActivo: function(){
      if (this.nuevoSubMenu == false){
        if (this.subMenuActivo == true){
          // controlo si hay un menu padre con el mismo nombre y categoria habilitado
          let listadoConMenus = this.menus.filter(element => element.nombre.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == this.selectedSubMenu.nombre.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") && element.inhabilitado == 0)
          if (listadoConMenus.length > 0){
            // informo que ya existe un menu activo
            Swal.fire({
              title: 'Modificando Sub Menu', 
              text: 'El sub menú que está habilitando ya se encuentra activo como Padre. Si continua con la modificación estará deshabilitando este menú como Padre.', 
              icon: 'info'
            })
            listadoConMenus[0].estado = 1
            listadoConMenus[0].inhabilitado = 1
            this.menuExistente = listadoConMenus[0]
          }else{
            this.menuExistente = {}
          }
        }else{
          this.menuExistente = {}
        }
      }
    }
  },
}
</script>

<style>

</style>