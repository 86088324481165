<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="50%"
    >
      <v-card
        flat
      >
        <v-card-title>
          <v-icon class="ma-2">mdi-card-account-details</v-icon> Datos Personales
          <v-spacer></v-spacer>
          <v-btn icon @click="activo = false" title="Cerrar"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="pt-2">
          <!-- Apellidos, Nombre y DNI -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" sm="4" md="4" class="py-2">
              <v-text-field 
                v-model="apellidos" 
                label="Apellido/s" 
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-2">
              <v-text-field 
                v-model="nombre" 
                label="Nombre/s" 
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-2">
              <v-text-field 
                v-model="documento" 
                label="N° Documento" 
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Fecha de Nacimiento, Género y Teléfono -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" sm="4" md="4" class="py-2">
              <v-text-field 
                v-model="fecha_nacimiento" 
                label="Fecha Nacimiento" 
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-2">
              <v-text-field 
                v-model="genero" 
                label="Genero" 
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-2">
              <v-text-field 
                v-model="telefono" 
                label="Telefono" 
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Calle y Nro. -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" sm="8" md="8" class="py-2">
              <v-text-field 
                v-model="calle" 
                label="Calle"
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-2">
              <v-text-field 
                v-model="calle_numero" 
                label="Numero" 
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end mx-2">
          <v-btn color="error" @click="activo = false">Volver</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalDatosPers', value)
      }
    },
    apellidos: {
      get(){
        return this.datos.apellidos
      }
    },
    nombre: {
      get(){
        return this.datos.nombre
      }
    },
    documento: {
      get(){
        return this.datos.documento
      }
    },
    fecha_nacimiento: {
      get(){
        return this.datos.fecha_nacimiento
      }
    },
    genero: {
      get(){
        return this.datos.genero
      }
    },
    telefono: {
      get(){
        return this.datos.telefono
      }
    },
    calle: {
      get(){
        return this.datos.calle
      }
    },
    calle_numero: {
      get(){
        return this.datos.calle_numero
      }
    }
  },
}
</script>

<style>

</style>