<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <!-- Filtros -->
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <!-- Titulo Filtros -->
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>mdi-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="getVentas" ref="formSearch">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="3" md="3" class="py-2">
                    <v-autocomplete
                      v-model="selectedEstadoCobro"
                      item-text="descripcion"
                      :items="estadosCobro"
                      hide-details
                      outlined
                      dense
                      return-object
                      label="Estado Cobro"
                      :rules="rules" 
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-2">
                    <v-autocomplete
                      v-model="selectedEstadoPedido"
                      item-text="estado"
                      :items="estado_pedidos"
                      hide-details
                      outlined
                      dense
                      return-object
                      label="Estado Pedido"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-2">
                    <FechaPicker
                      v-model="fechaDesde"
                      label="Fecha Desde"
                    />
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-2">
                    <FechaPicker
                      v-model="fechaHasta"
                      label="Fecha Desde"
                    />
                  </v-col>
                </v-row>
                <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-0 mb-0">
                  <v-col cols="12" align="right">
                    <BtnFiltro
                      :loading="load"
                      @clear="limpiar()"
                      clase="pt-0"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado de Ventas -->
        <v-data-table
          class="cebra elevation-2 mt-2"
          :headers="headers"
          :items="ventas"
          :loading="load"
          dense
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <!-- Buscador en el datatable -->
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <!-- Msj que se mostrara si no existen resultados -->
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              Sin Ventas para mostrar.
            </v-alert>
          </template>
          <!-- Acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom v-if="item.error_codigo == 0">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="cyan" @click="visualizarDatosUsuario(item)" v-on="on">
                  <v-icon small>mdi-account</v-icon>
                </v-btn>
              </template>
              <span>Descargar Comprobante</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small icon color="primary" @click="visualizar(item)" v-on="on">
                  <v-icon small>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Ver</span>
            </v-tooltip>
            <v-tooltip bottom v-if="parseInt(item.estado_pedido_codigo) === 1">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="indigo" @click="confirmarEnvio(item)" v-on="on">
                  <v-icon small>mdi-truck</v-icon>
                </v-btn>
              </template>
              <span>Enviar</span>
            </v-tooltip>
            <!-- <v-tooltip bottom v-if="!(parseInt(item.estado_pedido_codigo) === 0) && item.financiacion != 'RAPICOMPRAS'">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="warning" @click="confirmarConfirmarVolverEstado(item)" v-on="on">
                  <v-icon small>mdi-undo</v-icon>
                </v-btn>
              </template>
              <span>Volver Estado</span>
            </v-tooltip> -->
            <v-tooltip bottom v-if="item.estado_cobro == 'APROBADO'">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="verCupon(item)" v-on="on">
                  <v-icon small>mdi-credit-card-outline</v-icon>
                </v-btn>
              </template>
              <span>Datos del Cupon</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.estado_cobro == 'APROBADO'">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="verEnvio(item)" v-on="on">
                  <v-icon small>mdi-motorbike-electric</v-icon>
                </v-btn>
              </template>
              <span>Datos del Envio</span>
            </v-tooltip>
            <v-tooltip bottom v-if="parseInt(item.error_codigo) !== 0">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="warning" @click="errorCobro(item)" v-on="on">
                  <v-icon small>mdi-alert-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>Error mensaje</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.financiacion != 'RAPICOMPRAS' && item.estado_cobro == 'APROBADO'">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="error" @click="confirmarAnulacion(item)" v-on="on">
                  <v-icon small>mdi-cancel</v-icon>
                </v-btn>
              </template>
              <span>Anular</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.financiacion == 'RAPICOMPRAS' && (item.estado == 'ACEPTADA' || item.estado == 'FINALIZADA' || item.estado == 'APROBADO')">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="confirmarEntrega(item)" v-on="on">
                  <v-icon small>mdi-check-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>Marcar como Entregado</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.financiacion == 'RAPICOMPRAS' && item.estado != 'ENTREGADO'">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="info" @click="confirmarSync(item)" v-on="on">
                  <v-icon small>mdi-sync</v-icon>
                </v-btn>
              </template>
              <span>Sincronizar MACRO</span>
            </v-tooltip>
          </template>
          <!-- Estado Pedido -->
          <template v-slot:[`item.estado_pedido`]="{ item }">
            <v-chip :color="getColorEstadoPedido(item.estado_pedido_codigo)" small dark>{{ item.estado_pedido }}</v-chip>
          </template>
          <!-- Estado Cobro -->
          <template v-slot:[`item.estado_cobro`]="{ item }">
            <v-chip :color="getColor(item.estado_cobro)" small dark>{{ item.estado_cobro }}</v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Modal Datos de Envío -->
    <ModalDatosEnvio 
      :datos="dialogDatosEnvio"
      @setearModalDatosEnvio="setModalDatosEnvio"
    />
    <!--Modal Datos del Cupón -->
    <ModalDatosCupon 
      :datos="dialogDatosCupon"
      @setearModalDatosCupon="setModalDatosCupon"
    />
    <!-- Modal Datos Personales -->
    <ModalDatosPersonales 
      :datos="dialogDatosPersonales"
      @setearModalDatosPers="setModalDatosPers"
    />
    <!-- Modal Ver Datos de la Venta -->
    <ModalVerVenta 
      :datos="dialogVerVenta"
      @setearModalVerVenta="setModalVerVenta"
    />
    <!-- Modal Confirmar Envio-->
    <ModalConfirmarVue 
      :activoDialog="dialogConfEnvio"
      :texto="`¿Esta seguro de marcar el pedido como enviado?`"
      @action="enviar()"
      @setearModalConfAccion="setModalEnvio"
    />
    <!-- Modal Confirmar Volver Estado -->
    <ModalConfirmarVue 
      :activoDialog="dialogConfVolvEst"
      :texto="`¿Esta seguro de volver el estado al pedido?`"
      @action="volverEstado()"
      @setearModalConfAccion="setModalVolvEst"
    />
    <!-- Modal Confirmar Anulación -->
    <ModalConfirmarVue 
      :activoDialog="dialogConfAnular"
      :texto="`¿Esta seguro de anular el pedido?`"
      @action="anular()"
      @setearModalConfAccion="setModalAnular"
    />
    <!-- Modal Confirmar Entrega Cliente -->
    <ModalConfirmarVue 
      :activoDialog="dialogConfEntrega"
      :texto="`¿Esta seguro de informar a MACRO que esta venta fue entregada al Cliente?`"
      @action="entregaMacro()"
      @setearModalConfAccion="setModalEntrega"
    />
    <!-- Modal Confirmar Sync Macro -->
    <ModalConfirmarVue 
      :activoDialog="dialogConfSync"
      :texto="`¿Esta seguro de Sincronizar este Pedido con MACRO?`"
      @action="syncMacro()"
      @setearModalConfAccion="setModalSync"
    />
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import router from '../../router'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from 'moment'
import ModalDatosCupon from '../../components/admin/ventas/ModalDatosCupon.vue'
import ModalDatosEnvio from '../../components/admin/ventas/ModalDatosEnvio.vue'
import ModalDatosPersonales from '../../components/admin/ventas/ModalDatosPersonales.vue'
import ModalVerVenta from '../../components/admin/ventas/ModalVerVenta.vue'
import FechaPicker from '../../components/genericos/FechaPicker.vue'
import BtnFiltro from '../../components/genericos/BtnFiltro.vue'
import SearchDataTable from '../../components/genericos/SearchDataTable.vue'
import ModalConfirmarVue from '../../components/genericos/ModalConfirmar.vue'
import { textoVacio, objetoVacio } from '../../util/util'
export default {
  data() {
    return {
      load: false,
      panel: 0,
      search: '',
      sortBy:'fecha_log',
      sortDesc: true,
      headers: [
        {text: 'Fecha',align: 'center',value:'fecha_grabacion'},
        { text: 'Cliente', align: 'left',value:'displayName' },
        { text: 'Nro. Pedido', align: 'center',value:'numero_pedido' },
        { text: 'Importe',align: 'left',value:'forma_pago.importe_final'},
        { text: 'Financiacion',align: 'left',value:'forma_pago.financiacion'},
        { text: 'Estado Pedido',value:'estado_pedido'},
        { text: 'Estado Cobro',value:'estado_cobro'},
        { text: 'Acciones',sortable: false ,value: 'acciones'},
      ],
      ventas:[],
      estado_pedidos:[],
      estadosCobro:[],
      srvAnular:[],
      total_final:0,
      total_cantidad: 0,
      selectedEstadoCobro:'',
      rules: [
        v => !!v || 'Campo Obligatorio',
      ],
      validForm: true,
      fechaDesde: null,
      fechaHasta: null,
      selectedOperacion: {},
      intentosLoginJarvis: 0,
      rechazoMotivo: '',
      descripRechazo: '',
      estadoPrestamo: '',
      estadoApi: '',
      link: '',
      dialogDatosCupon: {
        activo: false,
        codigo_autorizacion: '',
        cuotas: '',
        fecha_cobro: '',
        financiacion: '',
        importe_final: '',
        lote: '',
        numero: '',
        titular: '',
        documento_numero: '',
        tarjeta_numero: '',
        marca: ''
      },
      dialogDatosEnvio: {
        activo: false,
        envio_domicilio: false,
        provincia: '',
        localidad: '',
        local_retiro: '',
        calle: '',
        calle_numero: '',
        costo_envio: '',
        datos_adicionales: ''
      },
      dialogDatosPersonales: {
        activo: false,
        apellidos: '',
        nombre: '',
        documento: '',
        fecha_nacimiento: '',
        genero: '',
        telefono: '',
        calle: '',
        calle_numero: ''
      },
      dialogVerVenta: {
        activo: false,
        detalles: []
      },
      dialogConfEnvio: false,
      dialogConfVolvEst: false,
      dialogConfAnular: false,
      dialogConfEntrega: false,
      dialogConfSync: false,
      selectedEstadoPedido: {}
    }
  },
  components: {
    ModalDatosCupon,
    ModalDatosEnvio,
    ModalDatosPersonales,
    ModalVerVenta,
    FechaPicker,
    BtnFiltro,
    SearchDataTable,
    ModalConfirmarVue
  },
  created () {
    this.getEstados()
    this.getEstCobro()
  },
  computed: {
    ...mapState(['respEstadosPedidos', 'respEstadosCobros', 'respVentasEcommerce', 'respEstadoPedido', 'respDatosApiJarvis', 'respCancelPayment', 'respUpdateSale'])
  },
  methods: {
    ...mapActions(['getEstadosPedidos', 'getEstadosCobros', 'getVentasEcommerce', 'enviarPedido', 'volverEstadoPedido', 'cancelPayment', 'updateSale']),
    getColorEstadoPedido(estado){
      if (estado == 1) return 'blue'
      else return 'blue'
    },
    getColor (estado) {
      if (estado == 'APROBADO') return 'green'
      else if (estado == 'ANULADO' || estado == 'FAILED' || estado ==  'INHABILITADO' || estado == 'DEVOLUCION') return 'red'
      else if (estado == 'RECHAZADO' || estado == 'PROCESADO CON ERROR' ) return 'orange'
      else return 'blue'
    },
    limpiar(){
      this.selectedEstadoCobro = {}
      this.fechaDesde = ''
      this.fechaHasta = ''
    },
    async getVentas(){
      if (!this.$refs.formSearch.validate()){
        return
      }
      if (textoVacio(this.fechaDesde) || textoVacio(this.fechaHasta)){
        this.$store.dispatch('executeSnackBar', {msj: 'Debe completar el rango de fechas.', color: 'warning', activo: true})
        return
      }
      // estado de pedido
      let estadoPedido = -1
      if (!objetoVacio(this.selectedEstadoPedido)) estadoPedido = this.selectedEstadoPedido.codigo
      this.ventas = []
      this.load = true
      let tokenLocal = JSON.parse(localStorage.getItem('tokenAdmin'))
      // convierto las fechas en YYYY-MM-DD
      let fechaD = moment(this.fechaDesde, 'DD/MM/YYYY').format('YYYY-MM-DD')
      let fechaH = moment(this.fechaHasta, 'DD/MM/YYYY').format('YYYY-MM-DD')
      // parametros
      let parametros = {
        estado: this.selectedEstadoCobro.descripcion,
        fechaDesde: fechaD,
        fechaHasta: fechaH,
        token: tokenLocal,
        estadoPedido: estadoPedido
      }
      // peticion
      await this.getVentasEcommerce(parametros)
    },
    async getEstados(){
      if (localStorage.getItem('estadosEnvio')) this.estado_pedidos = JSON.parse(localStorage.getItem('estadosEnvio'))
      else await this.getEstadosPedidos()
    },
    async getEstCobro(){
      if (localStorage.getItem('estadosCobros')) this.estadosCobro = JSON.parse(localStorage.getItem('estadosCobros'))
      else await this.getEstadosCobros()
    },
    visualizar(item){
      this.dialogVerVenta.detalles = JSON.parse( JSON.stringify( item.articulos ) )
      this.dialogVerVenta.activo = true
    },
    confirmarEnvio(item){
      this.selectedOperacion = item
      this.dialogConfEnvio = true
    },
    async enviar(){
      this.$store.dispatch('executeLoadCircular', {activo: true, msj: 'Enviando pedido...'})
      await this.enviarPedido(this.selectedOperacion)
    },
    confirmarConfirmarVolverEstado(item){
      this.selectedOperacion = item
      this.dialogConfVolvEst = true
    },
    async volverEstado(){
      let indexEstado = this.selectedOperacion.estado_pedido_codigo - 1 //Vuelvo un estado atras
      var estado = this.estado_pedidos.find(estado => estado.codigo == indexEstado)
      if(Object.keys(estado).length <= 0){
        this.$store.dispatch('executeSnackBar', {msj: 'No fue posible encontrar el estado anterior', color: 'warning', activo: true})
        return
      }
      this.$store.dispatch('executeLoadCircular', {activo: true, msj: 'Volviendo estado...'})
      await this.volverEstadoPedido({item: item, estado: estado})
    },
    confirmarAnulacion(item){
      this.selectedOperacion = item
      this.dialogConfAnular = true
    },
    confirmarEntrega(item){
      this.selectedOperacion = item
      this.dialogConfEntrega = true
    },
    async entregaMacro(){
      this.$store.dispatch('executeLoadCircular', {activo: true, msj: 'Marcando como entregado...'})
      // primero busco el id del prestamo en nuestra base de datos
      let pedidoPeticion = await this.$store.dispatch('rapiCompra/getPrestamoOracle', {
        nro_pedido: this.selectedOperacion.key,
        tipo_pedido: 20,
        dni: ''
      })
      if (pedidoPeticion.resultado == 0){
        this.$store.dispatch('executeLoadCircular', {activo: false, msj: ''})
        this.$store.dispatch('executeSnackBar', {msj: pedidoPeticion.msj, color: 'error', activo: true})
        return
      }
      // encontré el pedido?
      if (pedidoPeticion.prestamos.length == 0){
        this.$store.dispatch('executeLoadCircular', {activo: false, msj: ''})
        this.$store.dispatch('executeSnackBar', {msj: 'No se encontraron prestamos asociados al seleccionado.', color: 'error', activo: true})
        return
      }
      // tengo el id de prestamo?
      if (pedidoPeticion.prestamos[0].id_prestamo == null || pedidoPeticion.prestamos[0].id_prestamo == undefined || pedidoPeticion.prestamos[0].id_prestamo.toString().length == 0){
        this.$store.dispatch('executeLoadCircular', {activo: false, msj: ''})
        this.$store.dispatch('executeSnackBar', {msj: 'El pedido seleccionado no cuenta con un prestamo generado en la página de MACRO. No se puede proceder.', color: 'error', activo: true})
        return
      }
      // no hago nada si no se encuentra aprobada
      if (pedidoPeticion.prestamos[0].estado_id != 2 && pedidoPeticion.prestamos[0].estado_id != 8){
        this.$store.dispatch('executeLoadCircular', {activo: false, msj: ''})
        this.$store.dispatch('executeSnackBar', {msj: 'El prestamo se encuentra actualmente ' + pedidoPeticion.prestamos[0].estado_oracle + '. No se puede proceder.', color: 'info', activo: true})
        return
      }
      // ahora si paso a actualizar en MACRO
      // comentado el 29-08-2023 por MMURILLO ya que no van a pasar por el estado APPROVED_BY_ECOMMERCE
      // let marcarPeticion = await this.$store.dispatch('rapiCompra/reportEntregaMACRO', {
      //   id_prestamo: pedidoPeticion.prestamos[0].id_prestamo,
      //   body: {
      //     status: 'APPROVED_BY_ECOMMERCE',
      //     rejectedCode: '',
      //     rejectedDescription: '',
      //     financialProductRequestId: ''
      //   }
      // })
      // if (marcarPeticion.resultado == 0){
      //   Swal.fire('Marcar como Entregada', marcarPeticion.msj, 'error')
      //   return
      // }
      // actualizacion exitosa en MACRO, paso a actualizar en Firebase
      let pedidoFirebase = await this.$store.dispatch('firebaseService/getUnPedidoFirebase', {
        nro_pedido: this.selectedOperacion.key
      })
      if (pedidoFirebase.resultado == 0){
        this.$store.dispatch('executeLoadCircular', {activo: false, msj: ''})
        this.$store.dispatch('executeSnackBar', {msj: pedidoFirebase.msj, color: 'error', activo: true})
        return
      }
      // obtuve el pedido de firebase, paso a actualizarlo
      let pedidoFire = pedidoFirebase.pedido
      // comentado el 18-10-2023 por MMURILLO, solo cambiamos el estado de entrega del pedido, no el estado de cobro
      //pedidoFire.estado = 'ENTREGADO'
      // marco como entregada
      pedidoFire.estado_pedido.codigo = 5
      pedidoFire.estado_pedido.estado = 'Entregado al Cliente'
      // paso a actualizar
      let updateFire = await this.$store.dispatch('firebaseService/updateRapiCompraFirebase', {
        nro_pedido: this.selectedOperacion.key,
        pedido: pedidoFire
      })
      if (updateFire.resultado == 0){
        this.$store.dispatch('executeLoadCircular', {activo: false, msj: ''})
        this.$store.dispatch('executeSnackBar', {msj: updateFire.msj, color: 'error', activo: true})
        return
      }
      // actualización correcta en firebase, actualizo el pedido en Oracle
      let updateOracle = await this.$store.dispatch('rapiCompra/setEstadoSolPrestamo', {
        estado: 'DELIVERED',
        url: pedidoPeticion.prestamos[0].link,
        nro_pedido: this.selectedOperacion.key,
        rechazoMotivo: '',
        descripRechazo: ''
      })
      if (updateOracle.resultado == 0){
        this.$store.dispatch('executeLoadCircular', {activo: false, msj: ''})
        this.$store.dispatch('executeSnackBar', {msj: updateOracle.msj, color: 'error', activo: true})
        return
      }
      // actualizacion completa
      this.$store.dispatch('executeLoadCircular', {activo: false, msj: ''})
      this.$store.dispatch('executeSnackBar', {msj: 'Se marcó como Entregada correctamente.', color: 'success', activo: true})
      this.getVentas()
    },
    confirmarSync(item){
      this.selectedOperacion = item
      this.dialogConfSync = true
    },
    async syncMacro(){
      this.$store.dispatch('executeLoadCircular', {activo: true, msj: 'Sincronizando con MACRO...'})
      // obtengo los estados de RapiCompras
      let estadosPeticion = await this.$store.dispatch('rapiCompra/getEstadosRapiCompra')
      if (estadosPeticion.resultado == 0){
        this.$store.dispatch('executeLoadCircular', {activo: false, msj: ''})
        this.$store.dispatch('executeSnackBar', {msj: estadosPeticion.msj, color: 'error', activo: true})
        return
      }
      // obtengo los datos del prestamo en oracle
      let prestamoOraclePeticion = await this.$store.dispatch('rapiCompra/getPrestamoOracle', {
        nro_pedido: this.selectedOperacion.key,
        tipo_pedido: 20,
        dni: ''
      })
      if (prestamoOraclePeticion.resultado == 0){
        this.$store.dispatch('executeLoadCircular', {activo: false, msj: ''})
        this.$store.dispatch('executeSnackBar', {msj: prestamoOraclePeticion.msj, color: 'error', activo: true})
        return
      }
      if (prestamoOraclePeticion.prestamos.length == 0){
        this.$store.dispatch('executeLoadCircular', {activo: false, msj: ''})
        this.$store.dispatch('executeSnackBar', {msj: 'No se encontraron datos del Pedido ingresado.', color: 'error', activo: true})
        return
      }
      if (prestamoOraclePeticion.prestamos[0].id_prestamo == null || prestamoOraclePeticion.prestamos[0].id_prestamo == undefined){
        this.$store.dispatch('executeLoadCircular', {activo: false, msj: ''})
        this.$store.dispatch('executeSnackBar', {msj: 'No se encontraron datos del Pedido solicitado.', color: 'error', activo: true})
        return
      }
      // obtengo el prestamo desde la API DE MACRO
      let prestamoMacroPeticion = await this.$store.dispatch('rapiCompra/getPrestamoMACRO', {id_prestamo: prestamoOraclePeticion.prestamos[0].id_prestamo})
      if (prestamoMacroPeticion.resultado == 0 || (prestamoMacroPeticion.prestamo.hasOwnProperty('code') && prestamoMacroPeticion.prestamo.code == 404)){
        this.$store.dispatch('executeLoadCircular', {activo: false, msj: ''})
        this.$store.dispatch('executeSnackBar', {msj: 'No se pudo consultar la Solicitud de Prestamo en MACRO. Por favor, intente nuevamente más tarde.', color: 'error', activo: true})
        return
      }
      // obtengo el motivo y descripcion de rechazo
      if (prestamoMacroPeticion.prestamo.hasOwnProperty('rejectedCode') && prestamoMacroPeticion.prestamo.rejectedCode != null && 
      prestamoMacroPeticion.prestamo.rejectedCode != undefined && prestamoMacroPeticion.prestamo.rejectedCode.toString().length > 0) this.rechazoMotivo = prestamoMacroPeticion.prestamo.rejectedCode
      if (prestamoMacroPeticion.prestamo.hasOwnProperty('rejectedDescription') && prestamoMacroPeticion.prestamo.rejectedDescription != null && 
      prestamoMacroPeticion.prestamo.rejectedDescription != undefined && prestamoMacroPeticion.prestamo.rejectedDescription.toString().length > 0) this.descripRechazo = prestamoMacroPeticion.prestamo.rejectedDescription
      // obtengo el estado actual
      let unEstado = estadosPeticion.estados.filter(element => element.nombre_api.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == prestamoMacroPeticion.prestamo.status.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
      if (unEstado.length == 0){
        this.estadoPrestamo = 'PENDIENTE'
        this.estadoApi = 'CREATED'
      }else{
        this.estadoPrestamo = unEstado[0].nombre.toUpperCase()
        this.estadoApi = unEstado[0].nombre_api.toUpperCase()
      }
      // asigno el link
      this.link = prestamoMacroPeticion.prestamo.urlLenderInitFlow
      // obtengo el carrito de firebase
      let firebasePeticion = await this.$store.dispatch('firebaseService/getUnPedidoFirebase', {
        nro_pedido: this.selectedOperacion.key
      })
      if (firebasePeticion.resultado == 0){
        this.$store.dispatch('executeLoadCircular', {activo: false, msj: ''})
        this.$store.dispatch('executeSnackBar', {msj: 'No se pudo obtener el carrito de compra de su Solicitud de Prestamo. Por favor, intente nuevamente más tarde.', color: 'error', activo: true})
        return
      }
      if (this.estadoPrestamo != 'CREADA' || this.estadoPrestamo == 'PENDIENTE DE CREAR'){
        this.actualizarPrestamo(firebasePeticion.pedido, this.selectedOperacion.key)
        return
      }
      this.$store.dispatch('executeLoadCircular', {activo: false, msj: ''})
      this.$store.dispatch('executeSnackBar', {msj: 'Sin datos para Sincronizar.', color: 'info', activo: true})
    },
    async actualizarPrestamo(pedidoFire, nroPedido){
      //if (pedidoFire.estado == 'PENDIENTE'){
        // paso a actualizar en Firebase
        let pedido = pedidoFire
        if (pedido.estado != 'ENTREGADO'){
          pedido.estado = (this.estadoPrestamo == 'ACEPTADA' || this.estadoPrestamo == 'FINALIZADA') ? 'APROBADO' : this.estadoPrestamo
          if (this.estadoPrestamo == 'RECHAZADA' || this.estadoPrestamo == 'ABORTADA' || this.estadoPrestamo == 'EXPIRADA') pedido.estado = 'RECHAZADO'
          pedido.numero_pedido = nroPedido
          pedido.fecha_cobro = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          // esta aceptada o finalizada?
          if (this.estadoPrestamo == 'ACEPTADA' || this.estadoPrestamo == 'FINALIZADA'){
            pedido.estado_pedido.codigo = 1
            pedido.estado_pedido.estado = 'Pendiente de Envio'
          }
          // esta aprobada por el ecommerce?
          // if (this.estadoPrestamo == 'APROBADA POR EL ECOMMERCE' || this.estadoPrestamo == 'FINALIZADA'){
          //   pedido.estado_pedido.codigo = 5
          //   pedido.estado_pedido.estado = 'Entregado al Cliente'
          // }
          let updateFirePeticion = await this.$store.dispatch('firebaseService/updateRapiCompraFirebase', {
            nro_pedido: nroPedido,
            pedido: pedido
          })
          if (updateFirePeticion.resultado == 0){
            this.$store.dispatch('executeLoadCircular', {activo: false, msj: ''})
            this.$store.dispatch('executeSnackBar', {msj: 'No se pudo actualizar los valores de la Solicitud de Prestamo. Por favor, intente nuevamente más tarde.', color: 'error', activo: true})
            return
          }
        }
      //}
      // paso a actualizar en ORACLE
      //if (pedidoOracle.estado_id == 1){
        // solo actualizo el estado
        let updateOraPeticion = await this.$store.dispatch('rapiCompra/setEstadoSolPrestamo', {
          estado: this.estadoApi,
          url: '',
          nro_pedido: nroPedido,
          rechazoMotivo: this.rechazoMotivo,
          descripRechazo: this.descripRechazo
        })
        if (updateOraPeticion.resultado == 0){
          this.$store.dispatch('executeLoadCircular', {activo: false, msj: ''})
          this.$store.dispatch('executeSnackBar', {msj: 'No se pudo actualizar actualizar el estado de la Solicitud de Prestamo. Por favor, intente nuevamente más tarde.', color: 'error', activo: true})
          return
        }
      //}
      this.$store.dispatch('executeLoadCircular', {activo: false, msj: ''})
      this.$store.dispatch('executeSnackBar', {msj: 'Sincronización correcta.', color: 'success', activo: true})
      this.getVentas()
    },
    async anular(){
      this.$store.dispatch('executeLoadCircular', {activo: true, msj: 'Anulando cobro...'})
      let anularPeticion = await this.$store.dispatch('cobros/cancelPayment', this.selectedOperacion)
      if (anularPeticion.resultado == 1){
        // armo el body que vamos a actualizar en el pedido
        let bodyAnulacion = {
          "TransactionResult": 'VOIDED',
          "estado": 'ANULADO',
          "estado_pedido": {
            codigo: 3,
            estado: 'Anulado'
          }
        }
        // envio a actualizar
        await this.updateSale({datosAnulacion: bodyAnulacion, keyUsuario: this.selectedOperacion.uid, keyPedido: this.selectedOperacion.key})
      }else{
        this.$store.dispatch('executeLoadCircular', {activo: false, msj: ''})
        this.$store.dispatch('executeSnackBar', {msj: anularPeticion.msj, color: 'error', activo: true})
      }
    },
    errorCobro(item){
      this.$store.dispatch('executeSnackBar', {msj: item.error_mensaje, color: 'error', activo: true})
    },
    visualizarDatosUsuario(item){
      this.dialogDatosPersonales.apellidos = !item.datos_personales.hasOwnProperty('apellidos') ? item.datos_personales.apellido : item.datos_personales.apellidos
      this.dialogDatosPersonales.nombre = item.datos_personales.nombre
      this.dialogDatosPersonales.documento = item.datos_personales.documento
      this.dialogDatosPersonales.fecha_nacimiento = item.datos_personales.fecha_nacimiento
      this.dialogDatosPersonales.genero = item.datos_personales.genero
      this.dialogDatosPersonales.telefono = item.datos_personales.telefono
      this.dialogDatosPersonales.calle = item.datos_personales.calle
      this.dialogDatosPersonales.calle_numero = item.datos_personales.calle_numero
      this.dialogDatosPersonales.activo = true
    },
    verCupon(item){
      this.dialogDatosCupon.codigo_autorizacion = item.codigo_autorizacion
      this.dialogDatosCupon.cuotas = item.cuotas
      this.dialogDatosCupon.fecha_cobro = item.fecha_cobro
      this.dialogDatosCupon.financiacion = item.financiacion
      this.dialogDatosCupon.importe_final = item.importe_final
      this.dialogDatosCupon.lote = item.lote
      this.dialogDatosCupon.numero = item.numero
      this.dialogDatosCupon.titular = item.titular
      this.dialogDatosCupon.documento_numero = item.documento_numero
      this.dialogDatosCupon.tarjeta_numero = item.tarjeta_numero
      this.dialogDatosCupon.marca = item.marca
      this.dialogDatosCupon.activo = true
    },
    setModalDatosCupon(value){
      this.dialogDatosCupon.activo = value
      if (this.dialogDatosCupon.activo == false){
        this.dialogDatosCupon.codigo_autorizacion = ''
        this.dialogDatosCupon.cuotas = ''
        this.dialogDatosCupon.fecha_cobro = ''
        this.dialogDatosCupon.financiacion = ''
        this.dialogDatosCupon.importe_final = ''
        this.dialogDatosCupon.lote = ''
        this.dialogDatosCupon.numero = ''
        this.dialogDatosCupon.titular = ''
        this.dialogDatosCupon.documento_numero = ''
        this.dialogDatosCupon.tarjeta_numero = ''
        this.dialogDatosCupon.marca = ''
      }
    },
    setModalDatosEnvio(value){
      this.dialogDatosEnvio.activo = value
      if (this.dialogDatosEnvio.activo == false){
        this.dialogDatosEnvio.envio_domicilio = false
        this.dialogDatosEnvio.provincia = ''
        this.dialogDatosEnvio.localidad = ''
        this.dialogDatosEnvio.local_retiro = ''
        this.dialogDatosEnvio.calle = ''
        this.dialogDatosEnvio.calle_numero = ''
        this.dialogDatosEnvio.costo_envio = ''
        this.dialogDatosEnvio.datos_adicionales = ''
      }
    },
    setModalDatosPers(value){
      this.dialogDatosPersonales.activo = value
      if (this.dialogDatosPersonales.activo == false){
        this.dialogDatosPersonales.apellidos = ''
        this.dialogDatosPersonales.nombre = ''
        this.dialogDatosPersonales.documento = ''
        this.dialogDatosPersonales.fecha_nacimiento = ''
        this.dialogDatosPersonales.genero = ''
        this.dialogDatosPersonales.telefono = ''
        this.dialogDatosPersonales.calle = ''
        this.dialogDatosPersonales.calle_numero = ''
      }
    },
    setModalVerVenta(value){
      this.dialogVerVenta.activo = value
      if (this.dialogVerVenta.activo == false) this.dialogVerVenta.detalles = []
    },
    setModalEnvio(value){
      this.dialogConfEnvio = value
    },
    setModalVolvEst(value){
      this.dialogConfEnvio = value
    },
    setModalAnular(value){
      this.dialogConfAnular = value
    },
    setModalEntrega(value){
      this.dialogConfEntrega = value
    },
    setModalSync(value){
      this.dialogConfSync = value
    },
    verEnvio(item){
      this.dialogDatosEnvio.envio_domicilio = item.datos_entrega.envio_domicilio
      this.dialogDatosEnvio.provincia = item.datos_entrega.provincia
      this.dialogDatosEnvio.localidad = item.datos_entrega.localidad
      this.dialogDatosEnvio.local_retiro = item.datos_entrega.local_retiro
      this.dialogDatosEnvio.calle = item.datos_entrega.calle
      this.dialogDatosEnvio.calle_numero = item.datos_entrega.calle_numero
      this.dialogDatosEnvio.costo_envio = item.datos_entrega.costo_envio
      this.dialogDatosEnvio.datos_adicionales = item.datos_entrega.datos_adicionales
      this.dialogDatosEnvio.activo = true
    },
    validate(){
      this.$refs.formSearch.validate()
    },
    refrescarDataTable(item, estado){
      let pos = this.ventas.indexOf(item)
      if (parseInt(pos) !== -1){
        this.ventas[pos].estado_pedido = estado.estado
        this.ventas[pos].estado_pedido_codigo = estado.codigo
      }
    }
  },
  watch: {
    srvAnular:function(){
      if(this.srvAnular == 1){
        /*this.$swal({title:'Esta seguro de anular el pedido?',timer: 3000,
                    icon: 'success'})*/
      }else{
        /*this.$swal({title:this.srvAnular.msj,
                    icon: 'warning'})*/
      }
    },
    respEstadosPedidos: function(){
      if (Object.keys(this.respEstadosPedidos).length > 0){
        if (parseInt(this.respEstadosPedidos.resultado) === 1){
          this.estado_pedidos = this.respEstadosPedidos.estados
          // guardo en localStorage los estados
          localStorage.setItem('estadosEnvio', JSON.stringify(this.respEstadosPedidos.estados))
        }
      }
    },
    respEstadosCobros: function(){
      if (Object.keys(this.respEstadosCobros).length > 0){
        if (parseInt(this.respEstadosCobros.resultado) === 1){
          this.estadosCobro = this.respEstadosCobros.estados
          localStorage.setItem('estadosCobros', JSON.stringify(this.respEstadosCobros.estados))
        }
      }
    },
    respVentasEcommerce: function(){
      if (Object.keys(this.respVentasEcommerce).length > 0){
        this.load = false
        if (parseInt(this.respVentasEcommerce.resultado) === 1){
          this.ventas = this.respVentasEcommerce.ventas
        }else{
          // es porque se vencio el token?
          if (parseInt(this.respVentasEcommerce.tokenExpirado) === 1){
            // redireccionar al Login
            this.$store.dispatch('executeSnackBar', {msj: 'Su sesión se encuentra vencida. Por favor, vuelva a ingresar al Sistema.', color: 'info', activo: true})
            router.push({name: 'LoginAdmin'})
          }else{
            this.$store.dispatch('executeSnackBar', {msj: this.respVentasEcommerce.msj, color: 'error', activo: true})
          }
        }
      }
    },
    respEstadoPedido: function(){
      this.$store.dispatch('executeLoadCircular', {activo: false, msj: ''})
      if (Object.keys(this.respEstadoPedido).length > 0){
        if (parseInt(this.respEstadoPedido.resultado) === 1){
          this.$store.dispatch('executeSnackBar', {msj: 'El estado fue Actualizazo Exitosamente.', color: 'success', activo: true})
          this.refrescarDataTable(this.respEstadoPedido.item, this.respEstadoPedido.estado)
        }else this.$store.dispatch('executeSnackBar', {msj: this.respEstadoPedido.msj, color: 'error', activo: true})
      }
    },
    respDatosApiJarvis: function(){
      if (Object.keys(this.respDatosApiJarvis).length > 0){
        if (parseInt(this.respDatosApiJarvis.resultado) === 1){
          if (parseInt(this.intentosLoginJarvis) <= 3){
            this.intentosLoginJarvis = this.intentosLoginJarvis + 1
            // refresco la pagina
            location.reload()
          }else{

          }
        }
      }
    },
    respCancelPayment: async function(){
      if (Object.keys(this.respCancelPayment).length > 0){
        if (parseInt(this.respCancelPayment.resultado) === 1){
          // anulación exitosa?
          if (parseInt(this.respCancelPayment.anulacionDatos.exito) === 1){
            // exito real de anulacion, modificar el pedido del cliente indicando que su pedido fue anulado, de la misma forma en el nodo de pedidos
            // armo el body que vamos a actualizar en el pedido
            let bodyAnulacion = {
              "TransactionResult": 'VOIDED',
              "estado": 'ANULADO',
              "estado_pedido": {
                codigo: 3,
                estado: 'Anulado'
              }
            }
            // envio a actualizar
            await this.updateSale({datosAnulacion: bodyAnulacion, keyUsuario: this.selectedOperacion.uid, keyPedido: this.selectedOperacion.key})
          }else{
            // error al anular
            Swal.fire({
              title: 'Anulando Pedido',
              text: 'La operación no puede ser anulada: ' + this.respCancelPayment.msj,
              icon: 'error'
            })
          }
        }else{
          // error al anular
          Swal.fire({
            title: 'Anulando Pedido',
            text: 'La operación tuvo un problema al anular: ' + this.respCancelPayment.msj,
            icon: 'error'
          })
        }
      }
    },
    respUpdateSale: function(){
      if (Object.keys(this.respUpdateSale).length > 0){
        this.$store.dispatch('executeLoadCircular', {activo: false, msj: ''})
        if (parseInt(this.respUpdateSale.resultado) === 1){
          // actualizacion del estado del pedido exitosa
          this.$store.dispatch('executeSnackBar', {msj: 'La operación se anuló correctamente.', color: 'success', activo: true})
          this.getVentas()
        }else{
          // error durante la actualizacion del estado del pedido, pero la anulacion se supone correcta
          this.$store.dispatch('executeSnackBar', {msj: 'La operación se anuló correctamente pero ocurrio un problema al actualizar el pedido del cliente: ' + this.respUpdateSale.msj, color: 'error', activo: true})
        }
      }
    },
    selectedEstadoCobro: function(){
      if (this.selectedEstadoCobro == null || this.selectedEstadoCobro == undefined) this.selectedEstadoCobro = {}
    },
    selectedEstadoPedido: function(){
      if (this.selectedEstadoPedido == null || this.selectedEstadoPedido == undefined) this.selectedEstadoPedido = {}
    }
  },
}
</script>

<style>
  /* .v-data-table-header{
    background-color:#D8D8D8;
  } */
  .img_tam{
    width: 150px;
  }
</style>