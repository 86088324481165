<template>
  <v-container fluid class="pa-0">
    <v-row
      style="padding: 0px; height: 100%; margin: 0px;"
    >
      <!-- Listado de Articulos y Acciones -->
      <v-col
        cols="12"
        class="pa-0"
      >
        <v-snackbar
          v-model="snackBar"
          :timeout="3500"                    
          :color="snackBarColor"  
          top
        >
          <span> {{ snackBarText }} </span>
        </v-snackbar>
        <v-card>
          <v-toolbar dense flat >
            <v-toolbar-title><v-icon>mdi-tag-multiple-outline</v-icon> Productos</v-toolbar-title>
            <v-spacer></v-spacer>             
            <v-btn rounded color="teal accent-4" dark>
                <v-icon small>mdi-plus-circle-outline</v-icon>&nbsp;Agregar
            </v-btn>
          </v-toolbar>
          <v-card-title>
            <v-switch
              v-model="switch1"
              :label="msjSwitch"
              color="accent"
              @click="rearmarArticulos"
              :disabled="articulos.length == 0"
            ></v-switch>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"                        
                append-icon="mdi-magnify"
                label="Buscar"
                dense single-line hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="articulos"
            :search="search"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="(item,index) in items" :key="index">                
                  <td>
                    <img :src="(item.img_principal != null && item.img_principal != undefined && item.img_principal && item.img_principal != 0) ? item.img_principal : 
                    (item.imagenes_mini != null && item.imagenes_mini != undefined && item.imagenes_mini != 0 && Object.values(item.imagenes_mini).length > 0) ? Object.values(item.imagenes_mini)[0].url : 
                    (item.imagenes != null && item.imagenes != undefined && item.imagenes != 0 && Object.values(item.imagenes).length > 0) ? Object.values(item.imagenes)[0].url : ''" alt=""  class="img_tam"/>
                  </td>
                  <td>  
                    {{item.art_nom_e}}
                    <br/>
                    <strong>  {{ item.art_rubro_nom}} {{item.art_categoria_nom}}</strong>
                  </td>
                  <td>${{item.precios}}</td>
                  <td><strong>{{item.stock.stockTotal}} </strong> </td>
                  <td>
                    <v-chip small :color="getColor(item.art_estado)" dark>{{getEstadoName(item.art_estado)}}</v-chip>
                  </td>
                  <td>
                    <v-icon title="Editar Articulo" color="warning" @click="editarArticulo(item)">mdi-square-edit-outline</v-icon>
                    <v-icon title="Agregar Imagenes Grandes" color="indigo" @click="imagenesArticulo(item)">mdi-image-multiple</v-icon>                               
                    <v-icon title="Agregar Imagenes Mini" color="success" @click="imagenesArticuloMini(item)">mdi-image-multiple</v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- Modal Editar Articulo -->
    <template>
      <v-dialog  v-model="modalEditarArticulo" width="75%">           
        <ArticuloEditar 
          :key_firebase="art.key_firebase" 
          :articulo="art"  
          @close="closeModalEditarArticulo"
        ></ArticuloEditar>
      </v-dialog>   
    </template>
    <!-- Modal Imagenes Grandes -->
    <v-dialog
      v-model="modalImagenesArticulo"
      scrollable  
      max-width="800px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar dense flat >
          <v-toolbar-title>{{art.art_nom_e}} / {{art.art_codigo}} </v-toolbar-title>   
        </v-toolbar>
        <v-card-text>      
          <p>*Para seleccionar alguna imagen como principal haga click sobre la imagen</p>
          <v-row>           
            <v-col cols="5" md="3"  v-for="(img,index) in art.imagenes" :key="index">                                        
              <img :src=" img.url" alt=""  class="img_tam_modal"/>
            </v-col>
          </v-row>
          <v-checkbox  v-model="checkbox" label="Subir solo Principal"></v-checkbox>
          <v-container fluid style="margin: 0px; padding: 0px; width: 100%">
            <vue-dropzone  
              ref="dropzone"
              id="dropzone"  
              :options="dropzoneOptions" 
              :useCustomSlot=true
              :duplicateCheck=true
              @vdropzone-sending="sendingAllFiles"
              @vdropzone-complete-multiple="completeAllUpload"
              @vdropzone-duplicate-file="verifyDuplicateFile"
            >
              <div>
                <h3 class="dropzone-custom-title">Arrastra y suelta para cargar el contenido</h3>
                <div class="subtitle">...o haga clic para seleccionar un archivo de su computadora</div>                               
              </div>
            </vue-dropzone>
            <br/>
            <v-btn small rounded color="error" @click="removeAllFiles"  ><v-icon small left style="margin-right:1px">mdi-trash-can-outline</v-icon>limpiar</v-btn>                  
            <v-layout align-end justify-center row>
              <v-btn small rounded color="success" @click="uploadAllFiles"><v-icon small left style="margin-right:1px">mdi-upload</v-icon>subir</v-btn>          
            </v-layout>   
          </v-container>   
        </v-card-text>      
      </v-card>
    </v-dialog>
    <!-- Modal Imagenes Mini -->
    <v-dialog
      v-model="modalImagenesArticuloMini"
      scrollable  
      max-width="800px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar dense flat >
          <v-toolbar-title>{{art.art_nom_e}} / {{art.art_codigo}} </v-toolbar-title>   
        </v-toolbar>
        <v-card-text>      
          <p>*Para seleccionar alguna imagen como principal haga click sobre la imagen</p>
          <v-row>           
            <v-col cols="5" md="3"  v-for="(img,index) in art.imagenes" :key="index">                                        
              <img :src=" img.url" alt=""  class="img_tam_modal"/>
            </v-col>
          </v-row>
          <v-checkbox  v-model="checkbox" label="Subir solo Principal"></v-checkbox>
          <v-container fluid style="margin: 0px; padding: 0px; width: 100%">
            <vue-dropzone  
              ref="dropzoneMini"
              id="dropzoneMini"  
              :options="dropzoneOptionsMini" 
              :useCustomSlot=true
              :duplicateCheck=true
              @vdropzone-sending="sendingAllFilesMini"
              @vdropzone-complete-multiple="completeAllUploadMini"
              @vdropzone-duplicate-file="verifyDuplicateFileMini"
            >
              <div>
                <h3 class="dropzone-custom-title">Arrastra y suelta para cargar el contenido</h3>
                <div class="subtitle">...o haga clic para seleccionar un archivo de su computadora</div>                               
              </div>
            </vue-dropzone>
            <br/>
            <v-btn small rounded color="error" @click="removeAllFilesMini"  ><v-icon small left style="margin-right:1px">mdi-trash-can-outline</v-icon>limpiar</v-btn>                  
            <v-layout align-end justify-center row>
              <v-btn small rounded color="success" @click="uploadAllFilesMini"><v-icon small left style="margin-right:1px">mdi-upload</v-icon>subir</v-btn>          
            </v-layout>   
          </v-container>   
        </v-card-text>      
      </v-card>
    </v-dialog>
    <!-- Modal Loading -->
    <v-dialog v-model="dialogLoading" persistent width="350">
      <v-card color="#ed1841" dark>
        <v-card-title>
          {{ tituloModal }}
        </v-card-title>
        <v-card-text>
          <v-progress-circular
            style="margin-left:125px;"
            :size="50"
            :width="8"
            color="#ffff"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ArticuloEditar from '../../components/admin/articulos/ArticuloEditar'
import router from '../../router'
import {mapActions, mapState} from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {
  data() {
    return {
      search: '',
      headers: [
        { text: 'Producto', align: 'center',sortable: false,value:'art_nom_e'},
        { text: 'Nombre',align: 'center',value:'art_nom_e'},
        { text: 'Precio',align: 'center',value:'precios'},
        { text: 'Stock',align: 'center',value:'stock.stockTotal' },
        { text: 'Estado',align: 'center' },
        { text: 'Acciones',sortable:false,align: 'center' },
      ],      
      dropzoneOptions: {    
        url: '/', //ruta donde se encuentra el proceso que guarda los archivos
        autoProcessQueue: false, //condicion para que los archivos se suban una vez que se agregan 
        uploadMultiple: true, //condicion para subir varios archivos    
        parallelUploads : 10, //Cuántas cargas de archivos procesar en paralelo
        thumbnailWidth: 150,//ancho de la imagen que se ve en la vista previa  
        thumbnailHeight: 150,//alto de la imagen que se ve en la vista previa
        addRemoveLinks: true,//agrega a cada elemento un link para borrarlo de la zona de carga
        acceptedFiles : "image/webp",//Tipo de extensiones aceptadas
        maxFiles: 10, //cantidad maxima de archivos admitidos
        maxFilesize : 5,//tamaño maximo admitido por archvio
      },
      dropzoneOptionsMini: {    
        url: '/', //ruta donde se encuentra el proceso que guarda los archivos
        autoProcessQueue: false, //condicion para que los archivos se suban una vez que se agregan 
        uploadMultiple: true, //condicion para subir varios archivos    
        parallelUploads : 10, //Cuántas cargas de archivos procesar en paralelo
        thumbnailWidth: 150,//ancho de la imagen que se ve en la vista previa  
        thumbnailHeight: 150,//alto de la imagen que se ve en la vista previa
        addRemoveLinks: true,//agrega a cada elemento un link para borrarlo de la zona de carga
        acceptedFiles : "image/webp",//Tipo de extensiones aceptadas
        maxFiles: 10, //cantidad maxima de archivos admitidos
        maxFilesize : 5,//tamaño maximo admitido por archvio
      },
      modalEditarArticulo : false,
      modalImagenesArticulo :false,
      modalImagenesArticuloMini: false,
      snackBar : false,
      snackBarText : '',
      snackBarColor : '',
      art : "",
      dialogLoading : false,
      tituloModal : "",
      checkbox : false,
      articulos: [],
      switch1: false,
      msjSwitch: 'Incluir Stock en 0: No'
    }
  },
  components: {
    ArticuloEditar,
    vueDropzone: vue2Dropzone
  },
  computed: {
    ...mapState(['respArtsAdmin', 'respUploadImgAdmin'])
  },
  created () {
    this.usuarioLogueado()
  },
  methods: {
    ...mapActions(['getArticulosAdmin', 'uploadImagesEcommerce']),
    usuarioLogueado(){
      if (localStorage.getItem('tokenAdmin')){
        // prosigo con la carga
        this.getArticulosAdmin()
      }else{
        // redirecciono al Login
        router.push({name: 'LoginAdmin', params: {sesionExpirada: 1}})
      }
    },
    rearmarArticulos(){
      // pregunto si muestro o no articulos con stock mayor a 0
      if (this.switch1 == false){
        // solo los que son mayor a 0
        let listado = []
        for (let id in this.respArtsAdmin.articulos){
          // es un array?
          if (Array.isArray(Object.values(this.respArtsAdmin.articulos[id].stock))){
            // si lo es, tiene stockTotal como campo?
            if (this.respArtsAdmin.articulos[id].stock.hasOwnProperty('stockTotal')){
              // controlo si es mayor a 0
              if (parseInt(this.respArtsAdmin.articulos[id].stock.stockTotal) > 0){
                listado.push(this.respArtsAdmin.articulos[id])
              }
            }
          }
        }
        this.articulos = listado
      }else{
        // muestro todos
        this.articulos = this.respArtsAdmin.articulos
      }
    },
    getColor(estado) {
      if (parseInt(estado) == 0) return 'green'
      else if (parseInt(estado) == 1) return 'orange'
    },
    getEstadoName(estado){
      if (parseInt(estado) == 0) return 'Vigente'
      else if (parseInt(estado) == 1) return 'No Vigente'
    },
    editarArticulo(articulo){
      this.art = {}
      this.art = articulo
      this.modalEditarArticulo = true
    },
    refreshDataTable(datos){
      let pos = this.articulos.indexOf(this.art)
      if (parseInt(pos) !== -1){
        this.articulos[pos].art_estado = datos.art_estado
        this.articulos[pos].art_estado_nom = datos.art_estado_nom
        this.articulos[pos].art_nom_e = datos.art_nom_e
        this.articulos[pos].descuento = datos.descuento
        this.articulos[pos].nuevo_producto = datos.nuevo_producto
      }
    },
    closeModalEditarArticulo(articuloRespuesta){
      this.modalEditarArticulo = false
      this.refreshDataTable(articuloRespuesta)
    },        
    imagenesArticulo(articulo){
      this.art = articulo
      this.checkbox = false
      this.modalImagenesArticulo = true
    },
    imagenesArticuloMini(articulo){
      this.art = articulo
      this.checkbox = false
      this.modalImagenesArticuloMini = true
    },
    completeAllUpload(response){
      if (response[0].status.toUpperCase() == 'ERROR'){
        Swal.fire(
          'Subiendo Imagenes', 
          'Formato no Admitido', 
          'info'
        )
        this.removeAllFiles()
      }
    },
    completeAllUploadMini(response){
      if (response[0].status.toUpperCase() == 'ERROR'){
        Swal.fire(
          'Subiendo Imagenes', 
          'Formato no Admitido', 
          'info'
        )
        this.removeAllFilesMini()
      }
    },
    sendingAllFiles(file, xhr, formData){
      //esta funcion adjunta parametros extra a la peticion de suba de archivos
      formData.append('art_codigo', '2550'); //parametros que se envian junto con las archivos
    },
    sendingAllFilesMini(file, xhr, formData){
      //esta funcion adjunta parametros extra a la peticion de suba de archivos
      formData.append('art_codigo', '2550'); //parametros que se envian junto con las archivos
    },
    verifyDuplicateFile(file){
      //al agregar un archivo duplicado al dropzone se ejecuta esta advertencia
      this.snackBarText = 'El Archivo  '+ file.name +' ya se encuentra agregado.'
      this.snackBarColor = 'orange darken-1'
      this.snackBar = true
    },
    verifyDuplicateFileMini(file){
      //al agregar un archivo duplicado al dropzone se ejecuta esta advertencia
      this.snackBarText = 'El Archivo  '+ file.name +' ya se encuentra agregado.'
      this.snackBarColor = 'orange darken-1'
      this.snackBar = true
    },
    removeAllFiles() {
      //elimina todos los elemetos del dropzone 
      this.$refs.dropzone.removeAllFiles();
    },
    removeAllFilesMini() {
      //elimina todos los elemetos del dropzone 
      this.$refs.dropzoneMini.removeAllFiles();
    },
    async uploadAllFiles(){
      Swal.fire({
        title: 'Subir Imagenes',
        text: 'Esta subiendo IMAGENES GRANDES ¿Confirma esta accion?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.tituloModal = 'Subiendo Imagenes...'
          this.dialogLoading  = true
          //obtener todas las imagenes de la zona de caida
          let files =  this.$refs.dropzone.getAcceptedFiles(this.$refs.dropzone)
          if (files.length > 0){
            await this.uploadImagesEcommerce({files: files, opcion: 1, articuloCodigo: this.art.art_codigo, keyFirebase: this.art.key_firebase})
          }else{
            this.dialogLoading  = false
            Swal.fire(
              'Subir Imagenes', 
              'Sin Imagenes para Subir', 
              'info'
            )
          }
        }
      })
    },
    async uploadAllFilesMini(){
      Swal.fire({
        title: 'Subir Imagenes',
        text: 'Esta subiendo IMAGENES MINI ¿Confirma esta accion?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.tituloModal = 'Subiendo Imagenes Mini...'
          this.dialogLoading  = true
          //obtener todas las imagenes de la zona de caida
          let files =  this.$refs.dropzoneMini.getAcceptedFiles(this.$refs.dropzoneMini)
          if (files.length > 0){
            await this.uploadImagesEcommerce({files: files, opcion: 2, articuloCodigo: this.art.art_codigo, keyFirebase: this.art.key_firebase})
          }else{
            this.dialogLoading  = false
            Swal.fire(
              'Subir Imagenes', 
              'Sin Imagenes para Subir', 
              'info'
            )
          }
        }
      })
    },
  },
  watch: {
    respArtsAdmin: function(){
      if (Object.keys(this.respArtsAdmin).length > 0){
        if (parseInt(this.respArtsAdmin.resultado) === 1){
          for (let id in this.respArtsAdmin.articulos){
            this.respArtsAdmin.articulos[id].art_nom_e = this.respArtsAdmin.articulos[id].art_codigo + '-' + this.respArtsAdmin.articulos[id].art_nom_e
          }
          this.rearmarArticulos()
        }
      }
    },
    switch1: function(){
      if (this.switch1 == true){
        this.msjSwitch = 'Incluir Stock en 0: Si'
      }else{
        this.msjSwitch = 'Incluir Stock en 0: No'
      }
    },
    respUploadImgAdmin: function(){
      if (Object.keys(this.respUploadImgAdmin).length > 0){
        this.dialogLoading  = false
        if (parseInt(this.respUploadImgAdmin.resultado) === 1){
          Swal.fire(
            'Subiendo Imagenes', 
            'Imagenes subidas exitosamente', 
            'success'
          )
        }else{
          Swal.fire(
            'Subiendo Imagenes', 
            this.respUploadImgAdmin.msj, 
            'error'
          )
        }
        // limpio las imagenes seleccionadas
        if (parseInt(this.respUploadImgAdmin.opcion) === 1){
          this.removeAllFiles()
        }else{
          this.removeAllFilesMini()
        }
      }
    }
  },
}
</script>

<style>
  /* .v-data-table-header{
    background-color:#D8D8D8;
  } */
  .img_tam{
    width: 150px;
  }
  .img_tam_modal{
        width: 150px;
  }
  @media  (max-width: 600px) {
    .img_tam{
        display: none;
    }
  }
</style>