var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"transition":"scale-transition","max-width":"290px","min-width":"auto","close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{staticClass:"d-flex"},[_c('v-text-field-simplemask',{attrs:{"label":_vm.label,"properties":{
          placeholder: 'dd/mm/aaaa',
          clearable: _vm.clearable,
          'hide-details': _vm.hideDetails,
          readonly: _vm.disabled,
          filled: _vm.disabled,
          outlined: true,
          dense: true
        },"options":{
          inputMask: '##/##/####',
          outputMask: '##/##/####',
          empty: null
        }},on:{"blur":function($event){_vm.disabled ? '' : _vm.validar_fecha()},"change":function($event){return _vm.$emit('change')}},model:{value:(_vm.fecha),callback:function ($$v) {_vm.fecha=$$v},expression:"fecha"}}),_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",staticStyle:{"margin-top":"-2px"},attrs:{"color":_vm.disabled ? '' : 'info',"title":_vm.disabled ? '' : 'Seleccionar fecha',"disabled":_vm.disabled,"icon":""},on:{"click":function($event){_vm.date = _vm.parseDate(_vm.fecha)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-blank")])],1)],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{key:_vm.key,attrs:{"min":_vm.parseDate(_vm.fecha_minima),"max":_vm.parseDate(_vm.fecha_maxima),"no-title":""},on:{"input":function($event){_vm.menu = false},"change":function($event){return _vm.$emit('change')}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }