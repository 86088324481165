<template>
<div style="background-color: #f2f2f2">
<div>
  <Menu 
    :provinciaActual="provinciaNombre"
    :codPostalActual="codigoPostal"
    @cargarDashboardMovil="desplegarMenuMovil"
    @obtenerMenus="asignarMenus"
    @reloadPage="recargarPagina"
    @setDomicilioEntrega="ingresarDomEntrega"
  />
</div>
<div :style="$vuetify.breakpoint.width > 960 ? 'padding-top: 120px' : 'padding-top: 120px'">
  <ContenidoArticulos 
    :articulos="articulosEcommerce"
    :filtros="categoriaActualFiltro"
    :ordenFiltros="filtrosOrden"
  />
</div>
<div>
  <Footer 
    :listadoNulo="listadoVacio"
    :menus="menuHome"
  />
  <Loading :activo="dialogCarga"/>
  <DashboardMovil 
    :drawerParam="drawerHome"
    :groupParam="groupHome"
    :menus="menuHome"
    :subMenus="subMenuHome"
    @closeMenuMovil="cerrarMenuMovil"
  />
  <domicilioEntrega 
    :activo="dialogDomEntrega"
    @closeDomicilioEntrega="ingresarDomEntrega"
    @setDatosEntrega="setearDatosEntrega"
  />
</div>
</div>
</template>

<script>
import Menu from '../components/index/Menu'
import Footer from '../components/index/Footer'
import ContenidoArticulos from '../components/articulos/ContenidoArticulos'
import {mapActions, mapState} from 'vuex'
import Loading from '../components/genericos/Loading'
import DashboardMovil from '../components/movil/DashboardMovil'
import domicilioEntrega from '../components/index/domicilioEntrega.vue'
export default {
  components: {
    Menu,
    Footer,
    ContenidoArticulos,
    Loading,
    DashboardMovil,
    domicilioEntrega
  },
  computed: {
    ...mapState(['respCategoriaActual', 'respArtsEcommerceCat', 'rutaActual', 'respCatActualFiltro', 'respDatosApiJarvis'])
  },
  created () {
    this.iniciarFormulario()
  },
  data() {
    return {
      categoriaActual: '',
      articulosEcommerce: [],
      dialogCarga: false,
      categoriaActualFiltro: {},
      drawerHome: false,
      groupHome: null,
      menuHome: [],
      subMenuHome: [],
      intentosLoginJarvis: 0,
      dialogDomEntrega: false,
      provinciaNombre: '',
      codigoPostal: '',
      listadoVacio: false,
      filtrosOrden: []
    }
  },
  methods: {
    ...mapActions(['getCodCategoria', 'getArtsXCategoria', 'getArticulosCategoria']),
    async iniciarFormulario(){
      this.articulosEcommerce = []
      this.dialogCarga = true
      await this.getCodCategoria(this.$route.params.categoria)
    },
    async getArtsCategoria(articulos){
      await this.getArtsXCategoria({codCategoria: this.categoriaActual, articulos: articulos})
    },
    async getCategoriaActual(){
      await this.getArticulosCategoria(this.$route.params.categoria)
    },
    desplegarMenuMovil(){
      this.drawerHome = true
    },
    cerrarMenuMovil(group){
      this.drawerHome = false
      this.groupHome = group
    },
    asignarMenus(menus){
      this.menuHome = menus.menus
      this.subMenuHome = menus.subMenus
    },
    recargarPagina(){
      window.location.reload()
    },
    ingresarDomEntrega(bandDialog){
      this.dialogDomEntrega = bandDialog
    },
    setearDatosEntrega(){
      let datosEntrega = JSON.parse(localStorage.getItem('enviarA'))
      this.provinciaNombre = datosEntrega.provinciaNombre
      this.codigoPostal = datosEntrega.codigoPostal
    }
  },
  watch: {
    respCategoriaActual: function(){
      if (Object.keys(this.respCategoriaActual).length > 0){
        if (parseInt(this.respCategoriaActual.resultado) === 1){
          this.categoriaActual = this.respCategoriaActual.codigo
          // procedo a buscar los articulos con esta categoria
          this.getArtsCategoria(this.respCategoriaActual.articulos)
        }
      }
    },
    respArtsEcommerceCat: async function(){
      if (Object.keys(this.respArtsEcommerceCat).length > 0){
        this.articulosEcommerce = []
        if (parseInt(this.respArtsEcommerceCat.resultado) === 1){
          // tengo mi array de articulos, armo la estructura que necesito para mi listado de tarjetas
          let artsPeticion = await this.$store.dispatch('articulos/getArrayArticulos', {articulos: this.respArtsEcommerceCat.articulos, opcion: 0, categoria: 0, imgGrandes: 0})
          if (artsPeticion.resultado == 0){
            this.dialogCarga = false
            this.$store.dispatch('executeSnackBar', {msj: artsPeticion.msj, color: 'error', activo: true})
            return
          }
          this.articulosEcommerce = artsPeticion.listado
        }
        this.getCategoriaActual()
      }
    },
    respCatActualFiltro: function(){
      if (Object.keys(this.respCatActualFiltro).length > 0){
        this.dialogCarga = false
        if (parseInt(this.respCatActualFiltro.resultado) === 1){
          this.categoriaActualFiltro = this.respCatActualFiltro.categoria
          this.filtrosOrden = this.respCatActualFiltro.filtrosOrden
        }
      }
    },
    respDatosApiJarvis: function(){
      if (Object.keys(this.respDatosApiJarvis).length > 0){
        if (parseInt(this.respDatosApiJarvis.resultado) === 1){
          if (parseInt(this.intentosLoginJarvis) <= 3){
            this.intentosLoginJarvis = this.intentosLoginJarvis + 1
            // iniciar el formulario nuevamente
            this.iniciarFormulario()
          }else{
            console.log(this.respDatosApiJarvis.msj)
            this.dialogCarga = false
          }
        }
      }
    },
    articulosEcommerce: function(){
      if (this.articulosEcommerce.length === 0){
        this.listadoVacio = true
      }else{
        this.listadoVacio = false
      }
    }
  },
}
</script>

<style scoped>

</style>