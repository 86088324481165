<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="50%"
    >
      <v-card
        flat
      >
        <v-card-title>
          <v-icon class="ma-2">mdi-credit-card</v-icon> Datos del Cupón
          <v-spacer></v-spacer>
          <v-btn icon @click="activo = false" title="Cerrar"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="pt-2">
          <!-- Fecha de Cobro, Titular y Nro de Documento -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" sm="4" md="4" class="py-2">
              <v-text-field
                v-model="fecha_cobro" 
                label="Fecha Cobro"
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-2">
              <v-text-field
                v-model="titular" 
                label="Titular"
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-2">
              <v-text-field 
                v-model="documento_numero" 
                label="N° Documento"
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Cód. Autorizacion, Nro. Tarjeta, Lote y Nro -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" sm="3" md="3" class="py-2">
              <v-text-field 
                v-model="codigo_autorizacion" 
                label="Código Autorización" 
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="py-2">
              <v-text-field
                v-model="tarjeta_numero" 
                label="Tarjeta Numero" 
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="py-2">
              <v-text-field 
                v-model="lote" 
                label="Lote" 
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="py-2">
              <v-text-field
                v-model="numero" 
                label="Número"
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Marca, Importe y Cuotas -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" sm="4" md="4" class="py-2">
              <v-text-field
                v-model="marca" 
                label="Marca" 
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-2">
              <v-text-field 
                prefix="$" 
                v-model="importe_final" 
                label="Importe" 
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-2">
              <v-text-field 
                v-model="cuotas" 
                label="Cuotas" 
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Financiación -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" class="py-2">
              <v-text-field
                v-model="financiacion" 
                label="Financiacion" 
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end mx-2">
          <v-btn color="error" @click="activo = false">Volver</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalDatosCupon', value)
      }
    },
    fecha_cobro: {
      get(){
        return this.datos.fecha_cobro
      }
    },
    titular: {
      get(){
        return this.datos.titular
      }
    },
    documento_numero: {
      get(){
        return this.datos.documento_numero
      }
    },
    codigo_autorizacion: {
      get(){
        return this.datos.codigo_autorizacion
      }
    },
    tarjeta_numero: {
      get(){
        return this.datos.tarjeta_numero
      }
    },
    lote: {
      get(){
        return this.datos.lote
      }
    },
    numero: {
      get(){
        return this.datos.numero
      }
    },
    marca: {
      get(){
        return this.datos.marca
      }
    },
    importe_final: {
      get(){
        return this.datos.importe_final
      }
    },
    cuotas: {
      get(){
        return this.datos.cuotas
      }
    },
    financiacion: {
      get(){
        return this.datos.financiacion
      }
    },
  },
}
</script>

<style>

</style>