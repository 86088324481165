<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="50%"
    >
      <v-card
        flat
      >
        <v-card-title class="pb-0">
          <v-icon class="ma-2">mdi-moped-outline</v-icon> Datos del Envío
          <v-spacer></v-spacer>
          <v-btn icon @click="activo = false" title="Cerrar"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="pt-2">
          <!-- Envío a Domicilio -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <!-- Envio a Domicilio -->
            <v-col cols="12" class="py-0">
              <v-checkbox 
                v-model="envio_domicilio" 
                label="Entrega a domicilio" 
                disabled
              ></v-checkbox>
            </v-col>
          </v-row>
          <!-- Provincia, Localidad y Local de Retiro -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" :sm="!envio_domicilio ? 4 : 6" :md="!envio_domicilio ? 4 : 6" class="py-2">
              <v-text-field 
                v-model="provincia" 
                label="Provincia" 
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" :sm="!envio_domicilio ? 4 : 6" :md="!envio_domicilio ? 4 : 6" class="py-2">
              <v-text-field 
                v-model="localidad" 
                label="Localidad" 
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-2" v-if="!envio_domicilio">
              <v-text-field 
                v-model="local_retiro" 
                label="Local de retiro" 
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Calle, Número y Costo de Envío -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" sm="4" md="4" class="py-2">
              <v-text-field 
                v-model="calle" 
                label="Calle" 
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-2">
              <v-text-field 
                v-model="calle_numero" 
                label="Número"
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-2">
              <v-text-field 
                prefix="$" 
                v-model="costo_envio" 
                label="Costo de Envio" 
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Datos Adicionales -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" class="py-2">
              <v-text-field 
                v-model="datos_adicionales" 
                label="Datos adicionales" 
                type="text"
                outlined 
                dense 
                readonly
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end mx-2">
          <v-btn color="error" @click="activo = false">Volver</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalDatosEnvio', value)
      }
    },
    envio_domicilio: {
      get(){
        return this.datos.envio_domicilio
      }
    },
    provincia: {
      get(){
        return this.datos.provincia
      }
    },
    localidad: {
      get(){
        return this.datos.localidad
      }
    },
    local_retiro: {
      get(){
        return this.datos.local_retiro
      }
    },
    calle: {
      get(){
        return this.datos.calle
      }
    },
    calle_numero: {
      get(){
        return this.datos.calle_numero
      }
    },
    costo_envio: {
      get(){
        return this.datos.costo_envio
      }
    },
    datos_adicionales: {
      get(){
        return this.datos.datos_adicionales
      }
    }
  },
}
</script>

<style>

</style>