<template>
  <v-container fluid style="height: 100%; padding: 0px;">
    <v-row
      style="padding: 0px; height: 100%; margin: 0px;"
    >
      <v-col
        cols="12"
        style="height: 100%; padding-bottom: 0px;"
      >
        <v-card>
          <v-toolbar dense flat >
            <v-toolbar-title><v-icon>mdi-image-multiple</v-icon> Publicidad Inicio</v-toolbar-title>
            <v-spacer></v-spacer>       
            <v-btn rounded color="teal accent-4" dark @click="showModalNewPublicidad()"><v-icon small>mdi-plus-circle-outline</v-icon>&nbsp;Agregar</v-btn>
          </v-toolbar>             
          <v-card-title>
            <v-text-field
              v-model="search"                 
              append-icon="mdi-magnify"
              label="Buscar"
              single-line dense
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="publicidades"
            :search="search"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="(item,index) in items" :key="index">
                  <td> {{item.nombre}} </td>
                  <td>  
                    <v-icon color="indigo" @click="verImagen(item)">mdi-image</v-icon>
                  </td>
                  <td> 
                    <v-icon color="indigo" @click="verLink(item)">mdi-open-in-new</v-icon>
                  </td>
                  <td>
                    <v-chip small :color="getColor(item.inhabilitado)" dark>{{getEstadoName(item.inhabilitado)}}</v-chip>             
                  </td>                                           
                  <td>
                    <v-icon color="primary" @click="showModalEditPublicidad(item)">mdi-square-edit-outline</v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
        <!-- Modal New/Edit Publicidad  -->
        <template>
          <div class="text-xs-top">
            <v-dialog  v-model="dialogPublicidad" max-width="700px">
              <v-card>                 
                <v-toolbar dense flat >
                  <v-toolbar-title v-if="band_new_publicidad == false"><v-icon>mdi-circle-edit-outline</v-icon> Editar Publicidad</v-toolbar-title>
                  <v-toolbar-title v-if="band_new_publicidad == true"><v-icon>mdi-plus-circle-outline</v-icon> Nueva Publicidad</v-toolbar-title>
                </v-toolbar>                   
                <v-card-text>      
                  <v-container fluid style="margin: 0px; padding: 0px; width: 100%">
                    <v-row>
                      <v-col cols="12" sm="12" md="12" v-if="band_new_publicidad == false">
                        <v-img :src="publicidadAux.url_img" ></v-img>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="publicidadAux.nombre"
                          label="Nombre"
                          placeholder="Nombre"
                          outlined dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2">
                        <v-text-field
                          v-model="publicidadAux.orden"
                          label="Orden"
                          placeholder="Orden"
                          outlined dense
                        ></v-text-field>
                      </v-col> 
                      <v-col class="d-flex" cols="12" sm="4" md="4">                              
                        <v-switch style="margin-top:4px" v-model="publicidadAux.banner_estado" class="mx-2" label="Activo"></v-switch>
                      </v-col>                     
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="publicidadAux.url_redirect"
                          label="Url Redirect"
                          placeholder="Url Redirect"
                          outlined dense
                        ></v-text-field>
                      </v-col>                                                     
                      <v-col class="d-flex" cols="12" sm="12"> 
                        <v-file-input
                          v-model="files"
                          color="deep-purple accent-4" 
                          label="Imagen"
                          accept="image/*"
                          multiple dense  outlined   counter
                          placeholder="Click para seleccionar una Imagen"                                         
                          :show-size="1000"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip  v-if="index < 2" color="deep-purple accent-4" dark  label small> {{ text }}</v-chip>
                            <span
                              v-else-if="index === 2" 
                              class="overline grey--text text--darken-3 mx-2"
                            >
                              +{{ files.length - 2 }} File(s)
                            </span>
                          </template>
                        </v-file-input>
                      </v-col>
                    </v-row>
                    <v-col class="text-center" cols="12" sm="12">                         
                      <v-btn small color="primary" @click="modificarPublicidad()" v-if="band_new_publicidad == false">Modificar</v-btn>           
                      <v-btn small color="success" @click="grabarNewPublicidad()" v-if="band_new_publicidad == true">Grabar</v-btn>                 
                    </v-col>
                  </v-container>                      
                </v-card-text>      
              </v-card>
            </v-dialog>
          </div>
        </template>
        <!-- Modal Ver Img Publicidad -->
        <template>
          <div class="text-xs-top">
            <v-dialog  v-model="dialogImgPublicidad" max-width="850px">
              <v-card>
                <v-card-title>  
                  <v-toolbar dense flat >
                    <v-toolbar-title>{{img_publicidad.nombre}}</v-toolbar-title>
                  </v-toolbar>
                </v-card-title>      
                <v-card-text>      
                  <v-container fluid style="margin: 0px; padding: 0px; width: 100%">
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-img :src="img_publicidad.url" ></v-img>
                      </v-col>
                    </v-row>           
                    <v-col class="text-center" cols="12" sm="12">                         
                      <v-btn small color="warning" @click="dialogImgPublicidad = false">Cerrar</v-btn>                        
                    </v-col>              
                  </v-container>                      
                </v-card-text>      
              </v-card>
            </v-dialog>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import router from '../../router'
import {mapActions, mapState} from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
export default {
  data() {
    return {
      search: '',
      headers: [
        { text: 'Nombre', align: 'center',value:'nombre' },
        { text: 'Imagen',align: 'center',value:'url_imagen'},
        { text: 'Url Redirect',align: 'center',value:'url_redirect'},
        { text: 'Estado',align: 'center',value:'inhabilitado'},
        { text: 'Acciones',align: 'center'},
      ],
      publicidades: [],
      band_new_publicidad : false,
      files : [],
      publicidadAux: {},
      dialogPublicidad: false,
      img_publicidad: {},
      dialogImgPublicidad: false
    }
  },
  components: {

  },
  computed: {
    ...mapState(['respPublicidadAdmin', 'respNewPublicidad', 'respUpdatePublicidad'])
  },
  created () {
    this.usuarioLogueado()
  },
  methods: {
    ...mapActions(['getPublicidadAdmin', 'newPublicidad', 'updatePublicidad']),
    usuarioLogueado(){
      if (localStorage.getItem('tokenAdmin')){
        // prosigo con la carga
        this.getBannersInicio()
      }else{
        // redirecciono al Login
        router.push({name: 'LoginAdmin', params: {sesionExpirada: 1}})
      }
    },
    async getBannersInicio(){
      await this.getPublicidadAdmin()
    },
    getColor(estado) {
      if (parseInt(estado) == 0) return 'green'
      else if (parseInt(estado) == 1) return 'orange'
    },
    getEstadoName(estado){
      if (parseInt(estado) ==0) return 'Activo'
      else if (parseInt(estado) == 1) return 'Inactivo'
    },
    showModalNewPublicidad(){
      this.band_new_publicidad = true
      this.files = []
      this.publicidadAux = {
        key : "",
        nombre : "",
        url_redirect : "",
        url_img : "",
        publicidad_estado : false,
        orden: 1
      }  
      this.dialogPublicidad = true
    },
    validarObligatorios(){
      let respuesta = {}
      if (this.files.length === 0){
        respuesta.valido = false
        respuesta.msj = 'Por favor seleccione una Publicidad para proseguir'
        return respuesta
      }
      if (this.publicidadAux.nombre.length === 0){
        respuesta.valido = false
        respuesta.msj = 'Por favor, ingrese el nombre de la nueva Publicidad'
        return respuesta
      }
      if (this.publicidadAux.orden.length === 0){
        respuesta.valido = false
        respuesta.msj = 'Por favor, ingrese el orden de la nueva Publicidad'
        return respuesta
      }
      respuesta.valido = true
      respuesta.msj = 'OK'
      return respuesta
    },
    grabarNewPublicidad(){
      let publicidadValida = this.validarObligatorios()
      if (!publicidadValida.valido){
        Swal.fire(
          'Nueva Publicidad', 
          publicidadValida.msj, 
          'warning'
        )
        return
      }
      Swal.fire({
        title: 'Nueva Publicidad',
        text: '¿Confirma subir la Nueva Publicidad?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let estado = ""
          let data = {}
          if(this.publicidadAux.banner_estado == true ){
            estado = 0
          }else{
            estado = 1
          }
          data = {                
            nombre: this.publicidadAux.nombre,
            url_redirect: this.publicidadAux.url_redirect,
            inhabilitado: estado,
            file: this.files,
            url_imagen: 0,
            orden: this.publicidadAux.orden
          }
          await this.newPublicidad(data)
        }
      })
    },
    modificarPublicidad(){
      if (this.publicidadAux.url_redirect.length === 0 && this.files.length === 0){
        Swal.fire(
          'Modificar Publicidad', 
          'Por favor ingrese algún cambio', 
          'warning'
        )
        return
      }
      Swal.fire({
        title: 'Modificar Publicidad',
        text: '¿Confirma modificar la Publicidad seleccionada?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let estado = ""
          if(this.publicidadAux.banner_estado == true ){
            estado = 0
          }else{
            estado = 1
          }
          let data = {
            key: this.publicidadAux.key,
            nombre: this.publicidadAux.nombre,
            url_redirect: this.publicidadAux.url_redirect,
            url_img: this.publicidadAux.url_imagen,
            inhabilitado: estado,
            file: this.files,
            orden: this.publicidadAux.orden
          }
          await this.updatePublicidad(data)
        }
      })
    },
    verImagen(item){
      this.img_publicidad = {
        nombre: item.nombre,
        url: item.url_imagen
      }       
      this.dialogImgPublicidad = true
    },
    verLink(item){
      window.open(item.url_redirect)
    },
    showModalEditPublicidad(item){
      this.band_new_publicidad = false
      let estado = ""
      if(parseInt(item.inhabilitado) == 0){
        estado = true
      }else{
        estado = false
      }     
      this.publicidadAux = {
        key: item.key_firebase,
        nombre: item.nombre,
        url_redirect: item.url_redirect,
        url_img: item.url_imagen,
        banner_estado: estado,
        orden: item.orden
      }  
      this.dialogPublicidad = true
    }
  },
  watch: {
    respPublicidadAdmin: function(){
      if (Object.keys(this.respPublicidadAdmin).length > 0){
        if (parseInt(this.respPublicidadAdmin.resultado) === 1){
          this.publicidades = this.respPublicidadAdmin.publicidad
        }
      }
    },
    respNewPublicidad: function(){
      if (Object.keys(this.respNewPublicidad).length > 0){
        if (parseInt(this.respNewPublicidad.resultado) === 1){
          Swal.fire(
            'Nueva Publicidad', 
            'La nueva Publicidad fue subida exitosamente', 
            'success'
          )
          setTimeout(() => [this.getBannersInicio()], 3000)
        }else{
          Swal.fire(
            'Nueva Publicidad', 
            this.respNewPublicidad.msj, 
            'error'
          )
        }
        this.dialogPublicidad = false
      }
    },
    respUpdatePublicidad: function(){
      if (Object.keys(this.respUpdatePublicidad).length > 0){
        if (parseInt(this.respUpdatePublicidad.resultado) === 1){
          Swal.fire(
            'Modificar Publicidad', 
            'La Publicidad fue actualizada exitosamente', 
            'success'
          )
          setTimeout(() => [this.getBannersInicio()], 3000)
        }else{
          Swal.fire(
            'Modificar Publicidad', 
            this.respUpdatePublicidad.msj, 
            'error'
          )
        }
        this.dialogPublicidad = false
      }
    }
  },
}
</script>

<style>

</style>