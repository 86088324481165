<template>
  <v-row class="d-flex justify-center mt-16 pt-xl-16">
    <v-col cols="10" sm="6" md="3" xl="2">
      <v-card
        class="d-flex justify-center mt-4"
        style="opacity: 0.8"
      >
        <v-img
          style="position:absolute; z-index: 1; margin-top: -60px;"
          height="120"
          width="120"
          src="../../assets/tienda.webp"
        ></v-img>
        <div class="mt-16 pt-4">
          <v-card-title class="d-flex justify-center text-h5" style="cursor: default;">
            Iniciar sesión
          </v-card-title>
          <v-card-text>
            <v-form class="mx-12 mt-4 mb-8" @submit.prevent="login">
              <v-text-field
                v-model.trim="user"
                label="Usuario"
                prepend-icon="fas fa-user"
              ></v-text-field>
              <v-text-field
                v-model.trim="password"
                type="password"
                label="Clave"
                prepend-icon="fas fa-lock"
              ></v-text-field>
              <v-btn
                color="info"
                class="mt-4"
                type="submit"
                :loading="load"
                block
              >
                Ingresar
              </v-btn>
            </v-form>
            <v-expand-transition>
              <v-alert
                v-if="error"
                type="error"
                dense
              >
                {{ message }}
              </v-alert>
            </v-expand-transition>
          </v-card-text>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { VueEasyJwt } from 'vue-easy-jwt'
const jwt = new VueEasyJwt()
import router from '../../router'
export default {
  data () {
    return {
      load: false,
      error: false,
      user: '',
      password: '',
      message: '',
    }
  },
  created () {
    this.checkUserLogin()
  },
  computed: {

  },
  components: {

  },
  methods: {
    checkUserLogin(){
      if (localStorage.getItem('tokenAdmin')){
        // el token es válido?
        let token = JSON.parse(localStorage.getItem('tokenAdmin'))
        let expirado = jwt.isExpired(token)
        if (expirado == false){
          // redirecciono al inicio del admin
          router.push({name: 'VentasIndex'})
        }
      }
    },
    async login(){
      if (this.user.toString().length > 0 && this.password.toString().length > 0){
        this.load = true
        let loginPeticion = await this.$store.dispatch('usuarios/executeLoginAdmin', {
          usuario: this.user,
          clave: this.password
        })
        this.load = false
        if (loginPeticion.resultado == 0){
          this.$store.dispatch('executeSnackBar', {msj: loginPeticion.message, color: 'warning', activo: true})
          return
        }
        // actualizo en localStorage
        if (localStorage.getItem('tokenAdmin')) localStorage.removeItem('tokenAdmin')
        localStorage.setItem('tokenAdmin', JSON.stringify(loginPeticion.token))
        // redirecciono
        router.push({name: 'VentasIndex'})
      }
    }
  }
}
</script>