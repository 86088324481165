<template>
  <v-container fluid>
    <!-- Formulario para el Pago  -->
    <v-form ref="form" v-model="valid" lazy-validation>
      <!-- Eleccion de Forma de Pago -->
      <v-row>
        <v-col cols="12" :sm="conRapiCompra == 1 ? '4' : '6'" :md="conRapiCompra == 1 ? '4' : '6'">
          <v-chip v-model="chipTarjetaCredito" v-if="ismovil == false" class="ma-2" color="#ed1841" outlined text-color="black">
            <v-checkbox  v-model="chekTarjetaCredito" color="#ed1841"></v-checkbox>Tarjeta Crédito
          </v-chip>
          <v-chip v-model="chipTarjetaCredito" v-if="ismovil == true" class="ma-2" color="#ed1841" outlined text-color="black">
            <v-checkbox  v-model="chekTarjetaCredito" color="#ed1841"></v-checkbox>Tarjeta Crédito
          </v-chip>
        </v-col>
        <v-col cols="12" :sm="conRapiCompra == 1 ? '4' : '6'" :md="conRapiCompra == 1 ? '4' : '6'">
          <v-chip  v-model="chipTarjetaDebito" v-if="ismovil == false" class="ma-2" color="#ed1841" outlined text-color="black">
            <v-checkbox  v-model="chekTarjetaDebito" color="#ed1841"></v-checkbox>Tarjeta Débito
          </v-chip>
          <v-chip  v-model="chipTarjetaDebito" v-if="ismovil == true" class="ma-2" color="#ed1841" outlined text-color="black">
            <v-checkbox  v-model="chekTarjetaDebito" color="#ed1841"></v-checkbox>Tarjeta Débito
          </v-chip>
        </v-col>
        <v-col cols="12" sm="4" md="4" v-if="conRapiCompra == 1">
          <v-chip  v-model="chipRapi" v-if="ismovil == false" class="ma-2" color="#ed1841" outlined text-color="black">
            <v-checkbox  v-model="chekRapi" color="#ed1841"></v-checkbox>Préstamo Banco Macro
          </v-chip>
          <v-chip  v-model="chipRapi" v-if="ismovil == true" class="ma-2" color="#ed1841" outlined text-color="black">
            <v-checkbox  v-model="chekRapi" color="#ed1841"></v-checkbox>Préstamo Banco Macro
          </v-chip>
        </v-col>
      </v-row>
      <!-- Eleccion de Financiacion -->
      <v-row v-if="chekTarjetaCredito == true || chekTarjetaDebito == true">
        <v-col cols="12" sm="4" md="4" style="padding-bottom: 0px">
          <label style="font-size:12px">Seleccione tipo Tarjeta</label>
          <v-autocomplete 
            v-model="tipo_tarjeta" 
            :items="optionsTarjetasFilter" 
            @change="filterFinanciacion()" 
            :rules="Rules" 
            return-object 
            outlined dense
            clearable
            item-text="TARJETA"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="8" md="8" style="padding-bottom: 0px">
          <label style="font-size:12px">Seleccione Financiacion</label>
          <v-autocomplete 
            v-model="forma_pago" 
            :items="optionsFormaPagoFilter" 
            @change="setImporte()" 
            :rules="Rules" 
            return-object
            outlined clearable dense
            :disabled="chekTarjetaDebito == true"
            item-text="financiacion"
          >
            <template v-slot:item="data">
              <div v-if="data.item.financiacion.indexOf('¡Sin in') != -1">
                <h5>{{ data.item.financiacion.substr(0, data.item.financiacion.indexOf('¡Sin in')) }}<strong style="color: green">{{ data.item.financiacion.substr(data.item.financiacion.indexOf('¡Sin in'), 13) }}</strong></h5>
              </div>
              <div v-else>
                <h5>{{ data.item.financiacion }}</h5>
              </div>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <!-- Datos de la Tarjeta -->
      <v-row v-if="( Object.keys(tipo_tarjeta).length > 0 &&  Object.keys(forma_pago).length > 0) || chekTarjetaDebito == true">
        <v-col cols="12" sm="6" md="6" style="padding-top: 0px; padding-bottom: 0px">
          <label style="font-size:12px">Nombre y Apellido como figura en la Tarjeta</label>
          <v-text-field
            v-model="titular"
            outlined dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6" style="padding-top: 0px; padding-bottom: 0px">
          <label style="font-size:12px">Documento</label>
          <v-text-field
            v-model="documento"
            v-mask="'#.###.###'"
            v-if="documento.length <= 9"
            outlined dense
          ></v-text-field>
          <v-text-field
            v-model="documento"
            v-mask="'##.###.###'"
            v-if="documento.length == 10"
            outlined dense
          ></v-text-field>
          <v-text-field
            v-model="documento"
            v-mask="'##.###.####'"
            v-if="documento.length > 10"
            outlined dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4" style="padding-top: 0px; padding-bottom: 0px">
          <label style="font-size:12px">Tarjeta Número</label>
          <!-- <v-text-field
            v-model="tarjeta_numero"
            v-mask="'####-####-####-###'"
            v-if="tarjeta_numero.length <= 18"
            outlined dense
          ></v-text-field>
          <v-text-field
            v-model="tarjeta_numero"
            v-mask="'####-####-####-####'"
            v-if="tarjeta_numero.length == 19"
            outlined dense
          ></v-text-field>
          <v-text-field
            v-model="tarjeta_numero"
            v-mask="'####-####-#####-#####'"
            v-if="tarjeta_numero.length > 19"
            outlined dense
          ></v-text-field> -->
          <the-mask
            v-model="tarjeta_numero"
            :mask="['####-####-####-###', '####-####-####-####', '####-####-#####-#####' ]"
            class="tarjetaCss"
            id="tarId"
          ></the-mask>
        </v-col>
        <v-col cols="6" sm="2" md="2" style="padding-top: 0px; padding-bottom: 0px">
          <label style="font-size:12px">Mes Venc.</label>
          <v-autocomplete
            v-model="cardMonth"
						:items="mesesTarjetaCredito"
            outlined dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" sm="3" md="3" style="padding-top: 0px; padding-bottom: 0px">
          <label style="font-size:12px">Año Venc.</label>
          <v-autocomplete
            v-model="cardYear"
            :items="añosTarjetaCredito"
            outlined dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="3" md="3" style="padding-top: 0px; padding-bottom: 0px">
          <label style="font-size:12px">Código de Seguridad</label>
          <v-text-field
            v-model="cardCvv"
            type="number"
            maxlength="4"
            v-mask="'####'"
            outlined dense
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- 08-06-2023 por MMURILLO, agregamos RapiCompras -->
      <v-row v-if="chekRapi == true">
        <v-card flat>
          <v-card-title class="pb-0 formatoPalabras">
            <strong><p class="text-center" style="word-break: normal;">Está por iniciar el trámite con el Banco Macro para financiar esta venta por medio de un préstamo. ¿Desea continuar?</p></strong>
          </v-card-title>
          <v-card-actions>
            <v-row class="mt-0">
              <v-col cols="12" sm="6" md="6">
                <v-btn color="info" block @click="generarRapiCompras()">Si</v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-btn color="error" @click="chekRapi = false" block>No</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-row>
      <!-- Proceder a Pagar -->
      <v-row justify="end">
          <v-btn color="#1976d2" style="font-size:12px;float:center" dark default @click="confirmarPago()" v-if="chekTarjetaCredito == true || chekTarjetaDebito == true">Continuar Pago</v-btn>
      </v-row>
    </v-form>
    <!-- Modal Confirmar Pago  -->
    <v-dialog v-model="modalConfirmarPago" max-width="600px">
      <v-card>
        <v-card-title>
          <v-toolbar color="#ed1841" dark height="40%" >
            <v-toolbar-title style="font-size:20px">
              <v-icon>mdi-information-outline</v-icon>&nbsp;Datos del Pago
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text style="padding-bottom: 0px">
          <v-container fluid>
            <!-- <h1 align="center"> $ {{importe_final}} </h1>-->
            <br/> 
            <v-row>
              <br/>
              <v-col cols="12" sm="8" md="8" style="margin-top:-25px">
                <label style="font-size:12px">Titular</label>
                <v-text-field
                  v-model="titular"    
                  outlined dense 
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" style="margin-top:-25px">
                <label style="font-size:12px">Documento</label>
                <v-text-field
                  v-model="documento" 
                  outlined 
                  dense 
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" style="margin-top:-40px">
                <label style="font-size:12px">Tarjeta Número</label>
                <v-text-field
                  v-model="tarjeta_numero"
                  outlined dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="4"  style="margin-top:-40px">
                <label style="font-size:12px">Mes</label>
                <v-text-field
                  v-model="cardMonth" 
                  outlined 
                  dense 
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="4"  style="margin-top:-40px;">
                <label style="font-size:12px">Año</label>
                <v-text-field
                  v-model="cardYear" 
                  outlined 
                  dense 
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" style="margin-top:-40px;" >
                <label style="font-size:12px">Codigo Seguridad</label>
                <v-text-field
                  v-model="cardCvv" 
                  outlined 
                  dense 
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" style="margin-top:-40px">
                <label style="font-size:12px">Email</label>
                <v-text-field
                  v-model="datos_facturacion.email" 
                  outlined 
                  dense 
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" style="margin-top:-40px; padding-bottom: 0px">
                <label style="font-size:12px">Forma de Pago</label>
                <strong>
                  <v-text-field
                    v-model="forma_pago_descripcion" 
                    outlined 
                    dense 
                    readonly
                    v-if="ismovil == false"
                  ></v-text-field>
                  <v-textarea
                    v-model="forma_pago_descripcion"
                    outlined dense
                    readonly
                    v-if="ismovil == true"
                  ></v-textarea>
                </strong>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="padding-top:0 px">
          <v-row justify="center" style="margin: 0px">
            <v-btn
              style="float: left;font-size:10px" 
              color="#ed1841" 
              outlined
              dark
              @click="modalConfirmarPago = false"
              class="ma-2"
            >
              Volver
            </v-btn>
            <v-btn
              style="float: right;font-size:11px"
              color="#04B404"
              dark
              @click="procesarPago()"
              class="ma-2"
            >
              <v-icon small>mdi-check</v-icon>&nbsp;Pagar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal Resultado de la Venta  -->
    <v-dialog
      v-model="modalResultadoVenta"
      persistent
      max-width="600"
    >
      <v-card
        flat
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <v-card-title class="formatoPalabras">
          <v-toolbar
            color="primary"
            class="white--text"
          >
            <v-spacer></v-spacer>
            <v-toolbar-title>
              {{ tituloResultadoVenta }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="text-center">
          {{ msjResultadoVenta }}
        </v-card-text>
        <v-card-actions class="formatoPalabras">
          <v-row justify="center" style="margin: 0px">
            <v-btn
              style="float: right;font-size:11px"
              color="warning"
              dark
              @click="modalResultadoVenta = false"
              class="formatoPalabras ma-2"
              v-if="errorPago == true"
            >
              <v-icon small>mdi-arrow-left</v-icon>&nbsp;Volver
            </v-btn>
            <v-btn
              style="float: right;font-size:11px"
              color="#04B404"
              dark
              @click="cerrarModal()"
              class="formatoPalabras ma-2"
              v-if="errorPago == false"
            >
              <v-icon small>mdi-check</v-icon>&nbsp;Aceptar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal Resultado Prestamo -->
    <v-dialog
      v-model="modalPrestamo"
      persistent
      max-width="600"
    >
      <v-card
        flat
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <v-card-title class="formatoPalabras">
          <v-toolbar
            color="primary"
            class="white--text"
          >
            <v-spacer></v-spacer>
            <v-toolbar-title>
              {{ tituloPrestamo }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="text-center">
          {{ msjPrestamo }}
        </v-card-text>
        <v-card-actions class="formatoPalabras">
          <v-row justify="center" style="margin: 0px">
            <v-btn
              style="float: right;font-size:11px"
              color="#04B404"
              dark
              @click="volverInicio()"
              class="formatoPalabras ma-2"
            >
              <v-icon small>mdi-check</v-icon>&nbsp;Aceptar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {mapState, mapActions} from 'vuex'
import moment from 'moment'
import router from '../../router'
export default {
  props: {
    datos_facturacion: Object,
    datos_entrega: Object,
    stock_articulos: Array,
    ismovil: Boolean,
    uIdUsuario: String,
    stockMercaderia: Array
  },
  data() {
    return {
      intentosLoginJarvis: 0,
      errorPago: false,
      modalResultadoVenta: false,
      tituloResultadoVenta: '',
      msjResultadoVenta: '',
      valid: true,
      errors: false,
      optionsFormaPago: [],
      optionsFormaPagoFilter: [],
      optionsTarjetas: [],
      optionsTarjetasFilter: [],
      tipo_tarjeta: {},
      forma_pago: {},
      importe: '',
      importe_final: '',
      chekTarjetaCredito: false,
      chekTarjetaDebito: false,
      chekRapi: false,
      chipTarjetaCredito: false,
      chipTarjetaDebito: false,
      chipRapi: false,
      cardMonth: '',
      cardYear: '',
      cardCvv: '',
      minCardYear: new Date().getFullYear(),
      titular: '',
      tarjeta_numero: '',
      documento: '',
      Rules: [
        v => !!v || "Campo obligatorio.",
      ],
      modalConfirmarPago: false,
      modalSendPago: false,
      tituloModal: '',
      forma_pago_descripcion: '',
      srvFirstData: [],
      email_cliente: '',
      mesesTarjetaCredito: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      añosTarjetaCredito: [],
      payment: {},
      pedidoFinal: {
        articulos: [],
        datos_entrega: {},
        datos_facturacion: {},
        documento_numero: '',
        estado_pedido: {
          codigo: 0,
          estado: 'Pendiente de Pago'
        },
        estado: 'PENDIENTE',
        fecha_cobro: '',
        fecha_log: '',
        fecha_pedido: '',
        forma_pago: {
          cuota_desde: 0,
          cuota_interna: 0,
          financiacion: '',
          importe: '',
          importe_cuota: 0,
          importe_final: 0,
          importe_interes: 0,
          tarjeta_codigo: 0
        },
        importe: 0,
        stock_articulos: {},
        uid: ''
      },
      pedidoParcial: '',
      pedidoNumParcial: '',
      diasPedido: 0,
      modalPrestamo: false,
      tituloPrestamo: '',
      msjPrestamo: '',
      conRapiCompra: 0
    }
  },
  computed: {
    ...mapState(['respFinanciaciones', 'respEmailPetition']),
    calcularImporteTotal(){
      let total = 0
      let index = 0
      for(index in this.stock_articulos){
        total+=parseFloat(this.stock_articulos[index].cantidad)*parseFloat(this.stock_articulos[index].articuloPrecio)
      }
      if(parseInt(this.datos_entrega.costo_envio) > 0 && this.datos_entrega.envio_domicilio == true){
        total+= parseInt(this.datos_entrega.costo_envio)
      }
      return parseFloat(total).toFixed(2)
    }
  },
  async created () {
    let login = await this.$store.dispatch('usuarios/executeLoginApi')
    this.iniciarPago()
  },
  methods: {
    ...mapActions(['getFinanciaciones', 'showLoading', 'closeLoading', 'sendEmailPetition']),
    async iniciarPago(){
      await this.getFinanciaciones()
      await this.getPedidoVencimiento()
      await this.getRapiConfig()
    },
    async getRapiConfig(){
      let configPeticion = await this.$store.dispatch('rapiCompra/getConfigRapi')
      if (configPeticion.resultado == 1 && configPeticion.settings.visible == 1) this.conRapiCompra = 1
    },
    async getPedidoVencimiento(){
      let vencimientosPeticion = await this.$store.dispatch('pedidos/getDiasVencimientoPedidos')
      if (vencimientosPeticion.resultado == 1){
        if (vencimientosPeticion.dias == null || vencimientosPeticion.dias == 0){
          this.diasPedido = 2
        }else{
          this.diasPedido = parseInt(vencimientosPeticion.dias)
        }
      }else{
        this.diasPedido = 2
      }
    },
    filterTarjetas(){
      let tarjetas = []
      if(this.chekTarjetaCredito == true){
        let i
        for(i in this.optionsTarjetas){
          let count = this.optionsFormaPago.filter(fp => fp.TARJETA_CODIGO ==  this.optionsTarjetas[i].TARJETA_CODIGO && fp.FINANCIACION != 'DEBITO')
          if(count.length > 0 ){
            tarjetas.push( this.optionsTarjetas[i])
          }
        }
        this.optionsTarjetasFilter = [...tarjetas]
      }
      if(this.chekTarjetaDebito == true){
        let i
        for(i in this.optionsTarjetas){
          let count = this.optionsFormaPago.filter(fp => fp.TARJETA_CODIGO ==  this.optionsTarjetas[i].TARJETA_CODIGO && fp.FINANCIACION == 'DEBITO')
          if(count.length > 0 ){
            tarjetas.push( this.optionsTarjetas[i])
          }
        }
        this.optionsTarjetasFilter = [...tarjetas]
      }
    },
    filterFinanciacion(){
      this.optionsFormaPagoFilter = []
      if(this.chekTarjetaDebito == true){
        this.importe_final = this.calcularImporteTotal
        this.optionsFormaPagoFilter.push({
          financiacion: 'DEBITO',
          tarjeta_codigo: this.tipo_tarjeta.TARJETA_CODIGO,
          cuota_desde: 1,
          cuota_interna: 1,
          importe: this.importe_final,
          importe_final: this.importe_final,
          importe_cuota: parseFloat(this.importe).toFixed(2),
          importe_interes: 0
        })
        this.forma_pago = this.optionsFormaPagoFilter[0]
      }else{
        this.optionsFormaPago.forEach( item => {
          this.importe = this.calcularImporteTotal
          let total = 0, interes = 0 ,total_cuota = 0 , descripcion = ''
          if(item.TARJETA_CODIGO == this.tipo_tarjeta.TARJETA_CODIGO &&  item.FINANCIACION != 'DEBITO'){
            interes = parseFloat(this.importe) * (parseFloat(item.PORCENTAJE) / 100)
            total = parseFloat(this.importe) + parseFloat(interes.toFixed(2))
            total_cuota = total/item.CUOTAS_DESDE
            if (item.hasOwnProperty('MUESTRA_CUOTAS') && item.MUESTRA_CUOTAS == 1){
              if(item.PORCENTAJE == 0 ){
                if (item.hasOwnProperty('TOMA_CUOTA')){
                  if (parseInt(item.TOMA_CUOTA) === 1){
                    descripcion = 'En '+item.CUOTAS_DESDE+' cuota/s de $ '+total_cuota.toFixed(2)+' ¡Sin interés!'
                  }else{
                    descripcion = 'En ' + item.FINANCIACION + ' ¡Sin interés!'
                  }
                }else{
                  descripcion = 'En '+item.CUOTAS_DESDE+' cuota/s de $ '+total_cuota.toFixed(2)+' ¡Sin interés!'
                }
              }
              if(item.PORCENTAJE != 0 ){
                descripcion = 'En '+item.CUOTAS_DESDE+' cuota/s de $ '+total_cuota.toFixed(2)+' - Total : $ '+total.toFixed(2)
              }
            }else{
              descripcion = item.FINANCIACION
              if (item.PORCENTAJE == 0) descripcion = descripcion + ' ¡Sin interés!'
              else descripcion = descripcion + ' en ' + item.CUOTAS_DESDE + ' cuota/s de $ ' + total_cuota.toFixed(2) + ' - Total : $ ' + total.toFixed(2)
            }
            this.optionsFormaPagoFilter.push({
              financiacion : descripcion,
              tarjeta_codigo : item.TARJETA_CODIGO,
              cuota_desde : item.CUOTAS_DESDE,
              cuota_interna : item.CUOTA_INTERNA,
              importe : this.importe,
              importe_final : total,
              importe_cuota : parseFloat(total_cuota).toFixed(2),
              importe_interes : parseFloat(interes).toFixed(2),
            })
          }
        })
      }
      // ordenar por cuota
      this.optionsFormaPagoFilter.sort(function(a,b){
        if (parseInt(a.cuota_desde) < parseInt(b.cuota_desde)) {
          return -1;
        }
        if (parseInt(a.cuota_desde) > parseInt(b.cuota_desde)) {
          return 1;
        }
        // a must be equal to b
        return 0;
      })
    },
    setImporte(){
      if (this.forma_pago != null && this.forma_pago != undefined){
        if (Object.keys(this.forma_pago).length > 0){
          this.importe_final = this.forma_pago.importe_final
        }
      }
    },
    validate() {
      // Funcion para validar los campos que son obligatorios dentro del form 
      if (this.$refs.form.validate()) {
        // Si algunas de las Rules no cumple,la funcion retornara 'false'
        return (this.errors = true);
      }else{
        return (this.errors = false);
      }
    },
    confirmarPago(){
      //valido los campos que son obligatorios
      if (!this.validate()){
        return;
      }
      if(this.cardNumber == "" || this.cardName == "" ||
      this.cardMonth == "" || this.cardYear == "" ||
      this.cardCvv == "" || this.documento == "" || this.titular == "" ){
        alert('Complete todos los campos')
        return
      }
      //mostrar modal resumen pago
      if(this.chekTarjetaDebito == true){
        this.forma_pago_descripcion ='DEBITO - '+ this.tipo_tarjeta.TARJETA
      }else{
        this.forma_pago_descripcion =this.forma_pago.financiacion
      }
      this.modalConfirmarPago = true
    },
    convertDecimals(numero){
      return parseFloat(parseFloat(numero.toString().replace(',', '.')).toFixed(2).toString().replace(',', '.'))
    },
    async procesarPago(){
      this.errorPago = false
      // controlo tener datos en datos de entrega, facturacion y stock_articulos
      if (Object.keys(this.datos_facturacion).length > 0 && this.datos_facturacion.email.length > 0 &&
      Object.keys(this.datos_entrega).length > 0 && (this.datos_entrega.localidad.length > 0 || this.datos_entrega.local_retiro.length > 0) &&
      this.stock_articulos.length > 0){
        // cierro el modal de visualizacion del pago
        this.modalConfirmarPago = false
        // proceder a pagar
        this.showLoading()
        // es entrega rapida?
        let entregaRapida = 0
        if (this.datos_entrega.entrega_rapida == true){
          entregaRapida = 1
        }
        // preparo el stock de articulos
        let stockArticulos = {}
        if (this.stock_articulos.length == 1){
          this.stockArticulos = {
            articulo_codigo: this.stock_articulos[0].articuloCodigo,
						articulo_nombre: this.stock_articulos[0].articuloNombre,
						sucursal_compra: this.datos_entrega.provincia,
						sucursal_stock_codigo: this.stockMercaderia[0].stock.sucursal_codigo,
						sucursal_stock_nombre: this.stockMercaderia[0].stock.sucursal_nombre,
						sucursal_stock_cantidad: this.stockMercaderia[0].stock.cantidad,
						deposito_stock_codigo: this.stockMercaderia[0].stock.deposito_codigo,
						deposito_stock_nombre: this.stockMercaderia[0].stock.deposito_nombre,
						//deposito_stock_depende: this.stockMercaderia[0].stock.depo_dep_codigo,
						entrega_rapida: entregaRapida,
						entrega_demora: this.datos_entrega.horas_entrega,
						entrega_tiempo: 'HORAS'
          }
        }else{
          stockArticulos = []
          for (let id in this.stock_articulos){
            let stockUnArticulo = this.stockMercaderia.filter(element => 
              element.keyFirebase == this.stock_articulos[id].articuloKey
            )
            if (stockUnArticulo.length > 0){
              let unArticulo = {
                articulo_codigo: this.stock_articulos[id].articuloCodigo,
                articulo_nombre: this.stock_articulos[id].articuloNombre,
                sucursal_compra: this.datos_entrega.provincia,
                sucursal_stock_codigo: stockUnArticulo[0].stock.sucursal_codigo,
                sucursal_stock_nombre: stockUnArticulo[0].stock.sucursal_nombre,
                sucursal_stock_cantidad: stockUnArticulo[0].stock.cantidad,
                deposito_stock_codigo: stockUnArticulo[0].stock.deposito_codigo,
                deposito_stock_nombre: stockUnArticulo[0].stock.deposito_nombre,
                //deposito_stock_depende: stockUnArticulo[0].stock.depo_dep_codigo,
                entrega_rapida: entregaRapida,
                entrega_demora: this.datos_entrega.horas_entrega,
                entrega_tiempo: 'HORAS'
              }
              stockArticulos.push(unArticulo)
            }
          }
        }
        // rearmo los articulos
        let articulosFinal = []
        for (let id in this.stock_articulos){
          let otroArticulo = {
            codigo_bb: this.stock_articulos[id].articuloCodigo,
            key: this.stock_articulos[id].articuloKey,
            nombre: this.stock_articulos[id].articuloNombre,
            precio: this.stock_articulos[id].precioNeto,
            cantidad: this.stock_articulos[id].cantidad,
            img_principal: this.stock_articulos[id].articuloImagenes[0].src,
          }
          articulosFinal.push(otroArticulo)
        }
        // forma de pago
        let formaPago = {
          cuota_desde: this.forma_pago.cuota_desde,
          cuota_interna: this.forma_pago.cuota_interna,
          financiacion: this.forma_pago.financiacion,
          importe: this.forma_pago.importe,
          importe_cuota: this.forma_pago.importe_cuota,
          importe_final: this.forma_pago.importe_final,
          importe_interes: this.forma_pago.importe_interes,
          tarjeta_codigo: this.forma_pago.tarjeta_codigo,
          tarjeta_marca: this.tipo_tarjeta.TARJETA
        }
        // fecha de pedido y fecha log
        let fechaPedido = moment(new Date()).format('DD/MM/YYYY')
        let fechaLog = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        // controlo los pedidos de firebase y el numero de pedido de oracle
        let pedidoWeb = {}
        if (localStorage.getItem('pedidoWeb')){
          pedidoWeb = JSON.parse(localStorage.getItem('pedidoWeb'))
        }else{
          pedidoWeb = {
            pedidoWeb: ''
          }
        }
        if (pedidoWeb.pedidoWeb.toString().length > 0){
          // quiere decir que tengo algo en localStorage
          this.pedidoParcial = pedidoWeb.pedidoWeb
        }else{
          // pedido nuevo, restablecer
          this.pedidoParcial = ''
        }
        // armo mi pedido a procesar
        this.pedidoFinal.articulos = articulosFinal
        this.pedidoFinal.datos_entrega = this.datos_entrega
        this.pedidoFinal.datos_facturacion = this.datos_facturacion
        this.pedidoFinal.documento_numero = this.documento.replaceAll('.', '')
        this.pedidoFinal.fecha_log = fechaLog
        this.pedidoFinal.fecha_pedido = fechaPedido
        this.pedidoFinal.forma_pago = formaPago
        this.pedidoFinal.importe = this.forma_pago.importe_final
        this.pedidoFinal.stock_articulos = stockArticulos
        this.pedidoFinal.uid = this.uIdUsuario
        // datos para el cobro
        let totalAPagar = '0'
        if (this.convertDecimals(this.importe_final)%1 == 0){
          totalAPagar = parseInt(this.importe_final).toString() + '00'
        }else{
          totalAPagar = (this.convertDecimals(this.importe_final)*100)
          totalAPagar = parseInt(totalAPagar).toString()
        }
        this.pedidoNumParcial = ''
        this.payment = {
          tarjeta_numero: this.tarjeta_numero.replaceAll('-', ''),
          mes_expira: this.cardMonth,
          anio_expira: this.cardYear.toString().substr(2,2),
          codigo_seguridad: this.cardCvv,
          titular: this.titular,
          tipo_doc: 'dni',
          documento: this.documento.replaceAll('.', ''),
          empresa_id: 2, // esto es , RFB
          tipo_pedido: 17, // buscar en ORACLE, Tipos Pedidos, el 17 es cobros celushop
          importe: totalAPagar,
          cuotas: this.forma_pago.cuota_interna,
          tarjeta_codigo: this.forma_pago.tarjeta_codigo,
          key_boton: this.pedidoParcial, // este es el key de firebase del pedido
          pedido_numero: this.pedidoNumParcial // esto es el numero de pedido de oracle para la tabla solicitudes_cobro
        }
        // paso a cobrar
        let pagoPeticion = await this.$store.dispatch('cobros/processPayment', {pedidoFinal: this.pedidoFinal, datosCobro: this.payment, keyPedido: this.pedidoParcial, diasPedido: this.diasPedido})
        //console.log('pagoPeticion', pagoPeticion)
        // controlo a ver si me llegaron la key de firebase y el numero de pedido
        if (this.pedidoParcial.toString().length == 0){
          // pedí nuevos pedidos, por lo tanto debo guardarlo en localStorage
          pedidoWeb = {
            pedidoWeb: pagoPeticion.key
          }
          localStorage.setItem('pedidoWeb', JSON.stringify(pedidoWeb))
        }
        
        if (pagoPeticion.resultado == 2){
          // pago aprobado, completar los datos en firebase
          // indico que ya fue aprobado
          this.tituloResultadoVenta = 'Cobro Exitoso.'
          this.msjResultadoVenta = 'Detectamos que su cobro ya fue aprobado. Revise su email en unos minutos para corroborar el comprobante.'
          this.modalResultadoVenta = true
          // completo los datos en firenase
          let estados = []
          let estadosPeticion = await this.$store.dispatch('firebaseService/getEstadosCobros')
          estados = estadosPeticion.estados
          // acondiciono el cobro que obtuve de la api
          let pagoApi = {
            exito: 1,
            body: {
              id: pagoPeticion.resCobro[0].transaccion_id,
              status: 'APPROVED',
              status_details: {
                card_authorization_code: pagoPeticion.resCobro[0].codigo_autorizacion,
                address_validation_code: pagoPeticion.resCobro[0].lote,
                ticket: pagoPeticion.resCobro[0].numero
              },
              date: pagoPeticion.resCobro[0].tdate
            }
          }
          // peticion
          let updatePeticion = await this.$store.dispatch('firebaseService/updatePedidoOracle', {pedido: this.pedidoFinal, resCobro: pagoApi, estados: estados, key: pagoPeticion.key, datosCobroCliente: this.payment, pedidoNum: pagoPeticion.resCobro[0].numero_pedido})
          if (updatePeticion.resultado == 1){
            // quito de localStorage los valores que tenia del pedido web, ya que se confirmó la venta
            localStorage.removeItem('pedidoWeb')
            // envio una peticion para enviar el email al correo del cliente
            let body = {
              remitente: 'no-responder@borigenbetzel.com.ar',
              asunto: '¡Cobro Exitoso!',
              destinatario: this.datos_facturacion.email,
              proceso: 'Venta del Ecommerce',
              pedido_numero: pagoApi.body.id,
              id_template: 1,
              carpeta_template: 'CeluShop',
              casilla_id: 1,
              parametros: [
                {
                  nombreParam: 'nombre',
                  valorParam: this.datos_facturacion.nombre + ' ' + this.datos_facturacion.apellido
                },
                {
                  nombreParam: 'importe',
                  valorParam: this.pedidoFinal.importe
                }
              ],
              archivos_adjuntos: [
                {
                  plantilla_id: 1
                }
              ]
            }
            this.sendEmailPetition(body)
          }else{
            this.closeLoading()
            this.tituloResultadoVenta = 'Actualizar Pedido'
            this.msjResultadoVenta = 'No se pudo actualizar el pedido: ' + updatePeticion.msj
            this.modalResultadoVenta = true
          }
        }else if (pagoPeticion.resultado == 0){
          // error
          this.closeLoading()
          this.errorPago = true
          // podria llegar el msj de error vacio, colocar msj predeterminado
          if (pagoPeticion.hasOwnProperty('msjError')){
            if (pagoPeticion.msjError.length > 0){
              // 
              this.tituloResultadoVenta = 'No fue posible procesar el Pago'
              this.msjResultadoVenta = pagoPeticion.msjError
              this.modalResultadoVenta = true
            }else{
              this.tituloResultadoVenta = 'No fue posible procesar el Pago'
              this.msjResultadoVenta = 'Ocurrio un problema inesperado al procesar el Cobro'
              this.modalResultadoVenta = true
            }
          }else if(pagoPeticion.hasOwnProperty('msj')){
            this.tituloResultadoVenta = 'No fue posible procesar el Pago'
            this.msjResultadoVenta = pagoPeticion.msj
            this.modalResultadoVenta = true
          }else{
            this.tituloResultadoVenta = 'No fue posible procesar el Pago'
            this.msjResultadoVenta = 'Ocurrio un problema inesperado al procesar el Cobro 2'
            this.modalResultadoVenta = true
          }
        }else{
          // posible exito, controlo el campo exito
          if (pagoPeticion.resCobro.exito == 1){
            // aun no se asegura cobro exitoso
            if (pagoPeticion.resCobro.body.status.toUpperCase() == 'APPROVED'){
              // AQUI ES COBRO EXITOSO
              this.tituloResultadoVenta = '¡FELICIDADES!'
              this.msjResultadoVenta = 'Su compra fue procesada exitosamente.'
              this.modalResultadoVenta = true
              // quito de localStorage los valores que tenia del pedido web, ya que se confirmó la venta
              localStorage.removeItem('pedidoWeb')
              // envio una peticion para enviar el email al correo del cliente
              let body = {
                remitente: 'no-responder@borigenbetzel.com.ar',
                asunto: '¡Cobro Exitoso!',
                destinatario: this.datos_facturacion.email,
                proceso: 'Venta del Ecommerce',
                pedido_numero: pagoPeticion.resCobro.body.id,
                id_template: 1,
                carpeta_template: 'CeluShop',
                casilla_id: 1,
                parametros: [
                  {
                    nombreParam: 'nombre',
                    valorParam: this.datos_facturacion.nombre + ' ' + this.datos_facturacion.apellido
                  },
                  {
                    nombreParam: 'importe',
                    valorParam: this.pedidoFinal.importe
                  }
                ],
                archivos_adjuntos: [
                  {
                    plantilla_id: 1
                  }
                ]
              }
              this.sendEmailPetition(body)
            }else{
              this.closeLoading()
              // se quiso hacer el cobro pero fue rechazado, obtengo el motivo del rechazo
              let msjRechazo = ''
              if (pagoPeticion.resCobro.body.hasOwnProperty('status_details') && typeof pagoPeticion.resCobro.body.status_details == 'object' && Object.keys(pagoPeticion.resCobro.body.status_details).length > 0 && 
              pagoPeticion.resCobro.body.status_details.hasOwnProperty('error') && typeof pagoPeticion.resCobro.body.status_details.error == 'object' && Object.keys(pagoPeticion.resCobro.body.status_details.error).length > 0 &&
              pagoPeticion.resCobro.body.status_details.error.hasOwnProperty('reason') && typeof pagoPeticion.resCobro.body.status_details.error.reason == 'object' && Object.keys(pagoPeticion.resCobro.body.status_details.error.reason).length > 0){
                msjRechazo = pagoPeticion.resCobro.body.status_details.error.reason.description
              }else{
                msjRechazo = pagoPeticion.resCobro.body.status
              }
              this.errorPago = true
              this.tituloResultadoVenta = 'No fue posible procesar el Pago'
              this.msjResultadoVenta = 'Su cobro no pudo ser procesado: ' + msjRechazo
              this.modalResultadoVenta = true

            }
          }else{
            this.closeLoading()
            // error
            this.errorPago = true
            this.tituloResultadoVenta = 'No fue posible procesar el Pago'
            this.msjResultadoVenta = 'Ocurrio un problema inesperado al procesar el Cobro 3'
            this.modalResultadoVenta = true
          }
        }
      }else{
        Swal.fire({
          icon: 'info',
          title: 'Pago',
          text: 'No se puede procesar el Pago con los datos actuales. Por favor refresque la página y vuelva a intentarlo',
          showConfirmButton: true
        })
      }
    },
    volverInicio(){
      // limpio el carrito del usuario
      localStorage.removeItem('carritoUsuario')
      // redirecciono al inicio
      router.push({name: 'Home'})
    },
    cerrarModal(){
      if (Object.keys(this.respEmailPetition).length > 0){
        this.volverInicio()
      }else{
        this.modalResultadoVenta = false
      }
    },
    async generarRapiCompras(){
      // abro el modal
      this.showLoading()
      // preparo el stock de articulos
      let stockArticulos = {}
      if (this.stock_articulos.length == 1){
        this.stockArticulos = {
          articulo_codigo: this.stock_articulos[0].articuloCodigo,
          articulo_nombre: this.stock_articulos[0].articuloNombre,
          sucursal_compra: this.datos_entrega.provincia,
          sucursal_stock_codigo: this.stockMercaderia[0].stock.sucursal_codigo,
          sucursal_stock_nombre: this.stockMercaderia[0].stock.sucursal_nombre,
          sucursal_stock_cantidad: this.stockMercaderia[0].stock.cantidad,
          deposito_stock_codigo: this.stockMercaderia[0].stock.deposito_codigo,
          deposito_stock_nombre: this.stockMercaderia[0].stock.deposito_nombre,
          //deposito_stock_depende: this.stockMercaderia[0].stock.depo_dep_codigo,
          entrega_rapida: false,
          entrega_demora: this.datos_entrega.horas_entrega,
          entrega_tiempo: 'HORAS'
        }
      }else{
        stockArticulos = []
        for (let id in this.stock_articulos){
          let stockUnArticulo = this.stockMercaderia.filter(element => 
            element.keyFirebase == this.stock_articulos[id].articuloKey
          )
          if (stockUnArticulo.length > 0){
            let unArticulo = {
              articulo_codigo: this.stock_articulos[id].articuloCodigo,
              articulo_nombre: this.stock_articulos[id].articuloNombre,
              sucursal_compra: this.datos_entrega.provincia,
              sucursal_stock_codigo: stockUnArticulo[0].stock.sucursal_codigo,
              sucursal_stock_nombre: stockUnArticulo[0].stock.sucursal_nombre,
              sucursal_stock_cantidad: stockUnArticulo[0].stock.cantidad,
              deposito_stock_codigo: stockUnArticulo[0].stock.deposito_codigo,
              deposito_stock_nombre: stockUnArticulo[0].stock.deposito_nombre,
              //deposito_stock_depende: stockUnArticulo[0].stock.depo_dep_codigo,
              entrega_rapida: false,
              entrega_demora: this.datos_entrega.horas_entrega,
              entrega_tiempo: 'HORAS'
            }
            stockArticulos.push(unArticulo)
          }
        }
      }
      // rearmo los articulos
      let articulosFinal = []
      for (let id in this.stock_articulos){
        let otroArticulo = {
          codigo_bb: this.stock_articulos[id].articuloCodigo,
          key: this.stock_articulos[id].articuloKey,
          nombre: this.stock_articulos[id].articuloNombre,
          precio: this.stock_articulos[id].precioNeto,
          cantidad: this.stock_articulos[id].cantidad,
          img_principal: this.stock_articulos[id].articuloImagenes[0].src,
        }
        articulosFinal.push(otroArticulo)
      }
      // forma de pago
      let formaPago = {
        cuota_desde: '',
        cuota_interna: '',
        financiacion: 'RAPICOMPRAS',
        importe: this.calcularImporteTotal,
        importe_cuota: '',
        importe_final: this.calcularImporteTotal,
        importe_interes: '0',
        tarjeta_codigo: '',
        tarjeta_marca: 'RAPICOMPRAS'
      }
      // fecha de pedido y fecha log
      let fechaPedido = moment(new Date()).format('DD/MM/YYYY')
      let fechaLog = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      // controlo los pedidos de firebase y el numero de pedido de oracle
      let pedidoWeb = {}
      if (localStorage.getItem('pedidoWeb')){
        pedidoWeb = JSON.parse(localStorage.getItem('pedidoWeb'))
      }else{
        pedidoWeb = {
          pedidoWeb: ''
        }
      }
      if (pedidoWeb.pedidoWeb.toString().length > 0){
        // quiere decir que tengo algo en localStorage
        this.pedidoParcial = pedidoWeb.pedidoWeb
      }else{
        // pedido nuevo, restablecer
        this.pedidoParcial = ''
      }
      // armo mi pedido a procesar
      this.pedidoFinal.articulos = articulosFinal
      this.pedidoFinal.datos_entrega = this.datos_entrega
      this.pedidoFinal.datos_facturacion = this.datos_facturacion
      this.pedidoFinal.documento_numero = this.documento.replaceAll('.', '')
      this.pedidoFinal.fecha_log = fechaLog
      this.pedidoFinal.fecha_pedido = fechaPedido
      this.pedidoFinal.forma_pago = formaPago
      this.pedidoFinal.importe = this.calcularImporteTotal
      this.pedidoFinal.stock_articulos = stockArticulos
      this.pedidoFinal.uid = this.uIdUsuario
      // paso a cobrar
      let pagoPeticion = await this.$store.dispatch('rapiCompra/generateRapiCompras', {pedidoFinal: this.pedidoFinal, nro_pedido: this.pedidoParcial, diasPedido: this.diasPedido})
      this.closeLoading()
      if (pagoPeticion.resultado == 1){
        // guardo en localStorage
        let pedidoWeb = {
          pedidoWeb: pagoPeticion.pedidoOracle
        }
        localStorage.setItem('pedidoWeb', JSON.stringify(pedidoWeb))
        // redireccionar a la ip que nos dejó MACRO
        window.location = pagoPeticion.link
      }else{
        // falla por alguna razon, saco el pedido web si es que existiera
        if (localStorage.getItem('pedidoWeb')) localStorage.removeItem('pedidoWeb')
        // muestro el error y borro el nro de pedido que tenia en localStorage
        this.tituloPrestamo = 'Solicitar Prestamo MACRO.'
        this.msjPrestamo = pagoPeticion.msj
        this.modalPrestamo = true
      }
    }
  },
  watch: {
    respFinanciaciones: function(){
      if (Object.keys(this.respFinanciaciones).length > 0){
        if (parseInt(this.respFinanciaciones.resultado) === 1){
          this.optionsFormaPago = this.respFinanciaciones.financiaciones
          this.optionsTarjetas = this.respFinanciaciones.tarjetas
          for (let id = 0;id <= 12;id++){
            this.añosTarjetaCredito.push(this.minCardYear + id)
          }
        }else{
          // error al obtener las financiaciones, no se puede continuar con la compra?
          console.log(this.añosTarjetaCredito)
        }
      }
    },
    chekTarjetaCredito(){
      this.tipo_tarjeta = ''
      this.forma_pago = ''
      if(this.chekTarjetaCredito == true){
        this.chekTarjetaDebito = false
        this.chekRapi = false
      }
      this.filterTarjetas()
    },
    chekTarjetaDebito(){
      this.tipo_tarjeta = ''
      this.forma_pago = ''
      if(this.chekTarjetaDebito == true){
        this.chekTarjetaCredito= false
        this.chekRapi = false
      }
      this.filterTarjetas()
    },
    chekRapi: function(){
      this.tipo_tarjeta = ''
      this.forma_pago = ''
      if (this.chekRapi == true){
        this.chekTarjetaDebito = false
        this.chekTarjetaCredito= false
      }
    },
    forma_pago: function(){
      if (this.forma_pago == null || this.forma_pago == undefined){
        this.forma_pago = {}
      }
      if (Object.keys(this.tipo_tarjeta).length === 0 || Object.keys(this.forma_pago).length === 0){
        this.importe_final = 0
      }
    },
    tipo_tarjeta: function(){
      if (this.tipo_tarjeta == null || this.tipo_tarjeta == undefined){
        this.tipo_tarjeta = {}
      }
      if (Object.keys(this.tipo_tarjeta).length === 0 || Object.keys(this.forma_pago).length === 0){
        this.importe_final = 0
      }
      if (Object.keys(this.tipo_tarjeta).length > 0 && this.chekTarjetaCredito == true){
        this.forma_pago = {}
      }
    },
    respEmailPetition: function(){
      if (Object.keys(this.respEmailPetition).length > 0){
        this.closeLoading()
        if (this.modalResultadoVenta == false){
          this.volverInicio()
        }
      }
    }
  }
}
</script>

<style scoped>
  .formatoPalabras{
    font-family: 'Poppins', sans-serif;
  }
  .tarjetaCss {
    width: 100%;
    height: 40px;
    border-radius: 3px;
    box-shadow: none;
    border: 1px solid #A9A9A9;
    transition: all 0.3s ease-in-out;
    font-size: 14px;
    padding: 5px 10px;
    background: none;
    font-family: Roboto,sans-serif;
    font-size: 15px;
  }
  /*input[type=text]:focus{
    border-color: aqua;
    border-inline-color: aqua;
  }*/
</style>