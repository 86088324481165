<template>
  <div style="background-color: #f2f2f2">
    <div>
      <Menu 
        :provinciaActual="provinciaNombre"
        :codPostalActual="codigoPostal"
        @cargarDashboardMovil="desplegarMenuMovil"
        @obtenerMenus="asignarMenus"
        @reloadPage="recargarPagina"
        @setDomicilioEntrega="ingresarDomEntrega"
      />
    </div>
    <div :style="$vuetify.breakpoint.width > 960 ? 'padding-top: 120px' : 'padding-top: 120px'">
      <ContenidoBuscarArticulos 
        :articulos="articulosEcommerce"
        :filtros="categoriaActualFiltro"
        :pendientes="pendiente"
        :ordenFiltros="filtrosOrden"
      />
      <Loading :activo="dialogCarga"/>
      <DashboardMovil 
        :drawerParam="drawerHome"
        :groupParam="groupHome"
        :menus="menuHome"
        :subMenus="subMenuHome"
        @closeMenuMovil="cerrarMenuMovil"
      />
    </div>
    <div :style="articulosEcommerce.length > 0 ? 'padding-top: 0px' : 'padding-top: 50%'">
      <Footer 
        :listadoNulo="listadoVacio"
        :menus="menuHome"
      />
    </div>
    <domicilioEntrega 
      :activo="dialogDomEntrega"
      @closeDomicilioEntrega="ingresarDomEntrega"
      @setDatosEntrega="setearDatosEntrega"
    />
  </div>
</template>

<script>
import Menu from '../components/index/Menu'
import Footer from '../components/index/Footer'
import DashboardMovil from '../components/movil/DashboardMovil'
import ContenidoBuscarArticulos from '../components/articulos/ContenidoBuscarArticulos.vue'
import Loading from '../components/genericos/Loading'
import domicilioEntrega from '../components/index/domicilioEntrega.vue'
import {mapActions, mapState} from 'vuex'
export default {
  data() {
    return {
      drawerHome: false,
      groupHome: null,
      menuHome: [],
      subMenuHome: [],
      categoriaActualFiltro: {},
      articulosEcommerce: [],
      categoriasArticulos: [],
      empezarBusquedaCategorias: 0,
      dialogCarga: false,
      intentosLoginJarvis: 0,
      dialogDomEntrega: false,
      provinciaNombre: '',
      codigoPostal: '',
      pendiente: true,
      llamadaDeInicio: true,
      listadoVacio: false,
      filtrosOrden: []
    }
  },
  created(){
    this.iniciarFormulario()
  },
  components: {
    Menu,
    Footer,
    DashboardMovil,
    ContenidoBuscarArticulos,
    Loading,
    domicilioEntrega
  },
  computed: {
    ...mapState(['respArticulosEcommerce', 'respArtsCatBusqueda', 'respDatosApiJarvis'])
  },
  methods:{
    ...mapActions(['getBusquedaUsuario', 'getCategoriasBusqueda']),
    async iniciarFormulario(){
      this.articulosEcommerce = []
      this.dialogCarga = true
      await this.getBusquedaUsuario(this.$route.params.busqueda)
    },
    desplegarMenuMovil(){
      this.drawerHome = true
    },
    cerrarMenuMovil(group){
      this.drawerHome = false
      this.groupHome = group
    },
    asignarMenus(menus){
      this.menuHome = menus.menus
      this.subMenuHome = menus.subMenus
    },
    async getFiltrosCategorias(){
      await this.getCategoriasBusqueda(this.categoriasArticulos)
    },
    recargarPagina(){
      window.location.reload()
    },
    toTop(){
      this.$vuetify.goTo(0)
    },
    ingresarDomEntrega(bandDialog){
      this.dialogDomEntrega = bandDialog
    },
    setearDatosEntrega(){
      let datosEntrega = JSON.parse(localStorage.getItem('enviarA'))
      this.provinciaNombre = datosEntrega.provinciaNombre
      this.codigoPostal = datosEntrega.codigoPostal
    }
  },
  watch: {
    respArticulosEcommerce: async function(){
      if (Object.keys(this.respArticulosEcommerce).length > 0){
        if (parseInt(this.respArticulosEcommerce.resultado) === 1){
          if (this.respArticulosEcommerce.articulos.length > 0){
            this.articulosEcommerce = []
            this.categoriasArticulos = []
            this.pendiente = this.respArticulosEcommerce.pendiente
            let artsPeticion = await this.$store.dispatch('articulos/getArrayArticulos', {articulos: this.respArticulosEcommerce.articulos, opcion: 0, categoria: 0, imgGrandes: 0})
            if (artsPeticion.resultado == 0){
              this.dialogCarga = false
              this.$store.dispatch('executeSnackBar', {msj: artsPeticion.msj, color: 'error', activo: true})
              return
            }
            // coloco los articulos obtenidos
            for (let id in artsPeticion.listado){
              let unArt = this.articulosEcommerce.filter(element => element.articuloCodigo == artsPeticion.listado[id].articuloCodigo)
              if (unArt.length == 0){
                this.articulosEcommerce.push(artsPeticion.listado[id])
              }
            }
            // categorias
            for (let id in artsPeticion.categoriasArticulos){
              let unaCat = this.categoriasArticulos.filter(element => element == artsPeticion.categoriasArticulos[id])
              if (unaCat.length == 0){
                this.categoriasArticulos.push(artsPeticion.categoriasArticulos[id])
              }
            }
            if (this.pendiente == false){
              this.empezarBusquedaCategorias = 1
            }
            
            if (this.llamadaDeInicio == true){
              this.llamadaDeInicio = false
              this.toTop()
            }
          }else{
            //this.toTop()
            this.dialogCarga = false
          }
        }
      }else{
        //this.toTop()
        this.dialogCarga = false
      }
    },
    categoriasArticulos: async function(){
      if ((this.categoriasArticulos.length > 0) && (parseInt(this.empezarBusquedaCategorias) === 1)){
        this.empezarBusquedaCategorias = 0
        await this.getFiltrosCategorias()
      }else{
        //this.toTop()
        if (this.dialogCarga == false){
          this.dialogCarga = false
        }
      }
    },
    respArtsCatBusqueda: function(){
      if (Object.keys(this.respArtsCatBusqueda).length > 0){
        this.dialogCarga = false
        if (parseInt(this.respArtsCatBusqueda.resultado) === 1){
          if (this.respArtsCatBusqueda.hasOwnProperty('filtrosOrden') && Array.isArray(this.respArtsCatBusqueda.filtrosOrden)){
            this.filtrosOrden = this.respArtsCatBusqueda.filtrosOrden
          }
          if (this.respArtsCatBusqueda.categorias.length > 0){
            // tomo el primer elemento del array de categorias como mi filtro inicial
            let filtro = this.respArtsCatBusqueda.categorias[0]
            // ahora agrego los filtros de las demas categorias siempre y cuando no se encuentren repetidas
            for (let id = 1; id<=this.respArtsCatBusqueda.categorias.length - 1; id++){
              // recorro los filtros de la categoria que estoy tomando
              for (let jd in this.respArtsCatBusqueda.categorias[id].filtros){
                let filtroEncontrado = filtro.filtros.filter(element => element.toUpperCase() == this.respArtsCatBusqueda.categorias[id].filtros[jd].toUpperCase())
                if (filtroEncontrado.length === 0){
                  filtro.filtros.push(this.respArtsCatBusqueda.categorias[id].filtros[jd])
                }
              }
            }
            this.categoriaActualFiltro = filtro
          }else{
            this.categoriaActualFiltro = {}
          }
        }
        //this.toTop()
      }else{
        this.dialogCarga = false
      }
    },
    respDatosApiJarvis: function(){
      if (Object.keys(this.respDatosApiJarvis).length > 0){
        if (parseInt(this.respDatosApiJarvis.resultado) === 1){
          if (parseInt(this.intentosLoginJarvis) <= 3){
            this.intentosLoginJarvis = this.intentosLoginJarvis + 1
            // iniciar el formulario nuevamente
            this.iniciarFormulario()
          }else{
            console.log(this.respDatosApiJarvis.msj)
            this.dialogCarga = false
          }
        }
      }
    },
    articulosEcommerce: function(){
      if (this.articulosEcommerce.length === 0){
        this.listadoVacio = true
      }else{
        this.listadoVacio = false
      }
    }
  },
}
</script>

<style>

</style>