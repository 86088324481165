<template>
  <v-container fluid style="height: 100%; padding: 0px;">
    <v-row
      style="padding: 0px; height: 100%; margin: 0px;"
    >
      <!-- Listado de Sucursales -->
      <v-col
        cols="12"
        style="height: 100%; padding-bottom: 0px;"
      >
        <v-card>
          <v-toolbar dense flat >
            <v-toolbar-title><v-icon>mdi-home-group</v-icon> Sucursales</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-title>
            <v-text-field
              v-model="search"                        
              append-icon="mdi-magnify"
              label="Buscar"
              dense single-line hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="sucursalesArray"
            :search="search"
          >
            <template v-slot:item.estado="{ item }">            
              <v-chip small :color="getColor(item.estado)" dark>{{getEstadoName(item.estado)}}</v-chip>     
            </template>
            <template v-slot:item.actions="{ item }">            
              <v-icon color="indigo" @click="verLocales(item)">mdi-eye-outline</v-icon>       
            </template>
          </v-data-table>
        </v-card>
        <!-- Modal Locales  Sucursal-->
        <v-dialog
          v-model="modalLocalesSucursal"
          scrollable 
          max-width="800px"
          transition="dialog-transition"
        >
          <v-card>
            <v-toolbar color="primary" dark height="40%" text>
              <v-toolbar-title style="font-size:20px"> {{sucursal_aux.provincia}}</v-toolbar-title>
            </v-toolbar>    
            <v-card-text>
              <v-container fluid style="margin: 0px;width: 90%" >           
                <v-row>
                  <v-col cols="12" md="5" >
                    <v-text-field
                      v-model="sucursal_aux.provincia"
                      label="Nombre"
                      outlined dense          
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2" style="margin-top:-15px" >
                    <v-switch v-model="sucursal_estado" inset label="Activo" color="success"></v-switch>
                  </v-col>
                </v-row>
              </v-container>                                        
              <v-container fluid style="margin: 0px;width: 100%" >      
                <v-row class="text-center">
                  <v-col cols="8" md="8" >
                    <!-- <v-text-field
                      v-model="local_nombre"
                      label="Nuevo Local"
                      outlined dense          
                    ></v-text-field> -->
                    <v-autocomplete	
                      v-model="selectedLocal" 
                      :items="localesPendientes"
                      clearable
											outlined dense
                      return-object
                      label="Nuevo Local" 
                      item-text="localNombre" 
                      placeholder="Seleccione un Local">
										</v-autocomplete>
                  </v-col>    
                  <v-btn class="mx-2" fab dark small color="success" style="margin-top:10px" @click="agregarLocal()">
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </v-row>
                <v-data-table
                  :headers="headers_locales"
                  :items="localesArray"                                                              
                  class="elevation-1"
                  hide-default-footer
                >                   
                  <template v-slot:item.estado="{ item }">                                     
                    <v-chip small :color="getColor(item.estado)" dark>{{getEstadoName(item.estado)}}</v-chip>     
                  </template>
                  <template v-slot:item.actions="{ item }">            
                    <v-icon color="primary" @click="editLocal(item)">mdi-square-edit-outline</v-icon>       
                  </template>           
                </v-data-table>                                                            
              </v-container>
              <v-layout align-center justify-center row>
                <v-btn x-small color="warning" @click="modalLocalesSucursal=false">cerrar</v-btn> 
              </v-layout>
            </v-card-text>    
          </v-card>
        </v-dialog>
        <!-- ./Modal Locales  Sucursal-->
        <v-dialog
          v-model="modalEditLocal"         
          max-width="500px"
          transition="dialog-transition"
        >
          <v-card>
            <v-card-text>
              <v-container fluid style="margin: 0px;width: 90%" >           
                <v-row>
                  <v-col cols="12" md=10 >
                    <v-text-field
                      v-model="local_aux.direccion"
                      label="Nombre"
                      outlined dense          
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2" style="margin-top:-15px" >
                    <v-switch v-model="local_aux.estado" inset label="Activo" color="success"></v-switch>
                  </v-col>
                </v-row>
              </v-container>   
              <v-layout align-center justify-center row>
                <v-btn small color="primary" @click="modificarLocal()">Modificar</v-btn> 
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import router from '../../router'
import {mapActions, mapState} from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
export default {
  data() {
    return {
      arraySucursales : [],      
      headers:[
        { text: 'Provincia', align: 'center',sortable: false,value:'provincia'},
        { text: 'Estado',align: 'center',value:'estado'},
        { text: 'Opciones', align: 'center',sortable: false,value:'actions'}
      ],
      search :"",
      modalLocalesSucursal : false,
      sucursal_aux : {
        estado : 0,
        index : "",
        locales :"",
        provincia:""
      },
      sucursal_estado : false,
      headers_locales:[
        { text: 'Direccion', align: 'center',sortable: true,value:'direccion'},
        { text: 'Estado',align: 'center',value:'estado'},
        { text: 'Opciones', align: 'center',sortable: false,value:'actions'}
      ],
      modalEditLocal:false,
      local_aux:{
        index:"",
        direccion :"",
        estado:false
      },
      locales :{},
      local_nombre :"",
      selectedLocal: {},
      localesPendientes: [],
      intentosLoginJarvis: 0
    }
  },
  components: {
    
  },
  created () {
    this.usuarioLogueado()
  },
  computed: {
    ...mapState(['respSucursalesAdmin', 'respSetLocalAdmin', 'respDatosApiJarvis', 'respLocalSucursal']),
    sucursalesArray:function(){
      let data = []
      Object.entries(this.arraySucursales).forEach(([key, value]) => {
        let item = {
          index: key,
          provincia: value.provincia,
          estado: value.estado,
          locales: value.locales,
          sucursal_codigo: value.sucursal_codigo
        }
        data.push(item)
      })
      return data
    },
    localesArray:function(){
      let data = []
      Object.entries(this.sucursal_aux.locales).forEach(([key, value]) => {
        let item = {
          index : key,
          direccion : value.direccion,
          estado : value.estado,
        }
        data.push(item)
      })
      return data
    }
  },
  methods: {
    ...mapActions(['getSucursalesAdmin', 'setNewLocal', 'getLocalesXSucursal']),
    usuarioLogueado(){
      if (localStorage.getItem('tokenAdmin')){
        // prosigo con la carga
        this.getSucursales()
      }else{
        // redirecciono al Login
        router.push({name: 'LoginAdmin', params: {sesionExpirada: 1}})
      }
    },
    async getSucursales(){
      await this.getSucursalesAdmin()
    },
    getColor (estado) {
      if (parseInt(estado) == 1) return 'green'
      else if (parseInt(estado) == 0) return 'orange'
    },
    getEstadoName(estado){
      if (parseInt(estado) == 1) return 'Activo'
      else if (parseInt(estado) == 0) return 'Inactivo'
    },
    async verLocales(sucursal){
      this.locales = sucursal.locales
      this.sucursal_aux = {
        estado: sucursal.estado,
        index: sucursal.index,
        locales: sucursal.locales,
        provincia: sucursal.provincia
      }
      if(parseInt(sucursal.estado) == 1){
        this.sucursal_estado = true
      }
      this.local_nombre = ""
      // obtengo los depositos de la sucursal que me estan pasando de la api de jarvis
      await this.getLocalesXSucursal(sucursal.sucursal_codigo)
      //this.modalLocalesSucursal = true
    },
    editLocal(local){
      let estado_local  = false
      if(parseInt(local.estado) == 1){ 
        estado_local = true
      }
      this.local_aux ={
        index: local.index,
        direccion: local.direccion,
        estado: estado_local
      }
      this.modalEditLocal = true
    },
    async agregarLocal(){
      /*if (this.local_nombre.length === 0){
        Swal.fire(
          'Agregar nuevo Local', 
          'El nombre del Local no puede estar vacio', 
          'info'
        )
        return
      }*/
      if (Object.keys(this.selectedLocal).length === 0){
        Swal.fire(
          'Agregar nuevo Local', 
          'Debe seleccionar un Local para agregar', 
          'info'
        )
        return
      }
      Swal.fire({
        title: 'Agregar nuevo Local',
        text: '¿Esta seguro de agregar el local ' + this.local_nombre + ' a la Sucursal de ' + this.sucursal_aux.provincia + '?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let tokenLocal = JSON.parse(localStorage.getItem('tokenAdmin'))
          await this.setNewLocal({
            keySucursal: this.sucursal_aux.index, 
            localNombre: this.selectedLocal.localNombre,
            token: tokenLocal,
            opcion: 1,
            depCodigo: this.selectedLocal.depositoCodigo
          })
        }
      })
    },
    async modificarLocal(){
      if (this.local_aux.direccion.length === 0){
        Swal.fire(
          'Modificar Local', 
          'El nombre del Local no puede estar vacio', 
          'info'
        )
        return
      }
      Swal.fire({
        title: 'Modificar Local',
        text: '¿Esta seguro de modificar el Local seleccionado?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          // obtengo el token
          let tokenLocal = JSON.parse(localStorage.getItem('tokenAdmin'))
          // obtengo el estado
          let estado_loc = 0
          if(this.local_aux.estado == true){
            estado_loc = 1
          }
          // armo mi cuerpo de actualizacion
          let body = {
            estado : estado_loc,
            direccion : this.local_aux.direccion
          }
          // realizo la actualizacion
          await this.setNewLocal({
            keySucursal: this.sucursal_aux.index, 
            localNombre: this.local_aux.direccion,
            keyLocal: this.local_aux.index,
            token: tokenLocal,
            body: body,
            opcion: 2
          })
        }
      })
    }
  },
  watch: {
    respSucursalesAdmin: function(){
      if (Object.keys(this.respSucursalesAdmin).length > 0){
        if (parseInt(this.respSucursalesAdmin.resultado) === 1){
          this.arraySucursales = this.respSucursalesAdmin.sucursales
        }
      }
    },
    respSetLocalAdmin: function(){
      if (Object.keys(this.respSetLocalAdmin).length > 0){
        if (parseInt(this.respSetLocalAdmin.resultado) === 1){
          if (parseInt(this.respSetLocalAdmin.opcion) === 1){
            Swal.fire(
              'Agregar nuevo Local', 
              'Local agregado exitosamente', 
              'success'
            )
          }else{
            Swal.fire(
              'Modificar Local', 
              'El Local fue actualizado exitosamente', 
              'success'
            )
          }
          this.getSucursales()
        }else{
          // es por token expirado?
          if (parseInt(this.respSetLocalAdmin.tokenExpirado) === 1){
            // token expirado, redirecciono al Login
            router.push({name: 'LoginAdmin', params: {sesionExpirada: 1}})
          }else{
            // otro error
            if (parseInt(this.respSetLocalAdmin.opcion) === 1){
              Swal.fire(
                'Agregar nuevo Local', 
                this.respSetLocalAdmin.msj, 
                'error'
              )
            }else{
              Swal.fire(
                'Modificar Local Local', 
                this.respSetLocalAdmin.msj, 
                'error'
              )
            }
          }
        }
        this.modalEditLocal = false
        this.modalLocalesSucursal = false
      }
    },
    respDatosApiJarvis: function(){
      if (Object.keys(this.respDatosApiJarvis).length > 0){
        if (parseInt(this.respDatosApiJarvis.resultado) === 1){
          if (parseInt(this.intentosLoginJarvis) <= 3){
            this.intentosLoginJarvis = this.intentosLoginJarvis + 1
            // iniciar el formulario nuevamente
            location.reload()
          }else{
            console.log(this.respDatosApiJarvis.msj)
            this.dialogCarga = false
          }
        }
      }
    },
    respLocalSucursal: function(){
      if (Object.keys(this.respLocalSucursal).length > 0){
        if (parseInt(this.respLocalSucursal.resultado) === 1){
          this.localesPendientes = []
          let localesApi = this.respLocalSucursal.locales
          for (let id in localesApi){
            let cont = 0
            // saco los locales que ya existen en la api
            for (let jd in this.sucursal_aux.locales){
              if (localesApi[id].depositoCodigo == this.sucursal_aux.locales[jd].deposito_codigo){
                cont = cont + 1
              }
            }
            if (parseInt(cont) === 0){
              this.localesPendientes.push(localesApi[id])
            }
          }
          this.modalLocalesSucursal = true
        }else{
          Swal.fire(
            'No se pudieron obtener los Locales de la Sucursal seleccionada', 
            this.respLocalSucursal.msj, 
            'error'
          )
        }
      }
    }
  },
}
</script>

<style>

</style>