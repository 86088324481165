<template>
  <div>
    <v-container>
      <v-card raised>
        <v-card-title>
          <v-toolbar color="#ed1841" dark height="40%">
            <v-toolbar-title style="font-size:20px">
              <v-icon>mdi-inbox-full</v-icon> 
              Mis Pedidos
            </v-toolbar-title>
          </v-toolbar>  
        </v-card-title>
        <!--Tabla Detalle Pedidos-->
        <v-card-text>
          <v-data-table 
            :headers="headers"
            locale="es"
            :items="pedidos"  
            :sort-by="[sortBy]" 
            :sort-desc="[sortDesc]"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:item.importe="{ item }">
              <td><center>${{item.importe}}</center></td>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom  v-if="item.estado !== 'PENDIENTE'">
                <template v-slot:activator="{ on }">
                  <v-btn small icon color="primary" @click="visualizar(item)" v-on="on">
                    <v-icon  class="mr-2" >mdi-eye-outline</v-icon>
                  </v-btn>
                </template>
                <span>Ver</span>
              </v-tooltip>
            </template>
            <template v-slot:item.estado="{ item }">
              <v-chip :color="getColor(item.estado)" dark small>{{ item.estado }}</v-chip>
            </template>
            <template v-slot:no-data>
              <v-alert type="warning" transition="scale-transition">No hay Pedidos.</v-alert>
            </template>
          </v-data-table>  <!-- ./Tabla Detalle Pedidos-->
        </v-card-text>   
      </v-card>
      <!--Dialog Articulos -->
      <v-dialog v-model="dialogArticulos" max-width="70%">
        <v-card>
          <v-card-title>
            <v-toolbar color="#ed1841" dark height="30%">
              <v-toolbar-title style="font-size:18px">
                Pedido
              </v-toolbar-title>
            </v-toolbar> 
          </v-card-title>
          <v-card-text>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead >
                  <tr>
                    <th class="text-center" id="encabezado">Codigo</th>
                    <th class="text-center" id="encabezado"></th>
                    <th class="text-center" id="encabezado">Nombre</th>
                    <th class="text-center" id="encabezado">Cantidad</th>
                    <th class="text-center" id="encabezado">Precio</th>                                      
                  </tr>
                </thead>
                <tbody>                         
                  <tr v-for="(articulo,index) in articulos" :key="index">
                    <td>
                      <v-img 
                        :src="articulo.img_principal"
                        :lazy-src="articulo.img_principal"
                        aspect-ratio="1" class="grey lighten-2"
                        max-width="80" max-height="80"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </td>
                    <td>{{ articulo.codigo_bb }}</td>
                    <td>{{ articulo.nombre }}</td>
                    <td> {{ articulo.cantidad }}</td>
                    <td>$ {{  Intl.NumberFormat("de-DE").format( articulo.precio  ) }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th></th>
                    <th></th>
                    <td><strong>Total</strong></td>
                    <td>{{ total_cantidad }}</td>
                    <td>$ {{  Intl.NumberFormat("de-DE").format( total_final  ) }}*</td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
            &nbsp; *<small>Incluye intereses por forma de pago</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" @click="dialogArticulos = false">
              Volver
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <Loading :activo="dialogCarga" />
    <!-- Loading -->
    <LoadingCircular 
      :active.sync="loadingCarga" 
      :can-cancel="true"   
      color="#ed1841"         
      oader="dots"
      background-color="#252527"
      :is-full-page="true">
    </LoadingCircular>
  </div>
</template>

<script>
import LoadingCircular from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Loading from '../../components/genericos/Loading.vue'
import {mapState, mapActions} from 'vuex'
import router from '../../router'
import moment from 'moment'
export default {
  components: {
    LoadingCircular,
    Loading
  },
  data() {
    return {
      dialogCarga: false,
      usuario: {},
      pedidos: [],
      articulos: [],
      headers: [
        { text: 'Fecha', align: 'start', value: 'fecha_log'},
        { text: 'Estado', value: 'estado'},
        { text: 'Importe', value: 'importe'},
        { text: 'Opciones', value: 'actions', align: 'center', sortable: false},
      ],
      sortBy: 'fecha_log',
      sortDesc: true,
      dialogArticulos: false,
      total_final: 0,
      total_cantidad: 0
    }
  },
  computed: {
    ...mapState(['loadingCarga', 'articulosCarrito', 'respPedidosCliente', 'respActUserMisPedidos'])
  },
  created () {
    this.iniciarFormulario()
    this.iniciarCarrito()
  },
  methods: {
    ...mapActions(['loadCarrito', 'getPedidosCliente', 'activeUserMisPedidos', 'logout']),
    async iniciarCarrito(){
      await this.loadCarrito()
    },
    async iniciarFormulario(){
      this.dialogCarga = true
      await this.activeUserMisPedidos({paso: 1, opcion: 2})
      // tengo los datos del usuario en localStorage?
      /*if (localStorage.getItem('datosUsuario')){
        this.usuario = JSON.parse(localStorage.getItem('datosUsuario'))
        await this.getPedidosCliente(this.usuario)
      }else{
        // no tengo datos de usuario, redirecciono al inicio
        router.push({name: 'Home'})
      }*/
    },
    getColor (estado) {
      if (estado == 'APROBADO') return 'green'
      else if (estado == 'ANULADO' || estado == 'FAILED' || estado ==  'INHABILITADO' || estado == 'DEVOLUCION') return 'red'
      else if (estado == 'RECHAZADO' || estado == 'PROCESADO CON ERROR' ) return 'orange'
      else return 'blue'
    },
    visualizar(item){
      this.articulos = JSON.parse( JSON.stringify( item.articulos ) )
      this.total_final = JSON.parse( JSON.stringify( item.importe ) )
      let i
      let suma = 0
      for (i in this.articulos ){
        suma = suma + parseInt(this.articulos[i].cantidad)
      }
      this.total_cantidad = suma
      this.dialogArticulos = true
    },
  },
  watch: {
    respPedidosCliente: function(){
      this.dialogCarga = false
      if (Object.keys(this.respPedidosCliente).length > 0){
        if (parseInt(this.respPedidosCliente.resultado) === 1){
          this.pedidos = this.respPedidosCliente.pedidos
        }else{
          console.log(this.respPedidosCliente.msj)
        }
      }
    },
    pedidos: function(){
      if (this.pedidos.length > 0){
        for (let id in this.pedidos){
          this.pedidos[id].fecha_log =  moment( this.pedidos[id].fecha_log).format("DD/MM/YYYY")
        }
      }
    },
    dialogArticulos:function(){
      if(this.dialogArticulos == false){
        this.articulos.length = 0
        this.total_cantidad = 0
        this.total_final = 0
      }
    },
    respActUserMisPedidos: async function(){
      if (Object.keys(this.respActUserMisPedidos).length > 0){
        if (parseInt(this.respActUserMisPedidos.opcion) === 2){
          if (parseInt(this.respActUserMisPedidos.resultado) === 1){
            if (this.respActUserMisPedidos.existeUsuario == true){
              this.usuario = this.respActUserMisPedidos.datosUsuario
              await this.getPedidosCliente(this.usuario)
            }else{
              // no hay usuario activo con sesion, cierro por si acaso y redirecciono al inicio
              if (this.$route.name.toUpperCase() === 'MISPEDIDOS'){
                await this.logout()
                router.push({name: 'Home'})
              }
            }
          }else{
            // error con el status, redireccionar al inicio y cerrar sesion
            if (this.$route.name.toUpperCase() === 'MISPEDIDOS'){
              await this.logout()
              router.push({name: 'Home'})
            }
          }
        }
      }
    }
  },
}
</script>

<style>
  .v-data-table-header *{
    font-size: 12px ;
  }
  .v-data-table td {
    font-size: 13px ;
  }
  .v-data-footer {
    font-size: 11px ;
  }
</style>