<template>
  <div style="background-color: #f2f2f2">
    <div>
      <Menu 
        :provinciaActual="provinciaNombre"
        :codPostalActual="codigoPostal"
        @cargarDashboardMovil="desplegarMenuMovil"
        @obtenerMenus="asignarMenus"
        @setDomicilioEntrega="ingresarDomEntrega"
        @reloadPage="recargarPagina"
      />
    </div>
    <div :style="$vuetify.breakpoint.width > 960 ? 'padding-top: 120px' : 'padding-top: 120px'">
      <contenidoCarritoDetalle />
    </div>
    <div>
      <Footer 
        :menus="menuHome"
      />
      <DashboardMovil 
        :drawerParam="drawerHome"
        :groupParam="groupHome"
        :menus="menuHome"
        :subMenus="subMenuHome"
        @closeMenuMovil="cerrarMenuMovil"
      />
    </div>
    <domicilioEntrega 
      :activo="dialogDomEntrega"
      @closeDomicilioEntrega="ingresarDomEntrega"
      @setDatosEntrega="setearDatosEntrega"
    />
  </div>
</template>

<script>
import Menu from '../../components/index/Menu'
import Footer from '../../components/index/Footer'
import DashboardMovil from '../../components/movil/DashboardMovil'
import contenidoCarritoDetalle from '../../components/checkout/contenidoCarritoDetalle'
import domicilioEntrega from '../../components/index/domicilioEntrega.vue'
export default {
  components: {
    Menu,
    Footer,
    DashboardMovil,
    contenidoCarritoDetalle,
    domicilioEntrega
  },
  data() {
    return {
      dialogCarga: false,
      drawerHome: false,
      groupHome: null,
      menuHome: [],
      subMenuHome: [],
      dialogDomEntrega: false,
      provinciaNombre: '',
      codigoPostal: ''
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == null || from.name == undefined || from.name == 'null'){
      next()
    }else{
      next()
      location.reload()
    }
  },
  methods: {
    desplegarMenuMovil(){
      this.drawerHome = true
    },
    cerrarMenuMovil(group){
      this.drawerHome = false
      this.groupHome = group
    },
    asignarMenus(menus){
      this.menuHome = menus.menus
      this.subMenuHome = menus.subMenus
    },
    ingresarDomEntrega(bandDialog){
      this.dialogDomEntrega = bandDialog
    },
    setearDatosEntrega(){
      let datosEntrega = JSON.parse(localStorage.getItem('enviarA'))
      this.provinciaNombre = datosEntrega.provinciaNombre
      this.codigoPostal = datosEntrega.codigoPostal
    },
    recargarPagina(){
      window.location.reload()
    }
  },
}
</script>

<style>

</style>