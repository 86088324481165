<template>
  <v-container fluid style="height: 100%; padding: 0px;">
    
  </v-container>
</template>

<script>
export default {
  created () {
    
  },
  methods: {

  },
}
</script>

<style>

</style>