<template>
  <v-container fluid style="height: 100%; padding: 0px;">
    <v-row
      style="padding: 0px; height: 100%; margin: 0px;"
    >
      <!-- Listado de Carruseles -->
      <v-col
        cols="12"
        style="height: 100%; padding-bottom: 0px;"
      >
        <v-card>
          <v-toolbar dense flat >
            <v-toolbar-title>
              <v-icon>mdi-view-carousel-outline</v-icon> Carruseles
            </v-toolbar-title>
            <v-spacer></v-spacer>       
            <v-btn rounded color="teal accent-4" dark @click="agregarCarrusel()"><v-icon small>mdi-plus-circle-outline</v-icon>&nbsp;Agregar</v-btn>
          </v-toolbar>             
          <v-card-title>
            <v-text-field
              v-model="search"                 
              append-icon="mdi-magnify"
              label="Buscar"
              single-line dense
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="carruseles"
            :search="search"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="(item,index) in items" :key="index">
                  <td class="text-center"> {{item.nombre}} </td>
                  <td class="text-center">{{ item.orden }}</td>
                  <td class="text-center">
                    <v-chip small :color="getColor(item.estado)" dark>{{getEstadoName(item.estado)}}</v-chip>             
                  </td>
                  <td class="text-center">{{ item.criterio }}</td>
                  <td class="text-center">
                    <v-icon color="primary" title="Editar Carrusel" @click="editarCarrusel(item)">mdi-square-edit-outline</v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
        <!-- Dialog Nuevo Carrusel -->
        <v-dialog
          v-model="dialogCarrusel"
          max-width="850px"
        >
          <v-card
            flat
            tile
          >
            <v-container fluid>
              <v-card-title>
                <v-toolbar dense flat >
                  <v-toolbar-title v-if="nuevoCarrusel == true">
                    <v-icon>mdi-plus-circle-outline</v-icon> {{ tituloDialogCarrusel }}
                  </v-toolbar-title>
                  <v-toolbar-title v-if="nuevoCarrusel == false">
                    <v-icon>mdi-square-edit-outline</v-icon> {{ tituloDialogCarrusel }}
                  </v-toolbar-title>
                </v-toolbar>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="8"
                  >
                    <v-text-field
                      v-model="carruselNombre"
                      label="Nombre Carrusel"
                      placeholder="Nombre Carrusel"
                      outlined dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="2"
                  >
                    <v-text-field
                      v-model="carruselOrden"
                      label="Orden"
                      placeholder="Orden"
                      type="number"
                      outlined dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="2"
                  >
                    <v-switch style="margin-top:4px" v-model="carruselActivo" class="mx-2" label="Activo"></v-switch>
                  </v-col>
                </v-row>
                <br/>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-autocomplete
                      v-model="selectedCriterio"
                      :items="criterios"
                      color="white"
                      label="Criterio"
                      placeholder="Criterio"
                      :clearable="true"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row
                  justify="center"
                >
                  <v-col
                    cols="2"
                  >
                    <v-btn
                      color="accent"
                      tile
                      @click="guardarCarrusel"
                      v-if="nuevoCarrusel == true"
                    >
                      Guardar
                    </v-btn>
                    <v-btn
                      color="accent"
                      tile
                      @click="modificarCarrusel"
                      v-if="nuevoCarrusel == false"
                    >
                      Modificar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-container>  
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import router from '../../router'
import {mapActions, mapState} from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
export default {
  data() {
    return {
      search: '',
      headers: [
        { text: 'Nombre', align: 'center',value:'nombre' },
        { text: 'Orden', align: 'center',value:'orden' },
        { text: 'Estado', align: 'center',value:'estado' },
        { text: 'Criterio', align: 'center',value:'criterio' },
        { text: 'Acciones', align: 'center' }
      ],
      dialogCarrusel: false,
      tituloDialogCarrusel: '',
      carruselNombre: '',
      carruselOrden: 1,
      carruselActivo: false,
      selectedCriterio: '',
      nuevoCarrusel: false,
      selectedKey: ''
    }
  },
  components: {
    
  },
  computed: {
    ...mapState(['respCarruselesAdmin', 'respAddCarruselAdmin', 'respUpdateCarrAdmin']),
    carruseles(){
      if (Object.keys(this.respCarruselesAdmin).length > 0){
        if (parseInt(this.respCarruselesAdmin.resultado) === 1){
          let carruselesAdmin = []
          let listado = this.respCarruselesAdmin.carruseles
          let listadoCriterios = this.respCarruselesAdmin.criterios
          for (let id in listado){
            let unCriterio = listadoCriterios.filter(element => parseInt(element.key_firebase) === parseInt(listado[id].criterio))
            if (unCriterio.length > 0){
              let unCarrusel = {
                nombre: listado[id].nombre,
                orden: listado[id].orden,
                estado: listado[id].inhabilitado,
                criterio: unCriterio[0].nombre,
                key: listado[id].key_firebase
              }
              carruselesAdmin.push(unCarrusel)
            }
          }
          return carruselesAdmin
        }else{
          return []
        }
      }else{
        return []
      }
    },
    criterios(){
      if (Object.keys(this.respCarruselesAdmin).length > 0){
        if (parseInt(this.respCarruselesAdmin.resultado) === 1){
          let criteriosAdmin = this.respCarruselesAdmin.criterios.map(function (task, index, array) {
            return task.nombre
          })
          return criteriosAdmin
        }else{
          return []
        }
      }else{
        return []
      }
    }
  },
  created () {
    this.usuarioLogueado()
  },
  methods: {
    ...mapActions(['getCarruselesAdmin', 'addCarouselAdmin', 'updateCarouselAdmin']),
    async usuarioLogueado(){
      if (localStorage.getItem('tokenAdmin')){
        // prosigo con la carga
        await this.getCarruselesAdmin()
      }else{
        // redirecciono al Login
        router.push({name: 'LoginAdmin', params: {sesionExpirada: 1}})
      }
    },
    getColor(estado) {
      if (parseInt(estado) == 0) return 'green'
      else if (parseInt(estado) == 1) return 'orange'
    },
    getEstadoName(estado){
      if (parseInt(estado) ==0) return 'Activo'
      else if (parseInt(estado) == 1) return 'Inactivo'
    },
    agregarCarrusel(){
      this.nuevoCarrusel = true
      this.tituloDialogCarrusel = 'Nuevo Carrusel'
      this.carruselNombre = ''
      this.carruselOrden = 1
      this.carruselActivo = false
      this.selectedCriterio = ''
      this.dialogCarrusel = true
    },
    validarObligatorios(){
      let valido = {}
      if (this.carruselNombre.length === 0){
        valido.valido = false
        valido.msj = 'Por favor ingrese el nombre del Carrusel para proseguir'
        return valido
      }
      if (this.carruselOrden.length === 0){
        valido.valido = false
        valido.msj = 'Por favor ingrese el orden del Carrusel para proseguir'
        return valido
      }
      if (this.selectedCriterio.length === 0){
        valido.valido = false
        valido.msj = 'Por favor seleccione el criterio del Carrusel para proseguir'
        return valido
      }
      valido.valido = true
      valido.msj = 'OK'
      return valido
    },
    guardarCarrusel(){
      let valido = this.validarObligatorios()
      if (!valido.valido){
        Swal.fire(
          'Nuevo Carrusel', 
          valido.msj, 
          'warning'
        )
        return
      }
      Swal.fire({
        title: 'Nuevo Carrusel',
        text: '¿Confirma subir el Nuevo Carrusel?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          // obtengo la posicion del criterio elegido
          let pos = this.respCarruselesAdmin.criterios.filter(
            element => element.nombre.toUpperCase() == this.selectedCriterio.toUpperCase()
          )
          if (pos.length > 0){
            let estado = 1
            if (this.carruselActivo == true){
              estado = 0
            }
            let unCarrusel = {
              criterio: pos[0].key_firebase,
              inhabilitado: estado,
              nombre: this.carruselNombre,
              orden: this.carruselOrden
            }
            await this.addCarouselAdmin(unCarrusel)
          }else{
            Swal.fire(
              'Nuevo Carrusel', 
              'No se pudo encontrar el Criterio en la Lista de Criterios', 
              'error'
            )
          }
        }
      })
    },
    editarCarrusel(item){
      this.nuevoCarrusel = false
      this.tituloDialogCarrusel = 'Modificar Carrusel'
      this.carruselNombre = item.nombre
      this.carruselOrden = item.orden
      this.carruselActivo = parseInt(item.estado) === 1 ? false : true
      this.selectedCriterio = item.criterio
      this.selectedKey = item.key
      this.dialogCarrusel = true
    },
    modificarCarrusel(){
      let valido = this.validarObligatorios()
      if (!valido.valido){
        Swal.fire(
          'Modificar Carrusel', 
          valido.msj, 
          'warning'
        )
        return
      }
      Swal.fire({
        title: 'Modificar Carrusel',
        text: '¿Confirma modificar el Carrusel seleccionado?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          // obtengo la posicion del criterio elegido
          let pos = this.respCarruselesAdmin.criterios.filter(
            element => element.nombre.toUpperCase() == this.selectedCriterio.toUpperCase()
          )
          if (pos.length > 0){
            let estado = 1
            if (this.carruselActivo == true){
              estado = 0
            }
            let unCarrusel = {
              criterio: pos[0].key_firebase,
              inhabilitado: estado,
              nombre: this.carruselNombre,
              orden: this.carruselOrden,
              key: this.selectedKey
            }
            await this.updateCarouselAdmin(unCarrusel)
          }else{
            Swal.fire(
              'Modificar Carrusel', 
              'No se pudo encontrar el Criterio en la Lista de Criterios', 
              'error'
            )
          }
        }
      })
    }
  },
  watch: {
    selectedCriterio: function(){
      if (this.selectedCriterio == null || this.selectedCriterio == undefined){
        this.selectedCriterio = ''
      }
    },
    respAddCarruselAdmin: function(){
      if (Object.keys(this.respAddCarruselAdmin).length > 0){
        if (parseInt(this.respAddCarruselAdmin.resultado) === 1){
          Swal.fire(
            'Nuevo Carrusel', 
            'El nuevo Carrusel fue dado de alta exitosamente', 
            'success'
          )
          setTimeout(() => [this.getCarruselesAdmin()], 3000)
        }else{
          Swal.fire(
            'Nuevo Carrusel', 
            this.respAddCarruselAdmin.msj, 
            'error'
          )
        }
        this.dialogCarrusel = false
      }
    },
    respUpdateCarrAdmin: function(){
      if (Object.keys(this.respUpdateCarrAdmin).length > 0){
        if (parseInt(this.respUpdateCarrAdmin.resultado) === 1){
          Swal.fire(
            'Modificar Carrusel', 
            'El Carrusel fue actualizado exitosamente', 
            'success'
          )
          setTimeout(() => [this.getCarruselesAdmin()], 3000)
        }else{
          Swal.fire(
            'Modificar Carrusel', 
            this.respUpdateCarrAdmin.msj, 
            'error'
          )
        }
        this.dialogCarrusel = false
      }
    }
  },
}
</script>

<style>

</style>