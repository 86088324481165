<template>
  <v-container fluid>
    <v-row
      style="padding: 0px; height: 100%; margin: 0px;"
    >
      <v-col
        cols="12"
        style="height: 100%; padding-bottom: 0px;"
      >
        <h1>Pendiente</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import router from '../../router'
export default {
  components: {
    
  },
  created () {
    this.usuarioLogueado()
  },
  methods: {
    usuarioLogueado(){
      if (localStorage.getItem('tokenAdmin')){
        // prosigo con la carga
        // pendiente de definir
      }else{
        // redirecciono al Login
        //router.push({name: 'LoginAdmin', params: {sesionExpirada: 1}})
      }
    }
  },
}
</script>

<style>

</style>