<template>
  <div>
    <v-card
      max-height="auto"
    >                   
      <v-toolbar color="primary" dark height="40%" text>
        <v-toolbar-title style="font-size:20px">Modificar -  {{articuloLocal.art_nom_e}} / {{articuloLocal.art_codigo}} </v-toolbar-title>
      </v-toolbar>                    
      <v-card-text>   
        <v-row>
          <v-container fluid style="margin: 0px;width: 90%" >           
            <v-row>
              <v-col cols="12" md="5" ><!-- Articulo Nombre -->
                <v-text-field
                  v-model="articulo_nombre"
                  label="Nombre"
                  outlined
                  dense          
                ></v-text-field>
              </v-col><!-- ./ Articulo Nombre -->
              <v-col cols="6" md="2" style="margin-top:-15px" ><!-- Articulo Nuevo-->
                <v-switch v-model="articulo_nuevo" inset label="Nuevo" color="success"></v-switch>
              </v-col><!-- ./ Articulo Nuevo -->
              <v-col cols="6" md="2"  style="margin-top:-15px" ><!-- Articulo Estado -->
                <v-switch v-model="articulo_estado" inset label="Activo" color="success"></v-switch>
              </v-col><!-- ./ Articulo Estado -->
              <v-col cols="12" md="2"><!-- Articulo Descuento -->
                <v-text-field
                  v-model="articulo_descuento"
                  label="Descuento"
                  outlined dense            
                  type="number" 
                  min="0"
                ></v-text-field>
              </v-col><!-- ./Articulo Descuento -->
            </v-row>                                
          </v-container>                  
        </v-row>
        <v-row>                           
          <v-container fluid style="margin: 0px;width: 100%">    
            <v-row>
              <v-col cols="12" md="6" ><!-- Stock Articulo -->
                <v-data-table
                  :headers="headers_stock"
                  :items="items_stock"
                  item-key="deposito_codigo"
                  :items-per-page="5"
                  class="elevation-1"
                  dense 
                  hide-default-footer
                >
                  <template v-slot:item.actions="{ item }">
                    <div v-if ="item.stockLocales != null">
                      <v-icon color="success" @click="verStockLocales(item)">mdi-eye-outline</v-icon>
                    </div>                            
                  </template>
                </v-data-table>  
                <p style="margin-top:5px"><strong>Stock Total :</strong>&nbsp;{{articuloLocal.stock.stockTotal}} </p>              
              </v-col><!-- ./ Stock Articulo -->
              <v-col cols="12" md="6" style="height: auto"><!-- Caracteristicas Articulo -->
                <v-data-table
                  :headers="headers_caracteristicas"
                  :items="items_caracteristicas"                                                                   
                  class="elevation-1"
                  dense 
                  hide-default-footer
                ></v-data-table>                
              </v-col><!-- ./Caracteristicas Articulo -->
            </v-row>                 
          </v-container>
        </v-row>
      </v-card-text>      
      <v-card-actions>
        <v-col
          cols="12"
        >
          <div class="text-center">
            <v-btn color="primary" dark @click="updateArticulo()">Modificar</v-btn>
          </div>
        </v-col>
      </v-card-actions>
    </v-card>   
    <!-- Modal Stock Locales -->
    <v-dialog
      v-model="modalStockLocales"       
      max-width="600px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar color="primary" dark height="40%" text>
          <v-toolbar-title style="font-size:20px"> Deposito :  {{tituloModalStockLocales}} </v-toolbar-title>
        </v-toolbar>                    
        <v-card-text>                           
          <v-container fluid style="margin: 0px;width: 100%" >             
            <!-- Stock Locales -->                               
            <v-data-table
              :headers="headers_stock_locales"
              :items="items_stock_locales"                                                              
              class="elevation-1"
              dense 
              hide-default-footer
            ></v-data-table>                                                            
          </v-container><!-- ./ Stock Locales -->
          <v-layout align-center justify-center row>
            <v-btn x-small color="warning" @click="modalStockLocales=false">cerrar</v-btn> 
          </v-layout>
        </v-card-text>    
      </v-card>
    </v-dialog>
    <!-- Modal Loading  -->
    <v-dialog v-model="dialogLoadingPry" persistent width="430">
      <v-card color="primary" dark>
        <v-card-title>
          {{ tituloModalPry }}
        </v-card-title>
        <v-card-text>
          <v-progress-circular style="margin-left:160px;" :size="50" :width="8" color="#ffff" indeterminate></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {mapActions, mapState} from 'vuex'
export default {
  props: {
    articulo: Object,
    key_firebase: String
  },
  data() {
    return {
      articulo_nombre:"",
      articulo_nuevo : false,
      articulo_estado : false,
      articulo_descuento : 0,
      headers_stock:[
        { text: 'Sucursal', align: 'center',sortable: false,value:'sucursal_nombre'},
        { text: 'Deposito', align: 'center',sortable: false,value:'deposito_nombre'},
        { text: 'stock', align: 'center',sortable: false,value:'cantidad'},
        { text: 'Stock Locales', align: 'center',sortable: false,value:'actions'}
      ],
      items_stock :[],
      headers_caracteristicas:[
        { text: 'Caracteristica', align: 'center',sortable: false,value:'campo_det'},
        { text: 'valor', align: 'center',sortable: false,value:'valor_det'}
      ],
      items_caracteristicas:[],
      modalStockLocales:false,
      tituloModalStockLocales : "",
      headers_stock_locales:[
        { text: 'Sucursal', align: 'center',sortable: false,value:'sucursal_nombre'},
        { text: 'Deposito', align: 'center',sortable: false,value:'deposito_nombre'},
        { text: 'Cantidad', align: 'center',sortable: true,value:'cantidad'}
      ],
      items_stock_locales:[],
      dialogLoadingPry: false,
      tituloModalPry: '',
      articuloLocal: {
        stock: {
          stockTotal: "0"
        }
      }
    }
  },
  created(){
    //this.setValues()
    if (Object.keys(this.articulo).length > 0){
      this.articuloLocal = {}
      this.articuloLocal = this.articulo
      this.setValues()
    }
  },
  computed: {
    ...mapState(['respUpdateArt', 'respDatosApiJarvis']),
  },
  methods: {
    ...mapActions(['updateArtAdmin']),
    setValues(){    
      this.articulo_nombre = this.articuloLocal.art_nom_e
      this.articulo_descuento = this.articuloLocal.descuento
      if(parseInt(this.articuloLocal.nuevo_producto) == 1){
        this.articulo_nuevo = true
      }
      if(parseInt(this.articuloLocal.art_estado) == 0){
        this.articulo_estado = true
      }
      this.items_stock = this.articuloLocal.stock.stockSucursales
      this.items_caracteristicas = this.articuloLocal.caracteristicas
    },
    async updateArticulo(){             
      let estado = 0
      let nuevo = 1
      let estado_nombre = 'ACTIVO'
      let descuento = ''
      if(this.articulo_nuevo == false){
        nuevo = 0
      }
      if(this.articulo_estado == false){
        estado = 1 
        estado_nombre = 'INACTIVO'
      }
      if(this.articulo_descuento == "" || this.articulo_descuento == null){
        descuento = 0
      }
      Swal.fire({
        title: 'Actualizar Articulo',
        text: '¿Esta seguro de actualizar los datos del Artículo seleccionado?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.tituloModalPry = 'Actualizando artículo, por favor espere...'
          this.dialogLoadingPry = true
          // armo mi body
          let body = {
            "art_nom_e" : this.articulo_nombre,
            "art_estado" : estado,
            "art_estado_nom" : estado_nombre,
            "descuento" : descuento,
            "nuevo_producto" : nuevo
          }
          await this.updateArtAdmin({body: body, keyFirebase: this.key_firebase, articulo_codigo: this.articulo.art_codigo})
        }
      })
                /*var ref =  firebase.database().ref('/articulos/'+this.articulo.key)
                    ref.update({
                            art_nom_e : this.articulo_nombre,
                            art_estado : estado,
                            art_estado_nom : estado_nombre,
                            descuento : descuento,
                            nuevo_producto : nuevo,
                    }, function(error) {
                        if (error) {                     
                            verStockLocales('No fue posible realizar la modificacion...'+ error)
                            } else {                                                       
                                Swal.fire({
                                    title: "Articulo Modificado",
                                    text: "La modificacion se realizo con exito.",
                                    icon: "success",
                                    timer:2500,
                                    confirmButtonText: "Aceptar"
                                });                       
                            }
                    })               
                this.$emit('close');*/
    },
    verStockLocales(stock){
      this.tituloModalStockLocales = stock.deposito_nombre
      this.modalStockLocales = true
      this.items_stock_locales=stock.stockLocales
    }
  },
  watch: {
    articulo_descuento(){
      if(parseFloat(this.articulo_descuento) < 0 || this.articulo_descuento == ''){
        this.articulo_descuento = 0
      }  
    },
    respUpdateArt: function(){
      if (Object.keys(this.respUpdateArt).length > 0){
        this.dialogLoadingPry = false
        if (parseInt(this.respUpdateArt.resultado) === 1){
          Swal.fire(
            'Actualizar Artículo', 
            'El Artículo fue Actualizazo Exitosamente', 
            'success'
          )
        }else{
          Swal.fire(
            'Actualizar Artículo', 
            this.respUpdateArt.msj, 
            'error'
          )
        }
        setTimeout(() => [this.$emit('close', this.respUpdateArt.actualizacion)], 4000)
      }
    },
    articulo: function(){
      if (Object.keys(this.articulo).length > 0){
        this.articuloLocal = {}
        this.articuloLocal = this.articulo
        this.setValues()
      }
    },
    respDatosApiJarvis: function(){
      if (Object.keys(this.respDatosApiJarvis).length > 0){
        location.reload()
      }
    }
  },
}
</script>

<style>

</style>