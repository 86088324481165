<template>
  <v-container fluid style="height: 100%; padding: 0px;">
    <v-row
      style="padding: 0px; height: 100%; margin: 0px;"
    >
      <!-- Listado de Banners -->
      <v-col
        cols="12"
        style="height: 100%; padding-bottom: 0px;"
      >
        <v-card>
          <v-toolbar dense flat >
            <v-toolbar-title><v-icon>mdi-image-multiple</v-icon> Banners</v-toolbar-title>
            <v-spacer></v-spacer>       
            <v-btn rounded color="teal accent-4" dark @click="showModalNewBanner()"><v-icon small>mdi-plus-circle-outline</v-icon>&nbsp;Agregar</v-btn>
          </v-toolbar>             
          <v-card-title>
            <v-text-field
              v-model="search"                 
              append-icon="mdi-magnify"
              label="Buscar"
              single-line dense
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="bannerArray"
            :search="search"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="(item,index) in items" :key="index">
                  <td> {{item.nombre}} </td>
                  <td>  
                    <v-icon color="indigo" @click="verImagen(item)">mdi-image</v-icon>
                  </td>
                  <td> 
                    <v-icon color="indigo" @click="verLink(item)">mdi-open-in-new</v-icon>
                  </td>
                  <td>
                    <v-chip small :color="getColor(item.inhabilitado)" dark>{{getEstadoName(item.inhabilitado)}}</v-chip>             
                  </td>                                           
                  <td>
                    <v-icon color="primary" @click="showModalEditBanner(item)">mdi-square-edit-outline</v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
        <!-- Modal Ver Img Banner -->
        <template>
          <div class="text-xs-top">
            <v-dialog  v-model="dialogImgBanner" max-width="850px">
              <v-card>
                <v-card-title>  
                  <v-toolbar dense flat >
                    <v-toolbar-title>{{img_banner.nombre}}</v-toolbar-title>
                  </v-toolbar>
                </v-card-title>      
                <v-card-text>      
                  <v-container fluid style="margin: 0px; padding: 0px; width: 100%">
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-img :src="img_banner.url" ></v-img>
                      </v-col>
                    </v-row>           
                    <v-col class="text-center" cols="12" sm="12">                         
                      <v-btn small color="warning" @click="dialogImgBanner = false">Cerrar</v-btn>                        
                    </v-col>              
                  </v-container>                      
                </v-card-text>      
              </v-card>
            </v-dialog>
          </div>
        </template>
        <!-- Modal New/Edit Banner  -->
        <template>
          <div class="text-xs-top">
            <v-dialog  v-model="dialogBanner" max-width="700px">
              <v-card>                 
                <v-toolbar dense flat >
                  <v-toolbar-title v-if="band_new_banner == false"><v-icon>mdi-circle-edit-outline</v-icon> Editar Banner</v-toolbar-title>
                  <v-toolbar-title v-if="band_new_banner == true"><v-icon>mdi-plus-circle-outline</v-icon> Nuevo Banner</v-toolbar-title>
                </v-toolbar>                   
                <v-card-text>      
                  <v-container fluid style="margin: 0px; padding: 0px; width: 100%">
                    <v-row>
                      <v-col cols="12" sm="12" md="12" v-if="band_new_banner == false">
                        <v-img :src="bannerAux.url_img" ></v-img>
                      </v-col>
                      <v-col cols="12" sm="8" md="8">
                        <v-text-field
                          v-model="bannerAux.nombre"
                          label="Nombre"
                          placeholder="Nombre"
                          outlined dense
                        ></v-text-field>
                      </v-col>           
                      <v-col class="d-flex" cols="12" sm="4" md="4">                              
                        <v-switch style="margin-top:4px" v-model="bannerAux.banner_estado" class="mx-2" label="Activo"></v-switch>
                      </v-col>                     
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="bannerAux.url_redirect"
                          label="Url Redirect"
                          placeholder="Url Redirect"
                          outlined dense
                        ></v-text-field>
                      </v-col>                                                     
                      <v-col class="d-flex" cols="12" sm="12"> 
                        <v-file-input
                          v-model="files"
                          color="deep-purple accent-4" 
                          label="Imagen"
                          accept="image/*"
                          multiple dense  outlined   counter
                          placeholder="Click para seleccionar una Imagen"                                         
                          :show-size="1000"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip  v-if="index < 2" color="deep-purple accent-4" dark  label small> {{ text }}</v-chip>
                            <span
                              v-else-if="index === 2" 
                              class="overline grey--text text--darken-3 mx-2"
                            >
                              +{{ files.length - 2 }} File(s)
                            </span>
                          </template>
                        </v-file-input>
                      </v-col>
                    </v-row>
                    <v-col class="text-center" cols="12" sm="12">                         
                      <v-btn small color="primary" @click="modificarBanner()" v-if="band_new_banner == false">Modificar</v-btn>           
                      <v-btn small color="success" @click="grabarNewBanner()" v-if="band_new_banner == true">Grabar</v-btn>                 
                    </v-col>
                  </v-container>                      
                </v-card-text>      
              </v-card>
            </v-dialog>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import router from '../../router'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {mapActions, mapState} from 'vuex'
export default {
  data() {
    return {
      search: '',
      headers: [
        { text: 'Nombre', align: 'center',value:'nombre' },
        { text: 'Imagen',align: 'center',value:'url_imagen'},
        { text: 'Url Redirect',align: 'center',value:'url_redirect'},
        { text: 'Estado',align: 'center',value:'inhabilitado'},
        { text: 'Acciones',align: 'center'},
      ],
      dialogImgBanner : false,
      img_banner :{},
      dialogBanner : false,
      index : "",
      bannerAux : {},
      band_new_banner : false,
      files : [],
      banners :[]
    }
  },
  components: {
    
  },
  computed: {
    ...mapState(['respBanner', 'respNewBanner', 'respUpdateBanner']),
    bannerArray:function(){
      let data = []
      Object.entries(this.banners).forEach(([key, value]) => {
        let item = {
          index: value.key_firebase,
          nombre: value.nombre,
          inhabilitado: value.inhabilitado,
          url_imagen: value.url_imagen,
          url_redirect: value.url_redirect
        }
        data.push(item)
      })
      return data
    }
  },
  created () {
    this.usuarioLogueado()
  },
  methods: {
    ...mapActions(['getBannersEcommerce', 'updateBannerAdmin', 'newBanner']),
    usuarioLogueado(){
      if (localStorage.getItem('tokenAdmin')){
        // prosigo con la carga
        this.getBanners()
      }else{
        // redirecciono al Login
        router.push({name: 'LoginAdmin', params: {sesionExpirada: 1}})
      }
    },
    async getBanners(){
      await this.getBannersEcommerce()
    },
    verImagen(item){
      this.img_banner = {
        nombre: item.nombre,
        url: item.url_imagen
      }       
      this.dialogImgBanner = true
    },
    getColor(estado) {
      if (parseInt(estado) == 0) return 'green'
      else if (parseInt(estado) == 1) return 'orange'
    },
    getEstadoName(estado){
      if (parseInt(estado) ==0) return 'Activo'
      else if (parseInt(estado) == 1) return 'Inactivo'
    },
    verLink(item){
      window.open(item.url_redirect)
    },
    showModalNewBanner(){
      this.band_new_banner = true
      this.files = []
      this.bannerAux = {
        key : "",
        nombre : "",
        url_redirect : "",
        url_img : "",
        banner_estado : false
      }  
      this.dialogBanner = true
    },
    showModalEditBanner(item){
      this.band_new_banner = false
      let estado = ""
      if(parseInt(item.inhabilitado) == 0){
        estado = true
      }else{
        estado = false
      }     
      this.bannerAux = {
        key: item.index,
        nombre: item.nombre,
        url_redirect: item.url_redirect,
        url_img: item.url_imagen,
        banner_estado: estado
      }  
      this.dialogBanner = true
    },
    grabarNewBanner(){
      if (this.files.length === 0){
        Swal.fire(
          'Nuevo Banner', 
          'Por favor seleccione un Banner para proseguir', 
          'warning'
        )
        return
      }
      Swal.fire({
        title: 'Nuevo Banner',
        text: '¿Confirma subir el Nuevo Banner?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let estado = ""
          let data = {}
          if(this.bannerAux.banner_estado == true ){
            estado = 0
          }else{
            estado = 1
          }
          data = {                
            nombre: this.bannerAux.nombre,
            url_redirect: this.bannerAux.url_redirect,
            inhabilitado: estado,
            file: this.files,
            url_imagen: 0
          }
          this.nuevoBanner(data)
        }
      })
    },
    async nuevoBanner(data){
      await this.newBanner(data)
    },
    modificarBanner(){
      if (this.bannerAux.url_redirect.length === 0 && this.files.length === 0){
        Swal.fire(
          'Modificar Banner', 
          'Por favor ingrese algún cambio', 
          'warning'
        )
        return
      }
      Swal.fire({
        title: 'Modificar Banner',
        text: '¿Confirma modificar el Banner seleccionado?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let estado = ""
          if(this.bannerAux.banner_estado == true ){
            estado = 0
          }else{
            estado = 1
          }
          let data = {
            key: this.bannerAux.key,
            nombre: this.bannerAux.nombre,
            url_redirect: this.bannerAux.url_redirect,
            url_img: this.bannerAux.url_imagen,
            inhabilitado: estado,
            file: this.files
          }
          this.updateBanner(data)
        }
      })
    },
    async updateBanner(data){
      await this.updateBannerAdmin(data)
      
      //this.dialogBanner = false //cerramos modal editar banner
      //this.getBanners() //actualizamos los datos del dataTable
    },
  },
  watch: {
    respBanner: function(){
      if (Object.keys(this.respBanner).length > 0){
        if (parseInt(this.respBanner.resultado) === 1){
          this.banners = this.respBanner.banners.bannersCarrusel
        }
      }
    },
    respNewBanner: function(){
      if (Object.keys(this.respNewBanner).length > 0){
        if (parseInt(this.respNewBanner.resultado) === 1){
          Swal.fire(
            'Nuevo Banner', 
            'El Banner fue creado exitosamente', 
            'success'
          )
          this.getBanners()
        }else{
          Swal.fire(
            'Nuevo Banner', 
            this.respNewBanner.msj, 
            'error'
          )
        }
        this.files = []
        this.dialogBanner = false
      }
    },
    respUpdateBanner: function(){
      if (Object.keys(this.respUpdateBanner).length > 0){
        if (parseInt(this.respUpdateBanner.resultado) === 1){
          Swal.fire(
            'Modificar Banner', 
            'El Banner fue modificado exitosamente', 
            'success'
          )
          this.getBanners()
        }else{
          Swal.fire(
            'Modificar Banner', 
            this.respUpdateBanner.msj, 
            'error'
          )
        }
        this.dialogBanner = false
      }
    }
  },
}
</script>

<style>

</style>