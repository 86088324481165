<template>
  <v-container fluid style="height: 100%; padding: 0px;">
    <v-row
      style="padding: 0px; height: 100%; margin: 0px;"
    >
      <!-- Listado de Categorias Ecommerce -->
      <v-col
        cols="12"
        style="height: 100%; padding-bottom: 0px;"
      >
        <v-card>
          <v-toolbar dense flat >
            <v-toolbar-title>
              <v-icon>mdi-order-bool-ascending-variant</v-icon> Categorias
            </v-toolbar-title>
            <v-spacer></v-spacer>       
            <v-btn rounded color="teal accent-4" dark @click="agregarCategoria()"><v-icon small>mdi-plus-circle-outline</v-icon>&nbsp;Agregar</v-btn>
          </v-toolbar>             
          <v-card-title>
            <v-text-field
              v-model="search"                 
              append-icon="mdi-magnify"
              label="Buscar"
              single-line dense
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="categorias"
            :search="search"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="(item,index) in items" :key="index">
                  <td class="text-center"> {{item.nombre}} </td>
                  <td class="text-center">
                    <v-chip small :color="getColorTipo(item.padre)" dark>{{getTipoName(item.padre)}}</v-chip>             
                  </td>
                  <td class="text-center">
                    <v-chip small :color="getColor(item.inhabilitado)" dark>{{getEstadoName(item.inhabilitado)}}</v-chip>             
                  </td>
                  <td class="text-center">
                    <v-icon color="primary" title="Editar" @click="editarCategoria(item)">mdi-square-edit-outline</v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- Modal Modificar Categorias Ecommerce -->
    <v-dialog
      v-model="dialogModCategoria"
      max-width="900px"
    >
      <v-card
        flat
      >
        <!-- Titulo -->
        <v-card-title>
          <v-toolbar flat>
            <v-toolbar-title v-if="esNuevaCategoria == false">
              <v-icon>mdi-square-edit-outline</v-icon> Modificar Categoria {{ tituloDialogModCategoria }}
            </v-toolbar-title>
            <v-toolbar-title v-else>
              <v-icon>mdi-square-edit-outline</v-icon> Agregar Nueva Categoria
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <!-- Contenido -->
        <v-card-text>
          <v-row>
            <!-- Nombre -->
            <v-col cols="10">
              <v-text-field
                v-model="categoriaNombre"
                label="Nombre Categoria"
                placeholder="Nombre Categoria"
                outlined dense
                @keyup.enter="confirmarCategoriaEcommerce()"
              ></v-text-field>
            </v-col>
            <!-- Estado -->
            <v-col cols="2">
              <v-switch style="margin-top:4px" v-model="categoriaActivo" class="mx-2" label="Activo"></v-switch>
            </v-col>
            <!-- Filtros de la Categoria -->
            <v-col cols="6">
              <v-card>
                <v-card-title>
                  Filtros
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    color="indigo"
                    title="Agregar un Nuevo Filtro"
                    small
                    @click="agregarNuevoFiltro()"
                  >
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-data-table
                    :headers="headersFiltros"
                    :items="filtros"
                    :items-per-page="5"
                  >
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                          <td class="text-center"> {{item.nombre}} </td>
                          <td class="text-center">
                            <v-icon color="secondary" title="Quitar" @click="quitarFiltro(item)">mdi-close-circle-outline</v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- SubCategorias de la Categoria -->
            <v-col cols="6">
              <v-card>
                <v-card-title>
                  Sub Categorias
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    color="indigo"
                    title="Agregar una Nueva Sub Categoria"
                    small
                    :disabled="esPadre == false"
                    @click="agregarNuevaSubCategoria()"
                  >
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-data-table
                    :headers="headersSubC"
                    :items="subCategoriasExtra"
                    :items-per-page="5"
                  >
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                          <td class="text-center" v-if="item.inhabilitado == 0"> {{item.nombre}} </td>
                          <td class="text-center" v-if="item.inhabilitado == 0">
                            <v-icon color="secondary" title="Quitar" @click="quitarSubCategoria(item)">mdi-close-circle-outline</v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- Botones -->
        <v-card-actions style="justify-content: end;">
          <v-btn
            color="green"
            class="white--text"
            @click="confirmarCategoriaEcommerce()"
          >
            Guardar
          </v-btn>
          <v-btn
            color="indigo"
            class="white--text"
            @click="dialogModCategoria = false"
          >
            Volver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal Agregar un Nuevo Filtro -->
    <v-dialog
      v-model="dialogNuevoFiltro"
      max-width="1000px"
    >
      <v-card
        flat
        max-width="1000px"
      >
        <v-card-title>
          <v-icon>mdi-plus-circle-outline</v-icon> Agregar un Nuevo Filtro
        </v-card-title>
        <v-card-text>
          <v-row>
            <!-- muestro listado de Filtros existentes -->
            <v-col cols="7">
              <v-autocomplete
                v-model="selectedNuevoFiltro"
                :items="listadoFiltros"
                outlined dense
                item-text="nombre"
                label="Seleccione un Filtro"
                placeholder="Seleccione un Filtro"
                return-object
                :disabled="ingresaNuevoFiltro"
              ></v-autocomplete>
            </v-col>
            <!-- el Filtro no se encuentra en el listado, quieren crear uno nuevo -->
            <v-col cols="5">
              <v-btn
                text
                color="primary"
                max-width="auto"
                @click="ingresaNuevoFiltro = true"
                :disabled="ingresaNuevoFiltro"
              >
                El Filtro no se encuentra en el listado
              </v-btn>
            </v-col>
            <!-- Si presionan "El Filtro no se encuentra en el listado" se habilita el ingreso de nombre y boton -->
            <v-col cols="8" v-if="ingresaNuevoFiltro">
              <v-text-field
                v-model="nuevoNombreFiltro"
                label="Nombre Filtro"
                placeholder="Nombre Filtro"
                outlined dense
                @keyup.enter="confirmaNuevoFiltro()"
              ></v-text-field>
            </v-col>
            <!-- Si presionan "El Filtro no se encuentra en el listado" se habilita el ingreso de nombre y boton -->
            <v-col cols="4" v-if="ingresaNuevoFiltro">
              <v-btn
                color="green"
                class="white--text mr-2"
                @click="confirmaNuevoFiltro()"
              >
                Confirmar
              </v-btn>
              <v-btn
                color="indigo"
                class="white--text mr-2"
                @click="ingresaNuevoFiltro = false"
              >
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- Botones -->
        <v-card-actions style="justify-content: center;">
          <v-btn
            color="green"
            class="white--text"
            :disabled="ingresaNuevoFiltro == true"
            @click="confirmarFiltro()"
          >
            Seleccionar
          </v-btn>
          <v-btn
            color="indigo"
            class="white--text"
            @click="dialogNuevoFiltro = false"
            :disabled="ingresaNuevoFiltro == true"
          >
            Volver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal Agregar una Nueva SubCategoria -->
    <v-dialog
      v-model="dialogNuevaSubCategoria"
      max-width="800px"
    >
      <v-card
        flat
        max-width="800px"
      >
        <v-card-title>
          <v-icon>mdi-plus-circle-outline</v-icon> Agregar una Nueva SubCategoria
        </v-card-title>
        <v-card-text>
          <v-row>
            <!-- Nombre de la nueva Categoria -->
            <v-col cols="6">
              <v-text-field
                v-model="nuevaCategoria"
                label="Nombre Categoria"
                placeholder="Nombre Categoria"
                outlined dense
                @keyup.enter="confirmaNuevaSubCategoria()"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-btn
                color="green"
                class="white--text mr-2"
                @click="confirmaNuevaSubCategoria()"
              >
                Confirmar
              </v-btn>
              <v-btn
                color="indigo"
                class="white--text mr-2"
                @click="dialogNuevaSubCategoria = false"
              >
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import router from '../../router'
import {mapActions, mapState} from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
export default {
  data() {
    return {
      search: '',
      categoriaNombre: '',
      categoriaActivo: false,
      headers: [
        { text: 'Nombre', align: 'center', value:'nombre' },
        { text: 'Tipo', align: 'center', value:'tipo' },
        { text: 'Estado', align: 'center', value:'inhabilitado'},
        { text: 'Acciones', align: 'center'}
      ],
      categorias: [],
      tituloDialogModCategoria: '',
      dialogModCategoria: false,
      headersFiltros: [
        { text: 'Nombre', align: 'center', value:'nombre' },
        { text: 'Acciones', align: 'center'}
      ],
      filtros: [],
      headersSubC: [
        { text: 'Nombre', align: 'center', value:'nombre' },
        { text: 'Acciones', align: 'center'}
      ],
      subCategorias: [],
      subCategoriasExtra: [],
      listadoFiltros: [], // contiene todos los filtros existentes de TODAS las categorias
      dialogNuevoFiltro: false,
      selectedNuevoFiltro: {},
      ingresaNuevoFiltro: false, // indica que el fitro a agregar es no existente
      nuevoNombreFiltro: '',
      dialogNuevaSubCategoria: false,
      nuevaCategoria: '',
      selectedCategoria: {},
      intentosLoginJarvis: 0,
      tamNuevos: 0,
      cantNuevosControlados: 0,
      errorControlCategoria: false,
      categoriaModificada: {},
      categoriasApiJarvis: [],
      subCategoriasNuevas: [],
      esPadre: false,
      esNuevaCategoria: false
    }
  },
  components: {
    
  },
  created () {
    this.usuarioLogueado()
  },
  computed: {
    ...mapState(['respCategoriasAdmin', 'respDatosApiJarvis', 'respSubCategoriasAdmin', 'respUpCatAdmin', 'respNewCatEcommerceOracle', 'respNewCatPadreEcomOracle', 'respUpCatOracleAdmin'])
  },
  methods: {
    ...mapActions(['getArticulosCategorias', 'getCategoriaOracle', 'updateCategoriaEcommerceAdmin', 'setSubCategoryOracle', 'setCategoryOracle', 'updateCategoriaOracle']),
    async usuarioLogueado(){
      if (localStorage.getItem('tokenAdmin')){
        // prosigo con la carga
        await this.getArticulosCategorias()
      }else{
        // redirecciono al Login
        router.push({name: 'LoginAdmin', params: {sesionExpirada: 1}})
      }
    },
    getColor(estado) {
      if (parseInt(estado) == 0) return 'green'
      else if (parseInt(estado) == 1) return 'orange'
    },
    getEstadoName(estado){
      if (parseInt(estado) ==0 ) return 'Activo'
      else if (parseInt(estado) == 1) return 'Inactivo'
    },
    getColorTipo(tipo){
      if (parseInt(tipo) == 0) return 'green'
      else return 'indigo'
    },
    getTipoName(tipo){
      if (parseInt(tipo) == 0) return 'CATEGORIA'
      else return 'SUB-CATEGORIA'
    },
    agregarCategoria(){
      // indico que es nueva categoria padre
      this.esNuevaCategoria = true
      this.esPadre = true
      // limpio el nombre de la categoria
      this.categoriaNombre = ''
      // indico que va a estar habilitado
      this.categoriaActivo = true
      // vacio los listados de filtros y de subcategorias
      this.filtros = []
      this.subCategorias = []
      this.subCategoriasExtra = []
      // abro el modal
      this.dialogModCategoria = true
    },
    editarCategoria(item){
      // indico que estoy editando
      this.esNuevaCategoria = false
      // controlo si es padre
      if (item.padre == 0){
        this.esPadre = true
      }else{
        this.esPadre = false
      }
      this.selectedCategoria = {}
      this.selectedCategoria = item
      // asigno el nombre al modal
      this.tituloDialogModCategoria = item.nombre
      // asigno el nombre en otra variable por si quiere modificarlo
      this.categoriaNombre = item.nombre
      // que estado tiene?
      if (item.inhabilitado == 0){
        this.categoriaActivo = true
      }else{
        this.categoriaActivo = false
      }
      // limpio los filtros a editar
      this.filtros = []
      // los lleno si es que tiene filtros la categoria seleccionada
      if (item.hasOwnProperty('filtros')){
        if (Array.isArray(item.filtros)){
          for (let id in item.filtros){
            let unItem = {
              nombre: item.filtros[id],
              idFirebase: id
            }
            this.filtros.push(unItem)
          }
        }
      }
      // limpio las sub categorias
      this.subCategorias = []
      this.subCategoriasExtra = []
      // las lleno si es que tiene sub categorias la categoria seleccionada
      if (item.hasOwnProperty('subcategorias')){
        if (Array.isArray(item.subcategorias)){
          for (let id in item.subcategorias){
            if (item.subcategorias[id] != null && item.subcategorias != undefined){
              let unItem = {
                inhabilitado: item.subcategorias[id].inhabilitado,
                nombre: item.subcategorias[id].nombre,
                padre: item.subcategorias[id].padre,
                keyFirebase: id
              }
              this.subCategorias.push(unItem)
              if (unItem.inhabilitado == 0){
                this.subCategoriasExtra.push(unItem)
              }
            }
          }
        }
      }
      this.dialogModCategoria = true
    },
    agregarNuevoFiltro(){
      // limpio el filtro seleccionado
      this.selectedNuevoFiltro = {}
      // reinicio el ingreso manual de filtro
      this.ingresaNuevoFiltro = false
      // abro modal
      this.dialogNuevoFiltro = true
    },
    quitarFiltro(item){
      let pos = this.filtros.indexOf(item)
      this.filtros.splice(pos, 1)
    },
    confirmaNuevoFiltro(){
      // limpio mi filtro seleccionado
      this.selectedNuevoFiltro = {}
      // controlo la entrada
      if (this.nuevoNombreFiltro.length === 0){
        Swal.fire(
          'Agregando nuevo Filtro', 
          'El nombre del Filtro no puede ser Vacio. Por favor, complete el nombre.', 
          'error'
        )
        return
      }
      // ya existe en mi listado de filtros?
      let unFiltro = {
        nombre: this.nuevoNombreFiltro,
        existente: 0
      }
      let cont = 0
      for (let id in this.listadoFiltros){
        if (this.listadoFiltros[id].nombre.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == unFiltro.nombre.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")){
          cont = cont + 1
        }
      }
      if (parseInt(cont) > 0){
        Swal.fire(
          'Agregando nuevo Filtro', 
          'El Filtro que Ud. está ingresando ya existe en el listado de Filtros.', 
          'warning'
        )
        return
      }
      // si pasa los controles, lo asigno al filtro seleccionado y al listado
      this.listadoFiltros.push(unFiltro)
      this.selectedNuevoFiltro = unFiltro
      // cierro el modal
      this.ingresaNuevoFiltro = false
    },
    confirmarFiltro(){
      // controlo el filtro a agregar
      if (Object.keys(this.selectedNuevoFiltro).length == 0){
        Swal.fire(
          'Agregando Filtro', 
          'Debe seleccionar un Filtro del Listado para continuar.', 
          'error'
        )
        return
      }
      // contolo si ya existe
      let unFiltro = {
        idFirebase: null,
        nombre: this.selectedNuevoFiltro.nombre
      }
      let cont = 0
      for (let id in this.filtros){
        if (this.filtros[id].nombre.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == unFiltro.nombre.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")){
          cont = cont + 1
        }
      }
      if (parseInt(cont) > 0){
        Swal.fire(
          'Agregando Filtro', 
          'El Filtro seleccionado ya existe entre los filtros de la Categoria.', 
          'warning'
        )
        return
      }
      // si llega hasta aqui, agregar a los filtros
      this.filtros.push(unFiltro)
      let pos = this.filtros.indexOf(unFiltro)
      this.filtros[pos].idFirebase = pos
      // cerrar el modal agregar nuevo filtro
      this.dialogNuevoFiltro = false
    },
    agregarNuevaSubCategoria(){
      // limpio el nombre de la categoria
      this.nuevaCategoria = ''
      // abro el modal
      this.dialogNuevaSubCategoria = true
    },
    quitarSubCategoria(item){
      // quito de manera visual
      let pos = this.subCategoriasExtra.indexOf(item)
      this.subCategoriasExtra.splice(pos, 1)
      // inhabilito por detras si es que ya cuenta con Key de Firebase
      let pos1 = this.subCategorias.indexOf(item)
      if (parseInt(this.subCategorias[pos1].keyFirebase) > 0){
        this.subCategorias[pos1].inhabilitado = 1
      }else{
        this.subCategorias.splice(pos1, 1)
      }
    },
    confirmaNuevaSubCategoria(){
      // controlo de la entrada del usuario
      if (this.nuevaCategoria.length == 0){
        Swal.fire(
          'Agregando Nueva Sub Categoria', 
          'Debe ingresar un nombre para poder continuar.', 
          'error'
        )
        return
      }
      // ya existe en el listado real?
      let cont = 0
      let pos = 0
      for (let id in this.subCategorias){
        if (this.subCategorias[id].nombre.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == this.nuevaCategoria.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")){
          cont = cont + 1
          pos = id
        }
      }
      if (parseInt(cont) > 0){
        // la esta agregando, entonces le cambio el estado
        this.subCategorias[pos].inhabilitado = 0
      }
      // existe en el listado visual?
      let cont1 = 0
      for (let id in this.subCategoriasExtra){
        if (this.subCategoriasExtra[id].nombre.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == this.nuevaCategoria.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")){
          cont1 = cont + 1
        }
      }
      if (parseInt(cont1) > 0){
        // la esta agregando, entonces le cambio el estado
        Swal.fire(
          'Agregando Nueva Sub Categoria', 
          'La Categoria ingresada ya existe en el listado.', 
          'error'
        )
        return
      }
      // si llega hasta aqui, controlar si el usuario esta agregando o esta editando la categoria
      let unaCategoria = {}
      if (this.esNuevaCategoria == false){
        // esta editando
        unaCategoria = {
          inhabilitado: 1,
          keyFirebase: -1,
          nombre: this.nuevaCategoria,
          padre: this.subCategorias[0].padre
        }
      }else{
        unaCategoria = {
          inhabilitado: 1,
          keyFirebase: -1,
          nombre: this.nuevaCategoria,
          padre: -1
        }
      }
      // controlo los contadores
      if (parseInt(cont) == 0){
        this.subCategorias.push(unaCategoria)
      }
      if (parseInt(cont1) == 0){
        this.subCategoriasExtra.push(unaCategoria)
      }
      // cerrar el modal
      this.dialogNuevaSubCategoria = false
    },
    obtenerMayor(listado){
      // ordeno de menor a mayor
      listado.sort(function(a,b){
        if (parseInt(a.keyFirebase) > parseInt(b.keyFirebase)) {
          return -1;
        }
        if (parseInt(a.keyFirebase) < parseInt(b.keyFirebase)) {
          return 1;
        }
        // a must be equal to b
        return 0;
      })
      return parseInt(listado[0].keyFirebase)
    },
    obtenerMayor2(listado){
      // ordeno de menor a mayor
      listado.sort(function(a,b){
        if (parseInt(a.id) > parseInt(b.id)) {
          return -1;
        }
        if (parseInt(a.id) < parseInt(b.id)) {
          return 1;
        }
        // a must be equal to b
        return 0;
      })
      return parseInt(listado[0].id)
    },
    async loadListadoSubCategorias(listado, listadoExistentes){
      // tenemos existentes?
      if (listadoExistentes.length > 0){
        // recorro el listado y armo la estructura que necesito
        for (let id in listadoExistentes){
          let unaCat = {
            descripcion: listadoExistentes[id].descripcion,
            descripcion_larga: this.selectedCategoria.nombre + '/' + listadoExistentes[id].descripcion,
            id: listadoExistentes[id].id,
            inhabilitado: null,
            padre: listadoExistentes[id].padre
          }
          listado.push(unaCat)
        }
      }
      let mayorKey = this.obtenerMayor(this.subCategorias)
      let mayorId = this.obtenerMayor2(listado)
      // me quedo con el mayor de los dos
      let mayor = 0
      if (mayorKey > mayorId){
        mayor = mayorKey
      }else{
        mayor = mayorId
      }
      let listadoAux = this.categoriaModificada.subcategorias
      let listadoAuxx = []
      this.categoriaModificada.subcategorias = []
      // para evitar perder elementos en el medio del listado de categorias, recorro obligadamente TODO el listado de categorias definitivas
      for (let id = 0; parseInt(id) <= parseInt(mayor); id++){
        let encontrado = false
        // recorro listadoAux preguntando si la posicion en donde me encuentro corresponde al id que se encuentra en listado
        for (let jd in listado){
          if (parseInt(id) == parseInt(listado[jd].id)){
            encontrado = true
            // encontre que en esa posicion va lo de listado[id]
            let noVigente = 0
            if (listado[jd].inhabilitado == null){
              noVigente = 1
            }
            let unItem = {
              inhabilitado: noVigente,
              nombre: listado[jd].descripcion,
              padre: listado[jd].padre
            }
            listadoAuxx.push(unItem)
          }
        }
        // controlo si lo encontre
        if (encontrado == false){
          if (typeof listadoAux[id] == 'object'){
            listadoAuxx.push(listadoAux[id])
          }else{
            listadoAuxx.push(null)
          }
        }
      }
      this.categoriaModificada.subcategorias = listadoAuxx
      if (this.esNuevaCategoria == false){
        await this.updateCategoriaEcommerceAdmin({categoria: this.categoriaModificada, keyFirebase: this.selectedCategoria.key_firebase, nuevos: listado, subCat: this.subCategorias})
      }else{
        await this.updateCategoriaEcommerceAdmin({categoria: this.categoriaModificada, keyFirebase: this.respNewCatPadreEcomOracle.newCatPadreOracle, nuevos: listado, subCat: this.subCategorias})
      }
    },
    confirmarCategoriaEcommerce(){
      // controlo el nombre
      if (this.categoriaNombre.length == 0){
        if (this.esNuevaCategoria == false){
          Swal.fire(
            'Modificar Categoria', 
            'Debe ingresar un nombre de la Categoría para poder continuar.', 
            'error'
          )
        }else{
          Swal.fire(
            'Agregando una Categoria', 
            'Debe ingresar un nombre de la Categoría para poder continuar.', 
            'error'
          )
        }
        return
      }
      // confirmacion del usuario
      let tituloAviso = ''
      let textoAviso = ''
      if (this.esNuevaCategoria == false){
        tituloAviso = 'Modificar Categoria'
        textoAviso = '¿Confirma modificar la Categoria seleccionada?'
      }else{
        tituloAviso = 'Agregar Categoria'
        textoAviso = '¿Confirma agregar la Categoria configurada?'
      }
      Swal.fire({
        title: tituloAviso,
        text: textoAviso,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          // armamos la estructura a actualizar en firebase, estado
          this.categoriaModificada = {}
          this.categoriasApiJarvis = []
          this.tamNuevos = 0
          let estado = 0
          if (this.categoriaActivo == false){
            estado = 1
          }
          // asigno estado
          this.categoriaModificada.inhabilitado = estado
          // asigno nombre
          this.categoriaModificada.nombre = this.categoriaNombre
          // asigno el padre
          if (this.esNuevaCategoria == false){
            this.categoriaModificada.padre = this.selectedCategoria.padre
          }else{
            this.categoriaModificada.padre = -1
          }
          // asigno los filtros
          this.categoriaModificada.filtros = []
          if (this.filtros.length > 0){
            for (let id in this.filtros){
              this.categoriaModificada.filtros.push(this.filtros[id].nombre)
            }
          }
          // asigno subcategorias
          this.categoriaModificada.subcategorias = []
          // estoy creando o modificando una categoria?
          if (this.esNuevaCategoria == false){
            // estoy editando
            if (this.selectedCategoria.padre == 0){
              if (this.subCategorias.length > 0){
                // obtengo el mayor idFirebase
                let mayorKey = this.obtenerMayor(this.subCategorias)
                for (let id = 0; id <= mayorKey; id++){
                  let unObjeto = this.subCategorias.filter(element => parseInt(element.keyFirebase) == parseInt(id) && element.keyFirebase != -1)
                  if (unObjeto.length > 0){
                    // push en la posicion keyFirebase = id
                    let unItem = {
                      inhabilitado: unObjeto[0].inhabilitado,
                      nombre: unObjeto[0].nombre,
                      padre: unObjeto[0].padre
                    }
                    this.categoriaModificada.subcategorias.push(unItem)
                  }else{
                    // relleno de null
                    this.categoriaModificada.subcategorias.push(null)
                  }
                }
                // me fijo si estan agregando subCategorias nuevas
                let unListado = this.subCategorias.filter(element => parseInt(element.keyFirebase) == -1)
                if (unListado.length > 0){
                  this.subCategoriasNuevas = []
                  this.subCategoriasNuevas = unListado
                  this.tamNuevos = unListado.length
                  this.cantNuevosControlados = 0
                  // estan agregando nuevas categorias, controlo si ya existian en Oracle
                  for (let id in unListado){
                    let opcionActual = {
                      opcion: 1,
                      unaCategoria: unListado[id]
                    }
                    await this.getCategoriaOracle({categoria: unListado[id], opcion: opcionActual})
                  }
                }
              }
            }else{
              this.tamNuevos = 0
            }
          }else{
            // estoy creando, paso a crear primero la categoria padre en Oracle
            let opcionActual = {
              opcion: 4,
              unaCategoria: this.categoriaModificada
            }
            this.tamNuevos = 1 // esto es solo para que no entre en el if de abajo
            await this.setCategoryOracle({categoria: this.categoriaModificada, opcion: opcionActual})
            /*this.subCategoriasNuevas = []
            // como estoy creando, todo lo que se encuentre en this.subCategorias es nuevo
            if (this.subCategorias.length > 0){
              this.tamNuevos = this.tamNuevos.length
              this.cantNuevosControlados = 0
              for (let id in this.subCategorias){
                let opcionActual = {
                  opcion: 1,
                  unaCategoria: this.subCategorias[id]
                }
                await this.getCategoriaOracle({categoria: this.subCategorias[id], opcion: opcionActual})
              }
            }else{
              this.tamNuevos = 0
            }*/
          }
          if (parseInt(this.tamNuevos) === 0){
            // todo ok, paso solo a modificar en firebase
            await this.updateCategoriaEcommerceAdmin({categoria: this.categoriaModificada, keyFirebase: this.selectedCategoria.key_firebase, nuevos: [], subCat: this.subCategorias})
          }
        }
      })
    }
  },
  watch: {
    respCategoriasAdmin: function(){
      if (Object.keys(this.respCategoriasAdmin).length > 0){
        if (parseInt(this.respCategoriasAdmin.resultado) === 1){
          this.categorias = this.respCategoriasAdmin.catEcommerce
        }else{
          Swal.fire(
            'Iniciando Formulario', 
            this.respCategoriasAdmin.msj, 
            'error'
          )
        }
      }
    },
    categorias: function(){
      if (this.categorias.length > 0){
        // rearmo el listado de filtros existentes
        this.listadoFiltros = []
        for (let id in this.categorias){
          if (this.categorias[id].hasOwnProperty('filtros')){
            if (Array.isArray(this.categorias[id].filtros)){
              // recorro los filtros de la categoria actual
              for (let jd in this.categorias[id].filtros){
                let unFiltro = {
                  nombre: this.categorias[id].filtros[jd],
                  existente: 1
                }
                // se encuentra en mi listado?
                if (this.listadoFiltros.length == 0){
                  this.listadoFiltros.push(unFiltro)
                }else{
                  let cont = 0
                  for (let idd in this.listadoFiltros){
                    if (this.listadoFiltros[idd].nombre.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == unFiltro.nombre.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")){
                      cont = cont + 1
                    }
                  }
                  if (parseInt(cont) === 0){
                    this.listadoFiltros.push(unFiltro)
                  }
                }
              }
            }
          }
          if (this.categorias[id].hasOwnProperty('subcategorias')){
            // algunos listados vienen como objeto, se deben pasar a array sin perder la posicion del array por la actualizacion en firebase
            if (!Array.isArray(this.categorias[id].subcategorias) && this.categorias[id].subcategorias != null && typeof this.categorias[id].subcategorias == 'object'){
              // obtengo el mayor manualmente porque si lo convierto directamente a array, pierdo la key de Firebase
              let mayor = 0
              for (let jd in this.categorias[id].subcategorias){
                if (parseInt(jd) > mayor){
                  mayor = jd
                }
              }
              // armo el array ahora
              let listadoCat = []
              for (let jd = 0; parseInt(jd) <= parseInt(mayor); jd++){
                // recorro el objeto
                let band = false
                for (let idd in this.categorias[id].subcategorias){
                  if (parseInt(jd) == parseInt(idd)){
                    band = true
                    listadoCat.push(this.categorias[id].subcategorias[idd])
                  }
                }
                if (band == false){
                  listadoCat.push(null)
                }
              }
              this.categorias[id].subcategorias = []
              this.categorias[id].subcategorias = listadoCat
            }
          }
        }

      }
    },
    respDatosApiJarvis: async function(){
      if (Object.keys(this.respDatosApiJarvis).length > 0){
        if (parseInt(this.respDatosApiJarvis.resultado) === 1){
          if (parseInt(this.intentosLoginJarvis) <= 3){
            this.intentosLoginJarvis = this.intentosLoginJarvis + 1
            // iniciar nuevamente
            if (this.respDatosApiJarvis.opcionActual.opcion == 1){
              await this.getCategoriaOracle({categoria: this.respDatosApiJarvis.opcionActual.unaCategoria, opcion: this.respDatosApiJarvis.opcionActual})
            }
            if (this.respDatosApiJarvis.opcionActual.opcion == 2){
              await this.setSubCategoryOracle({categorias: this.respDatosApiJarvis.opcionActual.unaCategoria, opcion: this.respDatosApiJarvis.opcionActual})
            }
            if (this.respDatosApiJarvis.opcionActual.opcion == 3){
              await this.setSubCategoryOracle({categorias: this.respDatosApiJarvis.opcionActual.unaCategoria, opcion: this.respDatosApiJarvis.opcionActual})
            }
            if (this.respDatosApiJarvis.opcionActual.opcion == 4){
              await this.setCategoryOracle({categoria: this.respDatosApiJarvis.opcionActual.unaCategoria, opcion: this.respDatosApiJarvis.opcionActual})
            }
            if (this.respDatosApiJarvis.opcionActual.opcion == 5){
              await this.setSubCategoryOracle({categoria: this.respDatosApiJarvis.opcionActual.unaCategoria, opcion: this.respDatosApiJarvis.opcionActual})
            }
            if (this.respDatosApiJarvis.opcionActual.opcion == 6){
              await this.updateCategoriaOracle({categorias: this.respDatosApiJarvis.opcionActual.unaCategoria, opcion: this.respDatosApiJarvis.opcionActual})
            }
          }else{
            console.log(this.respDatosApiJarvis.msj)
            this.dialogCarga = false
          }
        }
      }
    },
    respSubCategoriasAdmin: async function(){
      if (Object.keys(this.respSubCategoriasAdmin).length > 0){
        this.cantNuevosControlados = this.cantNuevosControlados + 1
        if (parseInt(this.respSubCategoriasAdmin.resultado) === 1){
          // guardo las categorias de oracle en caso de llegar
          if (this.respSubCategoriasAdmin.subCategorias.length > 0){
            for (let id in this.respSubCategoriasAdmin.subCategorias){
              this.categoriasApiJarvis.push(this.respSubCategoriasAdmin.subCategorias[id])
            }
          }
          if ((parseInt(this.tamNuevos) == parseInt(this.cantNuevosControlados)) && this.errorControlCategoria == false){
            // esto es, las peticiones se terminaron y TODAS salieron bien, controlo a ver si me llegaron categorias de oracle
            if (this.categoriasApiJarvis.length > 0){
              // esto quiere decir que agregaron una categoria que ya existia en oracle, no necesariamente todas las que agregaron son nuevas
              // entonces, busco de acuerdo a la respuesta de la api de jarvis, cual categoria existe ya en oracle
              let listadoExistentes = []
              let listadoNoExistentes = []
              for (let id in this.subCategoriasNuevas){
                let unaCategoria = this.categoriasApiJarvis.filter(element => element.descripcion.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == this.subCategoriasNuevas[id].nombre.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
                if (unaCategoria.length > 0){
                  // lo que obtuve aqui es que la categoria es existente, la agrego al listado de existentes
                  listadoExistentes.push(unaCategoria[0])
                }else{
                  // no existe en oracle, agregar al listado de nuevos
                  listadoNoExistentes.push(this.subCategoriasNuevas[id])
                }
              }
              // pregunto si tengo que agregar nuevos en oracle
              if (listadoNoExistentes.length > 0){
                // pasar a agregar
                let opcionActual = {
                  opcion: 3,
                  unaCategoria: listadoNoExistentes,
                  existentes: listadoExistentes
                }
                await this.setSubCategoryOracle({categorias: listadoNoExistentes, opcion: opcionActual})
              }else{
                // solo tengo que agregar a las subcategorias de la categoria y pasar a actualizar en firebase
                this.loadListadoSubCategorias([], listadoExistentes)
              }
            }else{
              // se consulto en oracle todas las nuevas sub categorias que indico el usuario pero no existian, se procede a crearlas en oracle e insertar en firebase
              let opcionActual = {
                opcion: 2,
                unaCategoria: this.subCategoriasNuevas
              }
              await this.setSubCategoryOracle({categorias: this.subCategoriasNuevas, opcion: opcionActual})
            }
          }
        }else{
          // error en alguna peticion
          this.errorControlCategoria = true
          Swal.fire({
            title: 'Modificando Categoria Ecommerce', 
            text: this.respSubCategoriasAdmin.msj, 
            icon: 'error',
            timer: 4000
          })
          setTimeout(() => [location.reload()], 4000)
        }
      }
    },
    respUpCatAdmin: async function(){
      if (Object.keys(this.respUpCatAdmin).length > 0){
        if (parseInt(this.respUpCatAdmin.resultado) === 1){
          this.dialogModCategoria = false
          // paso la key
          if (this.esNuevaCategoria == false){
            this.categoriaModificada.keyFirebase = this.selectedCategoria.key_firebase
          }else{
            this.categoriaModificada.keyFirebase = this.respNewCatPadreEcomOracle.newCatPadreOracle
          }
          // paso a actualizar el estado 
          let opcionActual = {
            opcion: 6,
            unaCategoria: this.categoriaModificada
          }
          await this.updateCategoriaOracle({categorias: this.categoriaModificada, opcion: opcionActual})
          /*Swal.fire({
            title: 'Modificando Categoria Ecommerce', 
            text: 'Actualización finalizada con éxito.', 
            icon: 'success',
            timer: 4000
          })
          setTimeout(() => [location.reload()], 4000)*/
        }else{
          // salio un error al actualizar en firebase, es por token expirado?
          if (parseInt(this.respUpCatAdmin.tokenExpirado) == 1){
            // token expirado, lo quito y refresco la pagina
            localStorage.removeItem('tokenAdmin')
            location.reload()
          }else{
            Swal.fire({
              title: 'Modificando Categoria Ecommerce', 
              text: this.respUpCatAdmin.msj, 
              icon: 'error',
              timer: 4000
            })
            setTimeout(() => [location.reload()], 4000)
          }
        }
      }
    },
    respNewCatEcommerceOracle: function(){
      if (Object.keys(this.respNewCatEcommerceOracle).length > 0){
        if (parseInt(this.respNewCatEcommerceOracle.resultado) === 1){
          // controlo desde donde viene la llamada
          if (this.respNewCatEcommerceOracle.opciones.opcion == 2){
            // se insertaron correctamente en Oracle, paso a completar el listado de subcategorias nuevamente
            this.loadListadoSubCategorias(this.respNewCatEcommerceOracle.newCatOracle, [])
          }
          if (this.respNewCatEcommerceOracle.opciones.opcion == 3){
            // se insertaron correctamente en Oracle, paso a completar el listado de subcategorias nuevamente
            this.loadListadoSubCategorias(this.respNewCatEcommerceOracle.newCatOracle, this.respNewCatEcommerceOracle.opciones.existentes)
          }
          if (this.respNewCatEcommerceOracle.opciones.opcion == 5){
            // se insertaron correctamente en Oracle, paso a completar el listado de subcategorias nuevamente
            this.loadListadoSubCategorias(this.respNewCatEcommerceOracle.newCatOracle, [])
          }
        }else{
          // error al crear las categorias en Oracle, detengo la ejecución y no toco nada en Firebase
          Swal.fire({
            title: 'Modificando Categoria Ecommerce', 
            text: this.respNewCatEcommerceOracle.msj, 
            icon: 'error',
            timer: 4000
          })
          //setTimeout(() => [location.reload()], 4000)
        }
      }
    },
    respNewCatPadreEcomOracle: async function(){
      if (Object.keys(this.respNewCatPadreEcomOracle).length > 0){
        if (parseInt(this.respNewCatPadreEcomOracle.resultado) === 1){
          // exito en la creacion de la categoria Padre, asigno el id de la categoria
          this.categoriaModificada.padre = 0
          // tengo subCategorias agregadas?
          if (this.subCategorias.length > 0){
            // asigno el padre en cada una de ellas y agrego a oracle
            for (let id in this.subCategorias){
              this.subCategorias[id].padre = this.respNewCatPadreEcomOracle.newCatPadreOracle
            }
            // agrego a oracle las subcategorias
            let opcionActual = {
              opcion: 5,
              unaCategoria: this.subCategorias
            }
            await this.setSubCategoryOracle({categorias: this.subCategorias, opcion: opcionActual})
          }else{
            // paso directamente a actualizar en firebase
            await this.updateCategoriaEcommerceAdmin({categoria: this.categoriaModificada, keyFirebase: this.respNewCatPadreEcomOracle.newCatPadreOracle, nuevos: [], subCat: this.subCategorias})
          }
        }else{
          // error al crear la categoria padre, detengo todo y muestro msj de error
          Swal.fire({
            title: 'Creando Categoria Ecommerce', 
            text: this.respNewCatPadreEcomOracle.msj, 
            icon: 'error',
            timer: 4000
          })
          setTimeout(() => [location.reload()], 4000)
        }
      }
    },
    respUpCatOracleAdmin: function(){
      if (Object.keys(this.respUpCatOracleAdmin).length > 0){
        let titulo = ''
        if (this.esNuevaCategoria == false){
          titulo = 'Modificando Categoria Ecommerce'
        }else{
          titulo = 'Agregando Categoria Ecommerce'
        }
        if (parseInt(this.respUpCatOracleAdmin.resultado) === 1){
          Swal.fire({
            title: titulo, 
            text: 'Actualización finalizada con éxito.', 
            icon: 'success',
            timer: 4000
          })
          setTimeout(() => [location.reload()], 4000)
        }else{
          Swal.fire({
            title: titulo, 
            text: this.respUpCatOracleAdmin.msj, 
            icon: 'error',
            timer: 4000
          })
          setTimeout(() => [location.reload()], 4000)
        }
      }
    }
  },
}
</script>

<style>

</style>