<template>
  <v-container fluid style="height: 100%; padding: 0px;">
    <v-row
      style="padding: 0px; height: 100%; margin: 0px;"
    >
      <!-- Datos Contacto -->
      <v-col
        cols="12"
        style="height: 100%; padding-bottom: 0px;"
      >
        <v-card>
          <v-toolbar dense flat >
            <v-toolbar-title><v-icon>mdi-account-box-outline</v-icon>&nbsp;Datos de Contacto</v-toolbar-title>   
          </v-toolbar>
          <v-container>
            <v-row  class="wsp">
              <v-col cols="12" sm="4" md="4">
                <v-card class="mx-auto" outlined elevation="2">                    
                  <v-card-title >
                    <v-icon>mdi-whatsapp</v-icon>
                    &nbsp;Telefono
                  </v-card-title>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="configuraciones.contacto.telefono"
                      label="Numero"
                      placeholder="Placeholder"
                      outlined                           
                    ></v-text-field>
                  </v-col>
                </v-card>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-card class="mx-auto" outlined elevation="2">
                  <v-card-title>
                    <v-icon>mdi-email-outline</v-icon>
                    &nbsp;E-mail
                  </v-card-title>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="configuraciones.contacto.email"
                      label="E-mail"
                      placeholder="Placeholder"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-card>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-card class="mx-auto" outlined elevation="2">
                  <v-card-title>
                    <v-icon>mdi-account-group-outline</v-icon>
                    &nbsp;Redes Sociales
                  </v-card-title>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="configuraciones.contacto.facebook"
                      label="Facebook"
                      placeholder="Placeholder"
                      outlined
                      prepend-inner-icon="mdi-facebook"                        
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="configuraciones.contacto.instagram"
                      label="Instagram"
                      placeholder="Placeholder"
                      outlined                            
                      prepend-inner-icon="mdi-instagram"
                    ></v-text-field>
                  </v-col>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-col class="text-center" cols="12" sm="12">
              <div class="my-2">
                <v-btn  dark color="#04B431">Guardar</v-btn>
              </div>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import router from '../../router'
import {mapActions, mapState} from 'vuex'
export default {
  data() {
    return {
      configuraciones: {
        contacto: {
          telefono: '',
          email: '',
          facebook: '',
          instagram: ''
        }
      }
    }
  },
  components: {

  },
  created () {
    this.usuarioLogueado()
  },
  computed: {
    ...mapState(['respContactoAdmin']),
  },
  methods: {
    ...mapActions(['getInfoContacto']),
    usuarioLogueado(){
      if (localStorage.getItem('tokenAdmin')){
        // prosigo con la carga
        this.getContacto()
      }else{
        // redirecciono al Login
        router.push({name: 'LoginAdmin', params: {sesionExpirada: 1}})
      }
    },
    async getContacto(){
      await this.getInfoContacto()
    }
  },
  watch: {
    respContactoAdmin: function(){
      if (Object.keys(this.respContactoAdmin).length > 0){
        if (parseInt(this.respContactoAdmin.resultado) === 1){
          this.configuraciones = this.respContactoAdmin.contacto
        }
      }
    }
  },
}
</script>

<style>
  .wsp .v-card {
    border-top: 5px solid rgb(42, 152, 226) !important;
  }
</style>