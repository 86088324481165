<template>
  <v-container>
    <v-row>
      <!-- Logica de Compra -->
      <v-col cols="12" sm="8" md="8" xl="8">
        <v-stepper
          v-model="pasoActual"
          vertical
        >
          <!-- Paso 1 -->
          <v-stepper-step
            :complete="pasoActual > 1"
            step="1"
            class="formatoPalabras"
          >
            Validación de Email y Datos Personales
            <br/>
            <!-- Visualizacion Paso 1 -->
            <v-card
              max-width="500px"
              v-if="paso1Correcto"
              flat
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12" style="padding-top: 20px; padding-bottom: 0px">
                    <h4><strong>Nombre y Apellido: </strong>{{ nombreApellido }}</h4>
                  </v-col>
                  <v-col cols="12" style="padding-top: 0px; padding-bottom: 0px">
                    <h4><strong>Teléfono: </strong>{{ telefonoCliente }}</h4>
                  </v-col>
                  <v-col cols="12" style="padding-top: 0px; padding-bottom: 0px">
                    <h4><strong>Email: </strong>{{ email }}</h4>
                  </v-col>
                  <v-col cols="12" v-if="errorPaso1">
                    <v-alert dense text type="info">
                      {{ msjErrorPaso1 }}
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row justify="center">
                  <v-btn
                    outlined
                    color="primary"
                    v-if="existsUserSystem"
                    class="ma-2"
                    @click="modificarPaso(1,1)"
                  >
                    Modificar Datos Personales
                  </v-btn>
                  <v-btn
                    outlined
                    color="primary"
                    v-if="existsUserSystem"
                    @click="noSoyYo()"
                    class="ma-2"
                  >
                    No soy Yo
                  </v-btn>
                  <v-btn
                    outlined
                    color="primary"
                    v-if="existsUserSystem == false"
                    @click="modificarPaso(1,2)"
                    class="ma-2"
                  >
                    Modificar Datos Personales
                  </v-btn>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-stepper-step>
          <!-- Contenido Paso 1 -->
          <v-stepper-content step="1">
            <!-- Validacion de Email -->
            <v-card
              class="mb-12"
              v-if="modificaDatosPersonales == false && completarDatosPersonales == false"
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="email"
                      label="Email"
                      :rules="Rules"
                      placeholder="Ingrese su Email"
                      outlined dense
                      @keyup.enter="validarEmail()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="errorCompra">
                    <v-alert dense text type="info">
                      {{ msjErrorCompra }}
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions style="justify-content: right;">
                <v-btn
                  color="#1976d2"
                  @click="validarEmail()"
                  class="white--text"
                  v-if="validaPaso1"
                >
                  Continuar
                </v-btn>
                <!-- <v-btn 
                  text
                  color="primary"
                  @click="volverAlCarrito"
                >
                  Cancelar
                </v-btn> -->
              </v-card-actions>
            </v-card>
            <!-- Modificar Datos Personales para usaurios logueados o que existen en el sistema -->
            <contenidoMisDatos 
              :opcion="2"
              v-if="modificaDatosPersonales == true && completarDatosPersonales == false"
              @setDatosEntrega="setearDatosEntrega"
              @refreshPage="refrescarPage"
            />
            <!-- Agregar Datos Personales, solo para clientes NO existentes en el sistema -->
            <v-card
              flat
              class="mb-12"
              v-if="modificaDatosPersonales == false && completarDatosPersonales == true"
            >
              <v-card-title>
                <v-toolbar color="#ed1841" dark height="40%">
                  <v-toolbar-title style="font-size:20px">
                    <v-icon>mdi-account-details</v-icon>
                    Datos de Personales
                  </v-toolbar-title>
                </v-toolbar>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-alert dense text type="info">
                      Datos del  <strong>comprador</strong> a quien se realizara la facturación de la compra.
                    </v-alert>
                  </v-col>
                  <v-col  cols="12" sm="6" md="6" style="padding-top: 0px; padding-bottom: 0px">
                    <v-text-field v-model="email" label="Email" outlined dense @blur="validarEmail"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" style="padding-top: 0px; padding-bottom: 0px">
                    <v-text-field v-model="documentoFacturacion" type="number" :rules="Rules" label="N° Documento" placeholder="D.N.I." outlined dense clearable></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" style="padding-top: 0px; padding-bottom: 0px">
                    <v-text-field v-model="apellidosFacturacion" :rules="Rules" label="Apellido/s"  outlined dense clearable></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" style="padding-top: 0px; padding-bottom: 0px">
                    <v-text-field v-model="nombresFacturacion" :rules="Rules" label="Nombre/s"  outlined dense clearable></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" style="padding-top: 0px; padding-bottom: 0px">
                    <v-text-field v-model="telefonoFacturacion" :rules="Rules" label="Telefono"  outlined dense clearable></v-text-field>
                  </v-col>
                  <v-col cols="12" :sm="defineGenero == false ? 6 : 3" :md="defineGenero == false ? 6 : 3" style="padding-top: 0px; padding-bottom: 0px">
                    <v-text-field v-model="fechaNacimientoFacturacion" :rules="Rules" label="Fecha Nacimiento" v-mask="'##/##/####'" outlined dense clearable @blur="controlFecha(fechaNacimientoFacturacion)"></v-text-field>
                  </v-col>
                  <v-col cols="12" :sm="defineGenero == false ? 6 : 3" :md="defineGenero == false ? 6 : 3" style="padding-top: 0px; padding-bottom: 0px">
                    <v-select 
                      v-model="generoFacturacion" 
                      :items="generos"  
                      label="Genero" 
                      outlined dense clearable 
                      item-text="nombre" 
                      item-value="nombre" 
                      return-object
                      :rules="Rules"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" style="padding-top: 0px; padding-bottom: 0px" v-if="defineGenero == true">
                    <v-text-field v-model="generoPersonalizadoFacturacion" :rules="Rules" label="Ingresa tu Género" outlined dense clearable></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" style="padding-top: 0px; padding-bottom: 0px">
                    <v-text-field
                      v-model="codigoPostalFacturacion"
                      label="Código Postal"
                      placeholder="Ingrese su CP"
                      outlined dense
                      :rules="Rules"
                      @blur="getProvinciaCodigoPostal(2)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" style="padding-top: 0px; padding-bottom: 0px">
                    <v-select
                      v-model="selectedProvinciaFacturacion"
                      :items="provincias"
                      item-text="provincia_nombre"
                      outlined dense
                      label="Provincia"
                      placeholder="Seleccione una Provincia"
                      return-object
                      :rules="Rules"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6" style="padding-top: 0px; padding-bottom: 0px">
                    <v-text-field
                      v-model="localidadFacturacion"
                      label="Localidad"
                      placeholder="Ingrese su Localidad"
                      outlined dense
                      :rules="Rules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="8" style="padding-top: 0px; padding-bottom: 0px">
                    <v-text-field v-model="calleFacturacion" :rules="Rules" label="Calle"  placeholder="Ingrese su Calle" outlined dense></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" style="padding-top: 0px; padding-bottom: 0px">
                    <v-text-field v-model="calleNumeroFacturacion" :rules="Rules" label="Numero"  placeholder="Numero de Calle" outlined dense></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" style="padding-top: 0px; padding-bottom: 0px">
                    <v-text-field v-model="datosAdicionalesFacturacion" label="Piso /  Departamento / Barrio" placeholder="Ingrese datos Adicionales" outlined dense></v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="errorPaso1" style="padding-top: 0px; padding-bottom: 0px">
                    <v-alert dense text type="info">
                      {{ msjErrorPaso1 }}
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row justify="center" style="margin: 0px">
                  <v-btn 
                    color="blue" 
                    class="ma-2 white--text"
                    @click="buscarCodigoPostalFac()"
                  >
                    No sé mi Codigo Postal
                  </v-btn>
                  <v-btn 
                    color="primary" 
                    @click="confirmarDatosPersonales()"
                    class="ma-2"
                  >
                    Confirmar Datos Personales
                  </v-btn>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <!-- Paso 2 -->
          <v-stepper-step
            :complete="pasoActual > 2"
            step="2"
            class="formatoPalabras"
          >
            Elija cómo recibir su compra
            <br/>
            <v-card
              flat
              v-if="preFinPaso2 == true"
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-alert dense text type="info">
                      Domicilio donde se realizara el envio del Pedido.
                    </v-alert>
                  </v-col>
                  <!-- Comentado el 11-10-2023 por MMURILLO, dejamos de hacer envio a domicilio -->
                  <!-- <v-col cols="12" md="6">
                    <v-chip color="#ed1841" outlined text-color="black">
                      <v-checkbox v-model="envioDomicilio" color="#ed1841" disabled></v-checkbox>
                      {{ tituloEnvioDomicilio }}
                    </v-chip>
                  </v-col> -->
                  <v-col cols="12">
                    <v-chip  color="#ed1841" outlined text-color="black">
                      <v-checkbox  v-model="retiroSucursal" color="#ed1841" disabled></v-checkbox>Retiro en Sucursal - Gratis
                    </v-chip>
                  </v-col>
                  <v-col cols="12" v-if="errorPaso2">
                    <v-alert dense text type="info">
                      {{ msjErrorPaso2 }}
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <br/>
            <v-card
              class="mb-12"
              v-if="preFinPaso2 == true"
              flat
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="12" lg="12" xl="12" v-if="metodoEntregaSeleccionado && envioDomicilio == true">
										<v-alert v-if="envioDomicilio" border="left" colored-border  type="warning" elevation="2" icon="mdi-car-info">
											El pedido será entregado en las próximas <strong>{{ horasHabiles }} horas hábiles.</strong>
										</v-alert>
								  </v-col>
                  <v-col cols="12" md="12" lg="12" xl="12" v-if="metodoEntregaSeleccionado && retiroSucursal == true">
                    <v-alert v-if="retiroSucursal" border="left" colored-border  type="warning" elevation="2"  icon="mdi-car-info">
											El pedido estará disponible en el local en <strong>24 horas.</strong>
										</v-alert>
                  </v-col>
                </v-row>
                <!-- Si el cliente selecciona envio a Domicilio -->
                <v-row v-if="metodoEntregaSeleccionado == true && envioDomicilio == true">
                  <v-col cols="12" md="12" lg="12" xl="12" style="padding-bottom: 0px; padding-top: 0px">
                    <h4><strong>Calle/Nro Calle/Detalles: </strong>{{ direccionEnvioDomicilio }}</h4>
                    <h4><strong>Loc/Prov/Cod Postal: </strong>{{ provinciaEnvioDomicilio }}</h4>
                  </v-col>
                </v-row>
                <!-- Si el cliente selecciona Retiro en Sucursal -->
                <v-row v-if="metodoEntregaSeleccionado == true && retiroSucursal == true">
                  <v-col cols="12" style="padding-top: 20px; padding-bottom: 0px">
                    <h4><v-icon>mdi-map-marker-radius-outline</v-icon><strong>Provincia Retiro: </strong>{{ selectedProvinciaRetiro.provincia_nombre }}</h4>
                    <h4><v-icon>mdi-map-marker-radius-outline</v-icon><strong>Sucursal Retiro: </strong>{{ selectedLocalRetiro.direccion }}</h4>
									</v-col>
                </v-row>
                <v-row v-if="errorPaso2">
                  <v-col cols="12">
                    <v-alert dense text type="info">
                      {{ msjErrorPaso2 }}
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row justify="center">
                  <v-btn
                    color="primary"
                    outlined
                    v-if="preFinPaso2"
                    @click="modificarPaso(2,1)"
                    class="ma-2"
                  >
                    Modificar Metodo de Envio
                  </v-btn>
                  <v-btn
                    color="primary"
                    outlined
                    v-if="preFinPaso2 && envioDomicilio"
                    @click="modificarPaso(2,2)"
                    class="ma-2"
                  >
                    Modificar Domicilio de Entrega
                  </v-btn>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-stepper-step>
          <!-- Contenido Paso 2 -->
          <v-stepper-content step="2">
            <v-card
              flat
              v-if="preFinPaso2 == false"
            >
              <v-row>
                <v-col cols="12">
                  <v-alert dense text type="info">
                    Domicilio donde se realizara el envio del Pedido.
                  </v-alert>
                </v-col>
                <!-- Comentado el 11-10-2023 por MMURILLO, dejamos de hacer envio a domicilio -->
                <!-- <v-col cols="12" md="6">
									<v-chip color="#ed1841" outlined text-color="black">
										<v-checkbox v-model="envioDomicilio" color="#ed1841" ></v-checkbox>
										{{ tituloEnvioDomicilio }}
									</v-chip>
								</v-col> -->
								<v-col cols="12">
									<v-chip  color="#ed1841" outlined text-color="black">
										<v-checkbox  v-model="retiroSucursal" color="#ed1841"></v-checkbox>Retiro en Sucursal - Gratis
									</v-chip>
								</v-col>
                <v-col cols="12" v-if="errorPaso2">
									<v-alert dense text type="info">
                    {{ msjErrorPaso2 }}
                  </v-alert>
								</v-col>
              </v-row>
            </v-card>
            <v-card
              class="mb-12"
              v-if="preFinPaso2 == false && metodoEntregaSeleccionado == true"
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12" v-if="metodoEntregaSeleccionado">
										<v-alert v-if="envioDomicilio" border="left" colored-border  type="warning" elevation="2" icon="mdi-car-info">
											El pedido será entregado en las próximas <strong>{{ horasHabiles }} horas hábiles.</strong>
										</v-alert>
										<v-alert v-if="retiroSucursal" border="left" colored-border  type="warning" elevation="2"  icon="mdi-car-info">
											El pedido estará disponible en el local en <strong>24 horas.</strong>
										</v-alert>
								  </v-col>
                </v-row>
                <!-- Si el cliente selecciona envio a Domicilio -->
                <v-row v-if="metodoEntregaSeleccionado == true && envioDomicilio == true">
                  <v-col cols="12" md="4" style="padding-top: 0px; padding-bottom: 0px">
										<v-text-field
											v-model="codigoPostalEntrega"
											label="Código Postal"
											:rules="Rules"
											placeholder="Ingrese su CP de Entrega"
											outlined dense
											style="height: 55px"
											@blur="getProvinciaCodigoPostal(1)"
										></v-text-field>
                    <span style="color: #2196f3; padding-left: 50px" @click="buscarCodigoPostal()"><a>No sé mi CP</a></span>
									</v-col>
									<v-col cols="12" md="4" style="padding-top: 0px; padding-bottom: 0px">
										<v-select
											v-model="selectedProvinciaEntrega"
											:items="provincias"
											item-text="provincia_nombre"
											outlined dense
											label="Provincia"
											placeholder="Seleccione su Provincia de Entrega"
                      return-object
										></v-select>
									</v-col>
									<v-col cols="12" md="4" style="padding-top: 0px; padding-bottom: 0px">
										<v-text-field
											v-model="localidadEntrega"
											label="Localidad"
											:rules="Rules"
											placeholder="Ingrese su Localidad"
											outlined dense
										></v-text-field>
									</v-col>
									<v-col cols="12" md="8" style="padding-top: 0px; padding-bottom: 0px">
										<v-text-field
											v-model="calleEntrega"
											label="Calle"
											:rules="Rules"
											placeholder="Ingrese su Calle"
											outlined dense
										></v-text-field>
									</v-col>
									<v-col cols="12" md="4" style="padding-top: 0px; padding-bottom: 0px">
										<v-text-field
											v-model="calleNumeroEntrega"
											label="Numero"
											:rules="Rules"
											placeholder="Numero de Calle"
											outlined dense
										></v-text-field>
									</v-col>
									<v-col cols="12" md="12" style="padding-top: 0px; padding-bottom: 0px">
										<v-text-field
											v-model="datosAdiconalesEntrega"
											label="Piso /  Departamento / Barrio"
											placeholder="Ingrese datos Adicionales"
											outlined dense
                      :rules="Rules"
										></v-text-field>
									</v-col>
                </v-row>
                <!-- Si el cliente selecciona Retiro en Sucursal -->
                <v-row v-if="metodoEntregaSeleccionado == true && retiroSucursal == true">
                  <v-col cols="12" style="padding-top: 0px; padding-bottom: 0px">
										<v-autocomplete	
                      v-model="selectedProvinciaRetiro" 
                      :items="provinciasRetiro" 
                      :rules="Rules" 
                      clearable
											outlined dense
                      return-object
                      label="Provincia Retiro" 
                      item-text="provincia_nombre" 
                      placeholder="Seleccione una Provincia"
                      @change="obtenerLocales()">
										</v-autocomplete>
									</v-col>
                  <v-col cols="12" style="padding-top: 0px; padding-bottom: 0px">
										<v-autocomplete	
                      v-model="selectedLocalRetiro" 
                      :items="localesRetiro" 
                      :rules="Rules" 
                      clearable
											outlined dense
                      return-object
                      label="Sucursal Retiro" 
                      item-text="direccion" 
                      placeholder="Seleccione una Sucursal">
										</v-autocomplete>
									</v-col>
                </v-row>
                <!-- Error en Paso actual -->
                <v-row v-if="errorPaso2">
                  <v-col cols="12">
                    <v-alert dense text type="info">
                      {{ msjErrorPaso2 }}
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions style="justify-content: right;">
                <v-btn
                  color="#1976d2"
                  @click="siguientePaso(3)"
                  v-if="confirmarPreFin2"
                  class="white--text"
                >
                  Continuar
                </v-btn>
                <!-- <v-btn 
                  text
                  color="primary"
                  v-if="confirmarPreFin2"
                  @click="volverAlCarrito()"
                >
                  Cancelar
                </v-btn> -->
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <!-- Paso 3 -->
          <v-stepper-step
            :complete="pasoActual > 3"
            step="3"
            class="formatoPalabras"
          >
            Pago
          </v-stepper-step>
          <!-- Contenido Paso 3 -->
          <v-stepper-content step="3">
            <pagoCliente 
              :datos_facturacion="datosFacturacion"
              :datos_entrega="datosEntrega"
              :stock_articulos="carritoCliente"
              :ismovil="movil"
              :uIdUsuario="uidCliente"
              :stockMercaderia="stockDeArticulos"
            />
          </v-stepper-content>
        </v-stepper>
      </v-col>
      <!-- Resumen de la Compra -->
      <v-col cols="12" sm="12" md="4" xl="4" v-if="movil == false">
        <v-card 
          style="position: fixed"
          flat
          max-width="400px"
        >
          <!-- Listado de Articulos -->
          <v-card 
            v-scroll.self="onScroll"
            class="overflow-y-auto"
            max-height="270px"
            tile
          >
            <v-card
              v-for="(itemCarrito, index) in articulosCarrito" :key="index"
              height="90px"
              @click="verArticuloDetalle(itemCarrito)"
            >
              <v-card-text>
                <v-row style="padding-top: 0px;padding-bottom: 0px">
                  <v-col cols="8" style="padding-top: 20px; padding-bottom: 0px">
                    <h4 style="word-break: normal;" class="text-center formatoPalabras">{{itemCarrito.articuloNombre}}</h4>
                    <h4 class="text-center formatoPalabras">{{ itemCarrito.cantidad }} x {{ itemCarrito.precioNeto }}</h4>
                  </v-col>
                  <v-col cols="4" style="padding-top: 0px; padding-bottom: 0px">
                      <v-img
                        :src="itemCarrito.articuloImagenes[0].src"
                        contain
                        style="aspect-ratio: 1.5"
                      ></v-img>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
          <!-- Resumen de la Compra, Totales -->
          <v-card
            max-height="200px"
            color="#f8f8f8"
            tile
          >
            <v-card-text>
              <v-row>
                <v-col cols="6" style="padding-top: 5px;padding-bottom: 0px">
                  <h4 style="color: black;" class="formatoPalabras">Subtotal: </h4>
                </v-col>
                <v-col cols="6" style="padding-top: 5px;padding-bottom: 0px">
                  <h4 style="color: black;" class="text-end formatoPalabras">${{ calcularImporteParcial }}</h4>
                </v-col>
              </v-row>
              <v-row v-if="parseInt(costoEnvio) > 0">
                <v-col cols="6" style="padding-top: 5px;padding-bottom: 0px">
                  <h4 style="color: black;" class="formatoPalabras">Costo del envío: </h4>
                </v-col>
                <v-col cols="6" style="padding-top: 5px;padding-bottom: 0px">
                  <h4 style="color: black;" class="text-end formatoPalabras">${{costoEnvio}}</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" style="padding-top: 5px;padding-bottom: 0px">
                  <h2 style="color: black;" class="formatoPalabras"><strong>Total: </strong></h2>
                </v-col>
                <v-col cols="6" style="padding-top: 5px;padding-bottom: 0px">
                  <h2 style="color: black;" class="text-end formatoPalabras"><strong>${{ calcularImporteTotal }}</strong></h2>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <!-- Loading -->
    <Loading :activo="dialogCarga" />
    <!-- Loading Circular -->
    <LoadingCircular 
      :active.sync="loadingCarga" 
      :can-cancel="true"   
      color="#ed1841"         
      oader="dots"
      background-color="#252527"
      :is-full-page="true">
    </LoadingCircular>
    <!-- Modal Validacion Email -->
    <v-dialog
      persistent
      v-model="dialogValidarEmail"
      max-width="800px"
    >
      <v-card
        flat
        max-width="800px"
      >
        <v-row justify="center" style="margin-right: 0px; margin-bottom: 0px;">
          <!-- <v-card-title >{{ tituloValidarEmail }}</v-card-title> -->
          <v-card-text class="text-center formatoPalabras">
            <h3>{{ msjValidarEmail }}</h3>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              outlined
              @click="completarDatos"
              class="formatoPalabras"
            >
              Continuar con la Compra
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- Buscar Codigo Postal -->
    <buscarCP 
      :activo="dialogCP"
      @closeModalCP="cerrarModalCP"
      @setCodigoPostal="setearCodigoPostal"
    />
    <!-- Modal del Login -->
    <loginUsuario 
      :activo="showLoginModal"
      :msjLogin="msjInicioSesion"
      @cerrarModalLogin="setearModalLogin"
    />
    <!-- Modal Aviso -->
    <ModalAviso 
      :datos="dialogAviso"
      @setearModalAviso="setModalAviso"
      @action="refrescarPage"
    />
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import Loading from '../genericos/Loading.vue'
import LoadingCircular from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import router from '../../router'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import buscarCP from '../genericos/buscarCodigoPostal.vue'
import contenidoMisDatos from '../cliente/contenidoMisDatos.vue'
import moment from 'moment'
import pagoCliente from '../genericos/pago.vue'
import loginUsuario from '../index/loginUsuario.vue'
import ModalAviso from '../genericos/modalAviso.vue'
export default {
  components: {
    Loading,
    LoadingCircular,
    buscarCP,
    contenidoMisDatos,
    pagoCliente,
    loginUsuario,
    ModalAviso
  },
  data() {
    return {
      // datos de facturacion
      documentoFacturacion: '',
      apellidosFacturacion: '',
      nombresFacturacion: '',
      telefonoFacturacion: '',
      fechaNacimientoFacturacion: '',
      generoFacturacion: {},
      generoPersonalizadoFacturacion: '',
      codigoPostalFacturacion: '',
      selectedProvinciaFacturacion: {},
      localidadFacturacion: '',
      calleFacturacion: '',
      calleNumeroFacturacion: '',
      datosAdicionalesFacturacion: '',
      defineGenero: false,
      // fin datos facturacion
      msjInicioSesion: '',
      dialogCP: false,
      nombreApellido: '',
      telefonoCliente: '',
      datosCliente: {},
      dialogCarga: false,
      pasoActual: 1,
      email: '',
      horasHabiles: '0',
      Rules: [
        v => !!v || "Campo requerido.",
			],
      errorCompra: false,
      msjErrorCompra: '',
      dialogValidarEmail: false,
      tituloValidarEmail: '',
      msjValidarEmail: '',
      paso1Correcto: false,
      envioDomicilio: false,
      retiroSucursal: false,
      preFinPaso2: false,
      confirmarPreFin2: false,
      metodoEntregaSeleccionado: false,
      provincias: [],
      generos: [],
      costosEnvio: [],
      codigoPostalEntrega: '',
      selectedProvinciaEntrega: {},
      localidadEntrega: '',
      calleEntrega: '',
      calleNumeroEntrega: '',
      datosAdiconalesEntrega: '',
      selectedLocalRetiro: {},
      selectedProvinciaRetiro: {},
      provinciasRetiro: [],
      localesRetiro: [],
      carritoCliente: [],
      sucursalesRetiro: [],
      direccionEnvioDomicilio: '',
      provinciaEnvioDomicilio: '',
      llamarEnvioDomicilio: false,
      checkUserInicio: true, // usado para cuando se refresca la pagina, indica control de sesion del usuario
      existsUserSystem: false, // indica si el email ingresado corresponde a uno existente en firebase
      msjErrorPaso2: '',
      msjErrorPaso1: '',
      errorPaso2: false,
      errorPaso1: false,
      codPostalParaEntrega: 0, // cuando el cliente no sabe cual es su cod postal, indica si esta buscando para domicilio de entrega o para domicilio de facturacion
      modificaDomicilioEntrega: false, // indica que el cliente quiere modificar su domicilio de entrega despues de haber seleccionado "Envio de Domicilio" como metodo de entrega
      modificaDatosPersonales: false, // indica que el cliente quiere modificar sus datos personales durante la compra
      completarDatosPersonales: false, // para clientes no existentes en nuestro sistema, les permite completar sus datos personales
      entregaRapida: false, // indica si la totalidad de lo que lleva el cliente se encuentra en un mismo deposito
      costoEnvio: 0,
      dataInicio: null,
      tituloEnvioDomicilio: 'Envio a Domicilio',
      showLoginModal: false,
      validaPaso1: true,
      scrollInvoked: 0,
      movil: false,
      controlStockInicio: true, // indica que se realiza el control de stock al instanciar el formulario, esto es porque no necesariamente van a pasar por el detalle del carrito antes de la compra
      uidCliente: '',
      stockDeArticulos: [], // boedga de donde se va a sacar los articulos de la compra
      datosEntrega: {
        envio_domicilio: false,
				retiro_local: false,
				provincia: '',
				localidad: '',
				local_retiro: '',
				costo_envio: '',
				dias_entrega: '',
				entrega_rapida: false,
				horas_entrega: '',
				codigo_postal: '',
				calle: '',
				calle_numero: '',
				datos_adicionales: ''
      },
      datosFacturacion: {
        email: '',
				apellido: '',
				nombre: '',
				documento: '',
				fecha_nacimiento: '',
				genero: '',
				genero_personalizado: '',
				telefono: '',
				localidad: '',
				provincia: '',
				codigo_postal: '',
				calle: '',
				calle_numero: '',
				datos_adicionales: ''
      },
      dialogAviso: {
        activo: false,
        titulo: '',
        mensaje: ''
      }
    }
  },
  created(){
    this.iniciarCarrito()
    this.iniciarFormulario()
    this.isMovil()
  },
  computed: {
    ...mapState(['articulosCarrito', 'loadingCarga', 'respExistsUser', 'datosUsuario', 'respStartBuy',
                'respDatosEntregaCliente', 'articulosCarrito', 'respProvincias', 'respSucursales',
                'respStatusUser']),
    calcularImporteTotal(){
      if (this.articulosCarrito.length > 0){
        let total = 0
        let index = 0
        for(index in this.articulosCarrito){
          total+=parseFloat(this.articulosCarrito[index].cantidad)*parseFloat(this.articulosCarrito[index].articuloPrecio)
        }
        if(parseInt(this.costoEnvio) > 0 && this.envioDomicilio == true){
          total+= parseInt(this.costoEnvio)
        }
        return parseFloat(total).toFixed(2)
      }else{
        return 0
      }
    },
    calcularImporteParcial(){
      if (this.articulosCarrito.length > 0){
        let total = 0
        let index = 0
        for(index in this.articulosCarrito){
          total+=parseFloat(this.articulosCarrito[index].cantidad)*parseFloat(this.articulosCarrito[index].articuloPrecio)
        }
        return parseFloat(total).toFixed(2)
      }else{
        return 0
      }
    }
  },
  methods: {
    ...mapActions(['loadCarrito', 'showLoading', 'closeLoading', 'existsUser', 'startBuy', 'getDatosEntregaXCodPostal',
                  'getSucursales', 'checkActiveUser', 'logout']),
    async iniciarCarrito(){
      await this.loadCarrito()
    },
    async iniciarFormulario(){
      this.dialogCarga = true
      await this.startBuy()
    },
    async verificarSesionActiva(paso){
      this.showLoading()
      await this.checkActiveUser({paso: paso, opcion: 4})
    },
    toTop(){
      this.$vuetify.goTo(0)
    },
    iniciarCliente(){
      let infoCliente = this.respStatusUser.datosUsuario
      if (infoCliente.hasOwnProperty('datosCliente')){
        this.datosCliente = infoCliente.datosCliente
      }
      // completo los datos del cliente
      if (this.datosCliente.hasOwnProperty('apellidos') && this.datosCliente.hasOwnProperty('nombre')){
        this.nombreApellido = this.completarAsteriscos(this.datosCliente.nombre, 3) + ' ' + this.completarAsteriscos(this.datosCliente.apellidos, 3)
      }
      if (this.datosCliente.hasOwnProperty('telefono')){
        this.telefonoCliente = this.completarAsteriscos(this.datosCliente.telefono, 5)
      }
      this.existsUserSystem = true
      this.email = infoCliente.usuarioEmail
      // completo los datos de facturacion
      if (Object.keys(this.datosCliente).length > 0 && this.datosCliente.apellidos.length > 0){
        // indico paso 1 completo
        this.paso1Correcto = true
        // controlo la consistencia de los datos
        this.datosFacturacion.email = this.email
        if (this.datosCliente.hasOwnProperty('apellidos')){
          this.datosFacturacion.apellido = this.datosCliente.apellidos
        }else{
          this.datosFacturacion.apellido = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('nombre')){
          this.datosFacturacion.nombre = this.datosCliente.nombre
        }else{
          this.datosFacturacion.nombre = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('documento')){
          this.datosFacturacion.documento = this.datosCliente.documento
        }else{
          this.datosFacturacion.documento = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('fecha_nacimiento')){
          this.datosFacturacion.fecha_nacimiento = this.datosCliente.fecha_nacimiento
        }else{
          this.datosFacturacion.fecha_nacimiento = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('genero')){
          this.datosFacturacion.genero = this.datosCliente.genero
        }else{
          this.datosFacturacion.genero = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('generoPersonalizado')){
          this.datosFacturacion.genero_personalizado = this.datosCliente.generoPersonalizado
        }else{
          this.datosFacturacion.genero_personalizado = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('telefono')){
          this.datosFacturacion.telefono = this.datosCliente.telefono
        }else{
          this.datosFacturacion.telefono = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('localidad')){
          if (this.datosCliente.localidad.hasOwnProperty('localidad')){
            this.datosFacturacion.localidad = this.datosCliente.localidad.localidad
          }else{
            this.datosFacturacion.localidad = ''
            this.paso1Correcto = false
          }
        }else{
          this.datosFacturacion.localidad = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('provincia')){
          this.datosFacturacion.provincia = this.datosCliente.provincia
        }else{
          this.datosFacturacion.provincia = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('codigo_postal')){
          this.datosFacturacion.codigo_postal = this.datosCliente.codigo_postal
        }else{
          this.datosFacturacion.provincia = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('calle')){
          this.datosFacturacion.calle = this.datosCliente.calle
        }else{
          this.datosFacturacion.calle = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('calle_numero')){
          this.datosFacturacion.calle_numero = this.datosCliente.calle_numero
        }else{
          this.datosFacturacion.calle_numero = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('datos_adicionales')){
          this.datosFacturacion.datos_adicionales = this.datosCliente.datos_adicionales
        }else{
          this.datosFacturacion.datos_adicionales = ''
          this.paso1Correcto = false
        }
        if (this.paso1Correcto == true){
          // prosigo al paso 2
          this.pasoActual = 2
        }else{
          this.msjErrorCompra = 'Estimado Cliente, tiene sus datos personales incompletos. Puede hacerlo desde la sección "Mi Cuenta".'
          this.errorCompra = true
          // lo mando a completar sus datos personales
          this.modificaDatosPersonales = true
        }
      }else{
        // no tiene datos personales, debe iniciar sesión y completarlos si o si
        this.pasoActual = 1
        // indico que no va a poder avanzar en la compra hasta que no complete sus datos personales
        /*this.msjErrorCompra = 'Estimado Cliente, para continuar con su compra debe Iniciar Sesión en la sección "Mi Cuenta" y completar sus Datos Personales. Si ya inició sesión, por favor dirijase a la sección "Mi Cuenta" y luego a "Mis Datos" para completar sus Datos Personales.'
        this.errorCompra = true*/
        // que pase a completar sus datos personales
        this.modificaDatosPersonales = true
      }
    },
    siguientePaso(paso){
      if (parseInt(paso) === 3){
        // domicilio de entrega, estoy en el paso 2 y quiero pasar al 3
        if (this.envioDomicilio == true){
          if (this.codigoPostalEntrega.length > 0 && Object.keys(this.selectedProvinciaEntrega).length > 0 &&
          this.localidadEntrega.length > 0 && this.calleEntrega.length > 0 && this.calleNumeroEntrega.length > 0 &&
          this.datosAdiconalesEntrega.length > 0){
            // doy por finalizado el paso 2
            this.preFinPaso2 = true
            // quito los posibles errores que pude haber tenido
            this.errorPaso2 = false
            // completo la info para la vista del cliente
            this.direccionEnvioDomicilio = this.completarAsteriscos(this.calleEntrega, 3) + ' N° ' + this.calleNumeroEntrega + ' ' + this.completarAsteriscos(this.datosAdiconalesEntrega, 3)
            this.provinciaEnvioDomicilio = this.completarAsteriscos(this.localidadEntrega, 3) + ' - ' + this.completarAsteriscos(this.selectedProvinciaEntrega.provincia_nombre, 3) + ' - ' + this.codigoPostalEntrega
            // costo de envio
            let costo = 0
            let horas = 0
            if (Object.keys(this.selectedProvinciaEntrega).length > 0){
              // calculo el tiempo de envio
              let unaProvincia = this.costosEnvio.filter(element => element.provincia.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == this.selectedProvinciaEntrega.provincia_nombre.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
              if (unaProvincia.length > 0){
                costo = unaProvincia[0].precio
                horas = unaProvincia[0].entrega_rapida_hs
              }
            }
            // completo los datos de envio finales
            this.datosEntrega.envio_domicilio = this.envioDomicilio
            this.datosEntrega.retiro_local = this.retiroSucursal
            this.datosEntrega.provincia = this.selectedProvinciaEntrega.provincia_nombre
            this.datosEntrega.localidad = this.localidadEntrega
            this.datosEntrega.local_retiro = ''
            this.datosEntrega.costo_envio = costo
            this.datosEntrega.dias_entrega = parseInt(horas)/24
            this.datosEntrega.entrega_rapida = this.entregaRapida
            this.datosEntrega.horas_entrega = horas
            this.datosEntrega.codigo_postal = this.codigoPostalEntrega
            this.datosEntrega.calle = this.calleEntrega
            this.datosEntrega.calle_numero = this.calleNumeroEntrega
            this.datosEntrega.datos_adicionales = this.datosAdiconalesEntrega
            // procedo al paso 3
            this.pasoActual = paso
          }else{
            this.preFinPaso2 = false
            this.msjErrorPaso2 = 'Debe Completar la Información de Entrega para continuar con su Compra'
            this.errorPaso2 = true
          }
        }else{
          if (Object.keys(this.selectedProvinciaRetiro).length > 0 && Object.keys(this.selectedLocalRetiro).length > 0){
            // limpio los posibles errores que pude haber tenido
            this.errorPaso2 = false
            // indico fin de paso 2
            this.preFinPaso2 = true
            // guardo los datos de entrega
            this.datosEntrega.envio_domicilio = this.envioDomicilio
            this.datosEntrega.retiro_local = this.retiroSucursal
            this.datosEntrega.provincia = this.selectedProvinciaRetiro.provincia_nombre
            this.datosEntrega.localidad = ''
            this.datosEntrega.local_retiro = this.selectedLocalRetiro.direccion
            this.datosEntrega.costo_envio = '0'
            this.datosEntrega.dias_entrega = 1
            this.datosEntrega.entrega_rapida = this.entregaRapida
            this.datosEntrega.horas_entrega = 24
            this.datosEntrega.codigo_postal = ''
            this.datosEntrega.calle = ''
            this.datosEntrega.calle_numero = ''
            this.datosEntrega.datos_adicionales = ''
            // procedo al paso 3
            this.pasoActual = paso
          }else{
            this.preFinPaso2 = false
            this.msjErrorPaso2 = 'Debe seleccionar la Provincia y Sucursal de retiro para continuar con su Compra'
            this.errorPaso2 = true
          }
        }
      }
    },
    async modificarPaso(paso, opcion){
      if (parseInt(paso) === 1){
        if (parseInt(opcion) === 1){
          this.showLoading()
          await this.checkActiveUser({paso: paso, opcion: 4})
        }else{
          // es un cliente sin cuenta en nuestro sistema
          this.completarDatosPersonales = true
          // indico paso 1 incompleto
          this.paso1Correcto = false
          // vuelvo al paso 1
          this.pasoActual = 1
          // oculto el paso 2 y 3
          this.preFinPaso2 = false
        }
      }else if (parseInt(paso) === 2){
        // modificar paso 2
        if (parseInt(opcion) === 2){
          this.modificaDomicilioEntrega = true
        }else{
          this.costoEnvio = 0
          this.tituloEnvioDomicilio = 'Envio Domicilio'
          this.metodoEntregaSeleccionado = false
          this.modificaDomicilioEntrega = false
          this.envioDomicilio = false
          this.retiroSucursal = false
        }
        this.showLoading()
        await this.checkActiveUser({paso: paso, opcion: 4})
      }else{

      }
    },
    volverAlCarrito(){
      router.push({name: 'CarritoDetalle'})
    },
    formatoEmailValido(email){
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email.length > 0){
        if (re.test(email) == false){
          return false
        }else{
          return true
        }
      }else{
        return false
      }
    },
    async validarEmail(){
      this.errorCompra = false
      if (this.email.length > 0){
        if (this.formatoEmailValido(this.email)){
          // email valido, es un cliente que tiene registro en el ecommerce?
          this.showLoading()
          // comentado el 01-08-2023 por MMURILLO
          //await this.existsUser({email: this.email, opcion: 2})
          let usuarioPeticion = await this.$store.dispatch('usuarios/existsUser', {email: this.email})
          this.closeLoading()
          if (usuarioPeticion.resultado == 0){
            // hubo un problema para obtener los datos del cliente
            this.dialogAviso.titulo = 'Validando Email'
            this.dialogAviso.mensaje = dialogAviso.msj
            this.dialogAviso.activo = true
            return
          }
          if (usuarioPeticion.resultado == 1 && usuarioPeticion.existe == 1 && (usuarioPeticion.cliente == null || usuarioPeticion.cliente == undefined || usuarioPeticion.cliente.length == 0)){
            // error raro, no deberia pasar, se deja por si acaso
            this.dialogAviso.titulo = 'Validando Email'
            this.dialogAviso.mensaje = 'Ocurrió un problema temporal al buscar el email en nuestro sistema. Por favor, intente nuevamente más tarde.'
            this.dialogAviso.activo = true
            return
          }
          // obtuve los datos del cliente, evaluo la respuesta
          if (usuarioPeticion.resultado == 1 && usuarioPeticion.existe == 0){
            // no existe en la base de datos, indico que los datos no se van a autocompletar
            this.existsUserSystem = false
            // que proceda a completar sus datos personales
            this.completarDatosPersonales = true
            // pregunto si tengo Codigo Postal en la seccion "Enviar A"
            if (localStorage.getItem('enviarA')){
              let enviarA = JSON.parse(localStorage.getItem('enviarA'))
              // seteo codigo postal
              this.codigoPostalFacturacion = enviarA.codigoPostal
              // busco la provincia
              let unaProvincia = this.provincias.filter(element => parseInt(element.provincia_id) === parseInt(enviarA.provinciaId))
              if (unaProvincia.length > 0){
                this.selectedProvinciaFacturacion = unaProvincia[0]
              }
            }
            return
          }
          // si llego hasta aqui, es porque obtuve el cliente y si existe en nuestro sistema, evaluo los datos personales
          let clienteData = usuarioPeticion.cliente[0]
          if (clienteData.hasOwnProperty('datos_personales')){
            // obtengo el uid
            if (!clienteData.datos_personales.hasOwnProperty('uid')) clienteData.datos_personales.uid = usuarioPeticion.uId
            if (clienteData.datos_personales.hasOwnProperty('uid') && (clienteData.datos_personales.uid == null || clienteData.datos_personales.uid == undefined || clienteData.datos_personales.uid.toString().length == 0)){
              // no se pudo determinar el uId del cliente
              this.dialogAviso.titulo = 'Validando Email'
              this.dialogAviso.mensaje = 'Ocurrió un problema temporal al buscar el email en nuestra base de datos. Por favor, intente nuevamente más tarde.'
              this.dialogAviso.activo = true
              return
            }
            // asigno el uid
            this.uidCliente = clienteData.datos_personales.uid
            // prosigo, indico el usuario existe
            this.existsUserSystem = true
            // tiene cuenta en celushop y tiene datos personales
            this.tituloValidarEmail = 'Usuario Existente'
            this.msjValidarEmail = 'El email ingresado ya se encuentra registrado, el formulario se completará automáticamente con tus datos'
            this.dialogValidarEmail = true
            // ya no necesito completar datos personales
            this.completarDatosPersonales = false
            this.datosCliente = clienteData.datos_personales
            if (this.datosCliente.hasOwnProperty('apellidos') && this.datosCliente.hasOwnProperty('nombre')){
              this.nombreApellido = this.completarAsteriscos(this.datosCliente.nombre, 3) + ' ' + this.completarAsteriscos(this.datosCliente.apellidos, 3)
            }
            if (this.datosCliente.hasOwnProperty('telefono')){
              this.telefonoCliente = this.completarAsteriscos(this.datosCliente.telefono, 5)
            }
          }else{
            // no tengo datos personales, que pase a completar todos sus datos personales
            // tiene cuenta en celushop pero no tiene datos personales, me quedo en paso 1
            this.pasoActual = 1
            // indico el usuario existe
            this.existsUserSystem = true
            // indico que no va a poder avanzar en la compra hasta que no complete sus datos personales
            this.msjErrorCompra = 'Estimado Cliente, para continuar con su compra debe Iniciar Sesión en la sección "Mi Cuenta" y completar sus Datos Personales.'
            this.errorCompra = true
            // abro el modal login
            this.showLoginModal = true
            // texto del modal del inicio de sesion
            this.msjInicioSesion = 'Estimado Cliente, su correo ya existe. Por favor, inicie sesión y complete sus Datos Personales.'
            // ya no necesito completar datos personales
            this.completarDatosPersonales = false
          }
        }else{
          // email no valido
          this.msjErrorCompra = 'Por favor, ingrese un Email válido para continuar'
          this.errorCompra = true
        }
      }else{
        this.msjErrorCompra = 'Es necesario que ingrese su Email para continuar'
        this.errorCompra = true
      }
    },
    completarDatos(){
      // indico que se van a completar los datos por usuario existente en la base de datos
      this.existsUserSystem = true
      // completo los datos de facturacion
      if (Object.keys(this.datosCliente).length > 0){
        // indico paso 1 completo
        this.paso1Correcto = true
        // controlo la consistencia de los datos
        this.datosFacturacion.email = this.email
        if (this.datosCliente.hasOwnProperty('apellidos')){
          this.datosFacturacion.apellido = this.datosCliente.apellidos
        }else{
          this.datosFacturacion.apellido = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('nombre')){
          this.datosFacturacion.nombre = this.datosCliente.nombre
        }else{
          this.datosFacturacion.nombre = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('documento')){
          this.datosFacturacion.documento = this.datosCliente.documento
        }else{
          this.datosFacturacion.documento = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('fecha_nacimiento')){
          this.datosFacturacion.fecha_nacimiento = this.datosCliente.fecha_nacimiento
        }else{
          this.datosFacturacion.fecha_nacimiento = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('genero')){
          this.datosFacturacion.genero = this.datosCliente.genero
        }else{
          this.datosFacturacion.genero = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('generoPersonalizado')){
          this.datosFacturacion.genero_personalizado = this.datosCliente.generoPersonalizado
        }else{
          this.datosFacturacion.genero_personalizado = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('telefono')){
          this.datosFacturacion.telefono = this.datosCliente.telefono
        }else{
          this.datosFacturacion.telefono = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('localidad')){
          if (this.datosCliente.localidad.hasOwnProperty('localidad')){
            this.datosFacturacion.localidad = this.datosCliente.localidad.localidad
          }else{
            this.datosFacturacion.localidad = ''
            this.paso1Correcto = false
          }
        }else{
          this.datosFacturacion.localidad = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('provincia')){
          this.datosFacturacion.provincia = this.datosCliente.provincia
        }else{
          this.datosFacturacion.provincia = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('codigo_postal')){
          this.datosFacturacion.codigo_postal = this.datosCliente.codigo_postal
        }else{
          this.datosFacturacion.provincia = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('calle')){
          this.datosFacturacion.calle = this.datosCliente.calle
        }else{
          this.datosFacturacion.calle = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('calle_numero')){
          this.datosFacturacion.calle_numero = this.datosCliente.calle_numero
        }else{
          this.datosFacturacion.calle_numero = ''
          this.paso1Correcto = false
        }
        if (this.datosCliente.hasOwnProperty('datos_adicionales')){
          this.datosFacturacion.datos_adicionales = this.datosCliente.datos_adicionales
        }else{
          this.datosFacturacion.datos_adicionales = ''
          this.paso1Correcto = false
        }
        if (this.paso1Correcto == true){
          // prosigo al paso 2
          this.pasoActual = 2
        }else{
          this.msjErrorCompra = 'Estimado Cliente, tiene sus datos personales incompletos. Por favor inicie sesión en la sección "Mi Cuenta" para completarlos.'
          this.errorCompra = true
          // abro el modal de registracion
          this.showLoginModal = true
          // asigno el texto del login
          this.msjInicioSesion = 'Estimado Cliente, su correo ya existe. Por favor inicie sesión y complete sus Datos Personales'
        }
        // cierro el modal
        this.dialogValidarEmail = false
      }else{
        // cierro el modal
        this.dialogValidarEmail = false
        // no tiene datos personales, debe iniciar sesión y completarlos si o si
        this.pasoActual = 1
        // indico que no va a poder avanzar en la compra hasta que no complete sus datos personales
        this.msjErrorCompra = 'Estimado Cliente, para continuar con su compra debe Iniciar Sesión en la sección "Mi Cuenta" y completar sus Datos Personales.'
        this.errorCompra = true
      }
    },
    enviarADomicilio(){
      if (this.provincias.length > 0){
        // tengo los costos de envío, tengo datos de cliente?
        if (Object.keys(this.datosCliente).length > 0){
          // se autocompleta, no necesito confirmar
          this.confirmarPreFin2 = false
          // pongo el paso 2 como completado en principio
          this.preFinPaso2 = true
          // autocompletar
          if (this.datosCliente.hasOwnProperty('provincia')){
            if (this.datosCliente.provincia.length > 0){
              // tiempo de entrega del pedido
              // la provincia de entrega
              let otraProvincia = this.provincias.filter(element => element.provincia_nombre.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == this.datosCliente.provincia.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
              if (otraProvincia.length > 0){
                this.selectedProvinciaEntrega = otraProvincia[0]
              }else{
                // no consegui la provincia de entrega, que pase a completar
                this.selectedProvinciaEntrega = {}
                this.preFinPaso2 = false
              }
            }else{
              this.selectedProvinciaEntrega = {}
              this.preFinPaso2 = false
            }
          }else{
            this.selectedProvinciaEntrega = {}
            this.preFinPaso2 = false
          }
          // codigo postal de entrega
          if (this.datosCliente.hasOwnProperty('codigo_postal')){
            if (this.datosCliente.codigo_postal.length > 0){
              this.codigoPostalEntrega = this.datosCliente.codigo_postal
            }else{
              // sin codigo postal, que pase a completar
              this.codigoPostalEntrega = ''
              this.preFinPaso2 = false
            }
          }else{
            this.codigoPostalEntrega = ''
            this.preFinPaso2 = false
          }
          // localidad
          if (this.datosCliente.hasOwnProperty('localidad')){
            if (this.datosCliente.localidad.hasOwnProperty('localidad')){
              if (this.datosCliente.localidad.localidad.length > 0){
                this.localidadEntrega = this.datosCliente.localidad.localidad
              }else{
                // sin localidad, que pase a completar
                this.localidadEntrega = ''
                this.preFinPaso2 = false
              }
            }else{
              // sin localidad, que pase a completar
              this.localidadEntrega = ''
              this.preFinPaso2 = false
            }
          }else{
            // sin localidad, que pase a completar
            this.localidadEntrega = ''
            this.preFinPaso2 = false
          }
          // calle
          if (this.datosCliente.hasOwnProperty('calle')){
            if (this.datosCliente.calle.length > 0){
              this.calleEntrega = this.datosCliente.calle
            }else{
              // sin calle, que pase a completar
              this.calleEntrega = ''
              this.preFinPaso2 = false
            }
          }else{
            this.calleEntrega = ''
            this.preFinPaso2 = false
          }
          // numero de calle
          if (this.datosCliente.hasOwnProperty('calle_numero')){
            if (this.datosCliente.calle_numero.length > 0){
              this.calleNumeroEntrega = this.datosCliente.calle_numero
            }else{
              // sin numero de calle, que pase a completar
              this.calleNumeroEntrega = ''
              this.preFinPaso2 = false
            }
          }else{
            this.calleNumeroEntrega = ''
            this.preFinPaso2 = false
          }
          // datos adicionales
          if (this.datosCliente.hasOwnProperty('datos_adicionales')){
            if (this.datosCliente.datos_adicionales.length > 0){
              this.datosAdiconalesEntrega = this.datosCliente.datos_adicionales
            }else{
              this.preFinPaso2 = false
              this.datosAdiconalesEntrega = ''
            }
          }else{
            this.preFinPaso2 = false
            this.datosAdiconalesEntrega = ''
          }
          // se termina de completar automaticamente los datos?
          if (this.preFinPaso2 == true){
            // costo de envio
            let costo = 0
            let horas = 0
            if (Object.keys(this.selectedProvinciaEntrega).length > 0){
              // calculo el tiempo de envio
              let unaProvincia = this.costosEnvio.filter(element => element.provincia.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == this.selectedProvinciaEntrega.provincia_nombre.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
              if (unaProvincia.length > 0){
                costo = unaProvincia[0].precio
                horas = unaProvincia[0].entrega_rapida_hs
              }
            }
            // datos personales completos, completa los datos de entrega
            this.direccionEnvioDomicilio = this.completarAsteriscos(this.calleEntrega, 3) + ' N° ' + this.calleNumeroEntrega + ' ' + this.completarAsteriscos(this.datosAdiconalesEntrega, 3)
            this.provinciaEnvioDomicilio = this.completarAsteriscos(this.localidadEntrega, 3) + ' - ' + this.completarAsteriscos(this.selectedProvinciaEntrega.provincia_nombre, 3) + ' - ' + this.codigoPostalEntrega
            // completo los datos de entrega
            this.datosEntrega.envio_domicilio = this.envioDomicilio
            this.datosEntrega.retiro_local = this.retiroSucursal
            this.datosEntrega.provincia = this.selectedProvinciaEntrega.provincia_nombre
            this.datosEntrega.localidad = this.localidadEntrega
            this.datosEntrega.local_retiro = ''
            this.datosEntrega.costo_envio = costo
            this.datosEntrega.dias_entrega = parseInt(horas)/24
            this.datosEntrega.entrega_rapida = this.entregaRapida
            this.datosEntrega.horas_entrega = horas
            this.datosEntrega.codigo_postal = this.codigoPostalEntrega
            this.datosEntrega.calle = this.calleEntrega
            this.datosEntrega.calle_numero = this.calleNumeroEntrega
            this.datosEntrega.datos_adicionales = this.datosAdiconalesEntrega
            // prosigo con el paso 3
            this.pasoActual = 3

          }else{
            // es usuario existente?
            if (this.existsUserSystem == false){
              // datos personales incompletos, debe completar los datos de entrega
              this.confirmarPreFin2 = true
              // proceder a llenar campos, abro modal de Codigos Postales?
              if (localStorage.getItem('enviarA')){
                // uso los datos de envio de localStorage
                let infoCliente = JSON.parse(localStorage.getItem('enviarA'))
                this.codigoPostalEntrega = infoCliente.codigoPostal
                if (infoCliente.provinciaNombre.length > 0){
                  let unaProvincia = this.provincias.filter(element => element.provincia_nombre.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == infoCliente.provinciaNombre.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
                  if (unaProvincia.length > 0){
                    this.selectedProvinciaEntrega = unaProvincia[0]
                    // calculo el tiempo de entrega
                    let otraProvincia = this.costosEnvio.filter(element => element.provincia.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == this.selectedProvinciaEntrega.provincia_nombre.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
                    if (otraProvincia.length > 0){
                      this.horasHabiles = otraProvincia[0].entrega_rapida_hs
                      this.costoEnvio = parseFloat(unaProvincia[0].precio).toFixed(2)
                      let unaCadena = 'Envio a Domicilio - $' + parseInt(this.costoEnvio)
                      this.tituloEnvioDomicilio = unaCadena
                    }else{
                      // no encontre la provincia para enviar el pedido, que pase a completar
                      this.horasHabiles = ''
                      this.tituloEnvioDomicilio = 'Envio a Domicilio'
                    }
                  }else{
                    this.selectedProvinciaEntrega = {}
                    this.horasHabiles = ''
                    this.tituloEnvioDomicilio = 'Envio a Domicilio'
                  }
                }
              }else{
                // abro modal
                this.$emit('setDomicilioEntrega', true)
              }
            }else{
              if (this.respStatusUser.existeUsuario == false){
                this.metodoEntregaSeleccionado = false
                this.msjErrorPaso2 = 'Sus datos de entrega a domicilio se encuentran incompletos. Para completarlos por favor inicie sesión en la sección "Mi Cuenta".'
                this.errorPaso2 = true
              }else{
                // habilito botones del paso 2
                this.confirmarPreFin2 = true
              }
            }
          }
        }else{
          // es un usuario registrado en nuestro sistema?
          if (this.existsUserSystem == false){
            // usuario no registrado
            this.confirmarPreFin2 = true
            // proceder a llenar campos, abro modal de Codigos Postales?
            if (localStorage.getItem('enviarA')){
              // uso los datos de envio de localStorage
              let infoCliente = JSON.parse(localStorage.getItem('enviarA'))
              this.codigoPostalEntrega = infoCliente.codigoPostal
              if (infoCliente.provinciaNombre.length > 0){
                let unaProvincia = this.provincias.filter(element => element.provincia_nombre.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == infoCliente.provinciaNombre.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
                if (unaProvincia.length > 0){
                  this.selectedProvinciaEntrega = unaProvincia[0]
                }else{
                  this.selectedProvinciaEntrega = {}
                  this.horasHabiles = ''
                  this.tituloEnvioDomicilio = 'Envio a Domicilio'
                }
              }
            }else{
              // el codigo postal y la provincia de facturacion son no nulos?
              if (this.codigoPostalFacturacion.length > 0 && Object.keys(this.selectedProvinciaFacturacion).length > 0){
                this.codigoPostalEntrega = this.codigoPostalFacturacion
                this.selectedProvinciaEntrega = this.selectedProvinciaFacturacion
              }else{
                this.selectedProvinciaEntrega = {}
                this.horasHabiles = ''
                this.tituloEnvioDomicilio = 'Envio a Domicilio'
              }
            }
          }else{
            // cliente con cuenta en nuestro sistema sin datos personales, pero que no inicio sesion
            this.metodoEntregaSeleccionado = false
            this.msjErrorPaso2 = 'Para proseguir debe iniciar sesión en la sección "Mi Cuenta"'
            this.errorPaso2 = true
          }
        }
      }else{
        this.showLoading()
        this.llamarEnvioDomicilio = true
      }
    },
    completarAsteriscos(cadena, cantCaracteres){
      if (cadena.length > 0){
        let separarCadena = cadena.split(' ')
        let cadenaFinal = ''
        if (separarCadena.length == 1){
          if (parseInt(separarCadena[0].length) > parseInt(cantCaracteres)){
            cadenaFinal = separarCadena[0].substring(0, cantCaracteres) + separarCadena[0].substring(parseInt(cantCaracteres), separarCadena[0].length).replace(/./g, '*')
          }else{
            cadenaFinal = separarCadena[0].substring(0, cantCaracteres)
          }
        }else{
          // tiene espacios
          for (let id in separarCadena){
            if (parseInt(id) === 0){
              if (parseInt(separarCadena[id].length) > parseInt(cantCaracteres)){
                cadenaFinal = separarCadena[id].substring(0, cantCaracteres) + separarCadena[id].substring(parseInt(cantCaracteres), separarCadena[id].length).replace(/./g, '*')
              }else{
                cadenaFinal = separarCadena[id].substring(0, cantCaracteres)
              }
            }else{
              cadenaFinal = cadenaFinal + ' ' + separarCadena[id].replace(/./g, '*')
            }
          }
        }
        return cadenaFinal
      }else{
        return ''
      }
    },
    retiroEnSucursal(){
      // tengo el carrito del cliente
      if (this.carritoCliente.length > 0){
        // formo mi listado de articulos con sucursales
        let articulosSucursales = []
        for (let id in this.carritoCliente){
          let unArticulo = {
            keyFirebase: this.carritoCliente[id].articuloKey,
            sucursales: [] // sucursales que tienen stock suficiente para la demanda del cliente PARA UN ARTICULO
          }
          articulosSucursales.push(unArticulo)
        }
        // debo obtener las sucursales donde TODOS los articulos tengan stock suficiente para la cantidad demandada del cliente
        for (let jd in this.carritoCliente){
          // debo sacar el stock total por articulo, sumar todos los campos cantidad de stock
          for (let idd in this.provincias){
            let sumaStock = 0
            // stock total de la sucursal central
            let listadoSucursal = this.carritoCliente[jd].stock.stockSucursales.filter(element => 
              parseInt(element.sucursal_codigo) === parseInt(this.provincias[idd].sucursal_codigo) && parseInt(element.cantidad) > 0
            )
            if (listadoSucursal.length > 0){
              // me quedo solo con las que tienen stock
              //let stockSucursal = listadoSucursal.filter(element => parseInt(element.cantidad) > 0)
              if (listadoSucursal.length > 0){
                for (let i in listadoSucursal){
                  sumaStock = sumaStock + parseInt(listadoSucursal[i].cantidad)
                }
              }
            }
            // stock total de los locales
            for (let i in this.carritoCliente[jd].stock.stockSucursales){
              if (this.carritoCliente[jd].stock.stockSucursales[i].hasOwnProperty('stockLocales')){
                // es un array?
                if (Array.isArray(this.carritoCliente[jd].stock.stockSucursales[i].stockLocales)){
                  let listadoLocales = this.carritoCliente[jd].stock.stockSucursales[i].stockLocales.filter(element =>
                    parseInt(element.sucursal_codigo) === parseInt(this.provincias[idd].sucursal_codigo)
                  )
                  if (listadoLocales.length > 0){
                    // me quedo solo con las que tienen stock
                    let stockDeLocales = listadoLocales.filter(element => parseInt(element.cantidad) > 0)
                    if (stockDeLocales.length > 0){
                      for (let j in stockDeLocales){
                        sumaStock = sumaStock + parseInt(stockDeLocales[j].cantidad)
                      }
                    }
                  }
                }
              }
            }
            // en sumaStock tengo el total de stock para una sucursal de un articulo
            if (parseInt(sumaStock) > 0 && parseInt(sumaStock) >= parseInt(this.carritoCliente[jd].cantidad)){
              // agrego la sucursal al articulo
              for (let posAux in articulosSucursales){
                if (this.carritoCliente[jd].articuloKey == articulosSucursales[posAux].keyFirebase){
                  articulosSucursales[posAux].sucursales.push(this.provincias[idd])
                }
              }
            }
          }
        }
        // ahora cruzo los datos para ver las sucursales en comun
        if (articulosSucursales.length == 1){
          this.provinciasRetiro = articulosSucursales[0].sucursales
        }else{
          if (articulosSucursales.length > 1){
            // tomo la posicion 0 y los comparo contra todos
            for (let id in articulosSucursales[0].sucursales){
              let cont = 0
              for (let jd in articulosSucursales){
                let unListado = articulosSucursales[jd].sucursales.filter(element => 
                  parseInt(element.sucursal_codigo) === parseInt(articulosSucursales[0].sucursales[id].sucursal_codigo)
                )
                if (unListado.length > 0){
                  cont = cont + 1
                }
              }
              if (parseInt(cont) === parseInt(articulosSucursales.length)){
                this.provinciasRetiro.push(articulosSucursales[0].sucursales[id])
              }
            }
          }
        }
      }else{
        // sin items en el carrito, salgo de la venta
        router.push({name: 'Home'})
      }
    },
    async getProvinciaCodigoPostal(opcion){
      this.showLoading()
      if (parseInt(opcion) === 1){
        await this.getDatosEntregaXCodPostal({codigoPostal: this.codigoPostalEntrega, opcion: opcion})
      }else{
        await this.getDatosEntregaXCodPostal({codigoPostal: this.codigoPostalFacturacion, opcion: opcion})
      }
    },
    buscarCodigoPostal(){
      this.codPostalParaEntrega = 1
      this.dialogCP = true
    },
    buscarCodigoPostalFac(){
      this.codPostalParaEntrega = 2
      this.dialogCP = true
    },
    controlFecha(fecha){
      var fech = moment(fecha, 'DD/MM/YYYY' )
      if (!moment(fech).isValid() ){
        this.fechaNacimientoFacturacion = ''
        Swal.fire({title: 'Fecha inválida. Ejemplo de fecha válida: 01/02/1988.',icon: 'warning'})
        return
      }else{
        let añoActual = moment().year()
        let añoUsuario = fech.year()
        if (parseInt(añoUsuario) > parseInt(añoActual)){
          Swal.fire({title: 'El año ingresado no debe sobrepasar el año actual.',icon: 'warning'})
          this.fechaNacimientoFacturacion = ''
        }else{
          // es mayor de 12 años?
          if ((parseInt(añoActual) - parseInt(añoUsuario)) < 12){
            Swal.fire({title: 'Debe ser mayor de 12 años para poder realizar la compra',icon: 'warning'})
            this.fechaNacimientoFacturacion = ''
          }else{
            this.fechaNacimientoFacturacion = fech.format('DD/MM/YYYY')
          }
        }
      }
    },
    cerrarModalCP(){
      this.dialogCP = false
    },
    setearCodigoPostal(datos){
      if (parseInt(this.codPostalParaEntrega) === 1){
        // es para entrega
        this.selectedProvinciaEntrega = datos.provincia
        this.codigoPostalEntrega = datos.localidad.codigo_postal
        this.localidadEntrega = datos.localidad.localidad_nombre
      }else{
        // es para facturacion
        this.selectedProvinciaFacturacion = datos.provincia
        this.codigoPostalFacturacion = datos.localidad.codigo_postal
        this.localidadFacturacion = datos.localidad.localidad_nombre
      }
    },
    async obtenerLocales(){
      this.selectedLocalRetiro = {}
      if (typeof this.selectedProvinciaRetiro === 'object'){
        if (Object.keys(this.selectedProvinciaRetiro).length > 0){
          this.showLoading()
          if (this.sucursalesRetiro.length > 0){
            // ya tengo los locales
            this.closeLoading()
            let unListadoLocales = this.sucursalesRetiro.filter(element => 
              element.provincia.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == this.selectedProvinciaRetiro.provincia_nombre.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            )
            if (unListadoLocales.length > 0){
              this.localesRetiro = Object.values(unListadoLocales[0].locales).filter(element => element.estado == undefined || element.estado == null || element.estado == 1)
            }else{
              this.localesRetiro = []
            }
          }else{
            // los pido en firebase
            await this.getSucursales()
          }
        }
      }
    },
    async noSoyYo(){
      // deslogueo al usuario
      await this.logout()
      // refresco la pagina
      location.reload()
    },
    setearDatosEntrega(){
      /*let datosEntrega = JSON.parse(localStorage.getItem('enviarA'))
      this.provinciaNombre = datosEntrega.provinciaNombre
      this.codigoPostal = datosEntrega.codigoPostal*/
    },
    refrescarPage(){
      // refresco la pagina
      location.reload()
    },
    confirmarDatosPersonales(){
      this.errorPaso1 = false
      // controlo campos
      if (this.documentoFacturacion.length > 0 && this.apellidosFacturacion.length > 0 &&
      this.nombresFacturacion.length > 0 && this.telefonoFacturacion.length > 0 &&
      this.fechaNacimientoFacturacion.length > 0 && Object.keys(this.generoFacturacion).length > 0 &&
      this.codigoPostalFacturacion.length > 0 && Object.keys(this.selectedProvinciaFacturacion).length > 0 &&
      this.localidadFacturacion.length > 0 && this.calleFacturacion.length > 0 &&
      this.calleNumeroFacturacion.length > 0){
        // datos completos, cierro la entrada de datos personales
        this.completarDatosPersonales = false
        // indico paso 1 completo
        this.paso1Correcto = true
        // completo los datos de la vista
        this.nombreApellido = this.completarAsteriscos(this.nombresFacturacion, 3) + ' ' + this.completarAsteriscos(this.apellidosFacturacion, 3)
        this.telefonoCliente = this.completarAsteriscos(this.telefonoFacturacion, 5)
        // heredo los datos para entrega
        this.codigoPostalEntrega = this.codigoPostalFacturacion
        this.selectedProvinciaEntrega = this.selectedProvinciaFacturacion
        this.localidadEntrega = this.localidadFacturacion
        this.calleEntrega = this.calleFacturacion
        this.calleNumeroEntrega = this.calleNumeroFacturacion
        this.datosAdiconalesEntrega = this.datosAdicionalesFacturacion
        // completo mi objeto de Datos de Facturacion Final
        this.datosFacturacion.email = this.email
        this.datosFacturacion.apellido = this.apellidosFacturacion
        this.datosFacturacion.nombre = this.nombresFacturacion
        this.datosFacturacion.documento = this.documentoFacturacion
        this.datosFacturacion.fecha_nacimiento = this.fechaNacimientoFacturacion
        this.datosFacturacion.genero = this.generoFacturacion.nombre
        this.datosFacturacion.genero_personalizado = this.generoPersonalizadoFacturacion
        this.datosFacturacion.telefono = this.telefonoFacturacion
        this.datosFacturacion.localidad = this.localidadFacturacion
        this.datosFacturacion.provincia = this.selectedProvinciaFacturacion.provincia_nombre
        this.datosFacturacion.codigo_postal = this.codigoPostalFacturacion
        this.datosFacturacion.calle = this.calleFacturacion
        this.datosFacturacion.calle_numero = this.calleNumeroFacturacion
        this.datosFacturacion.datos_adicionales = this.datosAdicionalesFacturacion
        // sigo con el siguiente paso
        this.pasoActual = 2
      }else{
        // debe terminar de completar los campos
        this.msjErrorPaso1 = 'Debe terminar de completar sus Datos Personales para seguir con su Compra'
        this.errorPaso1 = true
      }
    },
    setearModalLogin(valor){
      this.showLoginModal = valor
      this.msjInicioSesion = ''
    },
    onScroll(){
      this.scrollInvoked++
    },
    verArticuloDetalle(articulo){
      // abrir en nueva pestaña
      let routeData = this.$router.resolve({name: 'ArticuloDetalle', params: {keyFirebase: articulo.articuloKey}});
      window.open(routeData.href, '_blank');
    },
    isMovil(){
			var mobile = {
        Android: function() {
          return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
          return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
          return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
          return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
          return (mobile.Android() || mobile.BlackBerry() || mobile.iOS() || mobile.Opera() || mobile.Windows());
        }
      }
      // controlo la salida de mobile
      if (Array.isArray(mobile.any())){
        if (mobile.any().length > 0){
          this.movil = true
        }
      }
    },
    setModalAviso(value){
      this.dialogAviso.activo = value
      if (this.dialogAviso.activo == false){
        this.dialogAviso.titulo = ''
        this.dialogAviso.mensaje = ''
      }
    }
  },
  watch: {
    articulosCarrito: function(){
      this.carritoCliente = this.articulosCarrito
      if (this.carritoCliente.length === 0){
        // no tiene items el carrito, direccionar al index
        router.push({name: 'Home'})
      }
      // control de stock disponible
      let cont = 0
      for (let id in this.carritoCliente){
        if (parseInt(this.carritoCliente[id].stock.stockTotal) === 0){
          cont = cont + 1
        }
      }
      if (parseInt(cont) > 0){
        // no hay stock para todos los articulos del usuario
        router.push({name: 'Home'})
      }
    },
    respExistsUser: function(){
      this.closeLoading()
      if (Object.keys(this.respExistsUser).length > 0){
        // llego info?
        if (parseInt(this.respExistsUser.resultado) === 1){
          // Obtengo el Uid
          if (this.respExistsUser.hasOwnProperty('datosCliente')){
            if (this.respExistsUser.datosCliente != null && this.respExistsUser.datosCliente != undefined){
              if (this.respExistsUser.datosCliente.hasOwnProperty('usuarioUid')){
                if (this.respExistsUser.datosCliente.usuarioUid.length > 0){
                  this.uidCliente = this.respExistsUser.datosCliente.usuarioUid
                }
              }
            }
          }
          // prosigo
          if (this.respExistsUser.datosCliente.nombre.length > 0){
            // indico el usuario existe
            this.existsUserSystem = true
            // tiene cuenta en celushop y tiene datos personales
            this.tituloValidarEmail = 'Usuario Existente'
            this.msjValidarEmail = 'El email ingresado ya se encuentra registrado, el formulario se completará automáticamente con tus datos'
            this.dialogValidarEmail = true
            // ya no necesito completar datos personales
            this.completarDatosPersonales = false
            this.datosCliente = this.respExistsUser.datosCliente
            if (this.datosCliente.hasOwnProperty('apellidos') && this.datosCliente.hasOwnProperty('nombre')){
              this.nombreApellido = this.completarAsteriscos(this.datosCliente.nombre, 3) + ' ' + this.completarAsteriscos(this.datosCliente.apellidos, 3)
            }
            if (this.datosCliente.hasOwnProperty('telefono')){
              this.telefonoCliente = this.completarAsteriscos(this.datosCliente.telefono, 5)
            }
          }
        }else if (parseInt(this.respExistsUser.resultado) === 2){
          // tiene cuenta en celushop pero no tiene datos personales, me quedo en paso 1
          this.pasoActual = 1
          // indico el usuario existe
          this.existsUserSystem = true
          // indico que no va a poder avanzar en la compra hasta que no complete sus datos personales
          this.msjErrorCompra = 'Estimado Cliente, para continuar con su compra debe Iniciar Sesión en la sección "Mi Cuenta" y completar sus Datos Personales.'
          this.errorCompra = true
          // abro el modal login
          this.showLoginModal = true
          // texto del modal del inicio de sesion
          this.msjInicioSesion = 'Estimado Cliente, su correo ya existe. Por favor, inicie sesión y complete sus Datos Personales.'
          // ya no necesito completar datos personales
          this.completarDatosPersonales = false
        }else if (parseInt(this.respExistsUser.resultado) === 3){
          // indico que los datos no se van a autocompletar
          this.existsUserSystem = false
          // que proceda a completar sus datos personales
          this.completarDatosPersonales = true
          // pregunto si tengo Codigo Postal en la seccion "Enviar A"
          if (localStorage.getItem('enviarA')){
            let enviarA = JSON.parse(localStorage.getItem('enviarA'))
            // seteo codigo postal
            this.codigoPostalFacturacion = enviarA.codigoPostal
            // busco la provincia
            let unaProvincia = this.provincias.filter(element => parseInt(element.provincia_id) === parseInt(enviarA.provinciaId))
            if (unaProvincia.length > 0){
              this.selectedProvinciaFacturacion = unaProvincia[0]
            }
          }
        }else{
          // error al buscar los datos del usuario
        }
      }
    },
    datosUsuario: function(){
      if (Object.keys(this.datosUsuario).length > 0){
        if (this.datosUsuario.hasOwnProperty('resultado')){
          if (parseInt(this.datosUsuario.resultado) === 1){
            // es nuevo registro o login normal?
            if (parseInt(this.datosUsuario.tipo) === 1){
              // login normal
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Inicio de Sesión',
                text: 'Su Inicio de Sesión fue exitoso, espere a ser redireccionado',
                showConfirmButton: false,
                timer: 3000
              })
            }else if (parseInt(this.datosUsuario.tipo) === 2){
              // registro
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Nuevo Registro',
                text: 'Su registro fue exitoso, espere a ser redireccionado',
                showConfirmButton: false,
                timer: 3000
              })
            }else{
              // hizo logout
              setTimeout(() => [location.reload()], 2000)
            }
          }else{
            if (parseInt(this.datosUsuario.tipo) === 1){
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Inicio de Sesión',
                text: this.datosUsuario.msj,
                showConfirmButton: false,
                timer: 3000
              })
            }else if (parseInt(this.datosUsuario.tipo) === 2){
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Nuevo Registro',
                text: this.datosUsuario.msj,
                showConfirmButton: false,
                timer: 3000
              })
            }else{
              // hizo logout
              setTimeout(() => [location.reload()], 2000)
            }
          }
        }
      }
    },
    envioDomicilio: function(){
      this.errorPaso1 = false
      if (this.envioDomicilio == true){
        this.errorPaso2 = false
        this.retiroSucursal = false
        this.preFinPaso2 = false
        this.metodoEntregaSeleccionado = true
        // reseteo lo de retiro en sucursal
        //this.confirmarPreFin2 = false
        this.selectedProvinciaRetiro = {}
        this.selectedLocalRetiro = {}
        // reseteo la provincia de entrega para que recalcule el costo de envio
        this.selectedProvinciaEntrega = {}
        this.enviarADomicilio()
      }
      if (this.envioDomicilio == false && this.retiroSucursal == false){
        this.metodoEntregaSeleccionado = false
      }
    },
    retiroSucursal: function(){
      this.errorPaso1 = false
      if (this.retiroSucursal == true){
        this.costoEnvio = 0
        this.tituloEnvioDomicilio = 'Envio Domicilio'
        this.errorPaso2 = false
        this.confirmarPreFin2 = false
        this.envioDomicilio = false
        this.preFinPaso2 = false
        this.metodoEntregaSeleccionado = true
        this.selectedProvinciaRetiro = {}
        this.selectedLocalRetiro = {}
        this.retiroEnSucursal()
      }
      if (this.envioDomicilio == false && this.retiroSucursal == false){
        this.metodoEntregaSeleccionado = false
      }
    },
    respStartBuy: function(){
      this.toTop()
      this.dialogCarga = false
      if (Object.keys(this.respStartBuy).length > 0){
        if (parseInt(this.respStartBuy.resultado) === 1){
          //this.provincias = this.respStartBuy.provincias.provincias
          this.generos = this.respStartBuy.generos
          this.costosEnvio = this.respStartBuy.costosEnvio
          this.verificarSesionActiva(1)
        }else{
          // no permitir seguir con la compra?

        }
      }
    },
    respProvincias: function(){
      this.closeLoading()
      if (Object.keys(this.respProvincias).length > 0){
        if (parseInt(this.respProvincias.resultado) === 1){
          this.provincias = this.respProvincias.provincias.provincias
          if (this.llamarEnvioDomicilio == true){
            this.llamarEnvioDomicilio = false
            this.enviarADomicilio()
          }
        }else{
          console.log(this.respProvincias.msj)
        }
      }
    },
    respDatosEntregaCliente: function(){
      this.closeLoading()
      if (Object.keys(this.respDatosEntregaCliente).length > 0){
        if (parseInt(this.respDatosEntregaCliente.resultado) === 1){
          // encontre la provincia del codigo postal, es por domicilio de entrega o por domicilio del cliente?
          if (parseInt(this.respDatosEntregaCliente.opcion) === 1){
            // es para entrega
            this.codigoPostalEntrega = this.respDatosEntregaCliente.datos.provincias[0].codigo_postal
            this.selectedProvinciaEntrega = this.respDatosEntregaCliente.datos.provincias[0]
          }else{
            // es para facturacion
            this.codigoPostalFacturacion = this.respDatosEntregaCliente.datos.provincias[0].codigo_postal
            this.selectedProvinciaFacturacion = this.respDatosEntregaCliente.datos.provincias[0]
          }
        }else{
          // error al encontrar la provincia del codigo postal
          if (parseInt(this.respDatosEntregaCliente.opcion) === 1){
            // es para entrega
            this.codigoPostalEntrega = ''
            this.selectedProvinciaEntrega = {}
          }else{
            // es para facturacion
            this.codigoPostalFacturacion = ''
            this.selectedProvinciaFacturacion = {}
          }
        }
      }
    },
    respSucursales: function(){
      this.closeLoading()
      if (Object.keys(this.respSucursales).length > 0){
        if (parseInt(this.respSucursales.resultado) === 1){
          this.sucursalesRetiro = this.respSucursales.sucursales
          if (Object.keys(this.selectedProvinciaRetiro).length > 0){
            let unListadoLocales = this.sucursalesRetiro.filter(element => 
              element.provincia.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == this.selectedProvinciaRetiro.provincia_nombre.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            )
            if (unListadoLocales.length > 0){
              this.localesRetiro = Object.values(unListadoLocales[0].locales).filter(element => element.estado == undefined || element.estado == null || element.estado == 1)
            }else{
              this.localesRetiro = []
            }
          }
        }
      }
    },
    selectedLocalRetiro: function(){
      if (this.selectedLocalRetiro == null || this.selectedLocalRetiro == undefined){
        this.selectedLocalRetiro = {}
      }
      if (Object.keys(this.selectedLocalRetiro).length > 0){
        if (Object.keys(this.selectedLocalRetiro).length > 0 && Object.keys(this.selectedProvinciaRetiro).length > 0){
          this.confirmarPreFin2 = true
        }else{
          this.confirmarPreFin2 = false
        }
      }
      // es entrega rapida? para ello, controlar si la totalidad de la mercaderia esta en el local seleccionado
      if (Object.keys(this.selectedProvinciaRetiro).length > 0 && Object.keys(this.selectedLocalRetiro).length > 0){
        let cont = 0
        // recorro el carrito
        for (let id in this.carritoCliente){
          // saco las sucursales (o sea, provincias) que coincidan con la provincia seleccionada del cliente
          let unlistadoProvincias = this.carritoCliente[id].stock.stockSucursales.filter(element => 
            element.sucursal_nombre.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == this.selectedProvinciaRetiro.provincia_nombre.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          )
          if (unlistadoProvincias.length === 1){
            // busco a ver si tengo el local seleccionado por el cliente
            let unLocal = unlistadoProvincias[0].stockLocales.filter(element => 
              parseInt(element.deposito_codigo) === parseInt(this.selectedLocalRetiro.deposito_codigo)
            )
            // obtuve resultado?
            if (unLocal.length === 1){
              // pregunto si tengo stock para la cantidad demandada del cliente
              if (parseInt(unLocal.cantidad) >= parseInt(this.carritoCliente[id].cantidad)){
                cont = cont + 1
              }
            }
          }
        }
        if (this.carritoCliente.length === parseInt(cont)){
          this.entregaRapida = true
        }else{
          this.entregaRapida = false
        }
      }
    },
    selectedProvinciaRetiro: function(){
      this.stockDeArticulos = []
      if (this.selectedProvinciaRetiro == null || this.selectedProvinciaRetiro == undefined){
        this.selectedProvinciaRetiro = {}
      }
      if (Object.keys(this.selectedProvinciaRetiro).length > 0){
        // obtengo los depositos de donde voy a sacar la mercaderia
        for (let id in this.carritoCliente){
          let stockEncontrado = false
          // empiezo buscando a ver si estan en alguna central
          let unaProvincia = this.carritoCliente[id].stock.stockSucursales.filter(
            element => parseInt(element.cantidad) >= parseInt(this.carritoCliente[id].cantidad) && parseInt(element.sucursal_codigo) == parseInt(this.selectedProvinciaRetiro.sucursal_codigo)
          )
          if (unaProvincia.length > 0){
            let unArticulo = {
              keyFirebase: this.carritoCliente[id].articuloKey,
              stock: unaProvincia[0]
            }
            this.stockDeArticulos.push(unArticulo)
          }else{
            // no esta en una central, busco a ver en que locales esta, pero de esa provincia
            let otraProvincia = this.carritoCliente[id].stock.stockSucursales.filter(
              element => parseInt(element.sucursal_codigo) == parseInt(this.selectedProvinciaRetiro.sucursal_codigo)
            )
            if (otraProvincia.length > 0){
              let jd = 0
              while ((parseInt(jd) <= otraProvincia.length - 1 ) && stockEncontrado == false){
                if (otraProvincia[jd].hasOwnProperty('stockLocales')){
                  let i = 0
                  while((parseInt(i) <= otraProvincia[jd].stockLocales.length - 1) && stockEncontrado == false){
                    if (parseInt(otraProvincia[jd].stockLocales[i].cantidad) >= parseInt(this.carritoCliente[id].cantidad)){
                      stockEncontrado = true
                      let unArticulo = {
                        keyFirebase: this.carritoCliente[id].articuloKey,
                        stock: otraProvincia[jd].stockLocales[i]
                      }
                      this.stockDeArticulos.push(unArticulo)
                    }
                    i = i + 1
                  }
                }
                jd = jd + 1
              }
            }
          }
        }
      }
    },
    respStatusUser: function(){
      if (Object.keys(this.respStatusUser).length > 0){
        this.closeLoading()
        // solo me interesan las peticiones que hago desde este componente
        if (parseInt(this.respStatusUser.opcion) === 4){
          if (parseInt(this.respStatusUser.resultado) === 1){
            // capturo el Uid del cliente
            if (this.respStatusUser.hasOwnProperty('datosUsuario')){
              if (this.respStatusUser.datosUsuario != null && this.respStatusUser.datosUsuario != undefined){
                if (this.respStatusUser.datosUsuario.hasOwnProperty('usuarioUid') && this.respStatusUser.datosUsuario.usuarioUid.length > 0){
                  this.uidCliente = this.respStatusUser.datosUsuario.usuarioUid
                }
              }
            }
            // prosigo
            if (this.checkUserInicio == true && this.respStatusUser.existeUsuario == true){
              this.checkUserInicio = false
              // completo los datos del usuario
              this.iniciarCliente()
            }else{
              this.checkUserInicio = false
              // entraron a modificar la direccion de entrega, es con sesion activa?
              if (this.respStatusUser.existeUsuario == true){
                // son sus datos, permitir modificar, en que paso estoy?
                if (parseInt(this.respStatusUser.paso) === 1){
                  // habilito para modificar datos personales
                  this.modificaDatosPersonales = true
                  this.pasoActual = 1
                  this.paso1Correcto = false
                }else if (parseInt(this.respStatusUser.paso) === 2){
                  this.preFinPaso2 = false
                  this.confirmarPreFin2 = true
                  this.pasoActual = 2
                }else{

                }
              }else{
                // si fue autocompletado por usuario existente, solicitar inicio de sesion
                if (this.existsUserSystem == true){
                  // cual paso quiere modificar?
                  if (parseInt(this.respStatusUser.paso) === 1){
                    // que inicie sesion si quiere modificar sus datos personales
                    this.msjErrorPaso1 = 'Para realizar esta accion debe Iniciar Sesión en la sección "Mi Cuenta"'
                    this.errorPaso1 = true
                    // abro el modal de registracion
                    this.showLoginModal = true
                    // asigno el texto del login
                    this.msjInicioSesion = 'Para realizar esta acción debe Iniciar Sesión.'
                  }else if (parseInt(this.respStatusUser.paso) === 2){
                    // lo que quiere modificar es el metodo de entrega o los datos de entrega a domicilio?
                    if (this.modificaDomicilioEntrega == true && this.envioDomicilio == true){
                      this.msjErrorPaso2 = 'Para realizar esta accion debe Iniciar Sesión en la sección "Mi Cuenta"'
                      this.errorPaso2 = true
                      // abro el modal de registracion
                      this.showLoginModal = true
                      // asigno el texto del login
                      this.msjInicioSesion = 'Para realizar esta acción debe Iniciar Sesión.'
                    }else{
                      this.preFinPaso2 = false
                      this.confirmarPreFin2 = true
                      this.pasoActual = 2
                      this.metodoEntregaSeleccionado = false
                      this.envioDomicilio = false
                      this.retiroSucursal = false
                      this.errorPaso2 = false
                    }
                  }else{

                  }
                }else{
                  // cliente sin cuenta en nuestro sistema
                  if (parseInt(this.pasoActual) === 3){
                    this.preFinPaso2 = false
                    this.confirmarPreFin2 = true
                    this.pasoActual = 2
                  }
                }
              }
            }
          }else{
            console.log(this.respStatusUser.msj)
          }
        }
      }
    },
    selectedProvinciaEntrega: function(){
      if (Object.keys(this.selectedProvinciaEntrega).length > 0){
        this.stockDeArticulos = []
        // calculo el tiempo de envio
        let unaProvincia = this.costosEnvio.filter(element => element.provincia.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == this.selectedProvinciaEntrega.provincia_nombre.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
        if (unaProvincia.length > 0){
          this.horasHabiles = unaProvincia[0].entrega_rapida_hs
          this.costoEnvio = parseFloat(unaProvincia[0].precio).toFixed(2)
          let unaCadena = 'Envio a Domicilio - $' + parseInt(this.costoEnvio)
          this.tituloEnvioDomicilio = unaCadena
        }else{
          // no encontre la provincia para enviar el pedido, que pase a completar
          this.horasHabiles = ''
          this.tituloEnvioDomicilio = 'Envio a Domicilio'
          this.metodoEntregaSeleccionado = false
          this.preFinPaso2 = false
        }
        // obtengo de que bodegas debo buscar el stock
        for (let id in this.carritoCliente){
          let stockEncontrado = false
          // empiezo buscando a ver si estan en alguna central
          let unaProvincia = this.carritoCliente[id].stock.stockSucursales.filter(
            element => parseInt(element.cantidad) >= parseInt(this.carritoCliente[id].cantidad)
          )
          if (unaProvincia.length > 0){
            let unArticulo = {
              keyFirebase: this.carritoCliente[id].articuloKey,
              stock: unaProvincia[0]
            }
            this.stockDeArticulos.push(unArticulo)
          }else{
            // no esta en una central, busco a ver en que locales esta
            let jd = 0
            while((parseInt(jd) <= this.carritoCliente[id].stock.stockSucursales.length - 1) && stockEncontrado == false){
              if (this.carritoCliente[id].stock.stockSucursales[jd].hasOwnProperty('stockLocales')){
                let i = 0
                while((parseInt(i) <= this.carritoCliente[id].stock.stockSucursales[jd].stockLocales.length - 1) && stockEncontrado == false){
                  if (parseInt(this.carritoCliente[id].stock.stockSucursales[jd].stockLocales[i].cantidad) >= parseInt(this.carritoCliente[id].cantidad)){
                    stockEncontrado = true
                    let unArticulo = {
                      keyFirebase: this.carritoCliente[id].articuloKey,
                      stock: this.carritoCliente[id].stock.stockSucursales[jd].stockLocales[i]
                    }
                    this.stockDeArticulos.push(unArticulo)
                  }
                  i = i + 1
                }
              }
              jd = jd + 1
            }
          }
        }
      }
    },
    generoFacturacion: function(){
      if (this.generoFacturacion == null || this.generoFacturacion == undefined){
        this.generoFacturacion = {}
      }
      if (Object.keys(this.generoFacturacion).length > 0){
        if (parseInt(this.generoFacturacion.key) === 2){
          this.defineGenero = true
          this.generoPersonalizadoFacturacion = ''
        }else{
          this.defineGenero = false
          this.generoPersonalizadoFacturacion = ''
        }
      }
    },
    selectedProvinciaFacturacion: function(){
      if (this.selectedProvinciaFacturacion == null || this.selectedProvinciaFacturacion == undefined){
        this.selectedProvinciaFacturacion = {}
      }
    },
    carritoCliente: function(){
      if (this.carritoCliente.length > 0){
        let cont = 0
        // el cliente puede llevar la cantidad que esta pidiendo? control de cantidad solicitada vs stock disponible
        for (let id in this.carritoCliente){
          if (parseInt(this.carritoCliente[id].stock.stockTotal) < parseInt(this.carritoCliente[id].cantidad)){
            cont = cont + 1
          }
        }
        // si no tengo stock redirecciono al carrito para el mismo cliente decida que comprar
        if (parseInt(cont) > 0 && this.controlStockInicio == true){
          // redireccionar
          router.push({name: 'CarritoDetalle'})
        }else{
          this.controlStockInicio = false
        }
      }
    }
  }
}
</script>

<style>
  .formatoPalabras{
    font-family: 'Poppins', sans-serif;
  }
</style>