<template>
  <div>
    <v-container>
      <v-card raised flat>
        <v-card-title>
          <v-toolbar color="#ed1841" dark height="40%">
            <v-toolbar-title style="font-size:20px">
              <v-icon>mdi-account-details</v-icon>
              Mis Datos
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <!-- Form Mis Datos  -->
        <v-card-text>
          <v-form ref="formMisDatos" v-model="valid" lazy-validation @submit.prevent="confirmarActualizacion">
            <v-row>
              <v-col  cols="12" sm="6" md="6" style="padding-bottom: 0px"><!-- Email -->
                <v-text-field v-model="usuario.usuarioEmail" label="Email" outlined dense readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" style="padding-bottom: 0px"><!-- N° Documento -->
                <v-text-field v-model="datos_personales.documento" type="number" label="N° Documento" placeholder="D.N.I." outlined dense clearable></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" style="padding-top: 0px; padding-bottom: 0px"><!-- Apellidos -->
                <v-text-field v-model="datos_personales.apellidos" label="Apellido/s"  outlined dense clearable></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" style="padding-top: 0px; padding-bottom: 0px"><!-- Nombres -->
                <v-text-field v-model="datos_personales.nombre" label="Nombre/s"  outlined dense clearable></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" style="padding-top: 0px; padding-bottom: 0px"><!-- Telefono -->
                <v-text-field v-model="datos_personales.telefono" label="Telefono"  outlined dense clearable></v-text-field>
              </v-col>
              <v-col cols="12" style="padding-top: 0px; padding-bottom: 0px" :sm="defineGenero == false ? 6 : 3" :md="defineGenero == false ? 6 : 3"><!-- Fecha Nacimiento -->
                <v-text-field v-model="datos_personales.fecha_nacimiento" label="Fecha Nacimiento" v-mask="'##/##/####'" outlined dense clearable @blur="controlFecha(datos_personales.fecha_nacimiento)"></v-text-field>
              </v-col>
              <v-col cols="12" style="padding-top: 0px; padding-bottom: 0px" :sm="defineGenero == false ? 6 : 3" :md="defineGenero == false ? 6 : 3">
                <v-select v-model="datos_personales.genero" :items="generos"  label="Genero" outlined dense clearable item-text="nombre" item-value="nombre" @change="verificarGenero(datos_personales.genero)"></v-select>
              </v-col>
              <v-col cols="12" style="padding-top: 0px; padding-bottom: 0px" sm="6" md="6" v-if="defineGenero == true">
                <v-text-field v-model="datos_personales.generoPersonalizado" label="Ingresa tu Género" outlined dense clearable></v-text-field>
              </v-col>
              <!-- Código Postal -->
              <v-col cols="12" md="3" style="padding-top: 0px; padding-bottom: 0px">
                <v-text-field
									v-model="codigo_postal"
									label="Código Postal"
									placeholder="Ingrese su CP"
									outlined dense
									@blur="getProvinciaCodigoPostal(2)"
                  height="10px"
								></v-text-field>
              </v-col>
              <v-col cols="12" md="3" style="padding-top: 0px; padding-bottom: 0px">
                <v-select
									v-model="selectedProvincia"
									:items="provincias"
									item-text="provincia_nombre"
									outlined dense
									label="Provincia"
									placeholder="Seleccione una Provincia"
                  return-object
								></v-select>
              </v-col>
              <v-col cols="12" md="6" style="padding-top: 0px; padding-bottom: 0px">
                <v-text-field
									v-model="localidad"
									label="Localidad"
									placeholder="Ingrese su Localidad"
									outlined dense
								></v-text-field>
              </v-col>
              <v-col cols="12" md="8" style="padding-top: 0px; padding-bottom: 0px">
                <v-text-field v-model="datos_personales.calle" label="Calle"  placeholder="Ingrese su Calle" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" md="4" style="padding-top: 0px; padding-bottom: 0px">
                <v-text-field v-model="datos_personales.calle_numero" label="Numero"  placeholder="Numero de Calle" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" md="12" style="padding-top: 0px; padding-bottom: 0px">
                <v-text-field v-model="datos_personales.datos_adicionales" label="Piso /  Departamento / Barrio" placeholder="Ingrese datos Adicionales" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" style="padding-top: 0px; padding-bottom: 0px">
                <v-row justify="center">
                  <v-btn color="blue" @click="buscarCodigoPostal" class="white--text ma-2">No sé mi Codigo Postal</v-btn>
                  <v-btn color="primary" type="submit" class="ma-2">Guardar Datos Personales</v-btn>
                  <v-btn color="primary" text v-if="opcion == 2" @click="volverAComprar" class="ma-2">Volver</v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
    <!-- Loading -->
    <Loading :activo="dialogCarga" />
    <!-- Loading -->
    <LoadingCircular 
      :active.sync="loadingCarga" 
      :can-cancel="true"   
      color="#ed1841"         
      oader="dots"
      background-color="#252527"
      :is-full-page="true">
    </LoadingCircular>
    <!-- Buscar Codigo Postal -->
    <buscarCP 
      :activo="dialogCP"
      @closeModalCP="cerrarModalCP"
      @setCodigoPostal="setearCodigoPostal"
    />
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import moment from 'moment'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import LoadingCircular from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Loading from '../genericos/Loading.vue'
import router from '../../router'
import buscarCP from '../genericos/buscarCodigoPostal.vue'
export default {
  props: {
    opcion: Number
  },
  data() {
    return {
      valid:true,
      Rules: [
        v => !!v || "Campo requerido.",
      ],
      //localidades:[],
      generos:[],
      //localidad:{},
      localidad: '',
      datos_personales:{},
      provincia:'',
      codigo_postal:'',
      dialogCarga: false,
      showCarrito: false,
      usuario: {},
      defineGenero: false,
      provincias: [],
      selectedProvincia: {},
      dialogCP: false,
      selectedLocalidad: {}
    }
  },
  components: {
    LoadingCircular,
    Loading,
    buscarCP
  },
  mounted () {
    this.iniciarCarrito()
    this.iniciarFormulario()
    this.obtenerProvincias()
  },
  computed: {
    ...mapState(["configuraciones", 'loadingCarga', 'datosUsuario', 'articulosCarrito', 'myData', 
                'respUpdateMydata', 'respProvincias', 'respDatosEntregaCliente', 'respActUserMisDatos'])
  },
  methods: {
    ...mapActions(['loadCarrito', 'startMyData', 'logout', 'updateMydata', 'getProvincias',
                  'getDatosEntregaXCodPostal', 'showLoading', 'closeLoading', 'activeUserMisDatos']),
    async iniciarCarrito(){
      await this.loadCarrito()
    },
    mostrarCarrito(){
      this.showCarrito = true
    },
    ocultarCarrito(){
      this.showCarrito = false
    },
    async iniciarFormulario(){
      this.dialogCarga = true
      // tengo los datos del usuario con sesion activa?
      await this.activeUserMisDatos({paso: 1, opcion: 3})
      /*if (localStorage.getItem('datosUsuario')){
        this.usuario = JSON.parse(localStorage.getItem('datosUsuario'))
        await this.startMyData(this.usuario)
      }else{
        // no tengo datos de usuario, redirecciono al inicio
        if (this.$route.name.toUpperCase() === 'MISDATOS'){
          router.push({name: 'Home'})
        }
      }*/
    },
    async obtenerProvincias(){
      await this.getProvincias()
    },
    controlFecha(fecha){
      var fech = moment(fecha, 'DD/MM/YYYY' )
      if (!moment(fech).isValid() ){
        this.datos_personales.fecha_nacimiento = ''
        Swal.fire({title: 'Fecha inválida. Ejemplo de fecha válida: 01/02/1988.',icon: 'warning'})
        return
      }else{
        let añoActual = moment().year()
        let añoUsuario = fech.year()
        if (parseInt(añoUsuario) > parseInt(añoActual)){
          Swal.fire({title: 'El año ingresado no debe sobrepasar el año actual.',icon: 'warning'})
          this.datos_personales.fecha_nacimiento = ''
        }else{
          if ((parseInt(añoActual) - parseInt(añoUsuario)) < 12){
            Swal.fire({title: 'Debe ser mayor de 12 años para poder operar',icon: 'warning'})
            this.datos_personales.fecha_nacimiento = ''
          }else{
            this.datos_personales.fecha_nacimiento = fech.format('DD/MM/YYYY')
          }
        }
      }
    },
    confirmarActualizacion(){
      if (this.verificarObligatorios()){
        /*Swal.fire({title: 'Esta seguro de actualizar sus datos?',
                  icon: 'question',
                  confirmButtonText: 'Aceptar'})
          .then((result) => {
            if (result.value) {
              this.updateData()
            }else{
              Swal.close()
            }
          })*/
        this.updateData()
      }else{
        Swal.fire({
          icon: 'info',
          title: 'Actualización de Perfil',
          text: 'Debe ingresar todos los datos necesarios para la actualización del Perfil',
          showConfirmButton: true,
          timer: 3000
        })
      }
    },
    verificarObligatorios(){
      if (this.usuario.hasOwnProperty('usuarioEmail')){
        if (this.usuario.usuarioEmail.length == 0){
          return false
        }
      }else{
        return false
      }
      if (this.datos_personales.hasOwnProperty('documento')){
        if (this.datos_personales.documento.length == 0){
          return false
        }
      }else{
        return false
      }
      if (this.datos_personales.hasOwnProperty('apellidos')){
        if (this.datos_personales.apellidos.length == 0){
          return false
        }
      }else{
        return false
      }
      if (this.datos_personales.hasOwnProperty('nombre')){
        if (this.datos_personales.nombre.length == 0){
          return false
        }
      }else{
        return false
      }
      if (this.datos_personales.hasOwnProperty('telefono')){
        if (this.datos_personales.telefono.length == 0){
          return false
        }
      }else{
        return false
      }
      if (this.datos_personales.hasOwnProperty('fecha_nacimiento')){
        if (this.datos_personales.fecha_nacimiento.length == 0){
          return false
        }
      }else{
        return false
      }
      if (this.datos_personales.hasOwnProperty('genero')){
        if (this.datos_personales.genero.length == 0){
          return false
        }
      }else{
        return false
      }
      if (this.codigo_postal.length == 0){
        return false
      }
      if (Object.keys(this.selectedProvincia).length == 0){
        return false
      }
      if (this.localidad.length == 0){
        return false
      }
      if (this.datos_personales.hasOwnProperty('calle')){
        if (this.datos_personales.calle.length == 0){
          return false
        }
      }else{
        return false
      }
      if (this.datos_personales.hasOwnProperty('calle_numero')){
        if (this.datos_personales.calle_numero.length == 0){
          return false
        }
      }else{
        return false
      }
      if (this.datos_personales.hasOwnProperty('datos_adicionales')){
        if (this.datos_personales.datos_adicionales.length == 0){
          return false
        }
      }else{
        return false
      }
      return true
    },
    async updateData(){
      // guardo el Uid del Usuario
        this.datos_personales.usuarioUid = this.usuario.usuarioUid
        // refresco los datos a actualizar
        if (this.datos_personales.hasOwnProperty('localidad')){
          // agrego lo que el usuario ingreso siempre y cuando el codigo postal sea diferente del que vino de firebase
          if (parseInt(this.datos_personales.localidad.codigo_postal) !== parseInt(this.codigo_postal)){
            let localidad = {
              codigo_postal: this.codigo_postal,
              localidad: this.localidad,
              pais: 'Argentina',
              provincia: this.selectedProvincia.provincia_nombre
            }
            this.datos_personales.localidad = localidad
          }
        }else{
          // defino los datos de la localidad
          let localidad = {
            codigo_postal: this.codigo_postal,
            localidad: this.localidad,
            pais: 'Argentina',
            provincia: this.selectedProvincia.provincia_nombre
          }
          this.datos_personales.localidad = localidad
        }
        // codigo postal por fuera de localidades
        this.datos_personales.codigo_postal = this.codigo_postal
        this.datos_personales.provincia = this.selectedProvincia.provincia_nombre
        // envio a actualizar
        await this.updateMydata(this.datos_personales)
    },
    verificarGenero(generoCliente){
      if (generoCliente.toUpperCase() === 'PERSONALIZADO'){
        this.defineGenero = true
        this.datos_personales.generoPersonalizado = ''
      }else{
        this.datos_personales.generoPersonalizado = ''
        this.defineGenero = false
      }
    },
    async getProvinciaCodigoPostal(opcion){
      if (this.codigo_postal.length > 0){
        this.selectedLocalidad = {}
        this.showLoading()
        await this.getDatosEntregaXCodPostal({codigoPostal: this.codigo_postal, opcion: opcion})
      }
    },
    buscarCodigoPostal(){
      this.dialogCP = true
    },
    cerrarModalCP(){
      this.dialogCP = false
    },
    setearCodigoPostal(datos){
      this.selectedProvincia = datos.provincia
      this.codigo_postal = datos.localidad.codigo_postal
      this.selectedLocalidad = datos.localidad
      this.localidad = datos.localidad.localidad_nombre
    },
    volverAComprar(){
      location.reload()
    }
  },
  watch: {
    articulosCarrito: function(){
      
    },
    datosUsuario: function(){
      if (Object.keys(this.datosUsuario).length > 0){
        if (this.datosUsuario.hasOwnProperty('resultado')){
          if (parseInt(this.datosUsuario.resultado) === 1){
            // es nuevo registro o login normal?
            if (parseInt(this.datosUsuario.tipo) === 1){
              // login normal
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Inicio de Sesión',
                text: 'Su Inicio de Sesión fue exitoso, espere a ser redireccionado',
                showConfirmButton: false,
                timer: 3000
              })
            }else if (parseInt(this.datosUsuario.tipo) === 2){
              // registro
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Nuevo Registro',
                text: 'Su registro fue exitoso, espere a ser redireccionado',
                showConfirmButton: false,
                timer: 3000
              })
            }else{
              // hizo logout
              setTimeout(() => [location.reload()], 2000)
            }
          }else{
            if (parseInt(this.datosUsuario.tipo) === 1){
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Inicio de Sesión',
                text: this.datosUsuario.msj,
                showConfirmButton: false,
                timer: 3000
              })
            }else if (parseInt(this.datosUsuario.tipo) === 2){
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Nuevo Registro',
                text: this.datosUsuario.msj,
                showConfirmButton: false,
                timer: 3000
              })
            }else{
              // hizo logout
              setTimeout(() => [location.reload()], 2000)
            }
          }
        }
      }
    },
    myData: async function(){
      this.dialogCarga = false
      if (Object.keys(this.myData).length > 0){
        if (parseInt(this.myData.resultado) === 1){
          this.datos_personales = this.myData.datosPersonales
          this.generos = this.myData.generos
          if (this.datos_personales.hasOwnProperty('generoPersonalizado')){
            if (this.datos_personales.genero.toUpperCase() === 'PERSONALIZADO'){
              this.defineGenero = true
            }
          }
        }else{
          // error al obtener los datos del usuario
          if (this.$route.name.toUpperCase() === 'MISDATOS'){
            await this.logout()
            router.push({name: 'Home'})
          }
        }
      }
    },
    datos_personales:function(){
      if(Object.keys(this.datos_personales).length > 0 ){
        if (this.datos_personales.hasOwnProperty('localidad')){
          // es un objeto?
          if (typeof this.datos_personales.localidad === 'object' &&
            !Array.isArray(this.datos_personales.localidad) && 
            this.datos_personales.localidad !== null){
              this.codigo_postal = this.datos_personales.localidad.codigo_postal
              let provinciaCliente = this.provincias.filter(element => element.provincia_nombre.replace(/[^A-Z0-9]+/ig, "_") == this.datos_personales.localidad.provincia.replace(/[^A-Z0-9]+/ig, "_"))
              if (provinciaCliente.length == 1){
                this.selectedProvincia = provinciaCliente[0]
              }
              this.localidad = this.datos_personales.localidad.localidad
            }
        }
        /*if(Object.keys(this.datos_personales.localidad).length > 0){
          this.localidad = this.datos_personales.localidad
          this.provincia  = this.datos_personales.provincia
          this.codigo_postal =  this.datos_personales.codigo_postal
        }*/
      }
    },
    respUpdateMydata: function(){
      if (Object.keys(this.respUpdateMydata).length > 0){
        if (parseInt(this.respUpdateMydata.resultado) === 1){
          Swal.fire({
            icon: 'success',
            title: 'Actualización de Perfil',
            text: 'Sus Datos fueron Actualizados correctamente',
            showConfirmButton: true,
            timer: 3000
          })
          // guardo los datos en localStorage
          let datosEntrega = {}
          if (Object.keys(this.selectedLocalidad).length > 0){
            datosEntrega.provinciaId = this.selectedProvincia.provincia_id
            datosEntrega.provinciaNombre = this.selectedProvincia.provincia_nombre
            datosEntrega.retiroSucursal = this.selectedProvincia.retiro_sucursal
            datosEntrega.sucursalCodigo = this.selectedProvincia.sucursal_codigo
            datosEntrega.codigoPostal = this.selectedLocalidad.codigo_postal
            datosEntrega.entregaDomicilio = this.selectedLocalidad.entrega_domicilio
            datosEntrega.localidadId = this.selectedLocalidad.localidad_id
            datosEntrega.localidadNombre = this.selectedLocalidad.localidad_nombre
          }else{
            datosEntrega.provinciaId = this.selectedProvincia.provincia_id
            datosEntrega.provinciaNombre = this.selectedProvincia.provincia_nombre
            datosEntrega.retiroSucursal = this.selectedProvincia.retiro_sucursal
            datosEntrega.sucursalCodigo = this.selectedProvincia.sucursal_codigo
            datosEntrega.codigoPostal = this.codigo_postal
            datosEntrega.entregaDomicilio = ''
            datosEntrega.localidadId = ''
            datosEntrega.localidadNombre = ''
          }
          // seteo los datos de entrega
          if (localStorage.getItem('enviarA')){
            localStorage.removeItem('enviarA')
          }
          localStorage.setItem('enviarA', JSON.stringify(datosEntrega))
          // actualizo los datos del cliente
          /*if (localStorage.getItem('datosUsuario')){
            let datosClientes = JSON.parse(localStorage.getItem('datosUsuario'))
            datosClientes.datosCliente = this.datos_personales
            localStorage.removeItem('datosUsuario')
            localStorage.setItem('datosUsuario', JSON.stringify(datosClientes))
          }*/
          this.$emit('setDatosEntrega')
          if (parseInt(this.opcion) === 2){
            this.$emit('refreshPage')
          }
        }else{
          Swal.fire({
            icon: 'info',
            title: 'Actualización de Perfil',
            text: this.respUpdateMydata.msj,
            showConfirmButton: true,
            timer: 3000
          })
        }
      }
    },
    respProvincias: function(){
      if (Object.keys(this.respProvincias).length > 0){
        if (parseInt(this.respProvincias.resultado) === 1){
          this.provincias = this.respProvincias.provincias.provincias
        }else{
          console.log(this.respProvincias.msj)
          this.selectedProvincia = {}
          this.provincias = []
        }
      }
    },
    respDatosEntregaCliente: function(){
      this.closeLoading()
      if (Object.keys(this.respDatosEntregaCliente).length > 0){
        if (parseInt(this.respDatosEntregaCliente.resultado) === 1){
          if (this.respDatosEntregaCliente.datos.provincias.length == 1){
            let provinciasFiltradas = this.provincias.filter(element => element.provincia_id == this.respDatosEntregaCliente.datos.provincias[0].provincia_id)
            if (provinciasFiltradas.length > 0){
              if (provinciasFiltradas.length === 1){
                this.selectedProvincia = provinciasFiltradas[0]
              }else{
                this.selectedProvincia = {}
                this.localidad = ''
              }
            }else{
              this.selectedProvincia = {}
              this.localidad = ''
            }
          }else{
            this.selectedProvincia = {}
            this.localidad = ''
          }
        }else{
          this.selectedProvincia = {}
          this.localidad = ''
        }
      }
    },
    respActUserMisDatos: async function(){
      if (Object.keys(this.respActUserMisDatos).length > 0){
        if (parseInt(this.respActUserMisDatos.opcion) === 3){
          if (parseInt(this.respActUserMisDatos.resultado) === 1){
            if (this.respActUserMisDatos.existeUsuario == true){
              this.usuario = this.respActUserMisDatos.datosUsuario
              await this.startMyData(this.usuario)
            }else{
              // no hay usuario activo con sesion, cierro por si acaso y redirecciono al inicio
              if (this.$route.name.toUpperCase() === 'MISDATOS'){
                await this.logout()
                router.push({name: 'Home'})
              }
            }
          }else{
            // error con el status, redireccionar al inicio y cerrar sesion
            if (this.$route.name.toUpperCase() === 'MISDATOS'){
              await this.logout()
              router.push({name: 'Home'})
            }
          }
        }
      }
    },
    selectedProvincia: function(){
      if (this.selectedProvincia == null || this.selectedProvincia == undefined){
        this.selectedProvincia = {}
      }
    }
  },
}
</script>

<style>

</style>